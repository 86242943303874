import axios from "axios";

export const switchCourseState = (courseId, data, callback) => {
  var promise = axios({
    method: "patch",
    url: `/api/courses/${courseId}/switch_state`,
    data: data,
    headers: {
      "X-CSRF-TOKEN": window.SomosEmpleos.token
    }
  });
  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};
