import React, { useState, useRef } from "react";
import {
  Row,
  Col,
  TabContent,
  TabPane,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  Badge
} from "reactstrap";
import CoursePostulation from "../course/Postulation";
import classnames from "classnames";
import ApplicantUserProfile from "./UserProfile";
import ApplicantFunctionalProfile from "./FunctionalProfile";
import MatchDetail from "./MatchDetail";
import Notifications from "../commons/Notifications";
import DiversityFlagIndicator from '../../../assets/images/lgbtq.png';
import FemaleIndicator from '../../../assets/images/female.png';
import AgeIndicator from '../../../assets/images/user_age.png';

const ApplicantShow = ({ applicant, isAdmin, course }) => {
  const notifications = useRef(null);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("1");

  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const showNotification = (title, message, type) => {
    notifications.current.addNotifications(title, message, type);
  };

  const avatar =
    applicant.user.avatar ||
    "/assets/User_Avatar_2-91bc95a48d1c6e787c0f24accbce21ae27acebed9a065689cd441caa3ceaf43d.png";

  const drawDiversityIndicator = () => {
    if (applicant && (applicant.user.personal_information.identify_lgtbi === "true" || applicant.user.personal_information.identify_lgtbi === true)) {
      return(
        <Badge color="light" className="badge-user-indicator mb-1 border">
          <img src={DiversityFlagIndicator}
               className="img-indicator mr-1"
               style={{ 'width': '18px' }}
               width="18"
          />
          LGTBI+
        </Badge>
      )
    }
  }

  const drawFemaleIndicator = () => {
    if (applicant && applicant.user.personal_information && applicant.user.personal_information.gender === "female") {
      return(
        <Badge color="light" className="badge-user-indicator mb-1 border">
          <img src={FemaleIndicator}
               className="img-indicator mr-1"
               style={{ 'width': '18px' }}
               width="18"
          />
          +Equidad
        </Badge>
      )
    }
  }

  return (
    <Row
      className={`profile-full ${
        applicant.user.disability_information.has_disability ? "has_disability" : ""
      }`}
    >
      <Notifications ref={notifications} />
      <Col lg="12">
        <Card className="mb-4 profile-full-card">
          <CardBody>
            <Row>
              <Col lg="6">
                <div className="media flex-wrap applicant-data align-items-lg-center">
                  {/*
                    <div className="d-block relative-item">
                      <div className="avatar-icon mr-2 mb-2 d-block d-flex align-items-center justify-content-center rounded-circle"
                        style={{ backgroundImage: "url(" + avatar + ")" }}
                      >
                      </div>
                      <div className="star-indicator rating">
                        <label className="rating-star active" htmlFor="star-rating">
                          <input
                            className="rating-star-input"
                            type="checkbox"
                            name="rating-input"
                            id="star-rating"
                          />
                          <i className="fa fa-star" aria-hidden="true"></i>
                        </label>
                      </div>
                    </div>
                  */}
                  <div className="media-body">
                    <h2 className="h4 mb-2 font-weight-bold __force-text_break-word">
                      {/*applicant.user.personal_information.social_name &&
                        applicant.user.personal_information.social_name
                      }{" "}
                      {applicant.user.personal_information.first_name}{" "}
                      {applicant.user.personal_information.last_name*/}
                      {applicant.user.personal_information.social_name === "" || applicant.user.personal_information.social_name === undefined ?
                        `${applicant.user.personal_information.first_name} ${applicant.user.personal_information.last_name}`
                        : `${applicant.user.personal_information.social_name} ${applicant.user.personal_information.last_name}`
                      }&nbsp;
                      {/* <Badge color="primary" className="badge-user-indicator">
                        Contactado
                      </Badge> */}
                      <div className="d-block mt-2 d-flex align-items-stretch flex-wrap">
                        {drawDiversityIndicator()}
                        {drawFemaleIndicator()}
                        {applicant && applicant.user.personal_information.age >= 60 && (
                          <Badge color="light" className="badge-user-indicator mb-1 border">
                            <img src={AgeIndicator}
                                 className="img-indicator mr-1"
                                 style={{ 'width': '18px' }}
                                 width="18"
                            />
                            {applicant.user.personal_information.age} años
                          </Badge>
                        )}
                        {applicant && applicant.user.disability_information.has_disability && (
                          <Badge color="info" className="badge-user-indicator badge-soft-primary mb-1">
                            <i className="fas fa-universal-access mr-1 fa-lg text-primary"></i>
                            Situación de Discapacidad
                          </Badge>
                        )}
                        {applicant &&
                          applicant.user.disability_information.has_disability_credential && (
                            <Badge color="info" className="badge-user-indicator badge-soft-primary mb-1">
                              Credencial
                            </Badge>
                          )}
                        {applicant && applicant.user.disability_information.has_invalidity_pension && (
                          <Badge color="info" className="badge-user-indicator badge-soft-primary mb-1">
                            Pensión
                          </Badge>
                        )}
                      </div>
                    </h2>
                    {/* <div className="text-left">
                      <Button size="sm" color="primary" className="m-md-3 m-lg-0 mb-lg-2 mr-lg-2">
                        Descargar CV
                      </Button>
                      <Button size="sm" color="secondary" className="m-md-3 m-lg-0 mb-lg-2">
                        Descargar Perfil Funcional
                      </Button>
                    </div> */}
                  </div>
                </div>
              </Col>
              <Col lg="6">
                <Nav pills className="nav-justified navigation-icons-tabs h-100 align-items-center">
                  <NavItem className="text-center small">
                    <NavLink
                      className={"p-2 " + classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        toggleTab("1");
                      }}
                    >
                      <i aria-hidden="true" className="fas fa-user d-block fa-3x mb-2"></i>
                      Perfil Usuario
                    </NavLink>
                  </NavItem>
                  <NavItem className="text-center small">
                    <NavLink
                      className={"p-2 " + classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        toggleTab("2");
                      }}
                    >
                      <div
                        className={` ${
                          applicant.user.disability_information.has_disability
                            ? "spark_indicator"
                            : ""
                        }`}
                      >
                        <i
                          aria-hidden="true"
                          className="fas fa-universal-access d-block fa-3x mb-2"
                        ></i>
                      </div>
                      Perfil Funcional
                    </NavLink>
                  </NavItem>
                  {/* <NavItem className="text-center small">
                    <NavLink
                      className={classnames({ active: activeTab === "3" })}
                      onClick={() => {
                        toggleTab("3");
                      }}
                    >
                      <i className="fas fa-suitcase d-block fa-3x mb-2"></i>
                      Postulaciones Asociadas
                    </NavLink>
                  </NavItem> */}
                  {(course.id && (
                    <NavItem className="text-center small">
                      <NavLink
                        className={"p-2 " + classnames({ active: activeTab === "4" })}
                        onClick={() => {
                          toggleTab("4");
                        }}
                      >
                        <div>
                          <i
                            aria-hidden="true"
                            className="fas fa-tasks d-block fa-3x mb-2"
                          ></i>
                        </div>
                        MATCH details
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <Col lg="12">
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <ApplicantUserProfile
              applicant={applicant}
              showNotification={showNotification}
              isAdmin={isAdmin}
            />
          </TabPane>
          <TabPane tabId="2">
            <ApplicantFunctionalProfile
              functionalAnswers={applicant.user.functional_answers}
              accessibilityAnswers={applicant.user.accessibility_answers}
              course={course}
            />
          </TabPane>
          <TabPane tabId="3">
            <CoursePostulation />
          </TabPane>
          <TabPane tabId="4">

          {(course.id && (
            <MatchDetail
              applicant={applicant}
              course={course}
            />
          ))}
          </TabPane>
        </TabContent>
      </Col>
    </Row>
  );
};

export default ApplicantShow;
