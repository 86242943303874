import React, { useState, useEffect } from "react";
import Select from "react-select";
import { getTerritoryNationalities } from "./Axios";

const NationalitiesSelect = props => {
  const [nationalities, setNationalities] = useState([]);

  const handleChange = inputValue => {
    props.handleChange(inputValue, "nationality");
  };

  useEffect(() => {
    var newNationalities = nationalities;
    getTerritoryNationalities(response => {
      response.data.map(nationality => {
        newNationalities.push({ value: nationality.id, label: nationality.name });
      });
    });
    setNationalities(newNationalities);
  }, []);

  return (
    <Select
      inputId="nationality"
      onChange={handleChange}
      options={nationalities}
      placeholder="Seleccione una opción"
      value={props.value.value ? props.value : null}
    />
  );
};

export default NationalitiesSelect;
