import axios from "axios";

export const confirmPurchase = (data, callback) => {
  var promise = axios({
    method: "post",
    url: "/api/company_purchases",
    data: data,
    headers: {
      "X-CSRF-TOKEN": window.SomosEmpleos.token
    }
  });
  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};

export const createPayment = (purchaseId, callback) => {
  var promise = axios({
    method: "post",
    url: `/api/company_purchases/${purchaseId}/create_payment`,
    headers: {
      "X-CSRF-TOKEN": window.SomosEmpleos.token
    }
  });
  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};
