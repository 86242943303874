import React, { Fragment } from "react";
import { Label, Col, FormGroup } from "reactstrap";
import CountriesSelect from "../countries/Select";
import RegionsSelect from "../regions/Select";
import CommunesSelect from "../communes/Select";
import FieldWrapper from "../../commons/FieldWrapper";

const TerritoryNestedSelects = props => {
  const { countryValue, regionValue, communeValue, col, uniqueId, handleChange } = props;

  const clean_value = { value: "", label: "" };

  const handleChangeCountry = event => {
    handleChange(event, "country");
    handleChange(clean_value, "region");
    handleChange(clean_value, "commune");
  };

  const handleChangeRegion = event => {
    handleChange(event, "region");
    handleChange(clean_value, "commune");
  };

  const handleChangeCommune = event => {
    handleChange(event, "commune");
  };

  return (
    <Fragment>
      <Col md={col || "3"}>
        <FormGroup>
          <Label htmlFor="country" className="input_required">
            País
          </Label>
          <FieldWrapper errors={communeValue.errors}>
            <CountriesSelect
              handleChange={handleChangeCountry}
              value={countryValue}
              uniqueId="country"
            />
          </FieldWrapper>
        </FormGroup>
      </Col>
      <Col md={col || "3"}>
        <FormGroup>
          <Label htmlFor="region" className="input_required">
            Región
          </Label>
          <FieldWrapper errors={communeValue.errors}>
            <RegionsSelect
              handleChange={handleChangeRegion}
              value={regionValue}
              countryId={countryValue.value}
              uniqueId="region"
            />
          </FieldWrapper>
        </FormGroup>
      </Col>
      <Col md={col || "3"}>
        <FormGroup>
          <Label htmlFor="commune" className="input_required">
            Comuna
          </Label>
          <FieldWrapper errors={communeValue.errors}>
            <CommunesSelect
              handleChange={handleChangeCommune}
              value={communeValue}
              countryId={countryValue.value}
              regionId={regionValue.value}
              uniqueId="commune"
            />
          </FieldWrapper>
        </FormGroup>
      </Col>
    </Fragment>
  );
};

export default TerritoryNestedSelects;
