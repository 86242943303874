import axios from "axios";

export const getTerritoryCommunes = (country_id, region_id, callback) => {
  var promise = axios({
    method: "get",
    url: `/api/territory_countries/${country_id}/territory_regions/${region_id}/territory_communes`
  });
  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};

export const getAllTerritoryCommunes = callback => {
  var promise = axios({
    method: "get",
    url: "/api/territory_communes/communes"
  });
  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};
