import React, { useState } from "react";
import { Card, CardBody, Col, Row, Button } from "reactstrap";
import Switch from "react-switch";
import { drawAasmState } from "../../commons/Functions";
import { switchState } from "./Axios";

const CompanyCentralsHit = ({ hit, showNotification }) => {
  const [companyCentral, setCompanyCentral] = useState(hit._source);
  const [checked, setChecked] = useState(companyCentral.aasm_state === "active" ? true : false);

  const handleSwithAasmState = () => {
    let switch_state;
    if (checked) {
      switch_state = "desactivate";
    } else {
      switch_state = "activate";
    }
    var formData = new FormData();
    formData.append("switch_state", switch_state);
    switchState(companyCentral.id, formData, response => {
      if (response.status == 200) {
        setChecked(!checked);
        setCompanyCentral(response.data);
        showNotification("Genial", "Estado cambiado con éxtio!", "success");
      } else if (response.status == 422) {
        showNotification("Ops", "Debe completar la información de la Empresa!", "danger");
      }
    });
  };

  const logo = companyCentral.avatar
    ? companyCentral.avatar
    : "/assets/somos_empleos_logo-9ffc635adaecf31642b6ee1c7b81aa2644f9bd63b4eb7ad83aa6b54206c7733b.png";

  return (
    <Col md="4" className="mt-3">
      <Card className="material-shadow">
        <CardBody>
          <Row>
            <Col md="6" className="text-center">
              <img src={logo} className="company_logo img-fluid d-inline-block mb-2" alt="..." />
            </Col>
            <Col md="6" className="text-right">
              <Switch
                checked={checked}
                onChange={handleSwithAasmState}
                offColor="#ff3547"
                onColor="#00c851"
                onHandleColor="#00c851"
                offHandleColor="#ff3547"
                handleDiameter={30}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={15}
                width={43}
                className="react-switch align-middle"
                id="material-switch"
              />
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <strong>{companyCentral.name}</strong>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <i className="fas fa-building" /> RUT: {companyCentral.identification_number}
            </Col>
            <Col md="12">
              <i className="fas fa-envelope" /> Email: {companyCentral.email}
            </Col>
            <Col md="12">
              <i className="fas fa-building" /> Rubro: {companyCentral.business_area.name}
            </Col>
            <Col md="12">
              <i className="fas fa-map-marker" /> Dirección: {companyCentral.address}
            </Col>
            <Col md="12">
              <i className="fas fa-wheelchair" /> Es Fundación:{" "}
              {companyCentral.is_foundation ? "Si" : "No"}
            </Col>
            <Col md="12">
              <i className="fas fa-info-circle" /> Estado:{" "}
              {drawAasmState(companyCentral.aasm_state)}
            </Col>
          </Row>
          <Row className="text-center mt-3">
            <Col>
              <Button
                color="primary"
                href={`/company/centrals/${companyCentral.id}/edit`}
                size="sm"
              >
                Ver Perfil
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default CompanyCentralsHit;
