import axios from "axios";

export const saveCompanyBrand = (companyBrand, data, callback) => {
  let method, url;

  if (companyBrand) {
    method = "patch";
    url = `/api/company_brands/${companyBrand.id}`;
  } else {
    method = "post";
    url = "/api/company_brands";
  }

  var promise = axios({
    method: method,
    url: url,
    data: data,
    headers: {
      "X-CSRF-TOKEN": window.SomosEmpleos.token
    }
  });
  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};
