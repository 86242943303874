import React, { Component } from "react";
import { Row, Col, Button, Card, CardBody } from "reactstrap";
import Moment from "react-moment";
import { deletePersonalEducation } from "./Axios";

class EducationHit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      personalEducation: this.props.personalEducation
    };
  }

  updateHit = data => {
    this.setState({ personalEducation: data });
  };

  handleDelete = () => {
    const { personalEducation } = this.state;
    deletePersonalEducation(personalEducation.id, response => {
      if (response.status === 200) {
        this.props.removePersonalEducation(personalEducation.id);
      }
    });
  };

  render() {
    const { personalEducation } = this.state;

    return (
      <Row>
        <Col md="12">
          <Card className="my-2">
            <CardBody>
              <div className="hit-actions float-right text-right">
                <Button
                  outline
                  color="info"
                  aria-label="Editar"
                  title="Editar"
                  className="btn-circle d-block mb-2"
                  onClick={() =>
                    this.props.handleEditPersonalEducation(this.state.personalEducation)
                  }
                >
                  <i className="fas fa-edit"></i>
                </Button>
                <Button
                  outline
                  color="danger"
                  aria-label="Borrar"
                  title="Borrar"
                  className="btn-circle d-block"
                  onClick={this.handleDelete}
                >
                  <i className="far fa-trash-alt"></i>
                </Button>
              </div>
              <Row>
                <Col lg="7">
                  <h3 className="font-weight-bold h5">
                    {personalEducation.education_career.label || personalEducation.career_name}
                    <br />
                    <small className="text-muted">
                      {personalEducation.education_institution.label ||
                        personalEducation.institution_name}
                    </small>
                    <br />
                    <small className="text-muted">
                      {personalEducation.education_degree.label} -{" "}
                      <Moment format="DD/MM/YYYY">{personalEducation.graduated_at}</Moment>
                    </small>
                  </h3>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default EducationHit;
