import React, { useState, Fragment } from "react";
import { Button, Card, CardBody, Badge, UncontrolledTooltip } from "reactstrap";
import MatchIndicator from "../commons/MatchIndicator";
import moment from "moment";
import _ from "lodash";
import { drawAasmState } from "../commons/Functions";

const PublicationsHit = ({ hit, is_public, canAdmin }) => {
  const [publication, setPublication] = useState(hit._source);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const toggle = () => {
    setDropDownOpen(!dropDownOpen);
  };

  const companyLogo = publication.course.company_central.avatar
    ? publication.course.company_central.avatar
    : "/assets/somos_empleos_logo-9ffc635adaecf31642b6ee1c7b81aa2644f9bd63b4eb7ad83aa6b54206c7733b.png";

  return (
    <Card className="mb-3 publication-list-item job-card material-shadow item-card">
      <CardBody>
        <div className="d-flex justify-content-between flex-wrap">
          <div className="flex-grow-1 pr-md-3 col-lg-6 col-md-9 p-0">
            <h2 className="h5 mb-3" id="publication_title">
              {publication.course.title}
              <Badge color="primary" className="badge-indicator ml-1">
                Nueva Oferta
              </Badge>
              <small className="d-inline-block text-muted d-block">
                <strong>Área:&nbsp;</strong>
                {publication.course.laboral_area.name}
              </small>
            </h2>
            <div className="media flex-wrap">
              <img
                className="mr-3 mr-sm-2 mb-2 area-icon rs_skip"
                alt={publication.course.laboral_area.name}
                src={publication.course.laboral_area.attachment}
              />
              <div className="media-body">
                <ul className="fa-ul list-unstyled mb-0 ml-4 publication-detail-list" id="publication-detail-list">
                  <li>
                    <span className="fa-li">
                      <i className="far fa-calendar-alt" title="Fecha de Publicación"></i>
                    </span>
                    {moment(publication.created_at).format("DD/MM/YYYY")}{" "}
                    {canAdmin && <span>{drawAasmState(publication.aasm_state)}</span>}
                  </li>
                  <li>
                    <span className="fa-li">
                      <i className="fas fa-business-time" title="Jornada, Horarios o Turnos"></i>
                    </span>
                    {publication.preferred_schedule &&
                      I18n.t(
                        "activerecord.enums.personal/laboral_requirement.preferred_schedules." +
                          publication.preferred_schedule
                      )}
                  </li>
                  {!publication.isConfidential && (
                    <li>
                      <span className="fa-li">
                        <i className="fas fa-map-marker-alt" title="Ubicación"></i>
                      </span>
                      {`${publication.address}. ${publication.commune.name}, ${publication.region.name}, ${publication.country.name}`}
                    </li>
                  )}
                  <li>
                    <span className="fa-li">
                      <i
                        className="fas fa-universal-access rs_skip"
                        aria-hidden="true"
                        title="Accesibilidad"
                      ></i>
                    </span>
                    Accesibilidad, Diversidad e inclusión laboral
                    {/* HIDDEN this content in MinisitePublicationsList -> */}
                    <div className="accesibility-icons-list d-block">
                      {_.map(publication.course.accessibilityAnswers, answer => {
                        if (answer.isChecked) {
                          return (
                            <div className="d-inline-block" key={answer.value}>
                              <img
                                src={answer.iconSrc}
                                className="rounded-lg mb-2 d-inline-block p-1 mx-1"
                                alt={answer.label}
                                width="30"
                                href="#"
                                id={"Tooltip-" + answer.id}
                              />
                              <UncontrolledTooltip placement="top" target={"Tooltip-" + answer.id}>
                                {answer.label}
                              </UncontrolledTooltip>
                            </div>
                          );
                        }
                      })}
                      {publication.course.company_central.has_diversity_policies && (
                        <div className="d-inline-block" key="has_diversity_policies">
                          <img
                            src={publication.course.company_central.has_diversity_policies_logo}
                            className="rounded-lg mb-2 d-inline-block p-1 mx-1"
                            alt="Políticas de Diversidad e Inclusión"
                            width="30"
                            href="#"
                            id={"Tooltip-has_diversity_policies-" + publication.id}
                          />
                          <UncontrolledTooltip placement="top" target={"Tooltip-has_diversity_policies-" + publication.id}>
                            {"Políticas de Diversidad e Inclusión"}
                          </UncontrolledTooltip>
                        </div>
                      )}
                      {publication.course.company_central.has_gender_equality_policies && (
                        <div className="d-inline-block" key="has_gender_equality_policies">
                          <img
                            src={publication.course.company_central.has_gender_equality_policies_logo}
                            className="rounded-lg mb-2 d-inline-block p-1 mx-1"
                            alt="Políticas de equidad de género"
                            width="30"
                            href="#"
                            id={"Tooltip-has_gender_equality_policies-" + publication.id}
                          />
                          <UncontrolledTooltip placement="top" target={"Tooltip-has_gender_equality_policies-" + publication.id}>
                            {"Políticas de equidad de género"}
                          </UncontrolledTooltip>
                        </div>
                      )}
                      {publication.course.company_central.has_discrimination_protocols && (
                        <div className="d-inline-block" key="has_discrimination_protocols">
                          <img
                            src={publication.course.company_central.has_discrimination_protocols_logo}
                            className="rounded-lg mb-2 d-inline-block p-1 mx-1"
                            alt="Protocolo de denuncia por discriminación"
                            width="30"
                            href="#"
                            id={"Tooltip-has_discrimination_protocols-" + publication.id}
                          />
                          <UncontrolledTooltip placement="top" target={"Tooltip-has_discrimination_protocols-" + publication.id}>
                            {"Protocolo de denuncia por discriminación"}
                          </UncontrolledTooltip>
                        </div>
                      )}
                      {publication.course.company_central.has_inlusive_cultural_policies && (
                        <div className="d-inline-block" key="has_inlusive_cultural_policies">
                          <img
                            src={publication.course.company_central.has_inlusive_cultural_policies_logo}
                            className="rounded-lg mb-2 d-inline-block p-1 mx-1"
                            alt="Cultura organizacional Diversa e Inclusiva"
                            width="30"
                            href="#"
                            id={"Tooltip-has_inlusive_cultural_policies-" + publication.id}
                          />
                          <UncontrolledTooltip placement="top" target={"Tooltip-has_inlusive_cultural_policies-" + publication.id}>
                            {"Cultura organizacional Diversa e Inclusiva"}
                          </UncontrolledTooltip>
                        </div>
                      )}

                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Show this content only to Login user with full MATCH data -> */}
          {!is_public && (
            <div className="flex-fill col-lg-3 col-md-3 p-0 text-center d-flex flex-column flex-sm-row flex-md-column flex-lg-column align-items-center justify-content-center">
              <MatchIndicator matchpercentage={Math.round(hit._score)} />
              {/*<div className="bg-light rounded font-weight-bold d-inline-block px-3 py-1 my-2">
                <img
                  width="30"
                  className="mr-2"
                  alt=""
                  title="Distancia"
                  src="https://somosempleos.cl/assets/geolocation-3a04fe45b104dbea3ce18da6bf4edfca3174dbcf01784c51360795152aeff990.png"
                />
                <small>Estás a 4km de este empleo</small>
              </div>*/}
            </div>
          )}
          {/* <- Show this content only to Login user with full MATCH data */}

          <div className="col-lg-2 p-0 flex-lg-shrink flex-fill text-right d-flex flex-column flex-sm-row flex-md-row flex-lg-column align-items-center justify-content-between mt-3 mt-lg-0">
            {/* HIDDEN this content to Login COMPANY USER -> */}
            <div className="company_info align-self-lg-end text-center text-sm-left text-lg-right">
              {/* <Button
                color="link"
                size="sm"
                aria-label="Acerca de la Empresa"
                title="Acerca de la Empresa"
                className="p-0 ml-2 text-muted float-right"
              >
                <i className="fas fa-external-link-alt"></i>
              </Button> */}
              {!publication.isConfidential && (
                <Fragment>
                  <img
                    src={companyLogo}
                    className="company_logo img-fluid d-inline-block mb-2"
                    alt="..."
                  />
                  <small className="d-block font-weight-bold">
                    <span className="sr-only">Publicado por</span>
                    {publication.course.company_central.name}
                  </small>
                </Fragment>
              )}
            </div>

            {/* SHOW this content to Login COMPANY USER + HIDDEN this content in MinisitePublicationsList-> */}
            {/*<div className="company_tools text-center align-self-lg-end">
              <ButtonDropdown isOpen={dropDownOpen} toggle={toggle}
                                className="mr-1"
                                aria-label="Administrar Publicación"
                                title="Administrar Publicación"
                >
                <DropdownToggle caret color="secondary" size="sm" onClick={toggle}>
                  <i className="fas fa-cogs mr-2" aria-hidden="true"></i>
                  Administrar
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem>Editar</DropdownItem>
                  <DropdownItem>Cerrar</DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            </div>*/}
            {/* <- SHOW this content to Login COMPANY USER + HIDDEN this content in MinisitePublicationsList */}

            <Button
              size="sm"
              color="primary"
              className="my-3 align-self-lg-end mb-lg-0"
              href={`/publications/${publication.slug}`}
            >
              Ver Oferta
            </Button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
  //}
};

export default PublicationsHit;
