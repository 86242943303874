import React, { createContext } from "react";

export const CourseContext = createContext();

const CourseProvider = props => {
  const { documentDescriptors } = props;

  return (
    <CourseContext.Provider value={{ descriptors: documentDescriptors }}>
      {props.children}
    </CourseContext.Provider>
  );
};

export default CourseProvider;
