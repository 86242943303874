import React, { Component } from "react";
import { Row, Col, Button, Collapse } from "reactstrap";
import CompanyEmployeesHit from "./Hit";
import CompanyEmployeesForm from "./Form";

class CompanyEmployeesIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapse: false,
      companyEmployee: null,
      companyEmployees: this.props.companyEmployees || []
    };

    this.handleUpdateEmployee = this.handleUpdateEmployee.bind(this);
  }

  toggle = () => {
    const { collapse } = this.state;

    this.setState({
      ...this.state,
      collapse: !collapse
    });

    if (collapse) {
      this.setState({
        companyEmployee: null
      });
      this.refs.companyEmployeeForm.handleCleanState();
    }
  };

  handleAddEmployee = data => {
    this.setState({
      ...this.state,
      companyEmployees: [data, ...this.state.companyEmployees]
    });
  };

  handleRemoveEmployee = id => {
    const currentCompanyEmployees = [...this.state.companyEmployees];
    const companyEmployees = currentCompanyEmployees.filter(
      companyEmployee => companyEmployee.id !== id
    );
    this.setState({ companyEmployees });
  };

  handleEditEmployee = companyEmployee => {
    this.setState({ companyEmployee, collapse: true });
  };

  handleUpdateEmployee = data => {
    this.refs[`companyEmployeeHit-${data.id}`].updateHit(data);
  };

  render() {
    const { companyEmployees, collapse, companyEmployee } = this.state;

    return (
      <Row>
        <Col md="12">
          <Collapse isOpen={collapse} className="collapse-border">
            <CompanyEmployeesForm
              handleUpdateEmployee={this.handleUpdateEmployee}
              ref="companyEmployeeForm"
              toggle={this.toggle}
              handleAddEmployee={this.handleAddEmployee}
              companyEmployee={companyEmployee}
              showNotification={this.props.showNotification}
              companyCentralId={this.props.companyCentralId}
            />
          </Collapse>
          <Col md="12" className="text-right">
            {!this.state.collapse && (
              <Button outline color="primary" className="my-2" onClick={this.toggle}>
                <i className="fas fa-plus mr-2"></i>
                Agregar Empleado
              </Button>
            )}
          </Col>
          {companyEmployees.map(employee => (
            <CompanyEmployeesHit
              ref={`companyEmployeeHit-${employee.id}`}
              key={employee.id}
              companyEmployee={employee}
              handleEditEmployee={this.handleEditEmployee}
              removehandleRemoveEmployee={this.removehandleRemoveEmployee}
              companyCentralId={this.props.companyCentralId}
              showNotification={this.props.showNotification}
            />
          ))}
        </Col>
      </Row>
    );
  }
}

export default CompanyEmployeesIndex;
