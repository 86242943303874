import React, { useState, useEffect } from "react";
import Select from "react-select";
import { getCompanyFoundations } from "./Axios";

const FoundationsSelect = props => {
  const [foundations, setFoundations] = useState([]);

  useEffect(() => {
    const newFoundations = foundations;
    getCompanyFoundations(response => {
      response.data.map(function(foundation) {
        newFoundations.push({ value: foundation.id, label: foundation.name });
      });
    });
    setFoundations(newFoundations);
  }, []);

  const handleChange = inputValue => {
    props.handleChange(inputValue, "disabilityInstitution");
  };

  return (
    <Select
      inputId="disabilityInstitution"
      isDisabled={props.isDisabled}
      onChange={handleChange}
      options={foundations}
      placeholder="Seleccione una opción"
      value={props.value.value ? props.value : null}
    />
  );
};

export default FoundationsSelect;
