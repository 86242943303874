import React, { Fragment } from "react";
import { Row, Col, Card, CardBody, CardHeader, ListGroup, ListGroupItem, ButtonGroup, Button } from "reactstrap";
import Swal from "sweetalert2";
import { format } from "rut.js";
import Moment from "react-moment";
import { getCv } from "./Axios";
import CourseInviteModal from "./CourseInviteModal";
import UpdateInfoModal from "./UpdateInfoModal";
import UpdatePasswordModal from "./UpdatePasswordModal";
import SocialNameImg from '../../../assets/images/form_icons/social_name.png';
import OTDFundationImg from '../../../assets/images/form_icons/otd.png';
import LGTBQImg from '../../../assets/images/form_icons/lgtbq.png';

const ApplicantUserProfile = ({ applicant, showNotification, isAdmin }) => {
  const handleClickGenerateCv = applicant_id => {
    getCv(applicant_id, response => {
      if (response.status === 200) {
        Swal.fire(`${textSuccess}!`, `Descargado correctamente`, "success");
      }
    });
  };

  const showEmailInstitute = () => {
    if (
      applicant.user.disability_information &&
      applicant.user.disability_information.disability_institution &&
      applicant.user.disability_information.disability_institution.email
    ) {
      return (
        <p className="font-weight-bold text-success small pt-2">
          {applicant.user.disability_information.disability_institution.label}
          <span className="text-muted d-block">
            <a
              href={"mailto:" + applicant.user.disability_information.disability_institution.email}
              data-toggle="tooltip"
              data-placement="bottom"
              title="Correo Electrónico de la Fundación"
            >
              {applicant.user.disability_information.disability_institution.email}
            </a>
          </span>
        </p>
      );
    }
  };

  const applicantFullName = `${applicant.user.personal_information.first_name} ${applicant.user.personal_information.last_name}`;

  return (
    <Fragment>
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <ButtonGroup className="responsive-btn-group-vertical">
                <Button
                  color="secondary"
                  outline
                  size="sm"
                  href={applicant.user.cv_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Descargar CV
                </Button>
                <Button
                  color="primary"
                  outline
                  size="sm"
                  href={`/applicants/${applicant.id}/download_pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Generar CV
                </Button>
                {isAdmin && (
                  <CourseInviteModal
                    buttonSize="sm"
                    applicant={{
                      value: applicant.id,
                      label: applicantFullName
                    }}
                    showNotification={showNotification}
                  />
                )}
                <UpdateInfoModal
                  buttonSize="sm"
                  applicant={{ value: applicant.id, label: applicantFullName }}
                  showNotification={showNotification}
                />
                <UpdatePasswordModal buttonSize="sm" applicant={applicant} showNotification={showNotification} />
              </ButtonGroup>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col lg="4" md="12">
          <Card className="mb-2 bg-light">
            <CardBody>
              <ul className="list-unstyled mb-0 fa-ul" style={{ lineHeight: "20px" }}>
                <li className="mb-3">
                  <span className="fa-li h4 text-primary mt-1">
                    <i className="far fa-id-card" aria-hidden="true"></i>
                  </span>
                  <p className="font-weight-bold mb-0">Rut:</p>
                  <span className="text-muted">
                    {applicant.user.identification_document_number
                      ? format(applicant.user.identification_document_number)
                      : "No ingresó"}
                  </span>
                </li>
                <li className="mb-3">
                  <span className="fa-li h4 text-primary mt-1">
                    <i className="fas fa-birthday-cake" aria-hidden="true"></i>
                  </span>
                  <p className="font-weight-bold mb-0">Fecha de Nacimiento:</p>
                  <span className="text-muted">
                    {applicant.user.personal_information.birthdate ? (
                      <Moment format="DD/MM/YYYY">{applicant.user.personal_information.birthdate}</Moment>
                    ) : (
                      "No ingresó"
                    )}
                  </span>
                </li>
                <li className="mb-3">
                  <span className="fa-li h4 text-primary mt-1">
                    <i className="far fa-envelope" aria-hidden="true"></i>
                  </span>
                  <p className="font-weight-bold mb-0">Email:</p>
                  <span className="text-muted text-lowercase d-block">{applicant.user.email}</span>
                  <span className="text-muted text-lowercase d-block">
                    {applicant.user.personal_information.last_email}
                  </span>
                </li>
                <li className="mb-3">
                  <span className="fa-li h4 text-primary mt-1">
                    <i className="fas fa-mobile-alt" aria-hidden="true"></i>
                  </span>
                  <p className="font-weight-bold mb-0">Celular:</p>
                  <span className="text-muted">{applicant.user.personal_information.cellphone}</span>
                </li>
                <li className="mb-3">
                  <span className="fa-li h4 text-primary mt-1">
                    <i className="fas fa-home" aria-hidden="true"></i>
                  </span>
                  <p className="font-weight-bold mb-0">Dirección:</p>
                  <span className="text-muted">
                    {applicant.user.personal_information.address}. {applicant.user.personal_information.commune.label},{" "}
                    {applicant.user.personal_information.region.label}.{" "}
                    {applicant.user.personal_information.country.label}.
                  </span>
                </li>
                <li className="mb-3">
                  <span className="fa-li h4 text-primary mt-1">
                    <i className="far fa-flag" aria-hidden="true"></i>
                  </span>
                  <p className="font-weight-bold mb-0">Nacionalidad:</p>
                  <span className="text-muted">
                    {applicant.user.personal_information.nationality.label
                      ? applicant.user.personal_information.nationality.label
                      : "No ingresó"}
                  </span>
                </li>
                <li className="mb-3">
                  <span className="fa-li h4 text-primary mt-1">
                    <i className="fas fa-venus-mars" aria-hidden="true"></i>
                  </span>
                  <p className="font-weight-bold mb-0">Género:</p>
                  <span className="text-muted">
                    {applicant.user.personal_information.gender
                      ? I18n.t(`personal/information.genders.${applicant.user.personal_information.gender}` )
                      : "No ingresó"}
                    {applicant.user.personal_information.gender === "other"
                      ? " " + applicant.user.personal_information.other_gender
                      : ""}
                  </span>
                </li>
                <li className="mb-3">
                  <span className="fa-li h4 text-primary mt-1">
                    <i className="fas fa-user-graduate" aria-hidden="true"></i>
                  </span>
                  <p className="font-weight-bold mb-0">Nivel Educacional:</p>
                  <span className="text-muted">
                    {applicant.user.personal_information.education_level.label
                      ? applicant.user.personal_information.education_level.label
                      : "No ingresó"}
                  </span>
                </li>
                <li className="mb-0">
                  <span className="fa-li h4 text-primary mt-1">
                    <i className="fas fa-car" aria-hidden="true"></i>
                  </span>
                  <p className="font-weight-bold mb-0">Licencia de Conducir:</p>
                  <span className="text-muted">
                    {applicant.user.personal_information.drivers_license.label
                      ? applicant.user.personal_information.drivers_license.label
                      : "No ingresó"}
                  </span>
                </li>
              </ul>
            </CardBody>
          </Card>
          <Card className="mb-2 bg-light">
            <CardBody>
              <ul className="list-unstyled mb-0 fa-ul" style={{ lineHeight: "20px" }}>
                <li className="">
                  <span className="fa-li h4 text-primary mt-1">
                    <i className="fas fa-user-friends mr-2" aria-hidden="true"></i>
                  </span>
                  <p className="font-weight-bold mb-0">Datos de Contacto Alternativo:</p>
                  <span className="text-muted">
                    {applicant.user.contact_information.relationship.label}
                    <span className="d-block">{applicant.user.contact_information.contact_name}</span>
                    <span className="d-block">{applicant.user.contact_information.contact_email}</span>
                    <span className="d-block">{applicant.user.contact_information.contact_phone_number}</span>
                  </span>
                </li>
              </ul>
            </CardBody>
          </Card>
        </Col>
        <Col lg="8" md="12">
          <Card className="mb-3">
            <CardHeader className="__with_collapsible-icons">
              <a
                className="d-block"
                data-toggle="collapse"
                data-target="#educationCollapse"
                aria-expanded="true"
                aria-controls="educationCollapse"
              >
                <h5 className="card-title mb-0 h6 font-weight-bold">
                  <i className="fa mr-2 fa-graduation-cap" aria-hidden="true"></i>
                  Estudios
                </h5>
              </a>
            </CardHeader>
            <div className="collapse show" id="educationCollapse">
              <CardBody>
                {_.map(applicant.user.personal_educations, education => (
                  <p className="font-weight-bold" key={education.id}>
                    {education.education_career || education.career_name}
                    <small className="text-muted d-block">
                      {education.education_institution || education.institution_name}
                    </small>
                    <small className="text-muted d-block">
                      {education.education_degree} - <Moment format="DD/MM/YYYY">{education.graduated_at}</Moment>
                    </small>
                  </p>
                ))}
              </CardBody>
            </div>
          </Card>
          <Card className="mb-3">
            <CardHeader className="__with_collapsible-icons">
              <a
                className="d-block"
                data-toggle="collapse"
                data-target="#laboralExperienceCollapse"
                aria-expanded="true"
                aria-controls="laboralExperienceCollapse"
              >
                <h5 className="card-title mb-0 h6 font-weight-bold">
                  <i className="fa mr-2 fa-briefcase" aria-hidden="true"></i>
                  Experiencia Laboral
                </h5>
              </a>
            </CardHeader>
            <div className="collapse show" id="laboralExperienceCollapse">
              <CardBody>
                {applicant.user.professional_information.is_my_first_job
                  ? "Sin experiencia Laboral y/o Buscando Primer Empleo"
                  : _.map(applicant.user.laboral_experiences, experience => (
                      <p className="font-weight-bold" key={experience.id}>
                        {experience.company_position}
                        <small className="text-muted d-block">
                          {experience.company_name} / {experience.laboral_area}
                        </small>
                        <small className="text-muted d-block">
                          <Moment format="DD/MM/YYYY">{experience.start_at}</Moment> -{" "}
                          {experience.end_at ? <Moment format="DD/MM/YYYY">{experience.end_at}</Moment> : "Actualidad"}
                        </small>
                        <small className="text-muted d-block text-justify">
                          <i className="fas fa-briefcase" title="Labores Realizadas" />{" "}
                          {experience.experience_details ? experience.experience_details : "No detalló"}
                        </small>
                        <small className="text-muted d-block text-justify">
                          <i className="fas fa-award" title="Logros destacados" />{" "}
                          {experience.achievements ? experience.achievements : "No detalló"}
                        </small>
                      </p>
                    ))}
              </CardBody>
            </div>
          </Card>
          <Card className="mb-3">
            <CardHeader className="__with_collapsible-icons">
              <a
                className="d-block"
                data-toggle="collapse"
                data-target="#diversityInfoCollapse"
                aria-expanded="true"
                aria-controls="diversityInfoCollapse"
              >
                <h5 className="card-title mb-0 h6 font-weight-bold">
                  <i className="fas fa-transgender-alt mr-2" aria-hidden="true"></i>
                  Diversidad
                </h5>
              </a>
            </CardHeader>
            <div className="collapse show" id="diversityInfoCollapse">
              <ListGroup flush className="border-0 d-flex flex-row flex-wrap borderless-dinamyc-list-group">
                <ListGroupItem className="col-md-6 rounded-0">
                  <div className="row">
                    <div className="col-md-4">
                      <img src={LGTBQImg}
                           className="img-fluid"
                      />
                    </div>
                    <div className="col-md-8 pl-md-0">
                      <p className="mb-0">Población LGTBIQ+</p>
                      <div className="h5 mb-0 d-inline-block">
                        <span
                          className={`badge badge-pill ${
                            applicant.user.personal_information.identify_lgtbi === true ? "badge-success" : "badge-warning"
                          }`}
                        >
                          {applicant.user.personal_information.identify_lgtbi === true ? "Si" : "No"}
                        </span>
                      </div>
                    </div>
                  </div>
                </ListGroupItem>
                <ListGroupItem className="col-md-6 rounded-0">
                  <div className="row">
                    <div className="col-md-4">
                      <img src={OTDFundationImg}
                           className="img-fluid"
                      />
                    </div>
                    <div className="col-md-8 pl-md-0">
                      <p className="mb-0">Asociado a OTD "Organizando Trans Diversidades"</p>
                      <div className="h5 mb-0 d-inline-block">
                        <span
                          className={`badge badge-pill ${
                            applicant.user.personal_information.is_otd === true ? "badge-success" : "badge-warning"
                          }`}
                        >
                          {applicant.user.personal_information.is_otd === true ? "Si" : "No"}
                        </span>
                      </div>
                    </div>
                  </div>
                </ListGroupItem>
                {applicant.user.personal_information.social_name && (
                  <React.Fragment>
                    <ListGroupItem className="col-md-6 rounded-0">
                      <div className="row">
                        <div className="col-md-4">
                          <img src={SocialNameImg}
                               className="img-fluid"
                          />
                        </div>
                        <div className="col-md-8 pl-md-0">
                          <p className="mb-0">Nombre Social</p>
                          <div className="h5 mb-0 d-inline-block">
                            <span
                              className={`badge badge-pill ${
                                applicant.user.personal_information.social_name ? "badge-success" : "badge-warning"
                              }`}
                            >
                              {applicant.user.personal_information.social_name ? "Si" : "No"}
                            </span>
                            <small>{applicant.user.personal_information.social_name}</small>
                          </div>
                        </div>
                      </div>
                    </ListGroupItem>
                    <ListGroupItem className="col-md-6 rounded-0">
                      <div className="text-info">
                        <i className="fas fa-info-circle mr-2 fa-pull-left" aria-hidden="true"></i>
                        <p className="small mb-0">Ten en cuenta que los documentos de la persona no necesariamente podrían calzar con el nombre con el 
                        que se identifica la persona.</p>
                      </div>
                    </ListGroupItem>
                  </React.Fragment>
                )}
              </ListGroup>
            </div>
          </Card>
          <Card className="mb-3">
            <CardHeader className="__with_collapsible-icons">
              <a
                className="d-block"
                data-toggle="collapse"
                data-target="#accessibilityInfoCollapse"
                aria-expanded="true"
                aria-controls="accessibilityInfoCollapse"
              >
                <h5 className="card-title mb-0 h6 font-weight-bold">
                  <i className="fas fa-universal-access mr-2" aria-hidden="true"></i>
                  Accessibilidad
                </h5>
              </a>
            </CardHeader>
            <div className="collapse show" id="accessibilityInfoCollapse">
              <ListGroup flush className="border-0 d-flex flex-row flex-wrap borderless-dinamyc-list-group">
                <ListGroupItem className="col-md-6 rounded-0">
                  <div className="row">
                    <div className="col-md-4">
                      <img
                        className="img-fluid"
                        alt=""
                        src="/assets/form_icons/disability-f43733779851b1931e097a94add1b92deb9df8acdb5edff2dccd61af7e33f844.png"
                      />
                    </div>
                    <div className="col-md-8 pl-md-0">
                      <p className="mb-0">Situación de Discapacidad</p>
                      <div className="h5 mb-0 d-inline-block">
                        <span
                          className={`badge badge-pill ${
                            applicant.user.disability_information.has_disability ? "badge-success" : "badge-warning"
                          }`}
                        >
                          {applicant.user.disability_information.has_disability ? "Si" : "No"}
                        </span>
                      </div>
                    </div>
                  </div>
                </ListGroupItem>
                <ListGroupItem className="col-md-6 rounded-0">
                  <div className="row">
                    <div className="col-md-4">
                      <img
                        className="img-fluid"
                        alt=""
                        src="/assets/form_icons/credential-370579e450221191e26f9a12f459ff44ace524d4230be8fecad68675e578adb2.png"
                      />
                    </div>
                    <div className="col-md-8 pl-md-0">
                      <p className="mb-0">Credencial de discapacidad</p>
                      <div className="h5 mb-0 d-inline-block">
                        <span
                          className={`badge badge-pill ${
                            applicant.user.disability_information.has_disability_credential
                              ? "badge-success"
                              : "badge-warning"
                          }`}
                        >
                          {applicant.user.disability_information.has_disability_credential ? "Si" : "No"}
                        </span>
                      </div>
                    </div>
                  </div>
                </ListGroupItem>
                <ListGroupItem className="col-md-6 rounded-0">
                  <div className="row">
                    <div className="col-md-4">
                      <img
                        className="img-fluid"
                        alt=""
                        src="/assets/form_icons/payment-b3574f1095b85b9e301ea2b2ce80e2ac361533978bfbfb97f6e0c001d428d628.png"
                      />
                    </div>
                    <div className="col-md-8 pl-md-0">
                      <p className="mb-0">Pensión de invalidez</p>
                      <div className="h5 mb-0 d-inline-block">
                        <span
                          className={`badge badge-pill ${
                            applicant.user.disability_information.has_invalidity_pension
                              ? "badge-success"
                              : "badge-warning"
                          }`}
                        >
                          {applicant.user.disability_information.has_invalidity_pension ? "Si" : "No"}
                        </span>
                      </div>
                    </div>
                  </div>
                </ListGroupItem>
                <ListGroupItem className="col-md-6 rounded-0">
                  <div className="row">
                    <div className="col-md-4">
                      <img
                        className="img-fluid"
                        alt=""
                        src="/assets/form_icons/institution-640f7233346cb52d7375d18b9f5b36df41ab37303ee3e82d19150c3541933eef.png"
                      />
                    </div>
                    <div className="col-md-8 pl-md-0">
                      <p className="mb-0">Asociado a institución de RedIncluye</p>
                      <div className="h5 mb-0 d-inline-block">
                        <span
                          className={`badge badge-pill ${
                            applicant.user.disability_information.has_associated_institution
                              ? "badge-success"
                              : "badge-warning"
                          }`}
                        >
                          {applicant.user.disability_information.has_associated_institution ? "Si" : "No"}
                        </span>
                      </div>
                      {showEmailInstitute()}
                    </div>
                  </div>
                </ListGroupItem>
              </ListGroup>
            </div>
          </Card>

          <Card className="mb-3">
            <CardHeader className="__with_collapsible-icons">
              <a
                className="d-block"
                data-toggle="collapse"
                data-target="#personalLaboralInfoCollapse"
                aria-expanded="true"
                aria-controls="personalLaboralInfoCollapse"
              >
                <h5 className="card-title mb-0 h6 font-weight-bold">
                  <i className="fas mr-2 fa-info-circle" aria-hidden="true"></i>
                  Expectativas Laborales
                </h5>
              </a>
            </CardHeader>
            <div className="collapse show" id="personalLaboralInfoCollapse">
              <ul className="list-group list-group-flush border-0 d-flex flex-row flex-wrap borderless-dinamyc-list-group">
                <li className="list-group-item col-md-6">
                  <span className="d-block">Jornada Laboral</span>
                  {applicant.user.personal_laboral_requirement.preferred_schedule && (
                    <span className="badge badge-primary badge-pill">
                      {I18n.t(
                        `activerecord.enums.personal/laboral_requirement.preferred_schedules.${applicant.user.personal_laboral_requirement.preferred_schedule}`
                      )}
                    </span>
                  )}
                </li>
                <li className="list-group-item col-md-6">
                  <span className="d-block">Extensión de la jornada</span>
                  {applicant.user.personal_laboral_requirement.preferred_labor_day && (
                    <span className="badge badge-primary badge-pill">
                      {I18n.t(
                        `activerecord.enums.personal/laboral_requirement.preferred_labor_days.${applicant.user.personal_laboral_requirement.preferred_labor_day}`
                      )}
                    </span>
                  )}
                </li>
                <li className="list-group-item col-md-6">
                  <span className="d-block">Horarios y/o Turnos</span>
                  {applicant.user.personal_laboral_requirement.preferred_turn && (
                    <span className="badge badge-primary badge-pill">
                      {I18n.t(
                        `activerecord.enums.personal/laboral_requirement.preferred_turns.${applicant.user.personal_laboral_requirement.preferred_turn}`
                      )}
                    </span>
                  )}
                </li>
                <li className="list-group-item col-md-6">
                  <span className="d-block">Área Laboral de Interés</span>
                  <div className="">
                    {_.map(applicant.user.personal_laboral_requirement.laboral_areas, area => (
                      <span key={area.value} className="badge badge-primary badge-pill text-wrap">
                        {area.label}
                      </span>
                    ))}
                  </div>
                </li>
                <li className="list-group-item col-md-6">
                  <span className="d-block">Comunas de Interés</span>
                  <div className="">
                    {_.map(applicant.user.personal_laboral_requirement.desired_communes, commune => (
                      <span key={commune.value} className="badge badge-primary badge-pill">
                        {commune.label}
                      </span>
                    ))}
                  </div>
                </li>
                <li className="list-group-item col-md-6">
                  <span className="d-block">Código de Vestimenta Laboral Formal</span>
                  {applicant.user.personal_laboral_requirement.dress_code && (
                    <span className="badge badge-primary badge-pill">
                      {I18n.t(
                        `activerecord.enums.personal/laboral_requirement.dress_code.${applicant.user.personal_laboral_requirement.dress_code}`
                      )}
                    </span>
                  )}
                </li>
                <li className="list-group-item col-md-6">
                  <span className="d-block">Expectativas de Sueldo</span>
                  {applicant.user.personal_laboral_requirement.expected_salary && (
                    <span className="badge badge-primary badge-pill">
                      ${applicant.user.personal_laboral_requirement.expected_salary}
                    </span>
                  )}
                </li>
              </ul>
            </div>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default ApplicantUserProfile;
