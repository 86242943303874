import React, { Fragment, useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  ListGroup,
  ListGroupItem,
  Collapse,
  Progress,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import _ from "lodash";
import Swal from "sweetalert2";
import MatchIndicator from "../commons/MatchIndicator";
import ApplicantData from "../applicant/Data";
import ApplicantStages from "../applicant/Stages";
import { switchPostulationState, getCv, submitSingleNextStage } from "./Axios";

const ApplicantsHit = props => {
  const {
    hit,
    isPostulation,
    courseId,
    workFlow,
    showNotification,
    handleCheckApplicant,
    isChecked
  } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [collapseAnswers, setCollapseAnswers] = useState(false);
  const [applicantHit, setApplicanttHit] = useState(hit._source);

  useEffect(() => {
    if (applicantHit !== hit._source) {
      setApplicanttHit(hit._source);
    }
  }, [hit]);

  const toggleDropdownMenu = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleAnswers = () => {
    setCollapseAnswers(!collapseAnswers);
  };

  const handleClickGenerateCv = applicant_id => {
    getCv(applicant_id, response => {
      if (response.status === 200) {
        Swal.fire(`${textSuccess}!`, `Descargado correctamente`, "success");
      }
    });
  };

  const handleSwitchPostulationState = switchState => {
    let text,
      textSuccess = "";
    switch (switchState) {
      case "approve":
        text = "Aceptar";
        textSuccess = "Aceptada";
        break;
      case "reject":
        text = "Rechazar";
        textSuccess = "Rechazada";
        break;
    }

    Swal.fire({
      icon: "question",
      title: "Estás seguro?",
      text: `La postulación se va a ${text}`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Si, ${text}`,
      cancelButtonText: "Cancelar"
    }).then(result => {
      if (result.value) {
        var formData = new FormData();
        formData.append("switch_state", switchState);
        try {
          switchPostulationState(
            courseId,
            applicantHit.id,
            formData,
            response => {
              if (response.status === 200) {
                Swal.fire({
                  icon: "success",
                  title: `${textSuccess}!`,
                  text: `La postulación ha sido ${textSuccess}.`,
                  showConfirmButton: false,
                  timer: 4000
                });
                setApplicanttHit(response.data);
              }
            }
          );
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Hubo un error, vuelve a intentarlo!",
            showConfirmButton: false,
            timer: 4000
          });
        }
      }
    });
  };

  const drawNextStageName = () => {
    if (
      applicant.enrollment.current_stage_index !== null &&
      applicant.enrollment.current_stage_index === workFlow.length - 1
    ) {
      return "Finalizó el Proceso";
    } else if (
      applicant.enrollment.current_stage_index !== null &&
      applicant.enrollment.current_stage_index >= 0
    ) {
      return workFlow[applicant.enrollment.current_stage_index + 1]["name"];
    } else {
      return workFlow[0]["name"];
    }
  };

  const handleSubmitNextStage = async () => {
    const { value: stageType } = await Swal.fire({
      title: "Siguiente Etapa",
      input: "select",
      inputOptions: {
        "Interview::Stage": "Entrevista",
        "Documentation::Stage": "Documentación",
        "Hiring::Stage": "Contratación"
      },
      inputPlaceholder: "Seleccione un Etapa",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Si, avanzar`,
      showCancelButton: true,
      inputValidator: value => {
        return new Promise(resolve => {
          if (!value) {
            resolve("Debes seleccionar una Etapa :)");
          } else {
            resolve();
          }
        });
      }
    });

    if (stageType) {
      Swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
        onOpen: () => {
          Swal.showLoading();
        }
      });
      let formData = new FormData();
      formData.append("next_stage", stageType);
      try {
        submitSingleNextStage(courseId, applicantHit.id, formData, response => {
          if (response.status === 200) {
            Swal.fire({
              icon: "success",
              title: "Genial",
              text: "Candidato cambiado de etapa exitosamente!",
              showConfirmButton: false,
              timer: 3000
            });
            setApplicanttHit(response.data);
          } else if (response.status === 422) {
            const errors = [];
            _.mapKeys(response.data, value => {
              _.map(value, val => {
                errors.push(val);
              });
            });
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: `Candidato no pudo ser cambiado de etapa: ${errors[0]}`
            });
          } else if (response.status === 403) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text:
                "Lo sentimos, no tienes los permisos suficientes para realizar esta acción!",
              showConfirmButton: false,
              timer: 3000
            });
          }
        });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Hubo un error, vuelve a intentarlo!",
          showConfirmButton: false,
          timer: 3000
        });
      }
    }
  };

  const drawApplicantLink = () => {
    var link = `/applicants/${applicant.id}`;

    if (props.courseId) {
      link += `?course_id=${props.courseId}`;
    }

    return link;
  };

  let applicant;

  if (isPostulation) {
    applicant = applicantHit.applicant;
  } else {
    applicant = applicantHit;
  }

  const { user } = applicant;

  const drawCheck = () => {
    return (
      <input
        type="checkbox"
        value={applicantHit.id}
        onChange={handleCheckApplicant}
        checked={isChecked}
        className="float-left"
      />
    );
  };

  return (
    <Card className="mb-3 publication-list-item item-card material-shadow">
      <CardBody>
        <div className="d-flex justify-content-between flex-wrap align-items-lg-center">
          <div className="col-lg-5 flex-grow-1 pr-md-3 p-0">
            {drawCheck()}
            <ApplicantData user={user} applicant={applicant} />
          </div>
          {isPostulation && (
            <Fragment>
              <div className="col-lg-2 p-0 flex-fill text-center d-flex flex-column flex-sm-row flex-md-column flex-lg-column align-items-center justify-content-center">
                <MatchIndicator matchpercentage={Math.round(hit._score)} />
              </div>
              <div className="col-lg-3 flex-fill text-left p-0">
                <ApplicantStages
                  courseId={courseId}
                  postulationId={applicantHit.id}
                  applicant={applicant}
                  postulatedAt={applicantHit.created_at}
                  postulationState={applicantHit.aasm_state}
                  stages={
                    applicant.enrollment ? applicant.enrollment.stages : []
                  }
                  setApplicanttHit={setApplicanttHit}
                  workFlow={workFlow}
                  showNotification={showNotification}
                />
              </div>
            </Fragment>
          )}
          {!isPostulation && (
            <div className="col-lg-4 flex-fill p-0">
              <p className="small text-primary mb-2 mt-2">
                Tiene el {parseInt(user.profile_progress).toFixed()}% del Perfil
                Cargado
              </p>
              <Progress
                animated
                value={parseInt(user.profile_progress).toFixed()}
              >
                {parseInt(user.profile_progress).toFixed()}%
              </Progress>

              <ul className="fa-ul list-unstyled ml-4 small text-muted mt-2">
                {parseInt(user.profile_progress) === 100 && (
                  <li className="">
                    <span className="fa-li">
                      <i
                        aria-hidden="true"
                        className="fas fa-check"
                        title="Indicador de Estado"
                      ></i>
                    </span>
                    Perfil Usuario&nbsp;
                    <small className="text-muted">Completado</small>
                  </li>
                )}
                {/* <li className="">
                  <span className="fa-li">
                    <i aria-hidden="true" className="fas fa-check" title="Indicador de Estado"></i>
                  </span>
                  Perfil funcional&nbsp;
                  <small className="text-muted">Completado</small>
                </li> */}
              </ul>
            </div>
          )}
          <div className="col-lg-2 p-0 flex-lg-shrink flex-fill text-right d-flex flex-column flex-sm-row flex-md-row flex-lg-column align-items-center justify-content-center align-items-lg-end justify-content-lg-center">
            {isPostulation && (
              <ButtonDropdown
                isOpen={dropdownOpen}
                toggle={toggleDropdownMenu}
                aria-label="Opciones de Candidato"
                title="Opciones de Candidato"
              >
                <DropdownToggle
                  caret
                  color="secondary"
                  size="sm"
                  className="m-2 m-lg-0 mb-lg-2"
                >
                  <i className="fas fa-user-cog mr-2" aria-hidden="true"></i>
                  Gestionar
                </DropdownToggle>
                <DropdownMenu right>
                  {user.cv_url ? (
                    <DropdownItem>
                      <a
                        href={user.cv_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Descargar CV
                      </a>
                    </DropdownItem>
                  ) : (
                    <DropdownItem>
                      <a
                        href={`/applicants/${applicant.id}/download_pdf`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Generar CV
                      </a>
                    </DropdownItem>
                  )}
                  {/* <DropdownItem>Descargar Respuestas</DropdownItem>
                    <DropdownItem divider />
                  <DropdownItem>Cambiar de Etapa</DropdownItem> */}
                  {applicantHit.aasm_state === "pending" && (
                    <Fragment>
                      <DropdownItem divider />
                      <DropdownItem
                        onClick={() => handleSwitchPostulationState("approve")}
                      >
                        Aceptar Postulatcón
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => handleSwitchPostulationState("reject")}
                      >
                        Rechazar Postulación
                      </DropdownItem>
                    </Fragment>
                  )}
                  {applicantHit.aasm_state === "approved" && (
                    <Fragment>
                      <DropdownItem divider />
                      <DropdownItem onClick={() => handleSubmitNextStage()}>
                        Avanzar Etapa
                      </DropdownItem>
                    </Fragment>
                  )}
                  {/* <DropdownItem>Contactar</DropdownItem>
                    <DropdownItem>Agendar Entrevista</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>Descartar</DropdownItem> */}
                </DropdownMenu>
              </ButtonDropdown>
            )}
            <Button
              size="sm"
              color="primary"
              className="m-2 m-lg-0 mb-lg-2"
              href={drawApplicantLink()}
              target="_blank"
              rel="noopener noreferrer"
            >
              Ver Perfil
            </Button>

            {isPostulation &&
              applicantHit.postulation_answers !== undefined &&
              applicantHit.postulation_answers.length >= 1 && (
                <Button
                  outline
                  size="sm"
                  color="info"
                  className="m-2 m-lg-0"
                  onClick={toggleAnswers}
                >
                  Ver Respuestas
                </Button>
              )}
          </div>
        </div>
      </CardBody>
      {isPostulation &&
        applicantHit.postulation_answers !== undefined &&
        applicantHit.postulation_answers.length >= 1 && (
          <Collapse isOpen={collapseAnswers}>
            <CardFooter className="bg-light">
              <ListGroup flush>
                {_.map(applicantHit.postulation_answers, answer => (
                  <ListGroupItem className="bg-light" key={answer.answer}>
                    <span className="font-weight-bold d-block">
                      {answer.publication_question_name}
                    </span>
                    <em>{answer.answer}</em>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </CardFooter>
          </Collapse>
        )}
    </Card>
  );
};

export default ApplicantsHit;
