import React, { Fragment, useState } from "react";
import {
  SearchkitManager,
  SearchkitProvider,
  Hits,
  NoHits,
  Pagination,
  SearchBox,
  HitsStats,
  SortingSelector
} from "searchkit";
import { Card, CardBody, Row, Col } from "reactstrap";
import PublicationsHits from "./Hits";

const PublicationsIndex = props => {
  const [searchkit, setSearchkit] = useState(
    new SearchkitManager(props.route, {
      httpHeaders: { "X-CSRF-TOKEN": window.SomosEmpleos.token }
    })
  );

  return (
    <SearchkitProvider searchkit={searchkit}>
      <Fragment>
        <Card className="mb-3 rs_skip">
          <CardBody>
            <Row>
              <Col md="6">
                <SearchBox
                  searchOnChange={true}
                  translations={{ "searchbox.placeholder": "Buscar Oferta Laboral" }}
                  prefixQueryFields={["id", "course.title", "course.created_by.first_name", "course.company_central.name", "course.laboral_area.name", "territory_commune.name"]}
                />
                <HitsStats
                  translations={{
                    "hitstats.results_found": "{hitCount} resultados encontrados en {timeTaken}ms"
                  }}
                />
              </Col>
              <Col></Col>
              <Col md="3">
                <SortingSelector
                  options={[
                    {
                      label: "Mayor coincidencia",
                      field: "_score",
                      order: "desc"
                    },
                    { label: "Menor coincidencia", field: "_score", order: "asc" },
                    {
                      label: "Fecha de Creación reciente",
                      field: "created_at",
                      order: "desc",
                      defaultOption: true
                    },
                    { label: "Fecha de Creación antigua", field: "created_at", order: "asc" }
                  ]}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Hits hitsPerPage={12} listComponent={<PublicationsHits {...props} />} />
        <NoHits
          translations={{
            "NoHits.NoResultsFound": "No se encontraron resultados para {query}",
            "NoHits.DidYouMean": "Busqueda para {suggestion}",
            "NoHits.SearchWithoutFilters": "Busqueda para {query} sin filtros",
            "NoHits.NoResultsFoundDidYouMean":
              "No se encontraron resultados para {query}. Quisiste decir {suggestion} ?"
          }}
        />
        <div className="mt-3 overflow-auto">
          <Pagination
            showNumbers={true}
            translations={{
              "pagination.previous": "Anterior",
              "pagination.next": "Siguiente"
            }}
          />
        </div>
      </Fragment>
    </SearchkitProvider>
  );
};

export default PublicationsIndex;
