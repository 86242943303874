import React, { Component } from "react";
import { Row, Col, Nav, NavItem, NavLink, Card, CardBody, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import FunctionalRequirements from "./functional_requirements/Form";
import AccessibilityRequirements from "./accessibility_requirements/Form";
import Notifications from "../commons/Notifications";
import Navigation from "../commons/Navigation";

const availableTabs = ["functionalProfileForm", "accessibilityRequirementsForm"];

class FunctionalProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "functionalProfileForm"
    };

    this.showNotification = this.showNotification.bind(this);
  }

  toggleTab = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  showNotification = (title, message, type) => {
    this.refs.notifications.addNotifications(title, message, type);
  };

  render() {
    return (
      <div>
        <Notifications ref="notifications" />
        <Row id="profile-panel-forms">
          <Col md="12">
            <Nav pills className="perfil-tabs-navigation text-center steps mb-3" role="tablist">
              <NavItem role="presentation">
                <NavLink
                  className={
                    "d-flex align-items-center " +
                    classnames({
                      active: this.state.activeTab === "functionalProfileForm"
                    })
                  }
                  onClick={() => {
                    this.toggleTab("functionalProfileForm");
                  }}
                  href="#functionalProfileForm"
                  aria-controls="functionalProfileForm"
                  role="tab"
                  data-toggle="tab"
                  id="functionalProfileForm-tab"
                  aria-label="Requerimientos del Puesto de Trabajo"
                >
                  <span className="step-content-link">
                    <i className="fas fa-tasks"></i>
                    <span className="d-none d-sm-inline-block ml-2">
                      Requerimientos del Puesto de Trabajo
                    </span>
                    <div className="input_match"></div>
                  </span>
                </NavLink>
              </NavItem>
              <NavItem role="presentation">
                <NavLink
                  className={
                    "d-flex align-items-center " +
                    classnames({
                      active: this.state.activeTab === "accessibilityRequirementsForm"
                    })
                  }
                  onClick={() => {
                    this.toggleTab("accessibilityRequirementsForm");
                  }}
                  href="#accessibilityRequirementsForm"
                  aria-controls="accessibilityRequirementsForm"
                  role="tab"
                  data-toggle="tab"
                  id="accessibilityRequirementsForm-tab"
                  aria-label="Requerimientos de Accesibilidad"
                >
                  <span className="step-content-link">
                    <i className="fas fa-universal-access"></i>
                    <span className="d-none d-sm-inline-block ml-2">
                      Requerimientos de Accesibilidad
                    </span>
                    <div className="input_match"></div>
                  </span>
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>

        <Card className="mb-5">
          <Navigation
            currentTab={this.state.activeTab}
            availableTabs={availableTabs}
            toggleTab={this.toggleTab}
          />
          <CardBody className="pt-3">
            <TabContent activeTab={this.state.activeTab} id="pills-tabContent">
              <TabPane
                id="functionalProfileForm"
                tabId="functionalProfileForm"
                aria-labelledby="functionalProfileForm-tab"
              >
                <h2 className="tab-pane-title text-muted font-weight-bold">
                  <i className="fas fa-tasks mr-2"></i>
                  <small className="mr-1">Requerimientos del Puesto de Trabajo</small>
                  <div className="input_match"></div>
                  <hr />
                </h2>
                <FunctionalRequirements
                  functional_answers={this.props.current_user.functional_answers}
                  showNotification={this.showNotification}
                  toggleTab={this.toggleTab}
                />
              </TabPane>
              <TabPane
                id="accessibilityRequirementsForm"
                tabId="accessibilityRequirementsForm"
                aria-labelledby="accessibilityRequirementsForm-tab"
              >
                <h2 className="tab-pane-title text-muted font-weight-bold">
                  <i className="fas fa-universal-access mr-2"></i>
                  <small className="mr-1">Requerimientos de Accesibilidad</small>
                  <div className="input_match"></div>
                  <hr />
                </h2>
                <AccessibilityRequirements
                  accessibility_answers={this.props.current_user.accessibility_answers}
                  showNotification={this.showNotification}
                  toggleTab={this.toggleTab}
                />
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default FunctionalProfile;
