import React, { Fragment, useState } from "react";
import { Row, Card, CardBody, Button, Col } from "reactstrap";
import Switch from "react-switch";
import EditProduct from "./Edit";
import { switchState } from "./Axios";

const TrialProductHit = ({
  product,
  currentUser,
  hasProductTrial,
  canEdit
}) => {
  const [productHit, setProductHit] = useState(product);

  const updateProduct = data => {
    setProductHit(data);
  };

  const handleSwithAasmState = () => {
    let switch_state;
    if (productHit.aasm_state === "active") {
      switch_state = "desactivate";
    } else {
      switch_state = "activate";
    }
    var formData = new FormData();
    formData.append("switch_state", switch_state);
    switchState(product.id, formData, response => {
      if (response.status == 200) {
        updateProduct(response.data);
      }
    });
  };

  return (
    <Col md="12" lg="12" className="p-lg-0">
      <Card className="mb-4 price-card with-ribbon promo border-0 py-2">
        <div className="ribbon ribbon-top-left ribbon-primary">
          <span>Prueba Gratis!</span>
        </div>
        <CardBody className="text-center">
          <Row className="d-flex justify-content-center align-items-center ">
            <Col sm="12" lg="3" className="text-center">
              <h4 className="card-title clearfix">
                <span className="font-weight-bold product-name h4">
                  {productHit.name}
                </span>
                <br />
                <span className="h2 price font-weight-bold">Gratis</span>
              </h4>
            </Col>
            <Col sm="12" lg="3">
              <ul className="list-group list-group-flush text-left">
                <li>
                  {" "}
                  {productHit.quantity}{" "}
                  {productHit.quantity > 1 ? "Publicaciones" : "Publicación"}{" "}
                  con vigencia de hasta 30 días
                </li>
                <li> Válido por tiempo limitado</li>
                <li> Solo 1 por Empresa</li>
              </ul>
            </Col>
            <Col sm="12" lg="3" size="12" className="text-center">
              {hasProductTrial ? (
                <Button
                  color="primary"
                  className="px-4 font-weight-bold text-wrap"
                >
                  Ya tienes tu prueba Gratis
                </Button>
              ) : currentUser ? null : (
                <a
                  className="btn btn-primary btn-sm px-4 font-weight-bold text-wrap"
                  href="/empresas#registro"
                >
                  Prueba Gratis
                </a>
              )}
            </Col>
          </Row>
          <div className="absolute-img d-none d-sm-block">
            <img
              className="img-fluid"
              src="/assets/love_match-94c064cbb5178f7af0191e9e9fed142a40effd1c3f9f791df160111cf736a1f8.png"
              alt=""
            />
          </div>
        </CardBody>
        {canEdit && (
          <div className="products-actions-container text-left d-block bg-light rounded-pill border">
            <Fragment>
              <EditProduct product={productHit} updateProduct={updateProduct} />{" "}
              <Switch
                checked={productHit.aasm_state === "active"}
                onChange={handleSwithAasmState}
                offColor="#ff3547"
                onColor="#00c851"
                onHandleColor="#00c851"
                offHandleColor="#ff3547"
                handleDiameter={20}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={10}
                width={30}
                className="react-switch align-middle"
                id="material-switch"
              />
            </Fragment>
          </div>
        )}
      </Card>
    </Col>
  );
};

export default TrialProductHit;
