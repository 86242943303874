import React, { useState, useEffect } from "react";
import _ from "lodash";
import ApplicantsHit from "./Hit";
import ApplicantsTools from "./ApplicantsTools";
import { FormatApplicant } from "./ApplicantHelper";
import { getAllApplicants } from "./Axios";

const ApplicantsHits = props => {
  const { hits, isPostulation, courseId, workFlow, reloadQuery, showNotification } = props;
  const [applicantsSelected, setApplicantsSelected] = useState([]);
  const [applicants, setApplicants] = useState([]);
  const [checkAllApplicant, setCheckAllApplicant] = useState(false)

  useEffect(() => {
    const getApplicants = () => {
      const newApplicants = applicants;
      getAllApplicants(courseId, response => {
        _.map(response.data, applicant => {
          newApplicants.push({ value: applicant.id, label: applicant.full_name });
        });
      });
      setApplicants(newApplicants);
    };

    getApplicants();
  }, []);

  const handleCheckApplicant = (event) => {
    let value = String(event.target.value);

    let newSelected = _.find(hits, hit => {
      return hit._source.id == value
    });

    const _applicantsSelected = [...applicantsSelected];

    if(event.target.checked && !isChecked(newSelected) && newSelected){
      _applicantsSelected.push(
        FormatApplicant(newSelected, isPostulation)
      )
    } else {
      let index = _.findIndex(_applicantsSelected, { id: value } )
      _applicantsSelected.splice(index, 1);
    }

    setApplicantsSelected(_applicantsSelected)
  }

  const isChecked = (hit) => {
    let applicant = isPostulation ? hit._source.applicant : hit._source;

    return _.some(applicantsSelected, { value: applicant.id })
  }

  const handleCheckAllApplicant = (event) => {
    let checked = event.target.checked;
    let _applicantSelected = [];
    setCheckAllApplicant(checked)

    if(checked){
      _applicantSelected = applicants
    }

    setApplicantsSelected(_applicantSelected)
  }

  return (
    <div>
      <ApplicantsTools
        isPostulation={isPostulation}
        courseId={courseId}
        workFlow={workFlow}
        reloadQuery={reloadQuery}
        showNotification={showNotification}
        applicantsSelected={ applicantsSelected }
        checkAllApplicant={ checkAllApplicant }
        handleCheckAllApplicant={ handleCheckAllApplicant }
      />
      {_.map(hits, hit => (
        <ApplicantsHit
          key={hit._id}
          hit={hit}
          isPostulation={isPostulation}
          courseId={courseId}
          workFlow={workFlow}
          showNotification={showNotification}
          handleCheckApplicant={ handleCheckApplicant }
          isChecked={ isChecked(hit) }
        />
      ))}
    </div>
  );
};

export default ApplicantsHits;
