import React, { Component, Fragment } from "react";
import { Row, Col, Form, FormGroup, Label, Input, Button, Card, CardBody, UncontrolledTooltip } from "reactstrap";
import { format } from "rut.js";
import NationalitiesSelect from "../../territory/nationalities/Select";
import TerritoryNestedSelects from "../../territory/nested/Selects";
import EducationLevelsSelect from "../../education/levels/Select";
import DriversLicensesSelect from "./DriversLicensesSelect";
import FoundationsSelect from "../../company/centrals/FoundationsSelect";
import { savePersonaInformation } from "./Axios";
import FieldWrapper from "../../commons/FieldWrapper";
import WhoCompletedFormSelect from "./WhoCompletedFormSelect";
import RelationshipContactSelect from "./RelationshipContactSelect";
import OTDFundationImg from '../../../../assets/images/form_icons/otd.png';

class PersonalInformationForm extends Component {
  constructor(props) {
    super(props);

    const { current_user } = this.props;

    this.state = {
      personalInformation: {
        id: { value: current_user.personal_information.id },
        avatar: { value: current_user.avatar, file: null, errors: [] },
        selfDescription: { value: current_user.personal_information.self_description, errors: [] },
        firstName: { value: current_user.personal_information.first_name, errors: [] },
        lastName: { value: current_user.personal_information.last_name, errors: [] },
        socialName: { value: current_user.personal_information.social_name, errors: [] },
        documentId: { value: current_user.identification_document_number, errors: [] },
        birthdate: { value: current_user.personal_information.birthdate, errors: [] },
        gender: { value: current_user.personal_information.gender, errors: [] },
        identify_lgtbi: { value: current_user.personal_information.identify_lgtbi, errors: [] },
        is_otd: { value: current_user.personal_information.is_otd, errors: [] },
        otherGender: { value: current_user.personal_information.other_gender, errors: [] },
        email: { value: current_user.email, errors: [] },
        lastEmail: { value: current_user.personal_information.last_email, errors: [] },
        cellphone: { value: current_user.personal_information.cellphone, errors: [] },
        landline: { value: current_user.personal_information.landline, errors: [] },
        address: { value: current_user.personal_information.address, errors: [] },
        country: {
          value: current_user.personal_information.country.value,
          label: current_user.personal_information.country.label,
          errors: []
        },
        region: {
          value: current_user.personal_information.region.value,
          label: current_user.personal_information.region.label,
          errors: []
        },
        commune: {
          value: current_user.personal_information.commune.value,
          label: current_user.personal_information.commune.label,
          errors: []
        },
        nationality: {
          value: current_user.personal_information.nationality.value,
          label: current_user.personal_information.nationality.label,
          errors: []
        },
        educationLevel: {
          value: current_user.personal_information.education_level.value,
          label: current_user.personal_information.education_level.label,
          errors: []
        },
        driversLicense: {
          value: current_user.personal_information.drivers_license.value,
          label: current_user.personal_information.drivers_license.label,
          errors: []
        },
        whoCompletedForm: {
          value: current_user.personal_information.who_completed_form.value,
          label: current_user.personal_information.who_completed_form.label,
          errors: []
        }
      },
      disabilityInformation: {
        id: { value: current_user.disability_information.id },
        hasDisability: { value: current_user.disability_information.has_disability, errors: [] },
        hasDisabilityCredential: {
          value: current_user.disability_information.has_disability_credential,
          errors: []
        },
        hasInvalidityPension: {
          value: current_user.disability_information.has_invalidity_pension,
          errors: []
        },
        hasAssociatedInstitution: {
          value: current_user.disability_information.has_associated_institution,
          errors: []
        },
        disabilityInstitution: {
          value: current_user.disability_information.disability_institution.value,
          label: current_user.disability_information.disability_institution.label,
          errors: []
        }
      },
      contactInformation: {
        id: { value: current_user.contact_information.id },
        relationship: {
          value: current_user.contact_information.relationship.value,
          label: current_user.contact_information.relationship.label,
          errors: []
        },
        contactName: { value: current_user.contact_information.contact_name, errors: [] },
        contactPhoneNumber: {
          value: current_user.contact_information.contact_phone_number,
          errors: []
        },
        contactEmail: { value: current_user.contact_information.contact_email, errors: [] }
      }
    };
  }

  handleChangePersonalInformation = (e, fieldName = null) => {
    const { personalInformation } = this.state;
    if (fieldName) {
      personalInformation[fieldName]["value"] = e.value;
      personalInformation[fieldName]["label"] = e.label;
      personalInformation[fieldName]["errors"] = [];
    } else if (e.target.id === "documentId") {
      personalInformation[e.target.name]["value"] = format(e.target.value);
      personalInformation[e.target.name]["errors"] = [];
    } else {
      personalInformation[e.target.name]["value"] = e.target.value;
      personalInformation[e.target.name]["errors"] = [];
    }
    this.setState({ personalInformation });
  };

  handleChangeDisabilityInformation = (e, fieldName = null) => {
    const { disabilityInformation } = this.state;
    if (fieldName) {
      disabilityInformation[fieldName]["value"] = e.value;
      disabilityInformation[fieldName]["label"] = e.label;
      disabilityInformation[fieldName]["errors"] = [];
    } else {
      disabilityInformation[e.target.name]["value"] = e.target.value;
      disabilityInformation[e.target.name]["errors"] = [];
    }
    this.setState({ disabilityInformation });
  };

  handleChangeContactInformation = (e, fieldName = null) => {
    const { contactInformation } = this.state;
    if (fieldName) {
      contactInformation[fieldName]["value"] = e.value;
      contactInformation[fieldName]["label"] = e.label;
      contactInformation[fieldName]["errors"] = [];
    } else {
      contactInformation[e.target.name]["value"] = e.target.value;
      contactInformation[e.target.name]["errors"] = [];
    }
    this.setState({ contactInformation });
  };

  handleClenDisabilityInformation = () => {
    const { disabilityInformation } = this.state;
    disabilityInformation.hasDisabilityCredential.value = "";
    disabilityInformation.hasInvalidityPension.value = "";
    disabilityInformation.hasAssociatedInstitution.value = "";
    this.setState({ disabilityInformation });
    this.handleCleanDisabilityInstitution();
  };

  handleClenDisabilityInformation = () => {
    const { personalInformation } = this.state;
    personalInformation.identify_lgtbi.value = "";
    personalInformation.socialName.value = "";
    personalInformation.otherGender.value = "";
    this.setState({ personalInformation });
    this.handleCleanDisabilityInstitution();
  };

  handleCleanDisabilityInstitution = () => {
    const { disabilityInformation } = this.state;
    disabilityInformation.disabilityInstitution.value = "";
    disabilityInformation.disabilityInstitution.label = "";
    this.setState({ disabilityInformation });
  };

  handleChangeAvatar = e => {
    const { personalInformation } = this.state;
    personalInformation["avatar"]["file"] = event.target.files[0];
    personalInformation["avatar"]["value"] = URL.createObjectURL(event.target.files[0]);
    personalInformation["avatar"]["errors"] = [];
    this.setState({ personalInformation });
  };

  serializeData = () => {
    let formData = new FormData();
    const { personalInformation, disabilityInformation, contactInformation } = this.state;
    formData.append("user[identification_document_number]", personalInformation.documentId.value);
    formData.append("user[email]", personalInformation.email.value);
    if (personalInformation.avatar.file) {
      formData.append("user[avatar]", personalInformation.avatar.file);
    }
    if (personalInformation.id.value) {
      formData.append("user[personal_information_attributes][id]", personalInformation.id.value);
    };
    formData.append(
      "user[personal_information_attributes][self_description]",
      personalInformation.selfDescription.value
    );
    formData.append(
      "user[personal_information_attributes][first_name]",
      personalInformation.firstName.value
    );
    formData.append(
      "user[personal_information_attributes][last_name]",
      personalInformation.lastName.value
    );
    formData.append(
      "user[personal_information_attributes][social_name]",
      personalInformation.socialName.value
    );
    formData.append(
      "user[personal_information_attributes][cellphone]",
      personalInformation.cellphone.value
    );
    formData.append(
      "user[personal_information_attributes][landline]",
      personalInformation.landline.value
    );
    formData.append(
      "user[personal_information_attributes][last_email]",
      personalInformation.lastEmail.value
    );
    formData.append(
      "user[personal_information_attributes][drivers_license]",
      personalInformation.driversLicense.value
    );
    formData.append(
      "user[personal_information_attributes][gender]",
      personalInformation.gender.value
    );
    formData.append(
      "user[personal_information_attributes][identify_lgtbi]",
      personalInformation.identify_lgtbi.value
    );
    formData.append(
      "user[personal_information_attributes][is_otd]",
      personalInformation.is_otd.value
    );
    formData.append(
      "user[personal_information_attributes][other_gender]",
      personalInformation.otherGender.value
    );
    formData.append(
      "user[personal_information_attributes][birthdate]",
      personalInformation.birthdate.value
    );
    formData.append(
      "user[personal_information_attributes][territory_commune_id]",
      personalInformation.commune.value
    );
    formData.append(
      "user[personal_information_attributes][address]",
      personalInformation.address.value
    );
    formData.append(
      "user[personal_information_attributes][territory_nationality_id]",
      personalInformation.nationality.value
    );
    formData.append(
      "user[personal_information_attributes][education_level]",
      personalInformation.educationLevel.value
    );
    formData.append(
      "user[personal_information_attributes][who_completed_form]",
      personalInformation.whoCompletedForm.value
    );
    if (disabilityInformation.id.value) {
      formData.append(
        "user[disability_information_attributes][id]",
        disabilityInformation.id.value
      );
    }
    if (disabilityInformation.hasDisability.value !== null) {
      formData.append(
        "user[disability_information_attributes][has_disability]",
        disabilityInformation.hasDisability.value
      );
    }
    if (disabilityInformation.hasDisabilityCredential.value !== null) {
      formData.append(
        "user[disability_information_attributes][has_disability_credential]",
        disabilityInformation.hasDisabilityCredential.value
      );
    }
    if (disabilityInformation.hasInvalidityPension.value !== null) {
      formData.append(
        "user[disability_information_attributes][has_invalidity_pension]",
        disabilityInformation.hasInvalidityPension.value
      );
    }
    if (disabilityInformation.hasAssociatedInstitution.value !== null) {
      formData.append(
        "user[disability_information_attributes][has_associated_institution]",
        disabilityInformation.hasAssociatedInstitution.value
      );
    }
    formData.append(
      "user[disability_information_attributes][disability_institution_id]",
      disabilityInformation.disabilityInstitution.value
    );
    if (contactInformation.id.value) {
      formData.append("user[contact_information_attributes][id]", contactInformation.id.value);
    }
    formData.append(
      "user[contact_information_attributes][relationship]",
      contactInformation.relationship.value
    );
    formData.append(
      "user[contact_information_attributes][contact_name]",
      contactInformation.contactName.value
    );
    formData.append(
      "user[contact_information_attributes][contact_email]",
      contactInformation.contactEmail.value
    );
    formData.append(
      "user[contact_information_attributes][contact_phone_number]",
      contactInformation.contactPhoneNumber.value
    );

    return formData;
  };

  setErrors = errors => {
    const { personalInformation } = this.state;
    personalInformation.selfDescription.errors = errors["personal_information.self_description"] || [];
    personalInformation.firstName.errors = errors["personal_information.first_name"] || [];
    personalInformation.lastName.errors = errors["personal_information.last_name"] || [];
    personalInformation.socialName.errors = errors["personal_information.social_name"] || [];

    personalInformation.commune.errors = errors["personal_information.territory_commune"] || [];
    personalInformation.cellphone.errors = errors["personal_information.cellphone"] || [];
    personalInformation.avatar.errors = errors.avatar || [];
    personalInformation.whoCompletedForm.errors =
      errors["personal_information.who_completed_form"] || [];
    personalInformation.documentId.errors = errors.identification_document_number || [];
    this.setState({
      ...this.state,
      personalInformation
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    let data = this.serializeData();
    savePersonaInformation(data, response => {
      if (response.status === 200) {
        this.props.show_notification("Guardado", "Guardado exitoso", "success");
        this.props.toggleTab("academicinfo");
      } else if (response.status === 422) {
        this.setErrors(response.data);
        this.props.show_notification(
          "Guardado",
          "Ocurrio un error, revisa el formulario",
          "danger"
        );
      }
    });
  };

  render() {
    const { personalInformation, disabilityInformation, contactInformation } = this.state;
    const image = personalInformation.avatar.value
     ? personalInformation.avatar.value
     : "/assets/User_Avatar_2-91bc95a48d1c6e787c0f24accbce21ae27acebed9a065689cd441caa3ceaf43d.png";

    return (
      <div>
        <Form>
          <Row>
            {/*
              <Col lg="3" md="3" className="text-center">
                <div className="avatar_container">
                  <FieldWrapper errors={personalInformation.avatar.errors}>
                    <img className="rounded-circle mx-auto d-block" alt="User Avatar" src={image} />
                    <Input
                      id="avatar"
                      className="inputfile input-primary"
                      type="file"
                      onChange={this.handleChangeAvatar}
                    />
                    <label htmlFor="avatar">
                      <small className="text-muted">
                        <i className="fas fa-upload mr-2"></i>
                        Sube o Arrastra tu foto de perfil
                      </small>
                    </label>
                  </FieldWrapper>
                </div>

                <div className="avatar_container">
                  <FormGroup>
                    <Label htmlFor="selfDescription" className="">
                      Deja una nota para que puedan conocerte mejor. Max 140 caracteres.
                    </Label>
                    <FieldWrapper errors={personalInformation.selfDescription.errors}>
                      <Input
                        id="selfDescription"
                        name="selfDescription"
                        placeholder="Coloca tu introducción aquí"
                        type="text"
                        onChange={this.handleChangePersonalInformation}
                        value={personalInformation.selfDescription.value}
                        maxLength={140}
                        autoFocus
                      />
                    </FieldWrapper>
                  </FormGroup>
                </div>
              </Col>
            */}
            <Col lg="12" md="12">
              <Row>
                <Col md="4">
                  <FormGroup>
                    <Label htmlFor="firstName" className="input_required">
                      Nombres
                    </Label>
                    <FieldWrapper errors={personalInformation.firstName.errors}>
                      <Input
                        id="firstName"
                        name="firstName"
                        placeholder="Nombres"
                        type="text"
                        onChange={this.handleChangePersonalInformation}
                        value={personalInformation.firstName.value}
                        autoFocus
                      />
                    </FieldWrapper>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label htmlFor="lastName" className="input_required">
                      Apellidos
                    </Label>
                    <FieldWrapper errors={personalInformation.lastName.errors}>
                      <Input
                        id="lastName"
                        name="lastName"
                        placeholder="Apellidos"
                        type="text"
                        onChange={this.handleChangePersonalInformation}
                        value={personalInformation.lastName.value}
                      />
                    </FieldWrapper>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label htmlFor="documentId" className="input_required">
                      RUT
                    </Label>
                    <FieldWrapper errors={personalInformation.documentId.errors}>
                      <Input
                        id="documentId"
                        name="documentId"
                        placeholder="RUT"
                        type="text"
                        onChange={this.handleChangePersonalInformation}
                        maxLength={12}
                        value={format(personalInformation.documentId.value)}
                      />
                    </FieldWrapper>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label htmlFor="birthdate">Fecha de Nacimiento</Label>
                    <Input
                      id="birthdate"
                      name="birthdate"
                      placeholder="Fecha de Nacimiento"
                      type="date"
                      onChange={this.handleChangePersonalInformation}
                      value={personalInformation.birthdate.value || ""}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label htmlFor="nationality">Nacionalidad</Label>
                    <NationalitiesSelect
                      handleChange={this.handleChangePersonalInformation}
                      value={personalInformation.nationality}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label htmlFor="whoCompletedForm" className="input_required">
                      Este formulario ha sido completado por
                    </Label>
                    <FieldWrapper errors={personalInformation.whoCompletedForm.errors}>
                      <WhoCompletedFormSelect
                        handleChange={this.handleChangePersonalInformation}
                        value={personalInformation.whoCompletedForm}
                      />
                    </FieldWrapper>
                  </FormGroup>
                </Col>
                <hr/>
                <Col md="4">
                  <FormGroup tag="fieldset">
                    <legend className="col-form-Label pt-0 h6" id="genderLabel">
                      Género
                    </legend>
                    <FormGroup role="radiogroup" aria-labelledby="genderLabel">
                      <FormGroup check inline>
                        <Label check htmlFor="genderCheck1">
                          <Input
                            id="genderCheck1"
                            name="gender"
                            type="radio"
                            value="female"
                            onChange={this.handleChangePersonalInformation}
                            checked={personalInformation.gender.value === "female"}
                            onChange={e => {
                              this.handleChangePersonalInformation(e);
                              this.handleClenPersonalInformation();
                            }}
                          />
                          Femenino
                        </Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Label check htmlFor="genderCheck2">
                          <Input
                            id="genderCheck2"
                            name="gender"
                            type="radio"
                            value="male"
                            checked={personalInformation.gender.value === "male"}
                            onChange={e => {
                              this.handleChangePersonalInformation(e);
                              this.handleClenPersonalInformation();
                            }}
                          />
                          Masculino
                        </Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Label check htmlFor="genderCheck4">
                          <Input
                            id="genderCheck4"
                            name="gender"
                            type="radio"
                            value="fluid_gender"
                            onChange={this.handleChangePersonalInformation}
                            checked={personalInformation.gender.value === "fluid_gender"}
                          />
                          Fluido
                        </Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Label check htmlFor="genderCheck5">
                          <Input
                            id="genderCheck5"
                            name="gender"
                            type="radio"
                            value="non_binary"
                            onChange={this.handleChangePersonalInformation}
                            checked={personalInformation.gender.value === "non_binary"}
                          />
                          No Binario
                        </Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Label check htmlFor="genderCheck3">
                          <Input
                            id="genderCheck3"
                            name="gender"
                            type="radio"
                            value="other"
                            onChange={this.handleChangePersonalInformation}
                            checked={personalInformation.gender.value === "other"}
                          />
                          Otro
                        </Label>
                      </FormGroup>
                    </FormGroup>
                  </FormGroup>
                </Col>
                {personalInformation.gender.value === "other" ? (
                    <Col md="4">
                      <FormGroup>
                        <Label htmlFor="otherGender" className="input_required">
                          Indique otro género
                        </Label>
                        <FieldWrapper errors={personalInformation.otherGender.errors}>
                          <Input
                            id="otherGender"
                            name="otherGender"
                            type="text"
                            onChange={this.handleChangePersonalInformation}
                            value={personalInformation.otherGender.value}
                          />
                        </FieldWrapper>
                      </FormGroup>
                    </Col>
                  ) : null}
                {personalInformation.gender.value === "other" || personalInformation.gender.value === "non_binary" || personalInformation.gender.value === "fluid_gender" ? (
                  <Col md="4">
                    <FormGroup>
                      <Label htmlFor="socialName" className="">
                        Nombre Social
                      </Label>
                      <FieldWrapper errors={personalInformation.socialName.errors}>
                        <Input
                          id="socialName"
                          name="socialName"
                          type="text"
                          onChange={this.handleChangePersonalInformation}
                          value={personalInformation.socialName.value}
                        />
                      </FieldWrapper>
                      <small id="passwordHelpBlock" className="form-text text-muted">
                        Tu nombre social aparecerá visible en la plataforma. Este, reemplazará a tu nombre antiguo.
                      </small>
                    </FormGroup>
                  </Col>
                ) : null}
                {personalInformation.gender.value === "other" || personalInformation.gender.value === "non_binary" || personalInformation.gender.value === "fluid_gender" ? (
                  <Col md="4">
                    <FormGroup tag="fieldset">
                      <legend className="col-form-Label pt-0 h6" id="lgtbiLabel">
                        ¿Te identificas como parte de la población LGTBIQ+?
                        <div className="d-inline text-primary ml-2">
                          <i className="fas fa-info-circle" id="LgtbiqInfo"></i>
                          <UncontrolledTooltip placement="right" target="LgtbiqInfo">
                            LGTBIQ+ es la sigla compuesta por las iniciales de las palabras Lesbiana, Gay, Transgénero, Bisexual, Intersexual, Queer. Agrupa a las personas con las orientaciones sexuales e identidades de género relativas a esas palabras.
                          </UncontrolledTooltip>
                        </div>
                      </legend>
                      <FormGroup role="radiogroup" aria-labelledby="lgtbiLabel">
                        <FormGroup check inline>
                          <Label check htmlFor="lgtbiCheck1">
                            <Input
                              id="lgtbiCheck1"
                              name="identify_lgtbi"
                              type="radio"
                              value={true}
                              onChange={this.handleChangePersonalInformation}
                              checked={
                                personalInformation.identify_lgtbi.value === "true" ||
                                personalInformation.identify_lgtbi.value === true
                              }
                            />
                            Si
                          </Label>
                        </FormGroup>
                        <FormGroup check inline>
                          <Label check htmlFor="lgtbiCheck2">
                            <Input
                              id="lgtbiCheck2"
                              name="identify_lgtbi"
                              type="radio"
                              value={false}
                              onChange={this.handleChangePersonalInformation}
                              checked={
                                personalInformation.identify_lgtbi.value === "false" ||
                                personalInformation.identify_lgtbi.value === false
                              }
                            />
                            No
                          </Label>
                        </FormGroup>
                      </FormGroup>
                    </FormGroup>
                  </Col>
                ) : null}
              </Row>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md="12">
              <h3 className="h6 font-weight-bold">
                <i className="far fa-address-book mr-2"></i>
                Datos de Contacto
              </h3>
            </Col>
            <Col lg="4" md="4">
              <FormGroup>
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  name="email"
                  placeholder="Email"
                  type="text"
                  onChange={this.handleChangePersonalInformation}
                  value={personalInformation.email.value}
                />
              </FormGroup>
            </Col>
            <Col lg="4" md="4">
              <FormGroup>
                <Label htmlFor="lastEmail">Email adicional</Label>
                <Input
                  id="lastEmail"
                  name="lastEmail"
                  placeholder="Email adicional"
                  type="text"
                  onChange={this.handleChangePersonalInformation}
                  value={personalInformation.lastEmail.value}
                />
              </FormGroup>
            </Col>
            <Col lg="4" md="4">
              <FormGroup>
                <Label htmlFor="cellphone" className="input_required">
                  Teléfono de Contacto
                </Label>
                <FieldWrapper errors={personalInformation.cellphone.errors}>
                  <Input
                    id="cellphone"
                    name="cellphone"
                    placeholder=" Teléfono de Contacto"
                    type="text"
                    onChange={this.handleChangePersonalInformation}
                    value={personalInformation.cellphone.value}
                  />
                </FieldWrapper>
              </FormGroup>
            </Col>
            {/* <Col lg="3" md="4">
              <FormGroup>
                <Label htmlFor="landline">Teléfono</Label>
                <Input
                  id="landline"
                  name="landline"
                  placeholder="Teléfono"
                  type="text"
                  onChange={this.handleChangePersonalInformation}
                  value={personalInformation.landline.value}
                />
              </FormGroup>
            </Col> */}
          </Row>
          <hr />
          <Row>
            <Col md="12">
              <h3 className="h6 font-weight-bold">
                <i className="fas fa-map-marker-alt mr-2"></i>
                Domicilio Actual
              </h3>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label htmlFor="address">Dirección</Label>
                <Input
                  id="address"
                  name="address"
                  placeholder="Dirección"
                  type="text"
                  onChange={this.handleChangePersonalInformation}
                  value={personalInformation.address.value}
                />
              </FormGroup>
            </Col>
            <TerritoryNestedSelects
              handleChange={this.handleChangePersonalInformation}
              countryValue={personalInformation.country}
              regionValue={personalInformation.region}
              communeValue={personalInformation.commune}
            />
          </Row>
          <hr />
          <Row>
            <Col md="4">
              <FormGroup>
                <Label htmlFor="educationLevel">Nivel Educacional</Label>
                <EducationLevelsSelect
                  handleChange={this.handleChangePersonalInformation}
                  value={personalInformation.educationLevel}
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label htmlFor="driversLicence">Licencia de Conducir</Label>
                <DriversLicensesSelect
                  handleChange={this.handleChangePersonalInformation}
                  value={personalInformation.driversLicense}
                />
              </FormGroup>
            </Col>
          </Row>
          {personalInformation.gender.value === "other" || personalInformation.gender.value === "non_binary" || personalInformation.gender.value === "fluid_gender" ? (
            <Card className="mt-3 mb-3" id="lgtbiData">
              <CardBody>
                <Row className="d-flex">
                  <Col md="6">
                    <Row>
                      <Col sm="4">
                        <img
                          className="img-fluid grayscale-img"
                          alt=""
                          src={OTDFundationImg}
                        />
                      </Col>
                      <Col sm="8">
                        <FormGroup tag="fieldset" className="mb-0">
                          <legend className="col-form-Label pt-0 h6" id="otdFundationLabel">
                            ¿Eres parte de la Asociación OTD "Organizando Trans Diversidades"?
                          </legend>
                          <FormGroup role="radiogroup" aria-labelledby="otdFundationLabel">
                            <FormGroup check inline>
                              <Label check htmlFor="otdFundation1">
                                <Input
                                  id="otdFundation1"
                                  name="is_otd"
                                  type="radio"
                                  value={true}
                                  onChange={this.handleChangePersonalInformation}
                                  checked={
                                    personalInformation.is_otd.value === "true" ||
                                    personalInformation.is_otd.value === true
                                  }
                                />
                                Si
                              </Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Label check htmlFor="otdFundation2">
                                <Input
                                  id="otdFundation2"
                                  name="is_otd"
                                  type="radio"
                                  value={false}
                                  onChange={this.handleChangePersonalInformation}
                                  checked={
                                    personalInformation.is_otd.value === "false" ||
                                    personalInformation.is_otd.value === false
                                  }
                                />
                                No
                              </Label>
                            </FormGroup>
                          </FormGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          ) : null}
          <Card className="mt-3 mb-3" id="accessibilityData">
            <CardBody>
              <Row className="d-flex">
                <Col md="6" className="order-2 order-md-1">
                  <Row>
                    <Col sm="4">
                      <img
                        className="img-fluid"
                        alt=""
                        src="/assets/form_icons/disability-f43733779851b1931e097a94add1b92deb9df8acdb5edff2dccd61af7e33f844.png"
                      />
                    </Col>
                    <Col sm="8">
                      <FormGroup tag="fieldset">
                        <legend className="col-form-Label pt-0 h6" id="hasDisabilityLabel">
                          ¿Ud tiene o presenta alguna situación de Discapacidad?
                        </legend>
                        <FormGroup check role="radiogroup" aria-labelledby="hasDisabilityLabel">
                          <Label check htmlFor="hasDisabilityYes" className="d-block">
                            <Input
                              id="hasDisabilityYes"
                              name="hasDisability"
                              type="radio"
                              value={true}
                              onChange={this.handleChangeDisabilityInformation}
                              checked={
                                disabilityInformation.hasDisability.value === "true" ||
                                disabilityInformation.hasDisability.value === true
                              }
                            />{" "}
                            Si
                          </Label>
                          <Label check htmlFor="hasDisabilityNo" className="d-block">
                            <Input
                              id="hasDisabilityNo"
                              name="hasDisability"
                              type="radio"
                              value={false}
                              onChange={e => {
                                this.handleChangeDisabilityInformation(e);
                                this.handleClenDisabilityInformation();
                              }}
                              checked={
                                disabilityInformation.hasDisability.value === "false" ||
                                disabilityInformation.hasDisability.value === false
                              }
                            />{" "}
                            No
                          </Label>
                        </FormGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col md="6" className="order-1 order-md-2">
                  <Card outline color="info" className="alert-info">
                    <CardBody>
                      Al seleccionar alguna de estas opciones deberás completar posteriormente
                      un&nbsp;
                      <strong>
                        <i className="fas fa-universal-access mr-1"></i>
                        Perfil Funcional
                      </strong>
                      &nbsp;para conocerte más y poder recomendarte empleos más acordes con tus
                      necesidades.
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md="6">
                  <Row>
                    <Col sm="4">
                      <img
                        className="img-fluid"
                        alt=""
                        src="/assets/form_icons/credential-370579e450221191e26f9a12f459ff44ace524d4230be8fecad68675e578adb2.png"
                      />
                    </Col>
                    <Col sm="8">
                      <FormGroup tag="fieldset">
                        <legend className="col-form-Label pt-0 h6" id="DisabilityInformationLabel">
                          ¿Tiene credencial de discapacidad?
                        </legend>
                        <FormGroup
                          check
                          role="radiogroup"
                          aria-labelledby="DisabilityInformationLabel"
                        >
                          <Label check htmlFor="hasDisabilityCredentialYes" className="d-block">
                            <Input
                              id="hasDisabilityCredentialYes"
                              name="hasDisabilityCredential"
                              type="radio"
                              value={true}
                              onChange={this.handleChangeDisabilityInformation}
                              checked={
                                disabilityInformation.hasDisabilityCredential.value === "true" ||
                                disabilityInformation.hasDisabilityCredential.value === true
                              }
                            />{" "}
                            Si
                          </Label>
                          <Label check htmlFor="hasDisabilityCredentialNo" className="d-block">
                            <Input
                              id="hasDisabilityCredentialNo"
                              name="hasDisabilityCredential"
                              type="radio"
                              value={false}
                              onChange={this.handleChangeDisabilityInformation}
                              checked={
                                disabilityInformation.hasDisabilityCredential.value === "false" ||
                                disabilityInformation.hasDisabilityCredential.value === false
                              }
                            />{" "}
                            No
                          </Label>
                        </FormGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col md="6">
                  <Row>
                    <Col sm="4">
                      <img
                        className="img-fluid"
                        alt=""
                        src="/assets/form_icons/payment-b3574f1095b85b9e301ea2b2ce80e2ac361533978bfbfb97f6e0c001d428d628.png"
                      />
                    </Col>
                    <Col sm="8">
                      <FormGroup tag="fieldset">
                        <legend className="col-form-Label pt-0 h6" id="hasInvalidityPensionLabel">
                          ¿Cuenta con pensión de invalidez?
                        </legend>
                        <FormGroup
                          check
                          role="radiogroup"
                          aria-labelledby="hasInvalidityPensionLabel"
                        >
                          <Label check htmlFor="hasInvalidityPensionYes" className="d-block">
                            <Input
                              id="hasInvalidityPensionYes"
                              name="hasInvalidityPension"
                              type="radio"
                              value={true}
                              onChange={this.handleChangeDisabilityInformation}
                              checked={
                                disabilityInformation.hasInvalidityPension.value === "true" ||
                                disabilityInformation.hasInvalidityPension.value === true
                              }
                            />{" "}
                            Si
                          </Label>
                          <Label check htmlFor="hasInvalidityPensionNo" className="d-block">
                            <Input
                              id="hasInvalidityPensionNo"
                              name="hasInvalidityPension"
                              type="radio"
                              value={false}
                              onChange={this.handleChangeDisabilityInformation}
                              checked={
                                disabilityInformation.hasInvalidityPension.value === "false" ||
                                disabilityInformation.hasInvalidityPension.value === false
                              }
                            />{" "}
                            No
                          </Label>
                        </FormGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md="6">
                  <Row>
                    <Col sm="4">
                      <img
                        className="img-fluid"
                        alt=""
                        src="/assets/form_icons/institution-640f7233346cb52d7375d18b9f5b36df41ab37303ee3e82d19150c3541933eef.png"
                      />
                    </Col>
                    <Col sm="8">
                      <FormGroup tag="fieldset">
                        <legend
                          className="col-form-Label pt-0 h6"
                          id="hasAssociatedInstitutionLabel"
                        >
                          ¿Está asociado a una institución de RedIncluye?
                        </legend>
                        <FormGroup
                          check
                          role="radiogroup"
                          aria-labelledby="hasAssociatedInstitutionLabel"
                        >
                          <Label check htmlFor="hasAssociatedInstitutionYes" className="d-block">
                            <Input
                              id="hasAssociatedInstitutionYes"
                              name="hasAssociatedInstitution"
                              type="radio"
                              value={true}
                              onChange={this.handleChangeDisabilityInformation}
                              checked={
                                disabilityInformation.hasAssociatedInstitution.value === "true" ||
                                disabilityInformation.hasAssociatedInstitution.value === true
                              }
                            />{" "}
                            Si
                          </Label>
                          <Label check htmlFor="hasAssociatedInstitutionNo" className="d-block">
                            <Input
                              id="hasAssociatedInstitutionNo"
                              name="hasAssociatedInstitution"
                              type="radio"
                              value={false}
                              onChange={e => {
                                this.handleChangeDisabilityInformation(e);
                                this.handleCleanDisabilityInstitution();
                              }}
                              checked={
                                disabilityInformation.hasAssociatedInstitution.value === "false" ||
                                disabilityInformation.hasAssociatedInstitution.value === false
                              }
                            />{" "}
                            No
                          </Label>
                        </FormGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col md="6">
                  {disabilityInformation.hasAssociatedInstitution.value === "true" ||
                  disabilityInformation.hasAssociatedInstitution.value === true ? (
                    <FormGroup>
                      <Label htmlFor="disabilityInstitution">Institución</Label>
                      <FoundationsSelect
                        handleChange={this.handleChangeDisabilityInformation}
                        value={disabilityInformation.disabilityInstitution}
                      />
                    </FormGroup>
                  ) : null}
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card className="bg-light mb-3">
            <CardBody>
              <Row>
                <Col md="12">
                  <h3 className="h6 font-weight-bold mb-3">
                    <i className="fas fa-user-friends mr-2"></i>
                    Datos de Contacto Alternativo
                    <small className="mt-1 form-text">
                      Un Familiar o amigo(a) con el que puedan contactarse para ubicarte
                    </small>
                  </h3>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="relationship">Parentesco</Label>
                    <FieldWrapper errors={contactInformation.relationship.errors}>
                      <RelationshipContactSelect
                        handleChange={this.handleChangeContactInformation}
                        value={contactInformation.relationship}
                      />
                    </FieldWrapper>
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="contactName">Nombre</Label>
                    <Input
                      id="contactName"
                      name="contactName"
                      placeholder="Nombre"
                      type="text"
                      onChange={this.handleChangeContactInformation}
                      value={contactInformation.contactName.value}
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="contactPhoneNumber">Teléfono</Label>
                    <Input
                      id="contactPhoneNumber"
                      name="contactPhoneNumber"
                      placeholder="Teléfono"
                      type="text"
                      onChange={this.handleChangeContactInformation}
                      value={contactInformation.contactPhoneNumber.value}
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="contactEmail">Email</Label>
                    <Input
                      id="contactEmail"
                      name="contactEmail"
                      placeholder="Email"
                      type="text"
                      onChange={this.handleChangeContactInformation}
                      value={contactInformation.contactEmail.value}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Row>
            <Col md="12" className="text-right">
              <Button color="primary" className="my-4" onClick={this.handleSubmit}>
                Guardar
                <i className="fas fa-arrow-right ml-2"></i>
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default PersonalInformationForm;
