import React from "react";
import { Row } from "reactstrap";
import _ from "lodash";
import PublicationsHit from "./Hit";

const PublicationsHits = ({ hits, is_public, canAdmin }) => {
  return (
    <div>
      {_.map(hits, hit => (
        <PublicationsHit key={hit._id} hit={hit} is_public={is_public} canAdmin={canAdmin} />
      ))}
    </div>
  );
};

export default PublicationsHits;
