import React, { useState, useEffect } from "react";
import Select from "react-select";
import { getEducationInstitutions } from "./Axios";

const EducationInstitutionsSelect = ({ handleChangePersonalEducation, value }) => {
  const [educationInstitutions, setEducationInstitutions] = useState([]);

  const handleChange = inputValue => {
    handleChangePersonalEducation(inputValue, "educationInstitution");
  };

  useEffect(() => {
    const newEducationInstitutions = educationInstitutions;
    getEducationInstitutions(response => {
      response.data.map(institution => {
        newEducationInstitutions.push({ value: institution.id, label: institution.name });
      });
    });
    setEducationInstitutions(newEducationInstitutions);
  }, []);

  return (
    <Select
      inputId="educationInstitution"
      onChange={handleChange}
      options={educationInstitutions}
      placeholder="Seleccione una opción"
      value={value.value ? value : null}
    />
  );
};

export default EducationInstitutionsSelect;
