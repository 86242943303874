import React, { Component } from "react";
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody
} from "reactstrap";
import _ from "lodash";
import classnames from "classnames";
import PublicationForm from "../publication/Form";
import PublicationPreview from "./Preview";
import FunctionalPublicationProfile from "../functional_requirements/FunctionalPublicationProfile";
import AccessibilityPublicationForm from "../functional_requirements/AccessibilityPublicationForm";
import { saveCourse, switchState } from "./Axios";
import Notifications from "../commons/Notifications";

class PublicationStepperForm extends Component {
  constructor(props) {
    super(props);

    const { course, companyCentral, courseFunctionalAnwers } = this.props;

    this.state = {
      activeTab: "publicationForm",
      course: {
        id: { value: course ? course.id : null },
        title: { value: course ? course.title : "", errors: [] },
        contractType: {
          value: course ? course.contractType.value : "",
          label: course ? course.contractType.label : "",
          errors: []
        },
        jobDescription: {
          value: course ? course.jobDescription : "",
          errors: []
        },
        vacancies: { value: course ? course.vacancies : 0, errors: [] },
        laboralArea: {
          value: course ? course.laboralArea.id : "",
          label: course ? course.laboralArea.name : "",
          errors: [],
          path: course
            ? course.laboralArea.attachment
            : "//via.placeholder.com/150x150"
        },
        companyCentral: companyCentral,
        minisitePublication: {
          id: { value: course ? course.minisitePublication.id : null },
          aasmState: {
            value: course ? course.minisitePublication.aasmState : null
          },
          country: {
            value: course ? course.minisitePublication.country.id : "",
            label: course ? course.minisitePublication.country.name : "",
            errors: []
          },
          region: {
            value: course ? course.minisitePublication.region.id : "",
            label: course ? course.minisitePublication.region.name : "",
            errors: []
          },
          commune: {
            value: course ? course.minisitePublication.commune.id : "",
            label: course ? course.minisitePublication.commune.name : "",
            errors: []
          },
          address: {
            value: course ? course.minisitePublication.address : "",
            errors: []
          },
          desactivateAt: {
            value: course ? course.minisitePublication.desactivateAt : "",
            errors: []
          },
          preferredSchedule: {
            value: course ? course.minisitePublication.preferredSchedule : "",
            errors: []
          },
          preferredLaborDay: {
            value: course ? course.minisitePublication.preferredLaborDay : "",
            errors: []
          },
          preferredTurn: {
            value: course ? course.minisitePublication.preferredTurn : "",
            errors: []
          },
          dressCode: {
            value: course ? course.minisitePublication.dressCode : "",
            errors: []
          },
          salaryOffered: {
            value: course ? course.minisitePublication.salaryOffered : 0,
            errors: []
          },
          showSalaryOffered: {
            value: course ? course.minisitePublication.showSalaryOffered : "",
            errors: []
          },
          isConfidential: {
            value: course ? course.minisitePublication.isConfidential : "",
            errors: []
          },
          educationLevel: {
            value: course
              ? course.minisitePublication.educationLevel.value
              : "",
            label: course
              ? course.minisitePublication.educationLevel.label
              : "",
            errors: []
          },
          requirements: {
            value: course ? course.minisitePublication.requirements : "",
            errors: []
          },
          knowledges: {
            value: course ? course.minisitePublication.knowledges : "",
            errors: []
          },
          benefits: {
            value: course ? course.minisitePublication.benefits : "",
            errors: []
          },
          experienceYears: {
            value: course ? course.minisitePublication.experienceYears : 0,
            errors: []
          },
          publicationQuestions: course
            ? course.minisitePublication.publicationQuestions
            : [],
          deletedPublicationQuestions: []
        },
        functionalAnswers: course ? course.functionalAnswers : [],
        accessibilityAnswers: course ? course.accessibilityAnswers : []
      }
    };

    this.showNotification = this.showNotification.bind(this);
  }

  showNotification = (title, message, type) => {
    this.refs.notifications.addNotifications(title, message, type);
  };

  handleChangeCourse = (e, fieldName = null) => {
    const { course } = this.state;
    if (fieldName) {
      course[fieldName]["value"] = e.value;
      course[fieldName]["label"] = e.label;
      if (fieldName === "laboralArea") {
        course[fieldName]["path"] = e.path;
      }
      course[fieldName]["errors"] = [];
    } else {
      course[e.target.name]["value"] = e.target.value;
      course[e.target.name]["errors"] = [];
    }

    this.setState({ course });
  };

  handleAddQuestion = (value, isDefault) => {
    let { course } = this.state;
    course.minisitePublication.publicationQuestions.push({
      id: "",
      value: value,
      errors: [],
      destroy: false,
      isDefault: isDefault
    });
    this.setState({
      ...this.state.course,
      course
    });
  };

  handleRemoveQuestion = id => {
    let { course } = this.state;
    if (course.minisitePublication.publicationQuestions[id].id) {
      course.minisitePublication.publicationQuestions[id].destroy = true;
      course.minisitePublication.deletedPublicationQuestions.push(
        course.minisitePublication.publicationQuestions[id]
      );
    }
    course.minisitePublication.publicationQuestions.splice(id, 1);
    this.setState({
      ...this.state.course,
      course
    });
  };

  handleChangeQuestion = (value, id) => {
    let { course } = this.state;
    course.minisitePublication.publicationQuestions[id]["value"] = value;
    course.minisitePublication.publicationQuestions[id]["errors"] = [];
    this.setState({
      ...this.state.course,
      course
    });
  };

  handleChangeMinisitePublication = (e, fieldName = null) => {
    const { course } = this.state;
    if (fieldName === "isConfidential") {
      course["minisitePublication"][fieldName]["value"] = e;
      course["minisitePublication"][fieldName]["errors"] = [];
    } else if (fieldName) {
      course["minisitePublication"][fieldName]["value"] = e.value;
      course["minisitePublication"][fieldName]["label"] = e.label;
      course["minisitePublication"][fieldName]["errors"] = [];
    } else if (e.target.name === "showSalaryOffered") {
      course["minisitePublication"][e.target.name]["value"] = e.target.checked;
      course["minisitePublication"][e.target.name]["errors"] = [];
    } else {
      course["minisitePublication"][e.target.name]["value"] = e.target.value;
      course["minisitePublication"][e.target.name]["errors"] = [];
    }

    this.setState({ course });
  };

  handleChangeJobDescription = description => {
    const { course } = this.state;
    course.jobDescription.value = description;
    course.jobDescription.errors = [];
    this.setState({ course });
  };

  handleChangeBenefits = benefits => {
    const { course } = this.state;
    course.minisitePublication.benefits.value = benefits;
    course.minisitePublication.benefits.errors = [];
    this.setState({ course });
  };

  toggleTab = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        ...this.state,
        activeTab: tab
      });
    }
  };

  serializeData = () => {
    const { course } = this.state;
    let formData = new FormData();

    if (course.id.value) {
      formData.append("course[id]", course.id.value);
    }
    formData.append("course[title]", course.title.value);
    if (course.contractType.value) {
      formData.append("course[contract_type]", course.contractType.value);
    }
    formData.append("course[job_description]", course.jobDescription.value);
    formData.append("course[laboral_area_id]", course.laboralArea.value);
    formData.append("course[vacancies]", course.vacancies.value);
    if (course.minisitePublication.id.value) {
      formData.append(
        "course[minisite_publication_attributes][id]",
        course.minisitePublication.id.value
      );
    }
    formData.append(
      "course[minisite_publication_attributes][title]",
      course.title.value
    );
    formData.append(
      "course[minisite_publication_attributes][address]",
      course.minisitePublication.address.value
    );
    formData.append(
      "course[minisite_publication_attributes][territory_commune_id]",
      course.minisitePublication.commune.value
    );
    formData.append(
      "course[minisite_publication_attributes][desactivate_at]",
      course.minisitePublication.desactivateAt.value
    );
    formData.append(
      "course[minisite_publication_attributes][preferred_schedule]",
      course.minisitePublication.preferredSchedule.value
    );
    formData.append(
      "course[minisite_publication_attributes][preferred_labor_day]",
      course.minisitePublication.preferredLaborDay.value
    );
    formData.append(
      "course[minisite_publication_attributes][preferred_turn]",
      course.minisitePublication.preferredTurn.value
    );
    formData.append(
      "course[minisite_publication_attributes][preferred_turn]",
      course.minisitePublication.preferredTurn.value
    );
    formData.append(
      "course[minisite_publication_attributes][dress_code]",

      course.minisitePublication.dressCode.value
    );
    formData.append(
      "course[minisite_publication_attributes][is_confidential]",
      course.minisitePublication.isConfidential.value
    );
    if (course.minisitePublication.educationLevel.value) {
      formData.append(
        "course[minisite_publication_attributes][education_level]",
        course.minisitePublication.educationLevel.value
      );
    }
    formData.append(
      "course[minisite_publication_attributes][show_salary_offered]",
      course.minisitePublication.showSalaryOffered.value
    );
    formData.append(
      "course[minisite_publication_attributes][requirements]",
      course.minisitePublication.requirements.value
    );
    formData.append(
      "course[minisite_publication_attributes][knowledges]",
      course.minisitePublication.knowledges.value
    );
    formData.append(
      "course[minisite_publication_attributes][benefits]",
      course.minisitePublication.benefits.value
    );
    formData.append(
      "course[minisite_publication_attributes][experience_years]",
      course.minisitePublication.experienceYears.value
    );
    if (
      course.minisitePublication.publicationQuestions.length > 0 ||
      course.minisitePublication.deletedPublicationQuestions.length > 0
    ) {
      _.map(
        course.minisitePublication.publicationQuestions,
        (question, key) => {
          formData.append(
            `course[minisite_publication_attributes][publication_questions_attributes][${key}][id]`,
            question.id
          );
          formData.append(
            `course[minisite_publication_attributes][publication_questions_attributes][${key}][name]`,
            question.value
          );
          formData.append(
            `course[minisite_publication_attributes][publication_questions_attributes][${key}][_destroy]`,
            question.destroy
          );
          formData.append(
            `course[minisite_publication_attributes][publication_questions_attributes][${key}][is_default]`,
            question.isDefault
          );
        }
      );
      _.map(
        course.minisitePublication.deletedPublicationQuestions,
        (question, key) => {
          formData.append(
            `course[minisite_publication_attributes][publication_questions_attributes][${key}][id]`,
            question.id
          );
          formData.append(
            `course[minisite_publication_attributes][publication_questions_attributes][${key}][name]`,
            question.value
          );
          formData.append(
            `course[minisite_publication_attributes][publication_questions_attributes][${key}][_destroy]`,
            question.destroy
          );
        }
      );
    }

    return formData;
  };

  setErrors = errors => {
    const { course } = this.state;
    course.title.errors = errors.title;
    course.jobDescription.errors = errors.job_description;
    course.laboralArea.errors = errors.laboral_area;
    course.vacancies.errors = errors.vacancies;
    course.minisitePublication.desactivateAt.errors =
      errors["minisite_publication.desactivate_at"];
    course.minisitePublication.address.errors =
      errors["minisite_publication.address"];
    course.minisitePublication.country.errors =
      errors["minisite_publication.territory_commune"];
    course.minisitePublication.region.errors =
      errors["minisite_publication.territory_commune"];
    course.minisitePublication.commune.errors =
      errors["minisite_publication.territory_commune"];
    course.minisitePublication.preferredSchedule.errors =
      errors["minisite_publication.preferred_schedule"];
    course.minisitePublication.preferredLaborDay.errors =
      errors["minisite_publication.preferred_labor_day"];
    course.minisitePublication.preferredTurn.errors =
      errors["minisite_publication.preferred_turn"];
    course.minisitePublication.dressCode.errors =
      errors["minisite_publication.dress_code"];
    course.minisitePublication.experienceYears.errors =
      errors["minisite_publication.experience_years"];
    course.minisitePublication.salaryOffered.errors =
      errors["minisite_publication.salary_offered"];
    _.map(course.minisitePublication.publicationQuestions, question => {
      if (question.value === "")
        question.errors =
          errors["minisite_publication.publication_questions.name"];
    });

    this.setState({
      ...this.state,
      course
    });
  };

  handleSubmitCourse = e => {
    e.preventDefault();

    const data = this.serializeData();
    saveCourse(data, response => {
      if (response.status === 200 || response.status === 201) {
        const { course } = this.state;
        course.id.value = response.data.id;
        course.accessibilityAnswers = response.data.accessibilityAnswers;
        course.functionalAnswers = response.data.functionalAnswers;
        course.minisitePublication.id.value =
          response.data.minisitePublication.id;
        course.minisitePublication.publicationQuestions =
          response.data.minisitePublication.publicationQuestions;
        this.setState({ ...this.state.course, course });
        this.toggleTab("functionalProfileForm");
        this.showNotification(
          "Genial",
          "Información guardad con éxito",
          "info"
        );
      } else if (response.status === 422) {
        this.setErrors(response.data);
        this.showNotification(
          "Ops",
          "Ocurrio un error, revisa el formulario",
          "danger"
        );
      }
    });
  };

  handleSubmitPublication = e => {
    const { course } = this.state;
    e.preventDefault();
    const data = {
      publication_id: course.minisitePublication.id.value,
      user: this.props.user,
      action: "activate"
    };
    switchState(data, response => {
      if (response.status === 201) {
        window.location.href = `/publications/${response.data.id}`;
      } else if (response.status === 422) {
        this.setErrors(response.data);
        this.refs.notifications.addNotifications(
          "Ops",
          "Ocurrio un error, revisa el formulario",
          "danger"
        );
        this.setState({
          ...this.state,
          activeTab: "publicationForm"
        });
      } else if (response.status === 403) {
        this.refs.notifications.addNotifications(
          "Ops",
          response.data,
          "danger"
        );
      }
    });
  };

  renderHeader = () => {
    switch (this.state.activeTab) {
      case "publicationForm":
        return (
          <p className="small text-muted mb-sm-0">
            Los ítems marcados con asterisco (
            <span className="text-danger font-weight-bold asterics">*</span>
            &nbsp;/&nbsp;
            <span className="text-secondary font-weight-bold asterics">*</span>)
            son requeridos para publicar y obtener un&nbsp;
            <span className="gradient-text-4t font-weight-bold">MATCH</span>
            &nbsp;más preciso.
          </p>
        );
      case "functionalProfileForm":
        return (
          <p className="small text-muted mb-sm-0">
            Completa el perfil funcional de tu oferta laboral. Esta información
            es primordial para hacer&nbsp;
            <span className="gradient-text-4t font-weight-bold">MATCH</span>
            &nbsp;con los candidatos.
          </p>
        );
      case "accessibilityRequirementsForm":
        return (
          <p className="small text-muted mb-sm-0">
            Selecciona los{" "}
            <strong>
              requerimientos de accesibilidad presentes en el lugar de tu oferta
              laboral
            </strong>
            . Esta información es primordial para hacer&nbsp;
            <span className="gradient-text-4t font-weight-bold">MATCH</span>
            &nbsp;con los candidatos.
          </p>
        );
      case "publicationPreview":
        return (
          <p className="small text-muted mb-sm-0">
            Previsualiza y revisa los detalles de tu publicación. Finaliza el
            proceso haciendo clic en el botón "publicar oferta laboral".
          </p>
        );
      default:
        return null;
    }
  };

  render() {
    const { course, activeTab } = this.state;

    return (
      <div>
        <Notifications ref="notifications" />
        <Card className="border-0 mb-4">
          <CardBody className="p-0">
            <Row className="d-flex align-items-center">
              <Col md="6" sm="12">
                <div className="d-block mb-1 d-flex align-items-center">
                  <i className="fas fa-bullhorn fa-pull-left h4 mb-0"></i>
                  <h3 className="h4 mb-0 d-inline-block">
                    Publicar Oferta Laboral
                  </h3>
                </div>
                {this.renderHeader()}
              </Col>
              <Col md="6" sm="12">
                <Nav
                  pills
                  className="nav-justified nav-stepper mt-5 mt-md-4"
                  role="tablist"
                >
                  <NavItem>
                    <NavLink
                      className={
                        "nav-link " +
                        classnames({
                          active: activeTab === "publicationForm"
                        })
                      }
                      onClick={() => {
                        this.toggleTab("publicationForm");
                      }}
                      aria-selected="true"
                      href="#publicationForm"
                      aria-controls="publicationForm"
                      data-toggle="tab"
                      id="publicationForm-tab"
                      aria-label="Oferta Laboral"
                      role="tab"
                    >
                      <button
                        className="btn btn-primary process-step font-weight-bold h2"
                        id="publicationForm_tab_nav"
                      >
                        1
                      </button>
                      <div className="step-data mt-3">
                        <small>Oferta Laboral</small>
                      </div>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={
                        "nav-link " +
                        classnames({
                          active: activeTab === "functionalProfileForm"
                        })
                      }
                      onClick={() => {
                        this.toggleTab("functionalProfileForm");
                      }}
                      href="#functionalProfileForm"
                      aria-controls="functionalProfileForm"
                      data-toggle="tab"
                      id="functionalProfileForm-tab"
                      aria-label="Perfil Funcional"
                      role="tab"
                    >
                      <button
                        className="btn btn-primary process-step font-weight-bold h2"
                        id="functionalprofile_tab_nav"
                      >
                        2
                      </button>
                      <div className="step-data mt-3">
                        <small>Perfil Funcional</small>
                      </div>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={
                        "nav-link " +
                        classnames({
                          active: activeTab === "accessibilityRequirementsForm"
                        })
                      }
                      onClick={() => {
                        this.toggleTab("accessibilityRequirementsForm");
                      }}
                      href="#accessibilityRequirementsForm"
                      aria-controls="accessibilityRequirementsForm"
                      data-toggle="tab"
                      id="accessibilityRequirementsForm-tab"
                      aria-label="Accesibilidad"
                      role="tab"
                    >
                      <button
                        className="btn btn-primary process-step font-weight-bold h2"
                        id="accessibilityRequirementsForm_tab_nav"
                      >
                        3
                      </button>
                      <div className="step-data mt-3">
                        <small>Accesibilidad</small>
                      </div>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={
                        "nav-link " +
                        classnames({
                          active: activeTab === "publicationPreview"
                        })
                      }
                      onClick={() => {
                        this.toggleTab("publicationPreview");
                      }}
                      href="#publicationPreview"
                      aria-controls="publicationPreview"
                      data-toggle="tab"
                      id="publicationPreview-tab"
                      aria-label="Vista Previa"
                      role="tab"
                    >
                      <button
                        className="btn btn-primary process-step font-weight-bold h2"
                        id="publicationPreview_tab_nav"
                      >
                        4
                      </button>
                      <div className="step-data mt-3">
                        <small>Vista Previa</small>
                      </div>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <TabContent activeTab={activeTab} id="publicationTabContent">
          <TabPane
            id="publicationForm"
            tabId="publicationForm"
            aria-labelledby="publicationForm-tab"
          >
            <PublicationForm
              toggleTab={this.toggleTab}
              course={course}
              handleChangeCourse={this.handleChangeCourse}
              handleChangeMinisitePublication={
                this.handleChangeMinisitePublication
              }
              handleChangeJobDescription={this.handleChangeJobDescription}

              handleChangeBenefits={this.handleChangeBenefits}
              handleAddQuestion={this.handleAddQuestion}
              handleRemoveQuestion={this.handleRemoveQuestion}
              handleChangeQuestion={this.handleChangeQuestion}
              handleSubmit={this.handleSubmitCourse}
            />
          </TabPane>
          <TabPane
            id="functionalProfileForm"
            tabId="functionalProfileForm"
            aria-labelledby="functionalProfileForm-tab"
          >
            {course.functionalAnswers.length > 0 && (
              <div>
                <FunctionalPublicationProfile
                  course={course}
                  toggleTab={this.toggleTab}
                  showNotification={this.showNotification}
                />
              </div>
            )}
          </TabPane>
          <TabPane
            id="accessibilityRequirementsForm"
            tabId="accessibilityRequirementsForm"
            aria-labelledby="accessibilityRequirementsForm-tab"
          >
            {course.accessibilityAnswers.length > 0 && (
              <div>
                <AccessibilityPublicationForm
                  course={course}
                  toggleTab={this.toggleTab}
                  showNotification={this.showNotification}
                />
              </div>
            )}
          </TabPane>
          <TabPane
            id="publicationPreview"
            tabId="publicationPreview"
            aria-labelledby="publicationPreview-tab"
          >
            <PublicationPreview
              toggleTab={this.toggleTab}
              handleSubmit={this.handleSubmitPublication}
              course={course}
              companyCentral={this.props.companyCentral}
              isEdit={this.props.isEdit}
            />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

export default PublicationStepperForm;
