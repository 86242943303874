import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Button, ButtonGroup } from "reactstrap";
import { BarLoader } from "react-spinners";
import Swal from "sweetalert2";
import Files from "react-butterfiles";
import Moment from "react-moment";
import { savePersonalDocument, deletePersonalDocument } from "./Axios";
import FieldWrapper from "../../commons/FieldWrapper";
import { setTimeout } from "timers";

const PersonalDocumentForm = ({ descriptor, personalDocuments, canDelete }) => {
  const [personalDocument, setPersonalDocument] = useState({});
  const [uploadErrors, setUploadErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const newDocument = _.find(personalDocuments, d => d.document_descriptor.id === descriptor.id);

    setPersonalDocument(newDocument ? newDocument : {});
  }, [descriptor]);

  const uploadFile = files => {
    setUploadErrors([]);
    let formData = new FormData();

    if (personalDocument.id) {
      formData.append("personal_document[id]", personalDocument.id);
    }
    formData.append("personal_document[document_descriptor_id]", descriptor.id);
    if (files.length > 0) {
      formData.append("personal_document[document]", files[0].src.file);
    }

    setLoading(true);
    savePersonalDocument(formData, response => {
      if (response.status === 201 || response.status === 200) {
        setPersonalDocument(response.data);
        setLoading(false);
      } else if (response.status === 422) {
        console.log(response);
      }
    });
  };

  const deleteFile = () => {
    Swal.fire({
      title: "Estás seguro?",
      text: `Tu documento se va a eliminar!`,
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Si, eliminar`,
      cancelButtonText: "Cancelar"
    }).then(result => {
      if (result.value) {
        try {
          deletePersonalDocument(personalDocument.id, response => {
            if (response.status === 200) {
              setPersonalDocument({});
              Swal.fire(`Eliminado!`, `El documento ha sido  eliminado exitosamente.`, "success");
            }
          });
        } catch (error) {
          Swal.fire({
            type: "error",
            title: "Oops...",
            text: "Hubo un error, vuelve a intentarlo!"
          });
        }
      }
    });
  };

  const setErrors = errors => {
    setLoading(true);
    const newErrors = [];
    let errorText;
    _.map(errors, error => {
      if (error.type === "unsupportedFileType") {
        errorText = "Formato No Permitido";
      } else if (error.type === "maxSizeExceeded") {
        errorText = "Exede el tamaño máximo de 2 mb.";
      }
      newErrors.push(errorText);
    });
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    setUploadErrors(newErrors);
  };

  return (
    <Row className="mb-5 align-middle">
      <Col xs="10" lg="5" md="6">
        <div className="media">
          <i
            className={`far fa-file fa-3x mr-3 mt-1 ${
              personalDocument.document ? "text-success" : null
            }`}
          />
          <div className="media-body">
            <h4 className={`mt-1 mb-0 h5 ${personalDocument.document ? "text-success" : null}`}>
              {descriptor.name}
            </h4>
            {personalDocument.document ? (
              <p className="small text-success mb-1">
                Fecha de Actualización{" "}
                <Moment format="DD/MM/YYYY HH:mm">{personalDocument.updated_at}</Moment>
              </p>
            ) : null}
            <p className="small text-muted mb-0">
              2 Mb. máximo
              <span className="d-block">
                Formatos permitidos:{" "}
                {_.map(descriptor.allowed_file_types, type => (
                  <span key={type}>{`${type.split("/")[1]}, `}</span>
                ))}
              </span>
            </p>
          </div>
        </div>
      </Col>
      <Col xs="12" lg="4" md="6">
        <div className="sweet-loading text-center mt-3">
          <BarLoader color={"#123abc"} loading={loading} height="10" width="300" />
        </div>
        <FieldWrapper errors={uploadErrors} />
      </Col>
      <Col xs="12" sm="12" md="6" lg="3" className="mt-3 text-lg-right">
        <Files
          multiple={false}
          maxSize="2mb"
          accept={descriptor.allowed_file_types}
          onSuccess={files => uploadFile(files)}
          onError={errors => {
            setErrors(errors);
          }}
        >
          {({ browseFiles }) => (
            <ButtonGroup>
              {personalDocument.document ? (
                <Fragment>
                  <Button color="success" outline size="sm" onClick={browseFiles}>
                    Actualizar
                  </Button>
                  <Button
                    color="info"
                    outline
                    size="sm"
                    href={personalDocument.document}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Descargar
                  </Button>
                  {canDelete && (
                    <Button color="danger" outline size="sm" onClick={deleteFile}>
                      Eliminar
                    </Button>
                  )}
                </Fragment>
              ) : (
                <Button color="success" outline size="sm" onClick={browseFiles}>
                  Subir Documento
                </Button>
              )}
            </ButtonGroup>
          )}
        </Files>
      </Col>
    </Row>
  );
};

export default PersonalDocumentForm;
