import React, { Fragment, useState } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  FormGroup
} from "reactstrap";
import FieldWrapper from "../commons/FieldWrapper";
import ApplicantData from "../applicant/Data";
import { saveStage } from "./Axios";
import AppointmentForm from "../interview/appointments/Form";
import DocumentationRequestForm from "../documentation/requests/Form";

const StagesForm = ({
  stage,
  applicant,
  courseId,
  postulationId,
  setApplicanttHit,
  showNotification
}) => {
  const [modal, setModal] = useState(false);
  const [observations, setObservations] = useState("");
  const [switchState, setSwitchState] = useState("");
  const [aasmErrors, setAasmErrors] = useState([]);

  const toggle = () => setModal(!modal);

  const handleSubmit = () => {
    let data = new FormData();
    data.append("stage[observations]", observations);
    data.append("stage[aasm_state]", switchState);

    saveStage(courseId, postulationId, stage.id, data, response => {
      if (response.status === 200) {
        toggle();
        setApplicanttHit(response.data);
        showNotification("Genial", "Información guardada con éxito!", "success");
      } else if (response.status === 422) {
        setAasmErrors(response.data.aasm_state);
        showNotification("Ops", "Hubo un error, revisa el formulario!", "danger");
      }
    });
  };

  const drawCustomStageForm = () => {
    switch (stage.type) {
      case "Interview::Stage":
        return (
          <Fragment>
            <hr />
            <AppointmentForm
              stage={stage}
              setApplicanttHit={setApplicanttHit}
              showNotification={showNotification}
              toggle={toggle}
            />
          </Fragment>
        );
      case "Documentation::Stage":
        return (
          <Fragment>
            <hr />
            <DocumentationRequestForm
              stage={stage}
              setApplicanttHit={setApplicanttHit}
              showNotification={showNotification}
              applicant={applicant}
              toggle={toggle}
            />
          </Fragment>
        );
      default:
        break;
    }
  };

  const canDrawResultForm = () => {
    if (stage.type === "Interview::Stage" && stage.appointment) {
      return true;
    } else if (stage.type === "Documentation::Stage" && stage.documentation_request) {
      return true;
    } else if (stage.type !== "Interview::Stage" && stage.type !== "Documentation::Stage") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Fragment>
      <a style={{ cursor: "pointer", color: "#5a3ee0" }} onClick={toggle}>
        {stage.name}
      </a>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader className="gradient-bg text-white" toggle={toggle}>
          Gestionar Etapa de {stage.name}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md="12">
              <h5 className="font-weight-bold text-muted">Candidato</h5>
              <ApplicantData user={applicant.user} />
            </Col>
          </Row>
          {drawCustomStageForm()}
          <hr />
          {canDrawResultForm() ? (
            <Fragment>
              <Row>
                <Col md="12">
                  <h5 className="font-weight-bold text-muted">Resultados de la Etapa</h5>
                  <FieldWrapper>
                    <Label for="observations">Observaciones</Label>
                    <Input
                      type="textarea"
                      name="text"
                      id="observations"
                      onChange={e => setObservations(e.target.value)}
                    />
                  </FieldWrapper>
                </Col>
              </Row>
              <Row className="mt-3 text-center">
                <Col md="12">
                  <FormGroup tag="fieldset">
                    <FormGroup role="radiogroup" aria-labelledby="genderLabel">
                      <FieldWrapper errors={aasmErrors}>
                        <FormGroup check inline>
                          <Label check htmlFor="aasmStateCheck1">
                            <Input
                              id="aasmStateCheck1"
                              name="aasm_state"
                              type="radio"
                              value="approved"
                              onChange={e => setSwitchState(e.target.value)}
                            />
                            Aprobar
                          </Label>
                        </FormGroup>
                        <FormGroup check inline>
                          <Label check htmlFor="aasmStateCheck2">
                            <Input
                              id="aasmStateCheck2"
                              name="aasm_state"
                              type="radio"
                              value="rejected"
                              onChange={e => setSwitchState(e.target.value)}
                            />
                            Rechazar
                          </Label>
                        </FormGroup>
                      </FieldWrapper>
                    </FormGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={{ offset: 4, size: 4 }}>
                  <Button color="success" block outline onClick={handleSubmit}>
                    Guardar Resultados
                  </Button>
                </Col>
              </Row>
            </Fragment>
          ) : null}
        </ModalBody>
        <ModalFooter className="gradient-bg"></ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default StagesForm;
