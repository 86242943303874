import React, { Fragment } from "react";
import { Card, CardBody, CardHeader, ListGroup, ListGroupItem } from "reactstrap";
import _ from "lodash";

const ApplicantFunctionalProfile = ({ functionalAnswers, accessibilityAnswers, course }) => {
  const getCorrectAnswer = answer => {
    let _answer = _.find(
      course.functionalAnswers,
      fa => fa.functional_question_id == answer.functional_question_id
    );
    return _answer;
  };

  const AnswerIsCorrect = answer => {
    let correctAnswer = getCorrectAnswer(answer);
    return correctAnswer && correctAnswer.employee_value >= answer.employee_value;
  };

  const drawCorrectAnswer = answer => {
    if ("id" in course && answer.functional_available_answer_id) {
      let correctAnswer = getCorrectAnswer(answer);
      var divClass = "";
      if (!AnswerIsCorrect(answer)) {
        divClass = "text-danger";
      } else if ("id" in course) {
        divClass = "text-success";
      }
      return (
        <div className={`d-flex align-items-start flex-row mt-2 bg-light p-2 rounded ` + divClass}>
          {drawSomosIcon(answer)}
          <div className="d-flex flex-column" style={{ fontSize: "14px" }}>
            <strong>Respuesta esperada:&nbsp;</strong>
            {correctAnswer.functional_available_answer_company_label}
          </div>
        </div>
      );
    }
  };

  const getTextKlass = answer => {
    if ("id" in course && answer.functional_available_answer_id && AnswerIsCorrect(answer)) {
      return "text-primary";
    }
  };

  const drawSomosIcon = answer => {
    if ("id" in course && answer.functional_available_answer_id) {
      let correctAnswer = getCorrectAnswer(answer);

      return (
        <img
          width="30px"
          className={`d-inline mr-2 ${AnswerIsCorrect(answer) ? "" : "grayscale-img"}`}
          alt="MATCH"
          src="/assets/match_effect-8ea7598f2a3e48443b51c35f320df40e46c3bd37fe8282d3ee8e9a16f296ccd6.png"
        />
      );
    }
  };

  const drawAnswer = answer => {
    let textKlass = getTextKlass(answer);

    return (
      <ListGroupItem key={answer.id} className={textKlass}>
        <div className="d-flex w-100 justify-content-between align-items-center">
          <strong>{answer.functional_question_label}:&nbsp;</strong>
        </div>
        {answer.functional_available_answer_label}
        {drawCorrectAnswer(answer)}
      </ListGroupItem>
    );
  };

  return (
    <Fragment>
      <Card className="mb-3">
        <CardHeader className="__with_collapsible-icons">
          <a
            className="d-block"
            data-toggle="collapse"
            data-target="#functionalanswersCollapse"
            aria-expanded="true"
            aria-controls="functionalanswersCollapse"
          >
            <h5 className="card-title mb-0 h6 font-weight-bold">
              <i className="fas fa-tasks mr-2" aria-hidden="true"></i>
              Requerimientos del Puesto de Trabajo
            </h5>
          </a>
        </CardHeader>
        <div className="collapse show" id="functionalanswersCollapse">
          <ListGroup flush className="my-0">
            {/* <ListGroupItem className="text-primary">
              <div className="d-flex w-100 justify-content-between align-items-center">
                <strong>Realizar tareas de pie:&nbsp;</strong>
                <img
                  width="30px"
                  className="d-inline"
                  alt="MATCH"
                  src="/assets/match_effect-8ea7598f2a3e48443b51c35f320df40e46c3bd37fe8282d3ee8e9a16f296ccd6.png"
                />
              </div>
              Puede permanecer de pie durante toda la jornada laboral para el desarrollo de las
              tareas (como un operario en línea de producción.
            </ListGroupItem> */}
            {_.map(functionalAnswers, answer => drawAnswer(answer))}
          </ListGroup>
        </div>
      </Card>
      <Card className="bg-light">
        <CardHeader className="__with_collapsible-icons">
          <a
            className="d-block"
            data-toggle="collapse"
            data-target="#accessibilityAnswersCollapse"
            aria-expanded="true"
            aria-controls="accessibilityAnswersCollapse"
          >
            <h5 className="card-title mb-0 h6 font-weight-bold">
              <i className="fas fa-universal-access mr-2" aria-hidden="true"></i>
              Requerimientos de Accesibilidad
            </h5>
          </a>
        </CardHeader>
        <div className="collapse show" id="accessibilityAnswersCollapse">
          <CardBody className="p-2">
            <div className="row">
              {_.map(accessibilityAnswers, answer => {
                if (answer.isChecked) {
                  return (
                    <div className="col-6 col-md-2" key={answer.id}>
                      <div className="my-3 d-flex flex-column justify-content-center align-items-center justify-content-center text-center">
                        <img
                          src={answer.iconSrc}
                          className="rounded-lg mb-2 d-block p-2 infraestructure-img-show mx-auto"
                          alt=""
                        />
                        <strong className="font-weight-bold small">{answer.label}</strong>
                        <small className="text-muted">{answer.observations}</small>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </CardBody>
        </div>
      </Card>
    </Fragment>
  );
};

export default ApplicantFunctionalProfile;
