import React, { useState, useEffect } from "react";
import Select from "react-select";
import { getLaboralAreas } from "./Axios";

const LaboralAreasSelect = ({ isMulti, handleChange, value }) => {
  const [laboralAreas, setLaboralAreas] = useState([]);

  useEffect(() => {
    const newLaboralAreas = laboralAreas;
    getLaboralAreas(response => {
      response.data.map(area => {
        newLaboralAreas.push({ value: area.id, label: area.name, path: area.path });
      });
    });
    setLaboralAreas(newLaboralAreas);
  }, []);

  const handleChangeAreas = inputValue => {
    const fieldName = isMulti ? "laboralAreas" : "laboralArea";
    handleChange(inputValue, fieldName);
  };

  let areaValue = null;
  if (value && value.value !== "") areaValue = value;

  return (
    <Select
      inputId={isMulti ? "idealLaboralArea" : "laboralArea"}
      isMulti={isMulti}
      onChange={handleChangeAreas}
      options={laboralAreas}
      placeholder={isMulti ? "Seleccione hasta 10 opciones" : "Seleccione una opción"}
      value={areaValue}
      aria-describedby="laboralAreaHelp"
    />
  );
};

export default LaboralAreasSelect;
