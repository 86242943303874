import axios from "axios";

export const getTerritoryNationalities = callback => {
  var promise = axios({
    method: "get",
    url: "/api/territory_nationalities"
  });
  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};
