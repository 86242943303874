import React, { Fragment, useState } from "react";
import { Row, Col, Input, Label, Alert, Button } from "reactstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { saveAppointment } from "./Axios";

registerLocale("es", es);

const AppointmentForm = ({ stage, setApplicanttHit, showNotification, toggle }) => {
  const appointment = stage ? stage.appointment : null;

  // States
  const [appointmentAt, setAppointmentAt] = useState(
    appointment ? new Date(`${appointment.appointmentDate}`) : new Date()
  );
  const [address, setAddress] = useState(appointment ? appointment.address : "");
  const [managerName, setManagerName] = useState(appointment ? appointment.managerName : "");
  const [managerContact, setManagerContact] = useState(
    appointment ? appointment.managerContact : ""
  );
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    if (
      appointmentAt === null ||
      address.trim() === "" ||
      managerName.trim() === "" ||
      managerContact.trim() === ""
    ) {
      setError(true);
      return;
    }

    setLoading(true);
    let data = new FormData();
    if (appointment) {
      data.append("interview_appointment[id]", appointment.id);
    }
    data.append("interview_appointment[interview_stage_id]", stage.id);
    data.append("interview_appointment[appointment_date]", appointmentAt);
    data.append("interview_appointment[address]", address);
    data.append("interview_appointment[manager_name]", managerName);
    data.append("interview_appointment[manager_contact]", managerContact);

    saveAppointment(data, response => {
      if (response.status === 201 || response.status === 200) {
        setApplicanttHit(response.data);
        showNotification("Genial", "Entrevista agendada exitosamente!", "success");
        toggle();
      } else if (response.status === 422) {
        setError(true);
      }
    });

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  return (
    <Fragment>
      <h5 className="font-weight-bold text-muted">Datos de la Entrevista</h5>
      {error && <Alert color="danger">Todos lo campos son obligatorios!</Alert>}
      <Row>
        <Col md="6">
          <Label className="input_required">Fecha y Hora de la Entrevista</Label>

          <DatePicker
            selected={appointmentAt}
            onChange={date => setAppointmentAt(date)}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            minDate={new Date()}
            locale="es"
            timeCaption="Hora"
            dateFormat="dd/MM/yyyy h:mm aa"
            className="form-control"
            style={{ width: "100%" }}
          />
        </Col>
        <Col md="6">
          <Label className="input_required">Dirección</Label>
          <Input type="text" value={address} onChange={e => setAddress(e.target.value)} />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md="6">
          <Label className="input_required">Persona a Cargo</Label>
          <Input type="text" value={managerName} onChange={e => setManagerName(e.target.value)} />
        </Col>
        <Col md="6">
          <Label className="input_required">Email y/o Teléfono de contacto</Label>
          <Input
            type="text"
            value={managerContact}
            onChange={e => setManagerContact(e.target.value)}
          />
        </Col>
      </Row>
      <Row className="mt-3 text-right">
        <Col md="12">
          <Button size="sm" onClick={handleSubmit}>
            <i className="fas fa-calendar-alt" />{" "}
            {loading ? "Agendando" : appointment ? "Reagendar" : "Agendar"}
            {loading ? <i className="fas fa-spinner fa-pulse ml-2"></i> : null}
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
};

export default AppointmentForm;
