import React, { Fragment } from "react";
import { Row, Button } from "reactstrap";
import PublicationShow from "./Show";

const PublicationPreview = ({ toggleTab, handleSubmit, course, companyCentral, isEdit }) => {
  return (
    <Fragment>
      <PublicationShow course={course} companyCentral={companyCentral} isPreview={true} />
      <Row>{/*<Col md="12">
          <PublicationItem />
        </Col>*/}</Row>
      <Row className="mt-4 text-center d-flex justify-content-center align-items-center">
        <Button
          outline
          color="primary"
          className="mb-5 mr-3"
          onClick={() => {
            toggleTab("functionalprofileform");
          }}
        >
          <i className="fa fa-arrow-left mr-2"></i>
          Anterior
        </Button>
        {isEdit && course.minisitePublication.aasmState.value === "active" ? (
          <Button color="success" className="mb-5" href={`/courses/${course.id.value}`}>
            Listo
          </Button>
        ) : (
          <Button color="success" className="mb-5" onClick={handleSubmit}>
            Publicar Oferta Laboral
          </Button>
        )}
      </Row>
    </Fragment>
  );
};

export default PublicationPreview;
