import React, { useEffect } from "react";
import Select from "react-select";
import { Row, Col, Form, FormGroup, Label, Input, Button, Card, CardBody } from "reactstrap";
import _ from "lodash";
import Switch from "react-switch";
import TerritoryNestedSelects from "../territory/nested/Selects";
import LaboralAreasSelect from "../laboral/areas/Select";
import EducationLevelsSelect from "../education/levels/Select";
import FieldWrapper from "../commons/FieldWrapper";
import DraftInput from "../commons/DraftInput";

const contractTypeOptions = [
  { value: "fixed_term", label: "Plazo Fijo" },
  { value: "indefinite_term", label: "Plazo Indefinido" },
  { value: "practical_term", label: "Práctica" }
];

const PublicationForm = props => {
  const {
    course,
    handleChangeCourse,
    handleChangeJobDescription,
    handleChangeBenefits,
    handleChangeMinisitePublication,
    handleAddQuestion,
    handleRemoveQuestion,
    handleChangeQuestion,
    handleSubmit
  } = props;

  const { publicationQuestions } = course.minisitePublication;

  const isConfidential =
    course.minisitePublication.isConfidential.value === true ||
    course.minisitePublication.isConfidential.value === "true"
      ? true
      : false;

  useEffect(() => {
    if (publicationQuestions.length === 0) {
      const defaultQuestions = [
        "En caso de encontrarte en una situación de discapacidad, indica si necesitas algún requerimiento para presentarte a la entrevista.",
        "Cómo te gustaría ser contactado? SMS, WhatsApp, Teléfono, Correo electrónico, otro."
      ];
      _.map(defaultQuestions, question => handleAddQuestion(question, true));
    }
  }, [publicationQuestions]);

  return (
    <Form>
      <Card className="mb-4 material-shadow">
        <CardBody>
          <Row>
            <Col lg="12" md="12">
              <FormGroup>
                <Label htmlFor="title" className="input_required">
                  Título de publicación
                </Label>
                <FieldWrapper errors={course.title.errors}>
                  <Input
                    id="title"
                    name="title"
                    placeholder="Título de publicación"
                    type="text"
                    maxLength={60}
                    onChange={handleChangeCourse}
                    value={course.title.value}
                  />
                </FieldWrapper>
              </FormGroup>
              <FormGroup>
                <Label for="jobDescription" className="input_required">
                  Descripción del Cargo
                </Label>
                <FieldWrapper errors={course.jobDescription.errors}>
                  <DraftInput onChangeInput={handleChangeJobDescription} value={course.jobDescription.value} />
                </FieldWrapper>
              </FormGroup>
            </Col>
            <Col lg="4" md="4">
              <FormGroup>
                <Label htmlFor="vacancies" className="input_required">
                  Vacantes
                </Label>
                <FieldWrapper errors={course.vacancies.errors}>
                  <Input
                    id="vacancies"
                    name="vacancies"
                    placeholder="Vacantes"
                    type="number"
                    min="1"
                    onChange={handleChangeCourse}
                    value={course.vacancies.value}
                  />
                </FieldWrapper>
              </FormGroup>
            </Col>
            {!course.id.value && (
              <Col lg="4" md="4">
                <FormGroup>
                  <Label htmlFor="desactivateAt" className="input_required">
                    Fecha Expiración (30 días máx.)
                  </Label>
                  <FieldWrapper errors={course.minisitePublication.desactivateAt.errors}>
                    <Input
                      id="desactivateAt"
                      name="desactivateAt"
                      placeholder="Fecha Expiración"
                      type="date"
                      onChange={handleChangeMinisitePublication}
                      value={course.minisitePublication.desactivateAt.value}
                    />
                  </FieldWrapper>
                </FormGroup>
              </Col>
            )}
            <Col lg="4" md="4">
              <FormGroup check>
                <Label check className="d-flex align-items-top justify-content-between">
                  <Switch
                    checked={isConfidential}
                    onChange={e => handleChangeMinisitePublication(e, "isConfidential")}
                    offColor="#ff3547"
                    onColor="#00c851"
                    onHandleColor="#00c851"
                    offHandleColor="#ff3547"
                    handleDiameter={30}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={15}
                    width={43}
                    className="react-switch align-middle"
                    id="material-switch"
                  />
                  <div className="d-inline-block">
                    ¿Publicación Confidencial?
                    <small className="form-text text-muted">
                      Si activas tu proceso confidencial, l@s candidat@s no verán la información de tu marca empleadora
                      (logo, nombre y dirección)
                    </small>
                  </div>
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <hr />
          <fieldset className="form-group">
            <Row>
              <Col sm="9">
                <FormGroup>
                  <Label className="input_match" htmlFor="idealLaboralArea">
                    Área de Empleo
                  </Label>
                  <FieldWrapper errors={course.laboralArea.errors}>
                    <LaboralAreasSelect value={course.laboralArea} handleChange={handleChangeCourse} />
                  </FieldWrapper>
                  <legend className="form-text text-muted small">
                    Al seleccionar el Área de Empleo aparecerá una imagen relacionada. Esta imagen es visible en los
                    avisos.
                  </legend>
                </FormGroup>
              </Col>
              <Col sm="3">
                <img src={course.laboralArea.path} className="img-fluid laboral-area-icon" alt={course.laboralArea} />
              </Col>
            </Row>
          </fieldset>
          <hr />
          <Row>
            <Col md="3">
              <FormGroup>
                <Label htmlFor="address" className="input_match">
                  Dirección
                </Label>
                <FieldWrapper errors={course.minisitePublication.address.errors}>
                  <Input
                    id="address"
                    name="address"
                    placeholder="Dirección"
                    type="text"
                    onChange={handleChangeMinisitePublication}
                    value={course.minisitePublication.address.value}
                  />
                </FieldWrapper>
              </FormGroup>
            </Col>
            <TerritoryNestedSelects
              handleChange={handleChangeMinisitePublication}
              countryValue={course.minisitePublication.country}
              regionValue={course.minisitePublication.region}
              communeValue={course.minisitePublication.commune}
            />
          </Row>
        </CardBody>
      </Card>
      <Card className="mb-3 material-shadow">
        <h3 className="card-header h6 font-weight-bold input_match">
          <i className="fas fa-business-time mr-2" aria-hidden="true"></i>
          Detalles Laborales
        </h3>
        <CardBody>
          <fieldset className="form-group">
            <Row>
              <legend className="col-form-label pt-0 col-sm-4 input_match">Jornada Laboral</legend>
              <Col sm="8">
                <Row>
                  <Col md="3">
                    <FormGroup check>
                      <Label htmlFor="preferredScheduleCheck1">
                        <Input
                          id="preferredScheduleCheck1"
                          name="preferredSchedule"
                          type="radio"
                          value="part_time"
                          onChange={handleChangeMinisitePublication}
                          checked={course.minisitePublication.preferredSchedule.value === "part_time"}
                        />
                        Part Time
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup check>
                      <Label htmlFor="preferredScheduleCheck2">
                        <Input
                          id="preferredScheduleCheck2"
                          name="preferredSchedule"
                          type="radio"
                          value="full_time"
                          onChange={handleChangeMinisitePublication}
                          checked={course.minisitePublication.preferredSchedule.value === "full_time"}
                        />
                        Jornada Completa
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup check>
                      <Label htmlFor="preferredScheduleCheck3">
                        <Input
                          id="preferredScheduleCheck3"
                          name="preferredSchedule"
                          type="radio"
                          value="half_time"
                          onChange={handleChangeMinisitePublication}
                          checked={course.minisitePublication.preferredSchedule.value === "half_time"}
                        />
                        Media Jornada
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup check>
                      <Label htmlFor="preferredScheduleCheck4">
                        <Input
                          id="preferredScheduleCheck4"
                          name="preferredSchedule"
                          type="radio"
                          value="remote_work"
                          onChange={handleChangeMinisitePublication}
                          checked={course.minisitePublication.preferredSchedule.value === "remote_work"}
                        />
                        Teletrabajo
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <FieldWrapper errors={course.minisitePublication.preferredSchedule.errors}></FieldWrapper>
            </Row>
          </fieldset>
          <hr />
          <fieldset className="form-group">
            <Row>
              <legend className="col-form-label pt-0 col-sm-4 input_match">Extensión de la jornada</legend>
              <Col sm="8">
                <Row>
                  <Col md="4">
                    <FormGroup check>
                      <Label htmlFor="preferredLaborDay1">
                        <Input
                          id="preferredLaborDay1"
                          name="preferredLaborDay"
                          type="radio"
                          value="monday_friday"
                          onChange={handleChangeMinisitePublication}
                          checked={course.minisitePublication.preferredLaborDay.value === "monday_friday"}
                        />
                        Lunes a Viernes
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup check>
                      <Label htmlFor="preferredLaborDay2">
                        <Input
                          id="preferredLaborDay2"
                          name="preferredLaborDay"
                          type="radio"
                          value="monday_sunday"
                          onChange={handleChangeMinisitePublication}
                          checked={course.minisitePublication.preferredLaborDay.value === "monday_sunday"}
                        />
                        Lunes a Domingo
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup check>
                      <Label htmlFor="preferredLaborDay3">
                        <Input
                          id="preferredLaborDay3"
                          name="preferredLaborDay"
                          type="radio"
                          value="weekends"
                          onChange={handleChangeMinisitePublication}
                          checked={course.minisitePublication.preferredLaborDay.value === "weekends"}
                        />
                        Fines de Semana
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <FieldWrapper errors={course.minisitePublication.preferredLaborDay.errors}></FieldWrapper>
            </Row>
          </fieldset>
          <hr />
          <fieldset className="form-group">
            <Row>
              <legend className="col-form-label pt-0 col-sm-4 input_match">Horarios y/o Turnos</legend>

              <Col sm="8">
                <Row>
                  <Col md="4">
                    <FormGroup check>
                      <Label htmlFor="preferredTurn1">
                        <Input
                          id="preferredTurn1"
                          name="preferredTurn"
                          type="radio"
                          value="day_time"
                          onChange={handleChangeMinisitePublication}
                          checked={course.minisitePublication.preferredTurn.value === "day_time"}
                        />
                        Diurno
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup check>
                      <Label htmlFor="preferredTurn2">
                        <Input
                          id="preferredTurn2"
                          name="preferredTurn"
                          type="radio"
                          value="vespertine"
                          onChange={handleChangeMinisitePublication}
                          checked={course.minisitePublication.preferredTurn.value === "vespertine"}
                        />
                        Vespertino
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup check>
                      <Label htmlFor="preferredTurn3">
                        <Input
                          id="preferredTurn3"
                          name="preferredTurn"
                          type="radio"
                          value="rotative"
                          onChange={handleChangeMinisitePublication}
                          checked={course.minisitePublication.preferredTurn.value === "rotative"}
                        />
                        Rotativos
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <FieldWrapper errors={course.minisitePublication.preferredTurn.errors}></FieldWrapper>
            </Row>
          </fieldset>
          <hr/>
          <FormGroup tag="fieldset">
            <Row>
              <Col sm="4">
                <legend className="col-form-label pt-0 input_match pb-0 mb-0" id="dressCodeLabel">
                  Código de Vestimenta Laboral Formal
                </legend>
                <small className="form-text text-muted">
                  A algunas personas les importa saber si tu empresa cuenta con una política de vestimenta formal.
                </small>
              </Col>
              <Col sm="8">
                <Row>
                  <Col md="4">
                    <FormGroup check>
                      <Label htmlFor="dressCode1">
                        <Input
                          id="dressCode1"
                          name="dressCode"
                          type="radio"
                          value="yes"
                          onChange={handleChangeMinisitePublication}
                          checked={course.minisitePublication.dressCode.value === "yes"}
                        />
                        Si
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup check>
                      <Label htmlFor="dressCode2">
                        <Input
                          id="dressCode2"
                          name="dressCode"
                          type="radio"
                          value="no"
                          onChange={handleChangeMinisitePublication}
                          checked={course.minisitePublication.dressCode.value === "no"}
                        />
                        No
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup check>
                      <Label htmlFor="dressCode3">
                        <Input
                          id="dressCode3"
                          name="dressCode"
                          type="radio"
                          value="indifferent"
                          onChange={handleChangeMinisitePublication}
                          checked={course.minisitePublication.dressCode.value === "indifferent"}
                        />
                        Indiferente
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </FormGroup>
        </CardBody>
      </Card>
      <Card className="mb-4 material-shadow">
        <h3 className="card-header h6 font-weight-bold">
          <i className="far fa-smile-wink mr-2" aria-hidden="true"></i>
          Beneficios
        </h3>
        <CardBody>
          <FormGroup>
            <Label htmlFor="benefits">Detalle de Beneficios</Label>
            <DraftInput onChangeInput={handleChangeBenefits} value={course.minisitePublication.benefits.value} />
          </FormGroup>
          <Row>
            <Col lg="4" md="4">
              <FormGroup>
                <Label htmlFor="contractType">Tipo de Contrato</Label>
                <Select
                  inputId="contractType"
                  onChange={e => handleChangeCourse(e, "contractType")}
                  options={contractTypeOptions}
                  placeholder="Seleccione una opción"
                  value={course.contractType.value ? course.contractType : null}
                />
              </FormGroup>
            </Col>
            <Col lg="4" md="4">
              <FormGroup>
                <Label htmlFor="salaryOffered">Sueldo Líquido</Label>
                <FieldWrapper errors={course.minisitePublication.salaryOffered.errors}>
                  <Input
                    id="salaryOffered"
                    name="salaryOffered"
                    placeholder="Sueldo Líquido"
                    type="number"
                    onChange={handleChangeMinisitePublication}
                    value={course.minisitePublication.salaryOffered.value}
                  />
                </FieldWrapper>
              </FormGroup>
            </Col>
            <Col lg="4" md="4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    name="showSalaryOffered"
                    onChange={handleChangeMinisitePublication}
                    checked={course.minisitePublication.showSalaryOffered.value}
                  />{" "}
                  Mostrar Sueldo en Publicación?
                </Label>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card className="mb-4 material-shadow">
        <h3 className="card-header h6 font-weight-bold">
          <i className="fas fa-list-ul mr-2" aria-hidden="true"></i>
          Requerimientos del Cargo
        </h3>
        <CardBody>
          <Row>
            <Col lg="4" md="4">
              <FormGroup>
                <Label htmlFor="experienceYears">Años de Experiencia</Label>
                <FieldWrapper errors={course.minisitePublication.experienceYears.errors}>
                  <Input
                    id="experienceYears"
                    name="experienceYears"
                    placeholder="Años de Experiencia"
                    type="number"
                    min="0"
                    onChange={handleChangeMinisitePublication}
                    value={course.minisitePublication.experienceYears.value}
                  />
                </FieldWrapper>
              </FormGroup>
            </Col>
            {/* <Col lg="4" md="4">
                <FormGroup>
                  <Label htmlFor="studiesStatus">Situación de Estudios</Label>
                  <Input
                    id="studiesStatus"
                    name="studiesStatus"
                    placeholder="Situación de Estudios"
                    type="text"
                    onChange={handleChangeCourse}
                  />
                </FormGroup>
              </Col> */}
            <Col lg="4" md="4">
              <FormGroup>
                <Label htmlFor="educationalLevel">Nivel Educacional</Label>
                <EducationLevelsSelect
                  value={course.minisitePublication.educationLevel}
                  handleChange={handleChangeMinisitePublication}
                />
              </FormGroup>
            </Col>
            <Col lg="12" md="12">
              <FormGroup>
                <Label for="requirements">Requerimientos Mínimos</Label>
                <Input
                  type="textarea"
                  name="requirements"
                  id="requirements"
                  onChange={handleChangeMinisitePublication}
                  value={course.minisitePublication.requirements.value}
                />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card className="mb-4 material-shadow">
        <h3 className="card-header h6 font-weight-bold">
          <i className="fas fa-question mr-2" aria-hidden="true"></i>
          Preguntas
        </h3>
        <CardBody>
          <p className="alert bg-primary font-weight-bold text-white material-shadow">
            <i className="fas fa-info-circle" /> Para poder facilitar tu proceso de reclutamiento, SomosEmpleos realiza
            siempre las siguientes preguntas (1 y 2) a los postulantes. Tú como reclutador, puedes realizar todas las
            que quieras.
          </p>
          <Row>
            <Col lg="12" md="12">
              {_.map(publicationQuestions, (question, key) => (
                <Card key={key} className="material-shadow mb-3">
                  <CardBody>
                    {!question.isDefault ? (
                      <div className="hit-actions float-right text-right">
                        <Button
                          outline
                          color="danger"
                          aria-label="Borrar"
                          title="Borrar"
                          className="btn-circle d-block"
                          onClick={() => handleRemoveQuestion(key)}
                        >
                          <i className="far fa-trash-alt" aria-hidden="true"></i>
                        </Button>
                      </div>
                    ) : null}
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <Label htmlFor={`question${key}`}>Pregunta {key + 1}</Label>
                          <FieldWrapper errors={question.errors}>
                            <Input
                              id={`question${key}`}
                              name="question"
                              placeholder="Escriba aquí su pregunta."
                              type="textarea"
                              value={question.value}
                              onChange={e => handleChangeQuestion(e.target.value, key)}
                              disabled={question.isDefault}
                            />
                          </FieldWrapper>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              ))}
            </Col>
            <Col md="12" className="text-right">
              <Button outline color="primary" className="my-4" onClick={() => handleAddQuestion("", false)}>
                <i className="fas fa-plus mr-2" aria-hidden="true"></i>
                Agregar Pregunta
              </Button>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md="12" className="text-right">
              <Button color="primary" className="mb-5" onClick={handleSubmit}>
                Continuar
                <i className="fas fa-arrow-right ml-2" aria-hidden="true"></i>
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Form>
  );
};

export default PublicationForm;
