import React, { Component } from "react";
import { Row, Col, Button, Card, CardBody } from "reactstrap";
import Moment from "react-moment";
import { deleteLaboralExperience } from "./Axios";

class LaboralExperienceHit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      laboralExperience: this.props.laboralExperience
    };
  }

  updateHit = data => {
    this.setState({ laboralExperience: data });
  };

  handleDelete = () => {
    const { laboralExperience } = this.state;
    deleteLaboralExperience(laboralExperience.id, response => {
      if (response.status === 200) {
        this.props.removeLaboralExperience(laboralExperience.id);
      }
    });
  };

  render() {
    const { laboralExperience } = this.state;

    return (
      <Row>
        <Col md="12">
          <Card className="material-shadow my-2">
            <CardBody>
              <div className="hit-actions float-right text-right">
                <Button
                  outline
                  color="info"
                  aria-label="Editar"
                  title="Editar"
                  className="btn-circle d-block mb-2"
                  onClick={() => this.props.handleEditLaboralExperience(this.state.laboralExperience)}
                >
                  <i className="fas fa-edit"></i>
                </Button>
                <Button
                  outline
                  color="danger"
                  aria-label="Borrar"
                  title="Borrar"
                  className="btn-circle d-block"
                  onClick={this.handleDelete}
                >
                  <i className="far fa-trash-alt"></i>
                </Button>
              </div>
              <Row>
                <Col lg="7">
                  <h3 className="font-weight-bold h5">
                    {laboralExperience.company_position}
                    <br />
                    <small className="text-muted">
                      {laboralExperience.company_name} / {laboralExperience.laboral_area.label}
                    </small>
                    <br />
                    <small className="text-muted">
                      <Moment format="DD/MM/YYYY">{laboralExperience.start_at}</Moment> -{" "}
                      {laboralExperience.end_at ? (
                        <Moment format="DD/MM/YYYY">{laboralExperience.end_at}</Moment>
                      ) : (
                        "Actualidad"
                      )}
                    </small>
                  </h3>
                  <p>
                    <strong>Labores realizadas: </strong>
                    {laboralExperience.experience_details}
                  </p>
                  <p>
                    <strong>Logros destacados: </strong>
                    {laboralExperience.achievements}
                  </p>
                </Col>
                <Col lg="5">
                  {laboralExperience.has_job_recomendation && (
                    <Card className="bg-light my-md-0">
                      <CardBody>
                        <h4 className="font-weight-bold text-muted h6">
                          <i className="far fa-address-book mr-2"></i>
                          Recomendación de este empleo
                        </h4>
                        <strong>{laboralExperience.recomendation_name}</strong>
                        <p className="mb-0">
                          {laboralExperience.recomendation_phone_number}
                          <br />
                          {laboralExperience.recomendation_email}
                        </p>
                      </CardBody>
                    </Card>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default LaboralExperienceHit;
