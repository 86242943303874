import React from "react";
import { Row, Col } from "reactstrap";
import Pagination from "rc-pagination";
import localeInfo from "rc-pagination/lib/locale/es_ES";

const PaginationComponent = props => {
  const { total, size, pages, totalPages, currentPage, setPage } = props;

  const onChange = page => {
    setPage(page - 1);
  };

  return (
    <Row className="mt-3">
      <Col md="12" className="d-flex justify-content-center">
        <Pagination
          total={total}
          pageSize={size}
          current={currentPage + 1}
          onChange={onChange}
          locale={localeInfo}
        />
      </Col>
    </Row>
  );
};

export default PaginationComponent;
