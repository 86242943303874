import React, { Fragment, useState, useEffect } from "react";
import { Table, Button } from "reactstrap";
import _ from "lodash";
import Moment from "react-moment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { deleteCompanyCredit } from "./Axios";
import { drawAasmState } from "../../commons/Functions";

const CompanyCreditsHits = ({ hits, companyCentralId }) => {
  const [companyCredits, setCompanyCredits] = useState(_.map(hits, hit => hit._source));

  useEffect(() => {
    const newCredits = _.map(hits, hit => hit._source);
    setCompanyCredits(newCredits);
  }, [hits]);

  const deleteCredit = id => {
    Swal.fire({
      title: "Estás seguro?",
      text: `El crédito se va a eliminar!`,
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Si, eliminar`,
      cancelButtonText: "Cancelar"
    }).then(result => {
      if (result.value) {
        try {
          deleteCompanyCredit(companyCentralId, id, response => {
            if (response.status === 200) {
              Swal.fire(`Eliminado!`, `El crédito se ha eliminado exitosamente!.`, "success");
              const newCredits = _.filter(companyCredits, credit => credit.id !== id);
              setCompanyCredits(newCredits);
            }
          });
        } catch (error) {
          Swal.fire({
            type: "error",
            title: "Oops...",
            text: "Hubo un error, vuelve a intentarlo!"
          });
        }
      }
    });
  };

  return (
    <Fragment>
      <Table size="sm" striped responsive>
        <thead>
          <tr>
            <th>Creado Por</th>
            <th>Fecha Creación</th>
            <th>Estado</th>
            <th>Usado Por</th>
            <th>Fecha Uso</th>
            <th>Publicación</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {_.map(companyCredits, credit => (
            <tr key={credit.id}>
              <td>{credit.createdBy.fullName}</td>
              <td>
                <Moment format="DD/MM/YYYY HH:mm">{credit.createdAt}</Moment>
              </td>
              <td>{drawAasmState(credit.aasmState)}</td>
              <td>{credit.usedBy.fullName}</td>
              <td>{credit.usedAt && <Moment format="DD/MM/YYYY HH:mm">{credit.usedAt}</Moment>}</td>
              <td>
                {credit.publication.id && (
                  <Button
                    href={`/publications/${credit.publication.slug}`}
                    size="sm"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ver Publicación
                  </Button>
                )}
              </td>
              <td>
                {credit.aasmState === "available" && (
                  <Button
                    title="Eliminar Crédito"
                    color="danger"
                    size="sm"
                    onClick={() => deleteCredit(credit.id)}
                  >
                    <i className="fas fa-trash-alt" />
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Fragment>
  );
};

export default CompanyCreditsHits;
