import React, { useState, useEffect } from "react";
import _ from "lodash";
import Select from "react-select";
import { getTerritoryCommunes } from "./Axios";

const CommunesSelect = ({ countryId, regionId, uniqueId, value, handleChange }) => {
  const [communes, setCommunes] = useState([]);

  useEffect(() => {
    if (countryId && regionId) {
      getCommunes(countryId, regionId);
    }
  }, [countryId, regionId]);

  const getCommunes = (countryId, regionId) => {
    getTerritoryCommunes(countryId, regionId, response => {
      const newCommunes = [];
      _.map(response.data, commune => {
        newCommunes.push({ value: commune.id, label: commune.name });
      });
      setCommunes(newCommunes);
    });
  };

  return (
    <Select
      inputId={`${uniqueId}`}
      onChange={e => handleChange(e, "commune")}
      options={communes}
      placeholder="Seleccione una opción"
      value={value.value ? value : null}
    />
  );
};

export default CommunesSelect;
