import React, { useState, useEffect } from "react";
import Select from "react-select";
import _ from "lodash";

const MultiSelect = props => {
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    const newOptions = [];
    _.map(props.items, item => {
      newOptions.push({
        value: item.key,
        label: `${props.translate(item.key)} (${item.doc_count})`
      });
    });
    setOptions(newOptions);
  }, [props.items]);

  const handleChange = selectedItems => {
    const items = [];
    setSelectedOptions(selectedItems);
    _.map(selectedItems, item => {
      items.push(item.value);
    });

    props.setItems(items);
  };

  return (
    <Select
      isMulti
      value={selectedOptions}
      options={options}
      clearable={true}
      placeholder="Seleccione Opción"
      noResultsText="No se encuentra la opción"
      onChange={handleChange}
    />
  );
};

export default MultiSelect;
