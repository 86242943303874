import axios from "axios";

export const saveFunctionalRequirementAnswers = (data, callback) => {
  var promise = axios({
    method: "put",
    url: "/api/functional_answers/massive_update",
    data: data,
    headers: {
      "X-CSRF-TOKEN": window.SomosEmpleos.token
    }
  });
  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};

export const getAvaliableAnswers = (question_id, callback) => {
  var promise = axios({
    method: "get",
    url: "/api/functional_questions/"+ question_id + "/get_avaliable_answers",
    headers: {
      "X-CSRF-TOKEN": window.SomosEmpleos.token
    }
  });
  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};

