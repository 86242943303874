import React, { useState, useEffect } from "react";
import Select from "react-select";
import { getAllTerritoryCommunes } from "./Axios";

const AllCommunesSelect = props => {
  const [communes, setCommunes] = useState([]);

  useEffect(() => {
    const newCommunes = communes;
    const getCommunes = () => {
      getAllTerritoryCommunes(response => {
        response.data.map(commune => {
          newCommunes.push({ value: commune.id, label: commune.name });
        });
      });
      setCommunes(newCommunes);
    };

    getCommunes();
  }, []);

  const handleChange = inputValue => {
    props.handleChange(inputValue, "desiredCommunes");
  };

  return (
    <Select
      isMulti
      onChange={handleChange}
      options={communes}
      placeholder="Seleccione hasta 5 opciones"
      value={props.value ? props.value : null}
      inputId="idealCommuneSelect"
      aria-describedby="idealCommuneSelectHelp"
    />
  );
};

export default AllCommunesSelect;
