import React, { Fragment } from "react";
import { Card, Col, Row, CardBody } from "reactstrap";
import _ from "lodash";
import PersonalDocumentForm from "../../profile/document/Form";

const UploadDocumentsForm = ({ requestedDocuments }) => {
  return (
    <Fragment>
      <Row>
        <Col md="12">
          <Card className="mb-4 material-shadow">
            <CardBody className="bg-light">
              <Row className="d-flex align-items-center">
                <Col md="12">
                  <div className="d-block mb-1 d-flex align-items-center">
                    <i className="fas fa-file fa-pull-left h4 mb-0" />
                    <h3 className="h4 mb-0 d-inline-block">Solicitud de Documentos</h3>
                  </div>
                  <p className="small text-dark mb-sm-0">
                    De acuerdo a tu postulación al cargo, la empresa ha solicitado que puedas enviar
                    algunos documentos para complementar tu información. Por favor sube tu
                    documentación a continuación.
                  </p>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card className="material-shadow">
            <CardBody>
              {_.map(requestedDocuments, document => (
                <PersonalDocumentForm
                  key={document.id}
                  descriptor={document.document_descriptor}
                  personalDocuments={requestedDocuments}
                />
              ))}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default UploadDocumentsForm;
