import React, { Fragment, useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Label,
  Alert
} from "reactstrap";
import Select from "react-select";
import _ from "lodash";
import { BeatLoader } from "react-spinners";
import { getAllPostulationsByCourse, submitNextStage } from "./Axios";

const NextStageModal = ({ courseId, workFlow, reloadQuery }) => {
  const [modal, setModal] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [postulations, setPostulations] = useState([]);
  const [postulationsSelected, setPostulationsSelected] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [showResultMessage, setShowResultMessage] = useState(false);
  const [nextStage, setNextStage] = useState(null);

  useEffect(() => {
    const getPostulationsByCourse = () => {
      if (courseId) {
        const newPostulations = postulations;
        getAllPostulationsByCourse(courseId, response => {
          _.map(response.data, postulation => {
            newPostulations.push({
              value: postulation.id,
              label: postulation.applicant_name,
              currentStage: postulation.current_stage,
              currentStageIndex: postulation.current_stage_index,
              errors: []
            });
          });
        });
        setPostulations(newPostulations);
      }
    };
    getPostulationsByCourse();
  }, []);

  const toggle = () => {
    setModal(!modal);
    setPostulationsSelected([]);
    setResponseData([]);
    setError(false);
    setLoading(false);
    setShowResultMessage(false);
    setNextStage(null);
  };

  const handleChangePostulationsSelected = newValue => {
    if (newValue) {
      setPostulationsSelected(newValue);
      setError(false);
    } else {
      setPostulationsSelected([]);
    }
  };

  const drawNextStageName = item => {
    if (
      item.currentStageIndex !== null &&
      item.currentStageIndex === workFlow.length - 1
    ) {
      return "Finalizó el Proceso";
    } else if (item.currentStageIndex !== null && item.currentStageIndex >= 0) {
      return workFlow[item.currentStageIndex + 1]["name"];
    } else {
      return workFlow[0]["name"];
    }
  };

  const drawInfoTable = () => {
    if (!showResultMessage && !loading && postulationsSelected.length > 0) {
      return (
        <Table striped size="sm">
          <thead>
            <tr>
              <th>#</th>
              <th>Candidato</th>
              <th>Etapa Actual</th>
              <th>Etapa Siguiente</th>
            </tr>
          </thead>
          <tbody>
            {_.map(postulationsSelected, (item, index) => (
              <tr key={item.value}>
                <td>{index + 1}</td>
                <td>{item.label}</td>
                <td>
                  {item.currentStage ? item.currentStage.name : "Postulación"}
                </td>
                <td>{nextStage ? nextStage.label : null}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    }
  };

  const drawResultTable = () => {
    if (showResultMessage && responseData.length > 0 && !loading) {
      return (
        <Table striped size="sm">
          <thead>
            <tr>
              <th>#</th>
              <th>Candidato</th>
              <th>Errores</th>
            </tr>
          </thead>
          <tbody>
            {_.map(responseData, (item, index) => (
              <tr key={item.applicantName}>
                <td>{index + 1}</td>
                <td>{item.applicantName}</td>
                <td>{item.errors}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    }
  };

  const drawResultMessage = () => {
    let type,
      message = "";
    if (
      showResultMessage &&
      responseData.length === postulationsSelected.length
    ) {
      type = "danger";
      message = "Se presentaron los siguientes errores:";
    } else if (showResultMessage && responseData.length > 0) {
      type = "warning";
      message =
        "Candidatos cambiados de etapa exitosamente, con las siguientes exepciones:";
    } else if (showResultMessage && responseData.length === 0) {
      type = "success";
      message = "Candidatos cambiados de etapa exitosamente.";
    }

    return <div className={`alert alert-${type}`}>{message}</div>;
  };

  const serializeData = () => {
    let formData = new FormData();
    _.map(postulationsSelected, postulation => {
      formData.append("postulation_ids[]", postulation.value);
    });
    formData.append("next_stage", nextStage.value);
    return formData;
  };

  const handleSubmitNextStage = async () => {
    if (postulationsSelected.length === 0 || !nextStage) {
      setError(true);
      return;
    }

    setLoading(true);
    const newResponseData = [];
    const data = serializeData();
    submitNextStage(courseId, data, response => {
      _.map(response.data, item => {
        const responseErrors = [];
        if (!_.isEmpty(item.errors)) {
          _.mapKeys(item.errors, value => {
            _.map(value, val => {
              responseErrors.push(val);
            });
          });
          newResponseData.push({
            applicantName: item.applicant_name,
            errors: responseErrors
          });
        }
      });
      setResponseData(newResponseData);
      setError(false);
      setLoading(false);
      setShowResultMessage(true);
      reloadQuery();
      if (newResponseData.length === 0) {
        toggle();
      }
    });
  };

  return (
    <Fragment>
      <Button color="success" outline onClick={toggle}>
        Cambio Masivo de Etapa
      </Button>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>Cambiar Candidatos de Etapa</ModalHeader>
        <ModalBody>
          {error && (
            <Alert color="danger">
              Debe seleccionar Candidatos y una Etapa!
            </Alert>
          )}
          <Label>Candidatos</Label>
          <Select
            isMulti
            placeholder="Seleccione Candiatos"
            options={postulations}
            onChange={handleChangePostulationsSelected}
            value={postulationsSelected}
          />
          <br />
          <Label>Etapa Siguiente</Label>
          <Select
            placeholder="Seleccione un Etapa"
            options={_.map(workFlow, stage => ({
              value: stage.type,
              label: stage.name
            }))}
            onChange={e => setNextStage(e)}
          />
          <div className=" mt-5 text-center">
            <BeatLoader color={"#123abc"} loading={loading} />
          </div>
          <div className="mt-4">{drawInfoTable()}</div>
          <div className="mt-4">{drawResultMessage()}</div>
          <div className="mt-4">{drawResultTable()}</div>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleSubmitNextStage}>
            Aceptar
          </Button>{" "}
          <Button color="danger" onClick={toggle}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default NextStageModal;
