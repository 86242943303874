import React, { Component } from "react";
import Select from "react-select";
import { getAvaliableAnswers } from "./Axios";

class AnswerSelect extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    avaliable_answers : []
  };

  handleChange = inputValue => {
    this.props.handleChange(inputValue, "answer", this.props.question_id);
  };

  componentDidMount() {
    this.getAnswers();
  }

  getAnswers = () => {
    let _this = this;
    getAvaliableAnswers(this.props.question_id, response => {
      var answers = [];
      response.data.map(function(answer) {
        var current_label = _this.props.is_course ? answer.company_name : answer.name;
        answers.push({ value: answer.id, label: current_label });
      });
      this.setState({ avaliable_answers: answers });
    });
  };

  render() {
    return (
      <Select
        inputId={"functionalQuestions_" + this.props.index}
        onChange={this.handleChange}
        options={this.state.avaliable_answers}
        placeholder="Usted..."
        value={this.props.value}
        key={'answer_select_' + this.props.index}
      />
    );
  }
}

export default AnswerSelect;
