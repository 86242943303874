import React, { useState, useEffect } from "react";
import Select from "react-select";
import { getEducationDegrees } from "./Axios";

const EducationDegreesSelect = ({ handleChangePersonalEducation, value }) => {
  const [educationDegrees, setEducationDegrees] = useState([]);

  const handleChange = inputValue => {
    handleChangePersonalEducation(inputValue, "educationDegree");
  };

  useEffect(() => {
    const newEducationDegrees = educationDegrees;
    getEducationDegrees(response => {
      response.data.map(degree => {
        newEducationDegrees.push({ value: degree.id, label: degree.name });
      });
    });
    setEducationDegrees(newEducationDegrees);
  }, []);

  return (
    <Select
      inputId="educationDegree"
      onChange={handleChange}
      options={educationDegrees}
      placeholder="Seleccione una opción"
      value={value.value ? value : null}
    />
  );
};

export default EducationDegreesSelect;
