import React, { useState, useEffect } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

const DraftInput = ({ value, onChangeInput }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (value) {
      const blocksFromHtml = htmlToDraft(value);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const editor = EditorState.createWithContent(contentState);
      setEditorState(editor);
    }
  }, []);

  const onEditorStateChange = editor => {
    setEditorState(editor);
    onChangeInput(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  return (
    <div>
      <Editor
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          options: [
            "inline",
            "blockType",
            "fontSize",
            "list",
            "textAlign",
            "colorPicker",
            "history"
          ],
          inline: {
            options: ["bold", "italic", "underline", "strikethrough"]
          },
          list: {
            options: ["unordered", "ordered"]
          }
        }}
      />
    </div>
  );
};

export default DraftInput;
