import React, { useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import Moment from "react-moment";
import { drawAasmState } from "../../commons/Functions";

const CompanyPurchasesHit = ({ hit }) => {
  const [companyPurchase, setCompanyPurchase] = useState(hit);

  useEffect(() => {
    setCompanyPurchase(hit);
  }, [hit]);

  const {
    totalPrice,
    flowPayment,
    companyCentral,
    boughtBy,
    product,
    flowStatus,
    aasmState,
    createdAt
  } = companyPurchase;

  return (
    <tr>
      <td className="text-center">
        {flowPayment ? flowPayment.flowOrder : ""}
      </td>
      <td>{companyCentral.name}</td>
      <td>{boughtBy ? boughtBy.email : ""}</td>
      <td>{product.name}</td>
      <td>
        <NumberFormat
          value={flowStatus ? flowStatus.amount : totalPrice}
          displayType={"text"}
          thousandSeparator="."
          decimalSeparator=","
          prefix={"$ "}
        />
      </td>
      <td>
        {flowStatus && flowStatus.paymentData
          ? flowStatus.paymentData.media
          : ""}
      </td>
      <td>
        {flowStatus && flowStatus.paymentData && flowStatus.paymentData.date ? (
          <Moment format="DD/MM/YYYY HH:mm">
            {flowStatus.paymentData.date}
          </Moment>
        ) : (
          ""
        )}
      </td>
      <td>{drawAasmState(aasmState)}</td>
    </tr>
  );
};

export default CompanyPurchasesHit;
