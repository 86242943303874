import React, { Component } from "react";
import { Row, Col, Form, FormGroup, Label, Input, Button } from "reactstrap";
import { savePersonaLaboralRequirement } from "./Axios";
import LaboralAreasSelect from "../../laboral/areas/Select";
import FieldWrapper from "../../commons/FieldWrapper";
import AllCommunesSelect from "../../territory/communes/AllSelect";

class PersonalLaboralRequirementForm extends Component {
  constructor(props) {
    super(props);

    const { personal_laboral_requirement } = this.props;
    this.state = {
      personalLaboralRequirement: {
        id: { value: personal_laboral_requirement.id },
        preferredSchedule: {
          value: personal_laboral_requirement.preferred_schedule,
          errors: []
        },
        preferredLaborDay: {
          value: personal_laboral_requirement.preferred_labor_day,
          errors: []
        },
        preferredTurn: {
          value: personal_laboral_requirement.preferred_turn,
          errors: []
        },
        dressCode: {
          value: personal_laboral_requirement.dress_code,
          errors: []
        },
        expectedSalary: { value: personal_laboral_requirement.expected_salary, errors: [] },
        dressCode: { value: personal_laboral_requirement.dress_code, errors: [] },
        laboralAreas: { value: personal_laboral_requirement.laboral_areas, errors: [] },
        desiredCommunes: { value: personal_laboral_requirement.desired_communes, errors: [] }
      }
    };
  }

  handleChangePersonalLaboralRequirement = (e, fieldName = null) => {
    const { personalLaboralRequirement } = this.state;
    if (fieldName === "laboralAreas" || fieldName === "desiredCommunes") {
      personalLaboralRequirement[fieldName]["value"] = e;
      personalLaboralRequirement[fieldName]["errors"] = [];
    } else if (fieldName) {
      personalLaboralRequirement[fieldName]["value"] = e.value;
      personalLaboralRequirement[fieldName]["label"] = e.label;
      personalLaboralRequirement[fieldName]["errors"] = [];
    } else {
      personalLaboralRequirement[e.target.name]["value"] = e.target.value;
      personalLaboralRequirement[e.target.name]["errors"] = [];
    }
    this.setState({ personalLaboralRequirement });
  };

  serializeData = () => {
    let formData = new FormData();
    const { personalLaboralRequirement } = this.state;

    if (personalLaboralRequirement.id.value) {
      formData.append("personal_laboral_requirement[id]", personalLaboralRequirement.id.value);
    }
    formData.append(
      "personal_laboral_requirement[preferred_schedule]",
      personalLaboralRequirement.preferredSchedule.value
    );
    formData.append(
      "personal_laboral_requirement[preferred_labor_day]",
      personalLaboralRequirement.preferredLaborDay.value
    );
    formData.append(
      "personal_laboral_requirement[preferred_turn]",
      personalLaboralRequirement.preferredTurn.value
    );
    formData.append(
      "personal_laboral_requirement[expected_salary]",
      personalLaboralRequirement.expectedSalary.value
    );
    formData.append(
      "personal_laboral_requirement[dress_code]",
      personalLaboralRequirement.dressCode.value
    );
    if (
      personalLaboralRequirement.laboralAreas.value &&
      personalLaboralRequirement.laboralAreas.value.length > 0
    ) {
      personalLaboralRequirement.laboralAreas.value.map(function(area) {
        formData.append("personal_laboral_requirement[laboral_area_ids][]", area.value);
      });
    } else {
      formData.append("personal_laboral_requirement[laboral_area_ids][]", []);
    }
    if (
      personalLaboralRequirement.desiredCommunes.value &&
      personalLaboralRequirement.desiredCommunes.value.length > 0
    ) {
      personalLaboralRequirement.desiredCommunes.value.map(function(commune) {
        formData.append("personal_laboral_requirement[desired_commune_ids][]", commune.value);
      });
    } else {
      formData.append("personal_laboral_requirement[desired_commune_ids][]", []);
    }

    return formData;
  };

  setErrors = errors => {
    const { personalLaboralRequirement } = this.state;
    personalLaboralRequirement.laboralAreas.errors = errors.laboral_areas;
    personalLaboralRequirement.desiredCommunes.errors = errors.desired_communes;
    this.setState({ personalLaboralRequirement });
  };

  handleSubmit = e => {
    e.preventDefault();
    let data = this.serializeData();
    savePersonaLaboralRequirement(data, response => {
      if (response.status === 200) {
        this.props.show_notification("Guardado", "Guardado exitoso", "success");
        window.location.href = `/user/functionalprofile`;
      } else if (response.status === 422) {
        this.props.show_notification(
          "Guardado",
          "Ocurrio un error, revisa el formulario",
          "danger"
        );
        this.setErrors(response.data);
      }
    });
  };

  render() {
    const { personalLaboralRequirement } = this.state;

    return (
      <div>
        <Form>
          <FormGroup tag="fieldset">
            <Row>
              <legend
                className="col-form-label pt-0 col-sm-4 input_match"
                id="preferredScheduleLabel"
              >
                Jornada Laboral
              </legend>
              <Col sm="8">
                <FormGroup check role="radiogroup" aria-labelledby="preferredScheduleLabel">
                  <Row>
                    <Col md="3">
                      <Label htmlFor="preferredScheduleCheck1">
                        <Input
                          id="preferredScheduleCheck1"
                          name="preferredSchedule"
                          type="radio"
                          value="part_time"
                          onChange={this.handleChangePersonalLaboralRequirement}
                          checked={
                            personalLaboralRequirement.preferredSchedule.value === "part_time"
                          }
                        />
                        Part Time
                      </Label>
                    </Col>
                    <Col md="3">
                      <Label htmlFor="preferredScheduleCheck2">
                        <Input
                          id="preferredScheduleCheck2"
                          name="preferredSchedule"
                          type="radio"
                          value="full_time"
                          onChange={this.handleChangePersonalLaboralRequirement}
                          checked={
                            personalLaboralRequirement.preferredSchedule.value === "full_time"
                          }
                        />
                        Jornada Completa
                      </Label>
                    </Col>
                    <Col md="3">
                      <Label htmlFor="preferredScheduleCheck3">
                        <Input
                          id="preferredScheduleCheck3"
                          name="preferredSchedule"
                          type="radio"
                          value="half_time"
                          onChange={this.handleChangePersonalLaboralRequirement}
                          checked={
                            personalLaboralRequirement.preferredSchedule.value === "half_time"
                          }
                        />
                        Media Jornada
                      </Label>
                    </Col>
                    <Col md="3">
                      <Label htmlFor="preferredScheduleCheck4">
                        <Input
                          id="preferredScheduleCheck4"
                          name="preferredSchedule"
                          type="radio"
                          value="remote_work"
                          onChange={this.handleChangePersonalLaboralRequirement}
                          checked={
                            personalLaboralRequirement.preferredSchedule.value === "remote_work"
                          }
                        />
                        Teletrabajo
                      </Label>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
          </FormGroup>
          <hr />
          <FormGroup tag="fieldset">
            <Row>
              <legend
                className="col-form-label pt-0 col-sm-4 input_match"
                id="preferredLaborDayLabel"
              >
                Extensión de la jornada
              </legend>
              <Col sm="8">
                <FormGroup check role="radiogroup" aria-labelledby="preferredLaborDayLabel">
                  <Row>
                    <Col md="4">
                      <Label htmlFor="preferredLaborDay1">
                        <Input
                          id="preferredLaborDay1"
                          name="preferredLaborDay"
                          type="radio"
                          value="monday_friday"
                          onChange={this.handleChangePersonalLaboralRequirement}
                          checked={
                            personalLaboralRequirement.preferredLaborDay.value === "monday_friday"
                          }
                        />
                        Lunes a Viernes
                      </Label>
                    </Col>
                    <Col md="4">
                      <Label htmlFor="preferredLaborDay2">
                        <Input
                          id="preferredLaborDay2"
                          name="preferredLaborDay"
                          type="radio"
                          value="monday_sunday"
                          onChange={this.handleChangePersonalLaboralRequirement}
                          checked={
                            personalLaboralRequirement.preferredLaborDay.value === "monday_sunday"
                          }
                        />
                        Lunes a Domingo
                      </Label>
                    </Col>
                    <Col md="4">
                      <Label htmlFor="preferredLaborDay3">
                        <Input
                          id="preferredLaborDay3"
                          name="preferredLaborDay"
                          type="radio"
                          value="weekends"
                          onChange={this.handleChangePersonalLaboralRequirement}
                          checked={
                            personalLaboralRequirement.preferredLaborDay.value === "weekends"
                          }
                        />
                        Fines de Semana
                      </Label>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
          </FormGroup>
          <hr />
          <FormGroup tag="fieldset">
            <Row>
              <legend className="col-form-label pt-0 col-sm-4 input_match" id="preferredTurnLabel">
                Horarios y/o Turnos
              </legend>
              <Col sm="8">
                <FormGroup check role="radiogroup" aria-labelledby="preferredTurnLabel">
                  <Row>
                    <Col md="4">
                      <Label htmlFor="preferredTurn1">
                        <Input
                          id="preferredTurn1"
                          name="preferredTurn"
                          type="radio"
                          value="day_time"
                          onChange={this.handleChangePersonalLaboralRequirement}
                          checked={personalLaboralRequirement.preferredTurn.value === "day_time"}
                        />
                        Diurno
                      </Label>
                    </Col>
                    <Col md="4">
                      <Label htmlFor="preferredTurn2">
                        <Input
                          id="preferredTurn2"
                          name="preferredTurn"
                          type="radio"
                          value="vespertine"
                          onChange={this.handleChangePersonalLaboralRequirement}
                          checked={personalLaboralRequirement.preferredTurn.value === "vespertine"}
                        />
                        Vespertino
                      </Label>
                    </Col>
                    <Col md="4">
                      <Label htmlFor="preferredTurn3">
                        <Input
                          id="preferredTurn3"
                          name="preferredTurn"
                          type="radio"
                          value="rotative"
                          onChange={this.handleChangePersonalLaboralRequirement}
                          checked={personalLaboralRequirement.preferredTurn.value === "rotative"}
                        />
                        Rotativos
                      </Label>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
          </FormGroup>
          <hr />
          <FormGroup row>
            <Col sm="4">
              <label className="input_match mb-0" htmlFor="idealLaboralArea">
                Área Laboral de Interés
              </label>
              <small id="laboralAreaHelp" className="form-text mt-0">
                Seleccione hasta 10 opciones.
              </small>
            </Col>
            <Col sm="8">
              <FieldWrapper errors={personalLaboralRequirement.laboralAreas.errors}>
                <LaboralAreasSelect
                  isMulti
                  value={personalLaboralRequirement.laboralAreas.value}
                  handleChange={this.handleChangePersonalLaboralRequirement}
                />
              </FieldWrapper>
            </Col>
          </FormGroup>
          <hr />
          <FormGroup row>
            <Col sm="4">
              <label className="input_match mb-0" htmlFor="idealCommuneSelect">
                Comunas de Interés
              </label>
              <small id="idealCommuneSelectHelp" className="form-text mt-0">
                Seleccione hasta 5 opciones.
              </small>
            </Col>
            <Col sm="8">
              <FieldWrapper errors={personalLaboralRequirement.desiredCommunes.errors}>
                <AllCommunesSelect
                  value={personalLaboralRequirement.desiredCommunes.value}
                  handleChange={this.handleChangePersonalLaboralRequirement}
                />
              </FieldWrapper>
            </Col>
          </FormGroup>
          <hr/>
          <FormGroup tag="fieldset">
            <Row>
              <legend className="col-form-label pt-0 col-sm-4 input_match" id="dressCodeLabel">
                Código de Vestimenta laboral Formal
              </legend>
              <small id="dressCodeHelp" className="form-text mt-0">
                Algunas empresas suelen exigir vestimenta formal.
              </small>
              <Col sm="8">
                <FormGroup check role="radiogroup" aria-labelledby="dressCodeLabel">
                  <Row>
                    <Col md="4">
                      <Label htmlFor="dressCode1">
                        <Input
                          id="dressCode1"
                          name="dressCode"
                          type="radio"
                          value="yes"
                          onChange={this.handleChangePersonalLaboralRequirement}
                          checked={personalLaboralRequirement.dressCode.value === "yes"}
                        />
                        Si
                      </Label>
                    </Col>
                    <Col md="4">
                      <Label htmlFor="dressCode2">
                        <Input
                          id="dressCode2"
                          name="dressCode"
                          type="radio"
                          value="no"
                          onChange={this.handleChangePersonalLaboralRequirement}
                          checked={personalLaboralRequirement.dressCode.value === "no"}
                        />
                        No
                      </Label>
                    </Col>
                    <Col md="4">
                      <Label htmlFor="dressCode3">
                        <Input
                          id="dressCode3"
                          name="dressCode"
                          type="radio"
                          value="indifferent"
                          onChange={this.handleChangePersonalLaboralRequirement}
                          checked={personalLaboralRequirement.dressCode.value === "indifferent"}
                        />
                        Indiferente
                      </Label>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
          </FormGroup>
          <hr />
          <FormGroup row>
            <Label className="col-sm-4" htmlFor="expectedSalary">
              Expectativas de Sueldo
            </Label>
            <Col sm="8">
              <Input
                id="expectedSalary"
                name="expectedSalary"
                className="form-control"
                placeholder="Expectativas de Sueldo"
                type="text"
                onChange={this.handleChangePersonalLaboralRequirement}
                value={personalLaboralRequirement.expectedSalary.value}
              />
            </Col>
          </FormGroup>
          <Row>
            <Col md="12" className="text-right">
              <Button color="primary" className="my-4" onClick={this.handleSubmit}>
                Guardar
                <i className="fas fa-arrow-right ml-2"></i>
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default PersonalLaboralRequirementForm;
