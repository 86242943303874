import React, { Component, Fragment } from "react";
import {
  SearchkitManager,
  SearchkitProvider,
  Hits,
  NoHits,
  Pagination,
  SearchBox,
  HitsStats,
  SortingSelector
} from "searchkit";
import { Card, CardBody, Row, Col, Button } from "reactstrap";
import CoursesHits from "./Hits";

class CoursesIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchkit: new SearchkitManager(this.props.route, {
        httpHeaders: { "X-CSRF-TOKEN": window.SomosEmpleos.token }
      })
    };
  }

  render() {
    const { canPublish } = this.props;

    return (
      <SearchkitProvider searchkit={this.state.searchkit}>
        <Fragment>
          <Card className="mb-3">
            <CardBody>
              <Row>
                <Col md="6">
                  <SearchBox
                    autofocus={true}
                    searchOnChange={true}
                    translations={{ "searchbox.placeholder": "Buscar" }}
                    prefixQueryFields={["id"]}
                  />
                  <HitsStats
                    translations={{
                      "hitstats.results_found": "{hitCount} resultados encontrados en {timeTaken}ms"
                    }}
                  />
                </Col>
                <Col></Col>
                <Col md="3">
                  <SortingSelector
                    options={[
                      {
                        label: "Fecha de Creación reciente",
                        field: "created_at",
                        order: "desc",
                        defaultOption: true
                      },
                      { label: "Fecha de Creación antigua", field: "created_at", order: "asc" }
                    ]}
                  />
                </Col>
              </Row>
              {/*<Row>
                <Col>
                  <Button href="/publications/new" className="btn btn-success" size="sm">
                    Nueva publicacion
                  </Button>
                </Col>
              </Row>*/}
            </CardBody>
          </Card>
          <Hits hitsPerPage={12} listComponent={<CoursesHits />} />
          <NoHits
            translations={{
              "NoHits.NoResultsFound": "No se encontraron resultados para {query}",
              "NoHits.DidYouMean": "Busqueda para {suggestion}",
              "NoHits.SearchWithoutFilters": "Busqueda para {query} sin filtros",
              "NoHits.NoResultsFoundDidYouMean":
                "No se encontraron resultados para {query}. Quisiste decir {suggestion} ?"
            }}
          />
          <div className="mt-3 overflow-auto">
            <Pagination
              showNumbers={true}
              translations={{
                "pagination.previous": "Anterior",
                "pagination.next": "Siguiente"
              }}
            />
          </div>
        </Fragment>
      </SearchkitProvider>
    );
  }
}

export default CoursesIndex;
