import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardHeader,
  ListGroup,
  ListGroupItem,
  FormGroup,
  Label,
  Input,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge
} from "reactstrap";
import MatchIndicator from "../commons/MatchIndicator";
import ApplicantStages from "../applicant/Stages";

class CoursePostulation extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  render() {
    const { dropdownOpen } = this.state;

    return (
      <Card className="mb-3 publication-list-item job-card">
        <CardBody>
          <div className="d-flex justify-content-between flex-wrap align-items-center">
            <div className="flex-grow-1 pr-md-3">
              <h2 className="h5 mb-3">
                Titulo de Oferta Laboral&nbsp;
                <Badge color="primary" className="badge-indicator">
                  Abierto
                </Badge>
                <small className="d-inline-block text-muted d-block">
                  <strong>Área:&nbsp;</strong>
                  Área laboral
                </small>
              </h2>
              <div className="media flex-wrap">
                <img
                  className="mr-2 mb-2 area-icon"
                  alt="Cargo dinámico"
                  src="/assets/cargos/guardia-ebf370455154c76482d7753dba73b790bf00b83c7d6bd29e14e22956c536ecf0.png"
                />
                <div className="media-body">
                  <ul className="fa-ul list-unstyled mb-0 ml-4 publication-detail-list">
                    <li>
                      <span className="fa-li">
                        <i
                          aria-hidden="true"
                          className="far fa-calendar-alt"
                          title="Fecha de Publicación"
                        ></i>
                      </span>
                      Fecha de Publicación 23-08-2019
                    </li>
                    <li>
                      <span className="fa-li">
                        <i
                          aria-hidden="true"
                          className="fas fa-business-time"
                          title="Jornada, Horarios y/o Turnos"
                        ></i>
                      </span>
                      Jornada Completa
                    </li>
                    <li>
                      <span className="fa-li">
                        <i
                          aria-hidden="true"
                          className="fas fa-map-marker-alt"
                          title="Ubicación"
                        ></i>
                      </span>
                      Las Condes, Región Metropolitana.
                    </li>
                    <li>
                      <span className="fa-li">
                        <i
                          className="fas fa-universal-access"
                          aria-hidden="true"
                          title="Infraestructura"
                        ></i>
                      </span>
                      Infraestructura Accesible
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="flex-fill text-center d-flex flex-column flex-sm-row flex-md-column flex-lg-column align-items-center justify-content-center">
              <MatchIndicator matchpercentage="80" />
            </div>

            <div className="flex-fill text-left">
              <ApplicantStages />
            </div>

            <div className="flex-lg-shrink flex-fill text-right d-flex flex-column flex-sm-row flex-md-row flex-lg-column align-items-center justify-content-center align-items-lg-end justify-content-lg-center">
              {/* Solo admin proceso */}
              <ButtonDropdown
                isOpen={dropdownOpen}
                toggle={this.toggle}
                aria-label="Opciones de Candidato"
                title="Opciones de Candidato"
              >
                <DropdownToggle caret color="secondary" size="sm" className="m-2 m-lg-0 mb-lg-2">
                  <i className="fas fa-user-cog mr-2" aria-hidden="true"></i>
                  Gestionar
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>Descargar CV</DropdownItem>
                  <DropdownItem>Descargar Respuestas</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem>Cambiar de Etapa</DropdownItem>
                  <DropdownItem>Contactar</DropdownItem>
                  <DropdownItem>Agendar Entrevista</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem>Descartar</DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>

              {/* Solo admin proceso */}
              <Button
                size="sm"
                color="primary"
                className="my-2 align-self-lg-end"
                href="/course/show"
              >
                Ver Proceso
              </Button>

              {/* Solo candidato */}
              <Button
                size="sm"
                color="primary"
                className="my-2 align-self-lg-end"
                href="/publication/show"
              >
                Ver Publicación
              </Button>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default CoursePostulation;
