import React, { useState } from "react";
import { Badge } from "reactstrap";
import DiversityFlagIndicator from '../../../assets/images/lgbtq.png';
import FemaleIndicator from '../../../assets/images/female.png';
import AgeIndicator from '../../../assets/images/user_age.png';

const ApplicantData = ({ user, applicant }) => {
  const [isFavourite, setIsFavourite] = useState(false);

  const handleStarApplicant = e => {
    setIsFavourite(e.target.checked);
  };
  const showEmailInstitute = () => {
  if (user.disability_information &&
    user.disability_information.disability_institution &&
    user.disability_information.disability_institution.email) {
      return(
        <li>
          <span className="fa-li">
            <i aria-hidden="true" className="fas fa-at" title="Email"></i>
          </span>
            <a href={"mailto:" + user.disability_information.disability_institution.email}
              data-toggle="tooltip" data-placement="bottom" title="Correo Electrónico de la Fundación">
              {user.disability_information.disability_institution.email}
            </a>
        </li>
      )
    }
  };

  const showAdress = () => {
    if (user.personal_information.address
      || user.personal_information.commune.label
      || user.personal_information.region.label
      || user.personal_information.country.label) {
      return(
        <span>
          {user.personal_information.address ? user.personal_information.address + ". " : ""}
          {user.personal_information.commune.label ?  user.personal_information.commune.label + ", " : ""}
          {user.personal_information.region.label ?  user.personal_information.region.label + ", " : ""}
          {user.personal_information.country.label ? user.personal_information.country.label + "." : ""}
        </span>
      )
    } else {
      return(
        <span>No ingresó</span>
      )
    }
  };

  const drawDiversityIndicator = () => {
    if (applicant && (applicant.user.personal_information.identify_lgtbi === "true" || applicant.user.personal_information.identify_lgtbi === true)) {
      return(
        <Badge color="light" className="badge-user-indicator mb-1 border">
          <img src={DiversityFlagIndicator}
               className="img-indicator mr-1"
               style={{ 'width': '18px' }}
               width="18"
          />
          LGTBI+
        </Badge>
      )
    }
  }

  const drawFemaleIndicator = () => {
    if (applicant && applicant.user.personal_information && applicant.user.personal_information.gender === "female") {
      return(
        <Badge color="light" className="badge-user-indicator mb-1 border">
          <img src={FemaleIndicator}
               className="img-indicator mr-1"
               style={{ 'width': '18px' }}
               width="18"
          />
          +Equidad
        </Badge>
      )
    }
  }

  const avatar = user.avatar
    ? user.avatar
    : "/assets/User_Avatar_2-91bc95a48d1c6e787c0f24accbce21ae27acebed9a065689cd441caa3ceaf43d.png";
  return (
    <div className="media flex-wrap applicant-data">
      {/*
        <div className="d-block relative-item">
          <div className="avatar-icon mr-2 mb-2 d-block d-flex align-items-center justify-content-center rounded-circle"
            style={{ backgroundImage: "url(" + avatar + ")" }}
          >
          </div>
          <div className="star-indicator rating">
            <label className={"rating-star " + (isFavourite ? "active" : "")} htmlFor="star-rating" >
              <input className="rating-star-input"
                     type="checkbox"
                     name="rating-input"
                     id="star-rating"
                     onChange={this.handleStarApplicant}
                     checked={isFavourite ? true : false}
              />
              <i className="fa fa-star" aria-hidden="true"></i>
            </label>
          </div>
        </div>
      */}

      <div className="media-body ml-3" >
        <h2 className="h5 mb-2 __force-text_break-word font-weight-bold">
          {user.personal_information.social_name === "" || user.personal_information.social_name === undefined ?
            `${user.personal_information.first_name} ${user.personal_information.last_name}`
            : `${user.personal_information.social_name} ${user.personal_information.last_name}`
          }
          <div className="d-block mt-1 d-flex align-items-strech flex-wrap">
            {drawDiversityIndicator()}
            {drawFemaleIndicator()}
            {applicant && applicant.user.personal_information.age >= 60 && (
              <Badge color="light" className="badge-user-indicator mb-1 border">
                <img src={AgeIndicator}
                     className="img-indicator mr-1"
                     style={{ 'width': '18px' }}
                     width="18"
                />
                {applicant.user.personal_information.age} años
              </Badge>
            )}
            {applicant && applicant.update_request_count > 0 && (
              <Badge color="success" className="badge-user-indicator mb-1">
                Contactado
              </Badge>
            )}
            {applicant && applicant.course_invitation_count > 0 && (
              <Badge color="info" className="badge-user-indicator mb-1">
                Invitado
              </Badge>
            )}
            {applicant && applicant.user.disability_information.has_disability && (
              <Badge color="primary" className="badge-user-indicator mb-1 badge-soft-primary">
                <i className="fas fa-universal-access mr-1 fa-lg"></i>
                Situación de Discapacidad
              </Badge>
            )}
            {applicant && applicant.user.disability_information.has_disability_credential && (
              <Badge color="primary" className="badge-user-indicator mb-1 badge-soft-primary">
                Credencial
              </Badge>
            )}
            {applicant && applicant.user.disability_information.has_invalidity_pension && (
              <Badge color="primary" className="badge-user-indicator mb-1 badge-soft-primary">
                Pensión
              </Badge>
            )}
          </div>
        </h2>
        <ul className="fa-ul list-unstyled mb-0 ml-4 publication-detail-list __force-text_break-word">
          <li className={user.identification_document_number ? "" : "disabled-info"}>
            <span className="fa-li">
              <i aria-hidden="true" className="far fa-id-card" title="RUT"></i>
            </span>
            {user.identification_document_number ? user.identification_document_number : "No ingresó"}
          </li>
          <li className={user.personal_information.cellphone ? "" : "disabled-info"}>
            <span className="fa-li">
              <i aria-hidden="true" className="fas fa-phone" title="Teléfono"></i>
            </span>
            {user.personal_information.cellphone ? user.personal_information.cellphone : "No ingresó"}
          </li>
          {(user.email || user.personal_information.last_email) && (
            <li>
              <span className="fa-li">
                <i aria-hidden="true" className="far fa-envelope" title="Email"></i>
              </span>
              {user.email}
              {user.personal_information.last_email
                ? " - " + user.personal_information.last_email
                : ""
              }
            </li>
          )}
          <li className={user.personal_information.address || user.personal_information.commune.label || user.personal_information.region.label || user.personal_information.country.label ? "" : "disabled-info"}>
            <span className="fa-li">
              <i aria-hidden="true" className="fas fa-map-marker-alt" title="Ubicación"></i>
            </span>
            {showAdress()}
          </li>
          {showEmailInstitute()}
        </ul>
      </div>
    </div>
  );
};

export default ApplicantData;
