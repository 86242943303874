import axios from "axios";

export const getCompanyFoundations = callback => {
  var promise = axios({
    method: "get",
    url: "/api/company_centrals/foundations"
  });
  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};

export const saveCompanyRegisterRequest = (data, callback) => {
  var promise = axios({
    method: "post",
    data: data,
    url: "/api/company_centrals/company_request",
    headers: {
      "X-CSRF-TOKEN": window.SomosEmpleos.token
    }
  });
  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};

export const saveCompanyCentral = (data, callback) => {
  let method, url;

  if (data.get("company_central[id]")) {
    method = "patch";
    url = `/api/company_centrals/${data.get("company_central[id]")}`;
  } else {
    method = "post";
    url = "/api/company_centrals";
  }

  var promise = axios({
    method: method,
    url: url,
    data: data,
    headers: {
      "X-CSRF-TOKEN": window.SomosEmpleos.token
    }
  });
  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};

export function switchState(company_central_id, data, callback) {
  var promise = axios({
    method: "patch",
    url: `/api/company_centrals/${company_central_id}/switch_state`,
    data: data,
    headers: {
      "X-CSRF-TOKEN": window.SomosEmpleos.token
    }
  });
  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
}
