import React, { Fragment } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardHeader,
  Badge
} from "reactstrap";
import moment from "moment";
import parse from "html-react-parser";
import _ from "lodash";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon
} from "react-share";
import PostulationForm from "../postulation/Form";
import PublicationQuestions from "./Questions";

const PublicationShow = props => {
  const {
    postulationAnswers,
    isAlreadyPostulated,
    user,
    course,
    isPreview,
    userSignedIn
  } = props;
  const { minisitePublication, companyCentral } = course;

  const description = isPreview
    ? parse(course.jobDescription.value)
    : parse(course.jobDescription);

  const benefits = isPreview
    ? parse(minisitePublication.benefits.value)
    : parse(minisitePublication.benefits);

  const isConfidential = isPreview
    ? minisitePublication.isConfidential.value
    : minisitePublication.isConfidential;

  const truncateText = (str, len) => {
    if (str.length > len) {
      return str.substring(0, len) + "...";
    }
  }

  return (
    <div>
      <Card className="mb-4 publication-full-card" id="publication_show_info">
        <CardHeader>
          <Row>
            <Col lg="9" md="8">
              <h2 className="h4 mb-3" id="publication_title">
                {isPreview ? course.title.value : course.title}&nbsp;
                <Badge color="primary" className="badge-indicator ml-1">
                  Nueva Oferta
                </Badge>
                <small className="d-inline-block text-muted d-block">
                  <strong>Área:&nbsp;</strong>
                  {isPreview
                    ? course.laboralArea.label
                    : course.laboralArea.name}
                </small>
              </h2>
              <div className="media mb-4 mb-md-0 d-flex align-items-start flex-wrap">
                <img
                  src={
                    isPreview
                      ? course.laboralArea.path
                      : course.laboralArea.attachment
                  }
                  className="img-fluid mb-2 mb-lg-0 mr-3 area-icon rs_skip_always"
                  alt={
                    isPreview
                      ? course.laboralArea.label
                      : course.laboralArea.name
                  }
                />
                <div className="media-body">
                  <ul className="fa-ul list-unstyled mb-0 ml-4 publication-detail-list" id="publication-detail-list">
                    <li>
                      <span className="fa-li">
                        <i
                          aria-hidden="true"
                          className="far fa-calendar-alt"
                          title="Fecha de Publicación"
                        ></i>
                      </span>
                      {isPreview
                        ? moment().format("DD/MM/YYYY")
                        : moment(course.createdAt).format("DD/MM/YYYY")}
                    </li>
                    <li>
                      <span className="fa-li">
                        <i
                          aria-hidden="true"
                          className="fas fa-briefcase"
                          title="Contrato"
                        ></i>
                      </span>
                      {course.contractType.label}
                    </li>
                    <li>
                      <span className="fa-li">
                        <i
                          aria-hidden="true"
                          className="fas fa-business-time"
                          title="Jornada, Horarios y/o Turnos"
                        ></i>
                      </span>
                      {!isPreview
                        ? `${
                            minisitePublication.preferredSchedule
                              ? I18n.t(
                                  "activerecord.enums.personal/laboral_requirement.preferred_schedules." +
                                    minisitePublication.preferredSchedule
                                )
                              : ""
                          }. ${
                            minisitePublication.preferredLaborDay
                              ? I18n.t(
                                  "activerecord.enums.personal/laboral_requirement.preferred_labor_days." +
                                    minisitePublication.preferredLaborDay
                                )
                              : ""
                          }. ${
                            minisitePublication.preferredTurn
                              ? I18n.t(
                                  "activerecord.enums.personal/laboral_requirement.preferred_turns." +
                                    minisitePublication.preferredTurn
                                )
                              : ""
                          }`
                        : `${
                            minisitePublication.preferredSchedule.value
                              ? I18n.t(
                                  "activerecord.enums.personal/laboral_requirement.preferred_schedules." +
                                    minisitePublication.preferredSchedule.value
                                )
                              : ""
                          }. ${
                            minisitePublication.preferredLaborDay.value
                              ? I18n.t(
                                  "activerecord.enums.personal/laboral_requirement.preferred_labor_days." +
                                    minisitePublication.preferredLaborDay.value
                                )
                              : ""
                          }. ${
                            minisitePublication.preferredTurn.value
                              ? I18n.t(
                                  "activerecord.enums.personal/laboral_requirement.preferred_turns." +
                                    minisitePublication.preferredTurn.value
                                )
                              : ""
                          }.`}
                    </li>
                    {minisitePublication.dressCode && (
                      <li>
                        <span className="fa-li">
                          <i
                            aria-hidden="true"
                            className="fas fa-user-tie"
                          ></i>
                        </span>
                        {!isPreview
                          ? `${
                              minisitePublication.dressCode
                              ? I18n.t(
                                  "activerecord.enums.personal/laboral_requirement.dress_code." +
                                    minisitePublication.dressCode
                                )
                              : ""
                            }`
                          : `${
                              minisitePublication.dressCode.value
                              ? I18n.t(
                                  "activerecord.enums.personal/laboral_requirement.dress_code." +
                                    minisitePublication.dressCode.value
                                )
                              : ""
                            }`}
                      </li>
                    )}
                    <li>
                      <span className="fa-li">
                        <i
                          aria-hidden="true"
                          className="fas fa-users"
                          title="Vacantes"
                        ></i>
                      </span>
                      {isPreview ? course.vacancies.value : course.vacancies}{" "}
                      Vacantes
                    </li>
                    {!isConfidential && (
                      <li>
                        <span className="fa-li">
                          <i
                            aria-hidden="true"
                            className="fas fa-map-marker-alt"
                            title="Ubicación"
                          ></i>
                        </span>
                        {!isPreview
                          ? `${minisitePublication.address}. ${minisitePublication.commune.name}, ${minisitePublication.region.name}, ${minisitePublication.country.name}`
                          : `${minisitePublication.address.value}. ${minisitePublication.commune.label}, ${minisitePublication.region.label}, ${minisitePublication.country.label}`}
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </Col>
            <Col lg="3" md="4" className="text-right rs_skip_always">
              {/*Social Buttons*/}
              <div className="d-flex justify-content-end align-items-center my-2">
                <FacebookShareButton
                  className="ml-2 "
                  url={window.location.href}
                  quote={isPreview ? course.title.value : course.title}
                >
                  <FacebookIcon size={35} round />
                </FacebookShareButton>

                <LinkedinShareButton
                  className="ml-2 "
                  url={window.location.href}
                >
                  <LinkedinIcon size={35} round />
                </LinkedinShareButton>

                <TwitterShareButton
                  className="ml-2 "
                  url={window.location.href}
                  title={isPreview ? course.title.value : course.title}
                >
                  <TwitterIcon size={35} round />
                </TwitterShareButton>

                <WhatsappShareButton
                  className="ml-2 "
                  url={window.location.href}
                  title={isPreview ? course.title.value : course.title}
                  separator=":: "
                >
                  <WhatsappIcon size={35} round />
                </WhatsappShareButton>
              </div>

              {!isConfidential && (
                <div className="company_info mb-3 text-right">
                  <strong className="d-block small text-muted font-weight-bold mb-2">
                    Publicado por:
                  </strong>
                  <img
                    src={
                      companyCentral.avatar
                        ? companyCentral.avatar
                        : "/assets/somos_empleos_logo-9ffc635adaecf31642b6ee1c7b81aa2644f9bd63b4eb7ad83aa6b54206c7733b.png"
                    }
                    className="company_logo img-fluid d-inline-block mb-2"
                    alt=""
                  />
                  <small className="d-block text-right text-muted">
                    <strong className="d-block">{companyCentral.name}</strong>
                    <span>
                      {companyCentral.description
                        ? truncateText(companyCentral.description, 140)
                        : "" }
                    </span>
                  </small>
                </div>
              )}
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col lg="12" md="12">
              <div className="publication_description mb-4" id="publication_description">
                <small className="d-block text-muted font-weight-bold">
                  Descripción
                </small>
                <Card>
                  <CardBody>{description}</CardBody>
                </Card>
              </div>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col md="6">
              <Card className="mb-2" id="publication_requirements">
                <CardBody>
                  <h3 className="h6 font-weight-bold">
                    <i className="fas fa-list-ul mr-2"></i>
                    Requerimientos del Cargo
                  </h3>
                  <p>
                    {isPreview
                      ? minisitePublication.requirements.value
                      : minisitePublication.requirements}
                  </p>
                  <hr />
                  <span className="d-block">
                    <strong>Experiencia:&nbsp;</strong>
                    {isPreview
                      ? minisitePublication.experienceYears.value
                      : minisitePublication.experienceYears}{" "}
                    años.
                  </span>
                  <span className="d-block">
                    <strong>Nivel Educacional:&nbsp;</strong>
                    {minisitePublication.educationLevel.value
                      ? I18n.t(
                          "activerecord.enums.publication/base.education_levels." +
                            minisitePublication.educationLevel.value
                        )
                      : null}
                  </span>
                </CardBody>
              </Card>
              <Card className="mb-2" id="publication_benefits">
                <CardBody>
                  <h3 className="h6 font-weight-bold">
                    <i className="far fa-smile-wink mr-2"></i>
                    Beneficios
                  </h3>
                  {benefits}
                  <strong>Tipo de Contrato: </strong>
                  {course.contractType.label}
                  <br />
                  {(isPreview &&
                    minisitePublication.showSalaryOffered.value === true) ||
                    (!isPreview && minisitePublication.showSalaryOffered && (
                      <Fragment>
                        <strong>Sueldo Líquido: </strong>
                        {isPreview
                          ? minisitePublication.salaryOffered.value
                          : minisitePublication.salaryOffered}
                      </Fragment>
                    ))}
                </CardBody>
              </Card>
            </Col>
            <Col md="6">
              <Card className="mb-2 bg-light" id="publication_accessibility">
                <CardBody>
                  <h3 className="h6 font-weight-bold">
                    <i className="fas fa-universal-access mr-2"></i>
                    Accesibilidad
                  </h3>
                  <Row>
                    {!userSignedIn && !isPreview && (
                      <Col>
                        <h6 className="text-center">
                          Para ver esta información, inicia sesión
                        </h6>
                      </Col>
                    )}
                    {course.id &&
                      isPreview &&
                      _.map(course.accessibilityAnswers, answer => {
                        if (answer.isChecked) {
                          return (
                            <Col xs="6" md="4" key={answer.label}>
                              <div className="my-3 d-flex flex-column justify-content-center align-items-center justify-content-center text-center">
                                <img
                                  src={answer.iconSrc}
                                  className="rounded-lg mb-2 d-block p-2 infraestructure-img-show mx-auto"
                                  alt=""
                                />
                                <small>{answer.label}</small>
                                <small>{answer.observations}</small>
                              </div>
                            </Col>
                          );
                        }
                      })}

                    {user &&
                      !isPreview &&
                      _.map(course.accessibilityAnswers, answer => {
                        if (answer.isChecked) {
                          let a = user.accessibility_answers.filter(function(
                            obj
                          ) {
                            return (
                              obj.isChecked === true &&
                              obj.value === answer.value
                            );
                          });
                          return (
                            <Col xs="6" md="4" key={answer.label}>
                              <div
                                className={
                                  `${
                                    a.length >= 1
                                      ? "infraestructure-match-indicator"
                                      : "infraestructure-no-match rs_skip"
                                  }` +
                                  " infraestructure-show-container my-3 d-flex flex-column justify-content-center align-items-center justify-content-center text-center relative-item"
                                }
                              >
                                <img
                                  src={answer.iconSrc}
                                  className="rounded-lg mb-2 d-block p-2 infraestructure-img-show mx-auto"
                                  alt=""
                                />
                                <small>{answer.label}</small>
                                <small>{answer.observations}</small>
                              </div>
                            </Col>
                          );
                        }
                      })}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div id="publication_info">
            {isPreview ? (
              <PublicationQuestions
                publicationQuestions={
                  course.minisitePublication.publicationQuestions
                }
              />
            ) : userSignedIn ? (
              <PostulationForm
                publicationId={course.minisitePublication.id}
                publicationQuestions={
                  course.minisitePublication.publicationQuestions
                }
                postulationAnswers={postulationAnswers}
                isAlreadyPostulated={isAlreadyPostulated}
              />
            ) : (
              <Fragment>
                <Row>
                  <Col md="12">
                    <Card className="bg-light border-0">
                      <CardBody>
                        <h4 className="text-center">
                          <strong className="font-weight-bold d-block">
                            ¿Quieres postular a esta oferta de empleo?
                          </strong>
                          <small>
                            Para postular a este empleo debes iniciar sesión.
                            Si aún no tienes cuenta Regístrate.
                          </small>
                        </h4>
                        <div className="text-center">
                          <Button color="primary" href="/users/sign_in" size="sm" className="mx-2 font-weight-bold">
                            Inicia Sesión
                          </Button>
                          <Button color="secondary" href="/users/sign_up" size="sm" className="mx-2 font-weight-bold">
                            Regístrate
                          </Button>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Fragment>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default PublicationShow;
