import React, { useState } from "react";
import Select from "react-select";

const WhoCompletedFormSelect = props => {
  const options = [
    { value: "me", label: "Solo por usted" },
    { value: "with_help", label: "Por usted con ayuda de un tercero" },
    { value: "by_other", label: "Por un tercero" }
  ];

  const [completedFormOptions] = useState(options);

  const handleChange = inputValue => {
    props.handleChange(inputValue, "whoCompletedForm");
  };

  return (
    <Select
      inputId="whoCompletedForm"
      onChange={handleChange}
      options={completedFormOptions}
      placeholder="Seleccione una opción"
      value={props.value.value ? props.value : null}
    />
  );
};

export default WhoCompletedFormSelect;
