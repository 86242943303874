import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { FadeLoader } from "react-spinners";

const PurchasesCountCard = ({ aggregations }) => {
  if (aggregations && aggregations.purchases_count) {
    const purchasesCount = aggregations.purchases_count.value;
    return (
      <Card className="material-shadow">
        <CardBody>
          <Row>
            <Col>
              <h5 className="card-title text-uppercase text-info mb-0">Compras Totales</h5>
              <span className="h3 text-muted mb-0">{purchasesCount}</span>
            </Col>
            <div className="col-auto d-flex justify-content-center align-items-center">
              <i className="fa fa-3x fa-cash-register text-info" />
            </div>
          </Row>
        </CardBody>
      </Card>
    );
  } else {
    return (
      <Row>
        <Col md="12">
          <FadeLoader />
        </Col>
      </Row>
    );
  }
};

export default PurchasesCountCard;
