import React, { useState } from "react";
import Select from "react-select";

const RelationshipContactSelect = props => {
  const options = [
    { value: "father", label: "Padre" },
    { value: "mother", label: "Madre" },
    { value: "brother_or_sister", label: "Hermano(a)" },
    { value: "cousin", label: "Primo(a)" },
    { value: "uncle_or_aunt", label: "Tio(a)" },
    { value: "friend", label: "Amigo(a)" }
  ];

  const [completedFormOptions] = useState(options);

  const handleChange = inputValue => {
    props.handleChange(inputValue, "relationship");
  };

  return (
    <Select
      inputId="relationship"
      onChange={handleChange}
      options={completedFormOptions}
      placeholder="Seleccione una opción"
      value={props.value.value ? props.value : null}
    />
  );
};

export default RelationshipContactSelect;
