import axios from "axios";

export const getAllPostulationsByCourse = (course_id, callback) => {
  var promise = axios({
    method: "get",
    url: `/api/courses/${course_id}/postulation_bases`,
    headers: {
      "X-CSRF-TOKEN": window.SomosEmpleos.token
    }
  });
  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};

export const submitNextStage = (course_id, data, callback) => {
  var promise = axios({
    method: "post",
    url: `/api/courses/${course_id}/postulation_bases/next_stage`,
    data: data,
    headers: {
      "X-CSRF-TOKEN": window.SomosEmpleos.token
    }
  });
  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};

export const switchPostulationState = (
  course_id,
  postulation_id,
  data,
  callback
) => {
  var promise = axios({
    method: "patch",
    url: `/api/courses/${course_id}/postulation_bases/${postulation_id}/switch_state`,
    data: data,
    headers: {
      "X-CSRF-TOKEN": window.SomosEmpleos.token
    }
  });
  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};

export const sendUpdateRequestToApplicant = (data, callback) => {
  var promise = axios({
    method: "post",
    url: `/api/applicants/send_update_request`,
    data: data,
    headers: {
      "X-CSRF-TOKEN": window.SomosEmpleos.token
    }
  });
  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};

export const getAllApplicants = (courseId, callback) => {
  let url;
  if (courseId) {
    url = `/api/applicants/get_applicants?course_id=${courseId}`;
  } else {
    url = `/api/applicants/get_applicants`;
  }

  var promise = axios({
    method: "get",
    url: url,
    headers: {
      "X-CSRF-TOKEN": window.SomosEmpleos.token
    }
  });
  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};

export const sendCourseInviteToApplicants = (data, callback) => {
  var promise = axios({
    method: "post",
    url: `/api/applicants/send_course_invitation`,
    data: data,
    headers: {
      "X-CSRF-TOKEN": window.SomosEmpleos.token
    }
  });
  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};

export const getAllCourses = callback => {
  var promise = axios({
    method: "get",
    url: `/api/courses/get_courses`,
    headers: {
      "X-CSRF-TOKEN": window.SomosEmpleos.token
    }
  });
  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};

export const getCv = (applicant_id, callback) => {
  var promise = axios({
    method: "get",
    url: `/applicants/${applicant_id}/download_pdf`,
    headers: {
      "X-CSRF-TOKEN": window.SomosEmpleos.token
    }
  });
  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};

export const submitSingleNextStage = (
  courseId,
  postulationId,
  data,
  callback
) => {
  var promise = axios({
    method: "post",
    url: `/api/courses/${courseId}/postulation_bases/${postulationId}/single_next_stage`,
    data: data,
    headers: {
      "X-CSRF-TOKEN": window.SomosEmpleos.token
    }
  });
  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};

export const updatePassword = (applicantId, data, callback) => {
  var promise = axios({
    method: "put",
    url: `/api/applicants/${applicantId}/update_password`,
    data: data,
    headers: {
      "X-CSRF-TOKEN": window.SomosEmpleos.token
    }
  });
  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};
