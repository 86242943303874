import axios from "axios";

export const saveCompanyEmployee = (data, companyCentralId, callback) => {
  let method,
    url = "";
  if (data.get("company_employee[id]")) {
    method = "patch";
    url = `/api/company_centrals/${companyCentralId}/company_employees/${data.get(
      "company_employee[id]"
    )}`;
  } else {
    method = "post";
    url = `/api/company_centrals/${companyCentralId}/company_employees`;
  }

  axios({
    method: method,
    url: url,
    data: data,
    headers: {
      "X-CSRF-TOKEN": window.SomosEmpleos.token
    }
  })
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};

export function switchState(
  companyCentralId,
  companyEmployeeId,
  data,
  callback
) {
  var promise = axios({
    method: "patch",
    url: `/api/company_centrals/${companyCentralId}/company_employees/${companyEmployeeId}/switch_state`,
    data: data,
    headers: {
      "X-CSRF-TOKEN": window.SomosEmpleos.token
    }
  });
  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
}

export function switchAccessApi(
  companyCentralId,
  companyEmployeeId,
  data,
  callback
) {
  var promise = axios({
    method: "patch",
    url: `/api/company_centrals/${companyCentralId}/company_employees/${companyEmployeeId}/switch_api`,
    data: data,
    headers: {
      "X-CSRF-TOKEN": window.SomosEmpleos.token
    }
  });
  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
}
