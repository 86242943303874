import React, { useState } from "react";
import Select from "react-select";

const DriversLicensesSelect = props => {
  const licenses = [
    { value: "not", label: "No Tengo" },
    { value: "a1", label: "Clase A1" },
    { value: "a2", label: "Clase A2" },
    { value: "a3", label: "Clase A3" },
    { value: "a4", label: "Clase A4" },
    { value: "a5", label: "Clase A5" },
    { value: "b", label: "Clase B" },
    { value: "c", label: "Clase C" },
    { value: "d", label: "Clase D" },
    { value: "e", label: "Clase E" },
    { value: "f", label: "Clase F" }
  ];

  const [driversLicenses] = useState(licenses);

  const handleChange = inputValue => {
    props.handleChange(inputValue, "driversLicense");
  };

  return (
    <Select
      inputId="driversLicence"
      onChange={handleChange}
      options={driversLicenses}
      placeholder="Seleccione una opción"
      value={props.value.value ? props.value : null}
    />
  );
};

export default DriversLicensesSelect;
