import axios from "axios";

export const saveDocumentationRequest = (data, callback) => {
  const promise = axios({
    method: "post",
    url: `/api/documentation_requests`,
    data: data,
    headers: {
      "X-CSRF-TOKEN": window.SomosEmpleos.token
    }
  });
  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};

export const rememberRequest = (id, callback) => {
  const promise = axios({
    method: "post",
    url: `/api/documentation_requests/${id}/remember_request`,
    headers: {
      "X-CSRF-TOKEN": window.SomosEmpleos.token
    }
  });
  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};
