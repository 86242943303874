import React from "react";
import Moment from "react-moment";
import _ from "lodash";
import { Badge } from "reactstrap";
import StagesForm from "../stage/Form";

const ApplicantStages = props => {
  const {
    postulationState,
    postulatedAt,
    stages,
    applicant,
    courseId,
    postulationId,
    setApplicanttHit,
    showNotification
  } = props;

  const drawIconState = aasmState => {
    let icon,
      color,
      title = "";
    switch (aasmState) {
      case "pending":
        icon = "far fa-clock text-white";
        color = "warning";
        title = "Pendiente";
        break;
      case "approved":
        icon = "fas fa-check";
        color = "success";
        title = "Aprobado";
        break;
      case "rejected":
        icon = "fas fa-times";
        color = "danger";
        title = "Rechazado";
        break;
      default:
        break;
    }

    return (
      <Badge color={color}>
        <i aria-hidden="true" className={icon} title={title}></i>
      </Badge>
    );
  };

  const currentStage = _.find(stages, stage => stage.is_current === true);

  return (
    <div className="my-2 my-lg-0">
      <small className="font-weight-bold">Etapas del candidato</small>
      <ul className="fa-ul list-unstyled ml-1 small text-muted">
        <li className="">
          {drawIconState(postulationState)}
          Postulación&nbsp;
          <small className="text-muted">
            <Moment format="DD/MM/YYYY HH:mm">{postulatedAt}</Moment>
          </small>
        </li>
        {_.map(stages, stage => (
          <li key={stage.id}>
            {drawIconState(stage.aasm_state)}
            {stage.aasm_state === "pending" ? (
              <StagesForm
                stage={stage}
                applicant={applicant}
                courseId={courseId}
                postulationId={postulationId}
                setApplicanttHit={setApplicanttHit}
                showNotification={showNotification}
              />
            ) : (
              stage.name
            )}{" "}
            <small className="text-muted">
              <Moment format="DD/MM/YYYY HH:mm">{stage.created_at}</Moment>
            </small>{" "}
            {stage.type === "Interview::Stage" && stage.appointment && (
              <i
                className="fas fa-calendar-check"
                style={{ color: "#28a745" }}
                title="Entrevista Agendada"
              />
            )}
            {stage.type === "Documentation::Stage" && stage.documentation_request && (
              <i
                className="fas fa-file-alt"
                style={{ color: "#28a745" }}
                title="Solicitud de Documentos enviada"
              />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ApplicantStages;
