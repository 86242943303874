import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import Moment from "react-moment";
import Swal from "sweetalert2";
import CourseStages from "./Stages";
import { drawAasmState } from "../commons/Functions";
import { switchCourseState } from "./Axios";
import CourseButtonTools from "./ButtonTools";

class CoursesHit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      hit: this.props.hit._source
    };
  }

  handleCloseCourse = () => {
    const courseId = this.state.hit.id;
    Swal.fire({
      title: "Estás seguro?",
      text: `El proceso se va a cerrar, esto desactivará la oferta laboral del portal.`,
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Si, cerrar`,
      cancelButtonText: "Cancelar"
    }).then(result => {
      if (result.value) {
        var formData = new FormData();
        formData.append("switch_state", "close");
        try {
          switchCourseState(courseId, formData, response => {
            if (response.status === 200) {
              Swal.fire(`Cerrado!`, `El proceso ha sido cerrado exitosamente!.`, "success");
              this.updateHit(response.data);
            } else if (response.status === 403) {
              Swal.fire({
                type: "error",
                title: "Oops...",
                text: "No tienes los permisos suficientes para realizar esta acción!"
              });
            }
          });
        } catch (error) {
          Swal.fire({
            type: "error",
            title: "Oops...",
            text: "Hubo un error, vuelve a intentarlo!"
          });
        }
      }
    });
  };

  updateHit = hit => {
    this.setState({
      ...this.state,
      hit
    });
  };

  render() {
    const { hit } = this.state;

    return (
      <Card className="mb-3 publication-list-item job-card material-shadow">
        <CardBody>
          <div className="d-flex justify-content-between flex-wrap">
            <div className="flex-grow-1 pr-md-3 col-lg-6 col-md-12 p-0">
              <h2 className="h5 mb-3">
                {hit.title}&nbsp;
                <small>{drawAasmState(hit.aasm_state)}</small>
                <small className="d-inline-block text-muted d-block">
                  <strong>Área:&nbsp;</strong>
                  {hit.laboral_area.name}
                </small>
              </h2>
              <div className="media flex-wrap">
                <img
                  className="mr-2 mb-2 area-icon"
                  alt={hit.laboral_area.name}
                  src={hit.laboral_area.attachment}
                />
                <div className="media-body">
                  <ul className="fa-ul list-unstyled mb-0 ml-4 publication-detail-list">
                    <li>
                      <span className="fa-li">
                        <i
                          aria-hidden="true"
                          className="far fa-calendar-alt"
                          title="Fecha de Publicación"
                        ></i>
                      </span>
                      <Moment format="DD/MM/YYYY">{hit.created_at}</Moment>{" "}
                      {drawAasmState(hit.minisite_publication.aasm_state)}
                    </li>
                    <li>
                      <span className="fa-li">
                        <i
                          aria-hidden="true"
                          className="fas fa-business-time"
                          title="Jornada, Horarios y/o Turnos"
                        ></i>
                      </span>
                      {I18n.t(
                        `activerecord.enums.publication/base.preferred_schedules.${hit.minisite_publication.preferred_schedule}`
                      )}
                    </li>
                    <li>
                      <span className="fa-li">
                        <i
                          aria-hidden="true"
                          className="fas fa-map-marker-alt"
                          title="Ubicación"
                        ></i>
                      </span>
                      {hit.minisite_publication.commune.name},{" "}
                      {hit.minisite_publication.region.name}.{" "}
                      {hit.minisite_publication.country.name}.
                    </li>
                    <li>
                      <span className="fa-li">
                        <i
                          className="fas fa-universal-access"
                          aria-hidden="true"
                          title="Infraestructura"
                        ></i>
                      </span>
                      Infraestructura Accesible
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="flex-fill p-0 col-lg-4 col-md-12 p-md-0 text-center d-flex flex-column flex-sm-row flex-md-column flex-lg-column align-items-center justify-content-center">
              <CourseStages postulationsCount={hit.minisite_publication.postulations_count} />
            </div>

            <div className="flex-lg-shrink flex-fill p-0 text-right d-flex flex-column flex-sm-row flex-md-row flex-lg-column align-items-center justify-content-center">
              <div className="company_tools text-center align-self-lg-end mt-3 mt-sm-0">
                <CourseButtonTools handleCloseCourse={this.handleCloseCourse} course={hit} />
              </div>

              <Button
                size="sm"
                color="primary"
                className="my-3 align-self-lg-end"
                href={`/courses/${hit.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Ver Postulaciones
              </Button>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default CoursesHit;
