import React, { useState } from "react";
import Select from "react-select";

const EducationLevelsSelect = ({ value, handleChange }) => {
  const levels = [
    { value: "special_education", label: "Educación Especial" },
    { value: "primary_incomplete", label: "Educación básica incompleta" },
    { value: "primary_complete", label: "Educación básica completa" },
    { value: "secondary_incomplete", label: "Educación media incompleta" },
    { value: "secondary_complete", label: "Educación media completa" },
    { value: "higher_incomplete", label: "Educación Superior incompleta" },
    { value: "higher_complete", label: "Educación Superior completa" }
  ];

  const [educationLevels] = useState(levels);

  const handleChangeLevel = inputValue => {
    handleChange(inputValue, "educationLevel");
  };

  return (
    <Select
      inputId="educationLevel"
      onChange={handleChangeLevel}
      options={educationLevels}
      placeholder="Seleccione una opción"
      value={value.value ? value : null}
    />
  );
};

export default EducationLevelsSelect;
