import React, { Fragment, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  FormGroup
} from "reactstrap";
import _ from "lodash";
import { saveProduct } from "./Axios";
import FieldWrapper from "../commons/FieldWrapper";

const EditProduct = ({ product, updateProduct }) => {
  const [modal, setModal] = useState(false);
  const [name, setName] = useState(product.name || "");
  const [price, setPrice] = useState(product.price || "");
  const [discount, setDiscount] = useState(product.discount || "");
  const [quantity, setQuantity] = useState(product.quantity || "");
  const [nameErrors, setNameErrors] = useState([]);
  const [priceErrors, setPriceErrors] = useState([]);
  const [quantityErrors, setQuantityErrors] = useState([]);

  const toggle = () => {
    setModal(!modal);
  };

  const setErrors = errors => {
    const newNameErrors = [...nameErrors, errors.name];
    const newPriceErrors = [...priceErrors, errors.price];
    const newQuantityErrors = [...quantityErrors, errors.quantity];
    setNameErrors(newNameErrors);
    setPriceErrors(newPriceErrors);
    setQuantityErrors(newQuantityErrors);
  };

  const handleSubmitProduct = () => {
    let data = new FormData();
    data.append("product[id]", product.id);
    data.append("product[name]", name);
    data.append("product[price]", price);
    data.append("product[discount]", discount);
    data.append("product[quantity]", quantity);

    saveProduct(data, response => {
      if (response.status === 200) {
        updateProduct(response.data);
        toggle();
      } else if (response.status === 422) {
        setErrors(response.data);
      }
    });
  };

  return (
    <Fragment>
      <i
        className="fas fa-edit text-warning"
        title="Editar"
        onClick={toggle}
        style={{ cursor: "pointer" }}
      />
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle} className="bg-warning text-dark">
          Editar Producto
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>Nombre</Label>
            <FieldWrapper errors={nameErrors}>
              <Input
                type="text"
                onChange={e => setName(e.target.value)}
                value={name}
              />
            </FieldWrapper>
          </FormGroup>
          {product.type && product.type !== "Product::Trial" && (
            <FormGroup>
              <Label>Precio</Label>
              <FieldWrapper errors={priceErrors}>
                <Input
                  type="number"
                  onChange={e => setPrice(e.target.value)}
                  value={price}
                />
              </FieldWrapper>
            </FormGroup>
          )}
          {product.type && product.type !== "Product::Trial" && (
            <FormGroup>
              <Label>Descuento</Label>
              <Input
                type="number"
                onChange={e => setDiscount(e.target.value)}
                value={discount}
              />
            </FormGroup>
          )}
          {product.type && product.type !== "Product::Unlimited" && (
            <FormGroup>
              <Label>Cantidad</Label>
              <FieldWrapper errors={quantityErrors}>
                <Input
                  type="number"
                  onChange={e => setQuantity(e.target.value)}
                  value={quantity}
                />
              </FieldWrapper>
            </FormGroup>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleSubmitProduct}>
            Guardar
          </Button>{" "}
          <Button color="danger" onClick={toggle}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default EditProduct;
