import React, { Fragment, useState, useRef } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import {
  SearchkitManager,
  SearchkitProvider,
  Hits,
  NoHits,
  Pagination,
  SearchBox,
  HitsStats,
  SortingSelector,
  ResetFilters,
  RefinementListFilter
} from "searchkit";
import ApplicantsHits from "./Hits";
import MultiSelect from "../commons/MultiSelect";
import Notifications from "../commons/Notifications";
import ApplicantsProvider from "./ApplicantsContext";

const ApplicantsIndex = props => {
  const { route, isPostulation, courseId } = props;

  const notificationsRef = useRef();

  const [searchkit, setSearchkit] = useState(
    new SearchkitManager(route, {
      httpHeaders: { "X-CSRF-TOKEN": window.SomosEmpleos.token }
    })
  );

  const reloadQuery = () => {
    setTimeout(function() {
      searchkit.reloadSearch();
    }, 1000);
    setSearchkit(searchkit);
  };

  const showNotification = (title, message, type) => {
    notificationsRef.current.addNotifications(title, message, type);
  };

  return (
    <ApplicantsProvider>
      <SearchkitProvider searchkit={searchkit}>
        <Fragment>
          <Notifications ref={notificationsRef} />
          <Card className="mb-3 material-shadow">
            <CardBody>
              <Row>
                <Col md="6">
                  <SearchBox
                    autofocus={true}
                    searchOnChange={true}
                    translations={{ "searchbox.placeholder": "Buscar" }}
                    queryFields={[
                      "user.email",
                      "user.personal_information.first_name",
                      "user.personal_information.last_name",
                      "applicant.user.personal_information.first_name",
                      "applicant.user.personal_information.last_name"
                    ]}
                    prefixQueryFields={[
                      "id",
                      "user.email",
                      "user.personal_information.first_name",
                      "user.personal_information.last_name",
                      "applicant.user.personal_information.first_name",
                      "applicant.user.personal_information.last_name"
                    ]}
                  />
                  <HitsStats
                    translations={{
                      "hitstats.results_found": "{hitCount} resultados encontrados en {timeTaken}ms"
                    }}
                  />
                </Col>
                <Col></Col>
                <Col md="3">
                  {isPostulation ? (
                    <SortingSelector
                      options={[
                        {
                          label: "Mayor coincidencia",
                          field: "_score",
                          order: "desc"
                        },
                        { label: "Menor coincidencia", field: "_score", order: "asc" },
                        {
                          label: "Fecha de Postulación reciente",
                          field: "created_at",
                          order: "desc"
                        },
                        {
                          label: "Fecha de Postulación antigua",
                          field: "created_at",
                          order: "asc"
                        },
                        {
                          label: "Posee discapacidad",
                          field: "applicant.user.disability_information.has_disability",
                          order: "desc",
                          defaultOption: true
                        },
                        {
                          label: "No posee discapacidad",
                          field: "applicant.user.disability_information.has_disability",
                          order: "asc"
                        }
                      ]}
                    />
                  ) : null}
                </Col>
              </Row>
              <ResetFilters translations={{ "reset.clear_all": "Limpiar filtros" }} />
              <Row></Row>
              <Row>
                <Col md="3">
                  <RefinementListFilter
                    id="preferred_schedule"
                    title="Jornada Laboral"
                    field="user.personal_laboral_requirement.preferred_schedule.raw"
                    operator="OR"
                    size={500}
                    orderKey="_term"
                    listComponent={MultiSelect}
                    translations={{
                      "": "No Ingresó",
                      full_time: "Jornada Completa",
                      part_time: "Part Time",
                      half_time: "Media Jornada"
                    }}
                  />
                </Col>
                <Col md="3">
                  <RefinementListFilter
                    id="desired_communes"
                    title="Comunas de Interés"
                    field="user.personal_laboral_requirement.desired_communes.label.raw"
                    operator="OR"
                    size={500}
                    orderKey="_term"
                    listComponent={MultiSelect}
                  />
                </Col>
                <Col md="3">
                  <RefinementListFilter
                    id="laboral_areas"
                    title="Área Laboral de Interés"
                    field="user.personal_laboral_requirement.laboral_areas.label.raw"
                    operator="OR"
                    size={500}
                    orderKey="_term"
                    listComponent={MultiSelect}
                  />
                </Col>
                <Col md="3">
                  <RefinementListFilter
                    id="accessibility_answers"
                    title="Requerimientos de Accesibilidad"
                    field="user.accessibility_answers.isChecked.raw"
                    operator="OR"
                    size={500}
                    orderKey="_term"
                    listComponent={MultiSelect}
                    translations={{
                      true: "Sí",
                      false: "No"
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <RefinementListFilter
                    id="is_employee"
                    title="Es empleado"
                    field="user.is_employee.raw"
                    operator="OR"
                    size={500}
                    orderKey="_term"
                    listComponent={MultiSelect}
                    translations={{
                      true: "Sí",
                      false: "No",
                      NULL: "No Ingresó",
                      "": "No Ingresó"
                    }}
                  />
                </Col>
                <Col md="3">
                  <RefinementListFilter
                    id="has_disability"
                    title="Posee discapacidad"
                    field="user.disability_information.has_disability.raw"
                    operator="OR"
                    size={500}
                    orderKey="_term"
                    listComponent={MultiSelect}
                    translations={{ true: "Si", false: "No", "": "No Ingresó" }}
                  />
                </Col>
                <Col md="3">
                  <RefinementListFilter
                    id="identify_lgtbi"
                    title="Comunidad LGTBI+"
                    field="user.personal_information.identify_lgtbi"
                    operator="OR"
                    orderKey="_term"
                    listComponent={MultiSelect}
                    translations={{
                      true: "Sí",
                      false: "No",
                      NULL: "No Ingresó",
                      "": "No Ingresó"
                    }}
                  />
                </Col>
                <Col md="3">
                  <RefinementListFilter
                    id="over_60"
                    title="Tercera Edad"
                    field="user.over_60.raw"
                    operator="OR"
                    size={500}
                    orderKey="_term"
                    listComponent={MultiSelect}
                    translations={{
                      true: "Sí",
                      false: "No",
                      NULL: "No Ingresó",
                      "": "No Ingresó"
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <RefinementListFilter
                    id="gender"
                    title="Genero"
                    field="user.personal_information.gender.raw"
                    operator="OR"
                    size={500}
                    orderKey="_term"
                    listComponent={MultiSelect}
                    translations={{
                      female: "Femenino",
                      male: "Masculino",
                      fluid_gender: "Fluido",
                      non_binary: "No Binario",
                      other: "Otro",
                      NULL: "No Ingresó",
                      "": "No Ingresó"
                    }}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Hits
            hitsPerPage={12}
            listComponent={
              <ApplicantsHits
                {...props}
                reloadQuery={reloadQuery}
                showNotification={showNotification}
              />
            }
          />
          <NoHits
            translations={{
              "NoHits.NoResultsFound": "No se encontraron resultados para {query}",
              "NoHits.DidYouMean": "Busqueda para {suggestion}",
              "NoHits.SearchWithoutFilters": "Busqueda para {query} sin filtros",
              "NoHits.NoResultsFoundDidYouMean":
                "No se encontraron resultados para {query}. Quisiste decir {suggestion} ?"
            }}
          />
          <div className="mt-3em">
            <Pagination
              showNumbers={true}
              translations={{
                "pagination.previous": "Anterior",
                "pagination.next": "Siguiente"
              }}
            />
          </div>
        </Fragment>
      </SearchkitProvider>
    </ApplicantsProvider>
  );
};

export default ApplicantsIndex;
