import React, { Component } from "react";
import { Row, Col, Form, FormGroup, Label, Input, Button } from "reactstrap";
import { format } from "rut.js";
import TerritoryNestedSelects from "../../territory/nested/Selects";
import FieldWrapper from "../../commons/FieldWrapper";
import Switch from "react-switch";
import { saveCompanyCentral } from "./Axios";

class CompanyCentralsForm extends Component {
  constructor(props) {
    super(props);

    const { companyCentral } = this.props;

    this.state = {
      companyCentral: {
        id: { value: companyCentral ? companyCentral.id : null },
        name: { value: companyCentral ? companyCentral.name : "", errors: [] },
        documentId: {
          value: companyCentral ? companyCentral.identificationNumber : "",
          errors: []
        },
        businessArea: {
          value: companyCentral ? companyCentral.businessArea.id : "",
          label: companyCentral ? companyCentral.businessArea.name : "",
          errors: []
        },
        email: { value: companyCentral ? companyCentral.email : "", errors: [] },
        phoneNumber: { value: companyCentral ? companyCentral.phoneNumber : "", errors: [] },
        country: {
          value: companyCentral ? companyCentral.country.id : "",
          label: companyCentral ? companyCentral.country.name : "",
          errors: []
        },
        region: {
          value: companyCentral ? companyCentral.region.id : "",
          label: companyCentral ? companyCentral.region.name : "",
          errors: []
        },
        commune: {
          value: companyCentral ? companyCentral.commune.id : "",
          label: companyCentral ? companyCentral.commune.name : "",
          errors: []
        },
        address: { value: companyCentral ? companyCentral.address : "", errors: [] },
        avatar: { value: companyCentral ? companyCentral.avatar : "", file: null, errors: [] },
        workersNumber: { value: companyCentral ? companyCentral.workersNumber : "", errors: [] },
        isFoundation: { value: companyCentral ? companyCentral.isFoundation : "", errors: [] },
        webSite: { value: companyCentral ? companyCentral.webSite : "", errors: [] },
        description: { value: companyCentral ? companyCentral.description : "", errors: [] },
        aasmState: { value: companyCentral ? companyCentral.aasmState : "", errors: [] },
        hasDiversityPolicies: { value: companyCentral ? companyCentral.hasDiversityPolicies : false, errors: [] },
        hasGenderEqualityPolicies: { value: companyCentral ? companyCentral.hasGenderEqualityPolicies : false, errors: [] },
        hasDiscriminationProtocols: { value: companyCentral ? companyCentral.hasDiscriminationProtocols : false, errors: [] },
        hasInlusiveulturalPolicies: { value: companyCentral ? companyCentral.hasInlusiveulturalPolicies : false, errors: [] }
      }
    };
  }

  handleChangeCompanyCentral = (e, fieldName = null) => {
    const { companyCentral } = this.state;
    if (fieldName) {
      companyCentral[fieldName]["value"] = e.value;
      companyCentral[fieldName]["label"] = e.label;
      companyCentral[fieldName]["errors"] = [];
    } else if (e.target.name === "documentId") {
      companyCentral[e.target.name]["value"] = format(e.target.value);
      companyCentral[e.target.name]["errors"] = [];
    } else {
      companyCentral[e.target.name]["value"] = e.target.value;
      companyCentral[e.target.name]["errors"] = [];
    }
    this.setState({ companyCentral });
  };

  handleHasDiversityPolicies= (e) => {
    const { companyCentral } = this.state;
    companyCentral["hasDiversityPolicies"]["value"] = e;
    this.setState({ companyCentral });
  };

  handleHasGenderEqualityPolicies= (e) => {
    const { companyCentral } = this.state;
    companyCentral["hasGenderEqualityPolicies"]["value"] = e;
    this.setState({ companyCentral });
  };

  handleHasDiscriminationProtocols= (e) => {
    const { companyCentral } = this.state;
    companyCentral["hasDiscriminationProtocols"]["value"] = e;
    this.setState({ companyCentral });
  };

  handleHasInlusiveulturalPolicies= (e) => {
    const { companyCentral } = this.state;
    companyCentral["hasInlusiveulturalPolicies"]["value"] = e;
    this.setState({ companyCentral });
  };

  serializeData = () => {
    let formData = new FormData();
    const { companyCentral } = this.state;
    if (companyCentral.id.value) {
      formData.append("company_central[id]", companyCentral.id.value);
    }
    if (companyCentral.avatar.file) {
      formData.append("company_central[avatar]", companyCentral.avatar.file);
    }
    formData.append("company_central[name]", companyCentral.name.value);
    formData.append("company_central[identification_number]", companyCentral.documentId.value);
    formData.append("company_central[email]", companyCentral.email.value);
    formData.append("company_central[phone_number]", companyCentral.phoneNumber.value);
    formData.append("company_central[territory_commune_id]", companyCentral.commune.value);
    formData.append("company_central[address]", companyCentral.address.value);
    formData.append("company_central[workers_number]", companyCentral.workersNumber.value);
    formData.append("company_central[web_site]", companyCentral.webSite.value);
    formData.append("company_central[description]", companyCentral.description.value);
    formData.append("company_central[has_diversity_policies]", companyCentral.hasDiversityPolicies.value);
    formData.append("company_central[has_gender_equality_policies]", companyCentral.hasGenderEqualityPolicies.value);
    formData.append("company_central[has_discrimination_protocols]", companyCentral.hasDiscriminationProtocols.value);
    formData.append("company_central[has_inlusive_cultural_policies]", companyCentral.hasInlusiveulturalPolicies.value);

    return formData;
  };

  setErrors = errors => {
    const { companyCentral } = this.state;
    companyCentral.name.errors = errors.name || [];
    companyCentral.documentId.errors = errors.identification_number || [];
    companyCentral.commune.errors = errors.territory_commune || [];
    this.setState({ ...this.state, companyCentral });
  };

  handleSubmit = e => {
    e.preventDefault();
    let data = this.serializeData();
    saveCompanyCentral(data, response => {
      if (response.status === 201) {
        window.location.href = `/company/centrals/${response.data.id}/edit`;
      } else if (response.status === 200) {
        this.props.showNotification("Genial", "Información guardada con exito", "success");
        this.props.toggleTab("companyUsers");
      } else if (response.status === 422) {
        this.setErrors(response.data);
        this.props.showNotification("Ops", "Ocurrio un error, revisa el formulario", "danger");
      }
    });
  };

  handleChangeAvatar = e => {
    const { companyCentral } = this.state;
    companyCentral["avatar"]["file"] = event.target.files[0];
    companyCentral["avatar"]["value"] = URL.createObjectURL(event.target.files[0]);
    companyCentral["avatar"]["errors"] = [];
    this.setState({ ...this.state, companyCentral });
  };

  render() {
    const { companyCentral } = this.state;
    const image = companyCentral.avatar.value
      ? companyCentral.avatar.value
      : "/assets/somos_empleos_logo-9ffc635adaecf31642b6ee1c7b81aa2644f9bd63b4eb7ad83aa6b54206c7733b.png";

    return (
      <div>
        <Form>
          <Row>
            <Col lg="4" md="3" className="text-center">
              <div className="avatar_container logo">
                <img className="rounded mx-auto d-block img-fluid" alt="Company Logo" src={image} />
                <Input
                  id="companyLogo"
                  className="avatar_input"
                  type="file"
                  onChange={this.handleChangeAvatar}
                />
                <label htmlFor="companyLogo">
                  <small className="text-muted">
                    <i className="fas fa-upload mr-2"></i>
                    Sube o Arrastra el logo de tu empresa
                  </small>
                </label>
              </div>
            </Col>
            <Col lg="8" md="9">
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="documentId" className="input_required">
                      Rut empresa
                    </Label>
                    <FieldWrapper errors={companyCentral.documentId.errors}>
                      <Input
                        id="documentId"
                        name="documentId"
                        placeholder="RUT"
                        type="text"
                        onChange={this.handleChangeCompanyCentral}
                        maxLength={12}
                        value={format(companyCentral.documentId.value)}
                      />
                    </FieldWrapper>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="name" className="input_required">
                      Nombre Comercial de la Empresa
                    </Label>
                    <FieldWrapper errors={companyCentral.name.errors}>
                      <Input
                        id="name"
                        name="name"
                        placeholder="Nombre Comercial de la Empresa"
                        type="text"
                        onChange={this.handleChangeCompanyCentral}
                        value={companyCentral.name.value}
                      />
                    </FieldWrapper>
                  </FormGroup>
                </Col>
                {/* <Col md="6">
                  <FormGroup>
                    <Label htmlFor="businessArea" className="input_required">
                      Razón Social
                    </Label>
                    <Input
                      id="businessArea"
                      name="businessArea"
                      placeholder="Razón Social"
                      type="text"
                      onChange={this.handleChangeCompanyCentral}
                    />
                  </FormGroup>
                </Col> */}
              </Row>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md="12">
              <h3 className="h6 font-weight-bold">
                <i className="far fa-address-book mr-2"></i>
                Información Contacto
              </h3>
            </Col>
            <Col lg="4" md="4">
              <FormGroup>
                <Label htmlFor="email">Email</Label>
                <FieldWrapper errors={companyCentral.email.errors}>
                  <Input
                    id="email"
                    name="email"
                    placeholder="Email"
                    type="text"
                    onChange={this.handleChangeCompanyCentral}
                    value={companyCentral.email.value || ""}
                  />
                </FieldWrapper>
              </FormGroup>
            </Col>
            <Col lg="4" md="4">
              <FormGroup>
                <Label htmlFor="phoneNumber">Teléfono</Label>
                <FieldWrapper errors={companyCentral.phoneNumber.errors}>
                  <Input
                    id="phoneNumber"
                    name="phoneNumber"
                    placeholder="Teléfono"
                    type="text"
                    onChange={this.handleChangeCompanyCentral}
                    value={companyCentral.phoneNumber.value || ""}
                  />
                </FieldWrapper>
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label htmlFor="webSite">Sitio Web</Label>
                <FieldWrapper errors={companyCentral.webSite.errors}>
                  <Input
                    id="webSite"
                    name="webSite"
                    placeholder="Sitio Web"
                    type="text"
                    onChange={this.handleChangeCompanyCentral}
                    value={companyCentral.webSite.value || ""}
                  />
                </FieldWrapper>
              </FormGroup>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md="12">
              <h3 className="h6 font-weight-bold">
                <i className="fas fa-map-marker-alt mr-2"></i>
                Ubicación Principal o Casa Matriz
              </h3>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label htmlFor="address">Dirección</Label>
                <FieldWrapper errors={companyCentral.address.errors}>
                  <Input
                    id="address"
                    name="address"
                    placeholder="Dirección"
                    type="text"
                    onChange={this.handleChangeCompanyCentral}
                    value={companyCentral.address.value || ""}
                  />
                </FieldWrapper>
              </FormGroup>
            </Col>
            <TerritoryNestedSelects
              handleChange={this.handleChangeCompanyCentral}
              countryValue={companyCentral.country}
              regionValue={companyCentral.region}
              communeValue={companyCentral.commune}
            />
          </Row>
          <hr />
          <Row>
            <Col md="12">
              <h3 className="h6 font-weight-bold mb-3">
                <i className="fas fa-building mr-2"></i>
                Información Adicional
              </h3>
            </Col>
            <Col md="4">
              {/* <FormGroup>
                <Label htmlFor="businessArea">Sector Empresarial</Label>
                <FieldWrapper errors={companyCentral.businessArea.errors}>
                  <Input
                    id="businessArea"
                    name="businessArea"
                    placeholder="Sector Empresarial"
                    type="text"
                    onChange={this.handleChangeCompanyCentral}
                  />
                </FieldWrapper>
              </FormGroup> */}
              <FormGroup>
                <Label htmlFor="workersNumber">Número de Trabajadores</Label>
                <FieldWrapper errors={companyCentral.workersNumber.errors}>
                  <Input
                    id="workersNumber"
                    name="workersNumber"
                    placeholder="Número de Trabajadores"
                    type="number"
                    min="0"
                    onChange={this.handleChangeCompanyCentral}
                    value={companyCentral.workersNumber.value || 0}
                  />
                </FieldWrapper>
              </FormGroup>
            </Col>
            <Col md="8">
              <FormGroup>
                <Label for="description">Descripción de la Empresa</Label>
                <FieldWrapper errors={companyCentral.description.errors}>
                  <Input
                    type="textarea"
                    name="text"
                    id="description"
                    name="description"
                    onChange={this.handleChangeCompanyCentral}
                    value={companyCentral.description.value || ""}
                  />
                </FieldWrapper>
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <div className="d-inline-block" key="hasDiversityPoliciesImg">
                  <img
                    src="/assets/1-politica-76e2f9fb9d505fc4faef8f31e1e1534abd4bcdc7af554c8e646c3d6cf86f8bf8.png"
                    className="rounded-lg mb-2 d-inline-block p-1 mx-1"
                    alt="has_diversity_policies_logo"
                    width="30"
                    href="#"
                    id={"Tooltip-has_diversity_policies"}
                  />
                </div>
                <Label htmlFor="hasDiversityPolicies">Posee Políticas de Diversidad e Inclusión</Label>{" "}
                <Switch
                  checked={companyCentral.hasDiversityPolicies.value}
                  onChange={this.handleHasDiversityPolicies}
                  offColor="#ff3547"
                  onColor="#00c851"
                  onHandleColor="#00c851"
                  offHandleColor="#ff3547"
                  handleDiameter={30}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={15}
                  width={43}
                  className="react-switch align-middle"
                  id="hasDiversityPolicies"
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <div className="d-inline-block" key="hasGenderEqualityPoliciesImg">
                  <img
                    src="/assets/2-equidad-genero-852cb4bd1d19859b8c7029f32e0b0815ca79550741658bad3b9c07594261692d.png"
                    className="rounded-lg mb-2 d-inline-block p-1 mx-1"
                    alt="has_diversity_policies_logo"
                    width="30"
                    href="#"
                    id={"Tooltip-has_diversity_policies"}
                  />
                </div>
                <Label htmlFor="hasGenderEqualityPolicies">Posee Políticas de equidad de género</Label>{" "}
                <Switch
                  checked={companyCentral.hasGenderEqualityPolicies.value}
                  onChange={this.handleHasGenderEqualityPolicies}
                  offColor="#ff3547"
                  onColor="#00c851"
                  onHandleColor="#00c851"
                  offHandleColor="#ff3547"
                  handleDiameter={30}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={15}
                  width={43}
                  className="react-switch align-middle"
                  id="hasGenderEqualityPolicies"
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <div className="d-inline-block" key="hasDiscriminationProtocolsImg">
                  <img
                    src="/assets/3-protocolo-ae17fc7cd340e58190e659e2228c98240186e339c905cc40680bd7f6ff77d8cf.png"
                    className="rounded-lg mb-2 d-inline-block p-1 mx-1"
                    alt="has_diversity_policies_logo"
                    width="30"
                    href="#"
                    id={"Tooltip-has_diversity_policies"}
                  />
                </div>
                <Label htmlFor="hasDiscriminationProtocols">Posee Protocolo de denuncia por discriminación</Label>{" "}
                <Switch
                  checked={companyCentral.hasDiscriminationProtocols.value}
                  onChange={this.handleHasDiscriminationProtocols}
                  offColor="#ff3547"
                  onColor="#00c851"
                  onHandleColor="#00c851"
                  offHandleColor="#ff3547"
                  handleDiameter={30}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={15}
                  width={43}
                  className="react-switch align-middle"
                  id="hasDiscriminationProtocols"
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <div className="d-inline-block" key="hasInlusiveulturalPoliciesImg">
                  <img
                    src="/assets/4-cultura-0d67c765ec28fa61357da628ff36dbc22011b70fc9cd4ba2dade8ad9e0c027af.png"
                    className="rounded-lg mb-2 d-inline-block p-1 mx-1"
                    alt="has_diversity_policies_logo"
                    width="30"
                    href="#"
                    id={"Tooltip-has_diversity_policies"}
                  />
                </div>

                <Label htmlFor="hasInlusiveulturalPolicies">Posee Cultura organizacional Diversa e Inclusiva</Label>{" "}
                <Switch
                  checked={companyCentral.hasInlusiveulturalPolicies.value}
                  onChange={this.handleHasInlusiveulturalPolicies}
                  offColor="#ff3547"
                  onColor="#00c851"
                  onHandleColor="#00c851"
                  offHandleColor="#ff3547"
                  handleDiameter={30}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={15}
                  width={43}
                  className="react-switch align-middle"
                  id="hasInlusiveulturalPolicies"
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md="12" className="text-right">
              <Button color="primary" className="my-4" onClick={this.handleSubmit}>
                Guardar
                <i className="fas fa-arrow-right ml-2"></i>
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default CompanyCentralsForm;
