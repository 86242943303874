import React, { Component } from "react";
import { Row, Col, Label, Input, Button, Collapse } from "reactstrap";
import LaboralExperienceForm from "./Form";
import LaboralExperienceHit from "./Hit";
import { getAllLaboralExperiences, updateProfessionalInformation } from "./Axios";

class LaboralExperience extends Component {
  constructor(props) {
    super(props);
    this.state = {
      laboralExperiences: [],
      collapse: false,
      laboralExperience: null,
      isMyFirstJob: this.props.isMyFirstJob
    };

    this.handleUpdateHit = this.handleUpdateHit.bind(this);
  }

  componentDidMount() {
    this.getLaboralExperiences();
  }

  getLaboralExperiences = () => {
    getAllLaboralExperiences(response => {
      this.setState({
        ...this.state,
        laboralExperiences: response.data
      });
    });
  };

  toggle = () => {
    const { collapse } = this.state;

    this.setState({
      ...this.state,
      collapse: !collapse
    });
    if (collapse) {
      this.setState({
        laboralExperience: null
      });

      this.refs.laboralExperienceForm.handleCleanState();
    }
  };

  addLaboralExperience = data => {
    this.setState({
      ...this.state,
      laboralExperiences: [data, ...this.state.laboralExperiences]
    });
  };

  removeLaboralExperience = id => {
    const currentLaboralExperiences = [...this.state.laboralExperiences];
    const laboralExperiences = currentLaboralExperiences.filter(
      laboralExperience => laboralExperience.id !== id
    );
    this.setState({ laboralExperiences });
  };

  handleEditLaboralExperience = laboralExperience => {
    this.setState({ laboralExperience, collapse: true });
  };

  handleUpdateHit = data => {
    this.refs[`experienceHit-${data.id}`].updateHit(data);
  };

  handleChangeIsMyFirstJob = e => {
    this.setState({
      isMyFirstJob: e.target.checked
    });

    const data = new FormData();
    data.append("professional_information[is_my_first_job]", e.target.checked);

    updateProfessionalInformation(data, response => {
      if (response.status == 200) {
        this.props.show_notification("Guardado", "Guardado exitoso", "success");
      } else if (response.status === 422) {
        this.props.show_notification(
          "Guardado",
          "Ocurrio un error, revisa el formulario",
          "danger"
        );
      }
    });
  };

  render() {
    const { laboralExperiences, collapse, laboralExperience, isMyFirstJob } = this.state;

    return (
      <Row>
        <Col md="12">
          {!isMyFirstJob && (
            <Collapse isOpen={collapse} className="collapse-border">
              <LaboralExperienceForm
                handleUpdateHit={this.handleUpdateHit}
                ref="laboralExperienceForm"
                toggle={this.toggle}
                addLaboralExperience={this.addLaboralExperience}
                laboralExperience={laboralExperience}
                show_notification={this.props.show_notification}
              />
            </Collapse>
          )}
          <Row className="d-flex align-items-center">
            <Col md="6" className="">
              {laboralExperiences.length === 0 && (
                <div className="form-check">
                  <Label className="form-check-Label" htmlFor="firstJob">
                    <Input
                      className="form-check-Input mr-3"
                      id="firstJob"
                      type="checkbox"
                      onChange={this.handleChangeIsMyFirstJob}
                      checked={isMyFirstJob ? true : false}
                    />
                    Sin experiencia Laboral y/o Buscando Primer Empleo
                  </Label>
                </div>
              )}
            </Col>
            <Col md="6" className="text-right">
              {!collapse && !isMyFirstJob && (
                <Button outline color="primary" className="my-2" onClick={this.toggle}>
                  <i className="fas fa-plus mr-2"></i>
                  Agregar Experiencia Laboral
                </Button>
              )}
            </Col>
          </Row>
          {laboralExperiences.map(experience => (
            <LaboralExperienceHit
              ref={`experienceHit-${experience.id}`}
              key={experience.id}
              laboralExperience={experience}
              handleEditLaboralExperience={this.handleEditLaboralExperience}
              removeLaboralExperience={this.removeLaboralExperience}
            />
          ))}
          <Row>
            <Col md="12 text-right">
              <Button
                color="primary"
                className="my-4"
                onClick={() => this.props.toggleTab("requirementsinfo")}
              >
                Continuar
                <i className="fas fa-arrow-right ml-2"></i>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default LaboralExperience;
