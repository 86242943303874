import axios from "axios";

export const saveProduct = (data, callback) => {
  let method, url;

  if (data.get("product[id]")) {
    method = "patch";
    url = `/api/products/${data.get("product[id]")}`;
  } else {
    method = "post";
    url = "/api/products";
  }

  var promise = axios({
    method: method,
    url: url,
    data: data,
    headers: {
      "X-CSRF-TOKEN": window.SomosEmpleos.token
    }
  });
  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};

export function switchState(productId, data, callback) {
  var promise = axios({
    method: "patch",
    url: `/api/products/${productId}/switch_state`,
    data: data,
    headers: {
      "X-CSRF-TOKEN": window.SomosEmpleos.token
    }
  });
  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
}
