import React from "react";
import _ from "lodash";
import CoursesHit from "./Hit";

const CoursesHits = ({ hits }) => {
  return (
    <div>
      {_.map(hits, hit => (
        <CoursesHit key={hit._id} hit={hit} />
      ))}
    </div>
  );
};

export default CoursesHits;
