import React, { Fragment, useState, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Container,
  Button,
  FormGroup,
  Label
} from "reactstrap";
import _ from "lodash";
import Files from "react-butterfiles";
import { SliderPicker } from "react-color";
import { BeatLoader } from "react-spinners";
import Switch from "react-switch";
import { saveCompanyBrand } from "./Axios";
import FieldWrapper from "../../commons/FieldWrapper";
import Notifications from "../../commons/Notifications";

const CompanyBrandsForm = ({ companyBrand }) => {
  const notifications = useRef(null);
  const [brand, setBrand] = useState(companyBrand);
  const [logo, setLogo] = useState({ url: brand ? brand.logo_url : null, file: null });
  const [banner, setBanner] = useState({ url: brand ? brand.banner_url : null, file: null });
  const [primaryColor, setPrimaryColor] = useState(brand ? brand.primary_color : "#5a3ee0");
  const [hideBanner, setHideBanner] = useState(brand ? brand.hide_banner : false);
  const [hideLogo, setHideLogo] = useState(brand ? brand.hide_logo : false);
  const [loading, setLoading] = useState(false);
  const [logoErrors, setLogoErrors] = useState([]);
  const [bannerErrors, setBannerErrors] = useState([]);

  const handleChangeColor = color => {
    setPrimaryColor(color.hex);
  };

  const handleChangeLogo = files => {
    setLogo({
      url: URL.createObjectURL(files[0].src.file),
      file: files[0].src.file
    });
    setLogoErrors([]);
  };

  const handleChangeBanner = files => {
    setBanner({
      url: URL.createObjectURL(files[0].src.file),
      file: files[0].src.file
    });
    setBannerErrors([]);
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (bannerErrors.length > 0 || logoErrors.length > 0) {
      notifications.current.addNotifications(
        "Ops",
        "Hubo un error, favor revisa el formulario!",
        "danger"
      );
      return;
    }
    setLoading(true);
    let formData = new FormData();
    formData.append("company_brand[primary_color]", primaryColor);
    formData.append("company_brand[hide_banner]", hideBanner);
    formData.append("company_brand[hide_logo]", hideLogo);
    if (banner.file) {
      formData.append("company_brand[banner]", banner.file);
    }
    if (logo.file) {
      formData.append("company_brand[logo]", logo.file);
    }

    saveCompanyBrand(brand, formData, response => {
      if (response.status === 200 || response.status === 201) {
        setBrand(response.data);
        notifications.current.addNotifications(
          "Genial",
          "Información guardada con éxito!",
          "success"
        );
      } else if (response.status === 403) {
        notifications.current.addNotifications(
          "Ops",
          "No tiene los permisos suficientes para relaizar esta acción",
          "danger"
        );
      }
    });
    setLoading(false);
  };

  const handleSaveBannerErrors = errors => {
    let text = "";
    _.map(errors, error => {
      if (error.type === "unsupportedFileType") {
        text = "Formato no permitido";
      } else if (error.type === "maxSizeExceeded") {
        text = "Excede el tamaño de 2mb";
      }
      const newBannerErrors = [...bannerErrors];
      newBannerErrors.push(text);
      setBannerErrors(newBannerErrors);
    });
  };

  const handleSaveLogoErrors = errors => {
    let text = "";
    _.map(errors, error => {
      if (error.type === "unsupportedFileType") {
        text = "Formato no permitido";
      } else if (error.type === "maxSizeExceeded") {
        text = "Excede el tamaño de 2mb";
      }
      const newLogoErrors = [...logoErrors];
      newLogoErrors.push(text);
      setLogoErrors(newLogoErrors);
    });
  };

  return (
    <Fragment>
      <Notifications ref={notifications} />
      <Row>
        <Col sm="12" md="4">
          <Card className="bg-light mb-3">
            <CardBody>
              <div className="d-flex align-items-center mb-1">
                <i className="fas fa-palette mr-2 h4 mb-0"></i>
                <h2 className="h4 mb-0 d-inline-block">
                  <span>Branding</span>
                </h2>
              </div>
              <p className="text-dark mb-0 small">
                <span className="font-weight-bold">
                  Configura color e imágenes asociados a tu imagen corporativa.
                </span>
              </p>
              <p className="text-dark mb-0 small">
                <i className="fas fa-info-circle mr-2" />
                <span>Banner: Dimensiones 600x130px</span>
              </p>
              <p className="text-dark mb-0 small">
                <i className="fas fa-info-circle mr-2" />
                <span>Logo: Dimensiones 200x130px</span>
              </p>
              <p className="text-dark mb-0 small">
                <i className="fas fa-info-circle mr-2" />
                <span>Formatos permitidos: png, jpg, jpeg</span>
              </p>
              <p className="text-dark mb-0 small">
                <i className="fas fa-info-circle mr-2" />
                <span>Tamaño máximo: 2mb</span>
              </p>
            </CardBody>
          </Card>
          <Card className="material-shadow mb-3">
            <CardBody>
              <form onSubmit={handleSubmit}>
                <FieldWrapper errors={bannerErrors}>
                  <Files
                    multiple={false}
                    maxSize="2mb"
                    accept={["image/jpg", "image/jpeg", "image/png"]}
                    onSuccess={files => handleChangeBanner(files)}
                    onError={errors => handleSaveBannerErrors(errors)}
                  >
                    {({ browseFiles }) => (
                      <Button
                        block
                        color="primary"
                        outline
                        size="sm"
                        onClick={browseFiles}
                        className="mt-3"
                      >
                        <i className="fas fa-upload mr-2"></i>
                        Cambiar Banner
                      </Button>
                    )}
                  </Files>
                </FieldWrapper>
                <FieldWrapper errors={logoErrors}>
                  <Files
                    multiple={false}
                    maxSize="2mb"
                    accept={["image/jpg", "image/jpeg", "image/png"]}
                    onSuccess={files => handleChangeLogo(files)}
                    onError={errors => handleSaveLogoErrors(errors)}
                  >
                    {({ browseFiles }) => (
                      <Button
                        block
                        color="info"
                        outline
                        size="sm"
                        onClick={browseFiles}
                        className="mt-3"
                      >
                        <i className="fas fa-upload mr-2"></i>
                        Cambiar Logo
                      </Button>
                    )}
                  </Files>
                </FieldWrapper>
                <FormGroup className="mt-3 card-body border rounded">
                  <label className="small">Cambiar Color</label>
                  <SliderPicker color={primaryColor} onChange={handleChangeColor} />
                </FormGroup>
                <FormGroup>
                  <Label>Ocultar Banner?</Label>{" "}
                  <Switch
                    checked={hideBanner}
                    onChange={e => setHideBanner(e)}
                    offColor="#ff3547"
                    onColor="#00c851"
                    onHandleColor="#00c851"
                    offHandleColor="#ff3547"
                    handleDiameter={30}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={15}
                    width={43}
                    className="react-switch align-middle"
                    id="material-switch-banner"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Ocultar Logo?</Label>{" "}
                  <Switch
                    checked={hideLogo}
                    onChange={e => setHideLogo(e)}
                    offColor="#ff3547"
                    onColor="#00c851"
                    onHandleColor="#00c851"
                    offHandleColor="#ff3547"
                    handleDiameter={30}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={15}
                    width={43}
                    className="react-switch align-middle"
                    id="material-switch-logo"
                  />
                </FormGroup>
                {loading ? (
                  <div className="sweet-loading text-center">
                    <BeatLoader color={"#123abc"} loading={loading} />
                  </div>
                ) : (
                  <Button color="success" size="sm" type="submit" block className="mt-3">
                    Guardar
                  </Button>
                )}
              </form>
            </CardBody>
          </Card>
        </Col>
        <Col sm="12" md="8">
          <Card className="material-shadow profile-card email-brand-preview-card mx-auto">
            <div className="card-banner" style={{ 'height': !hideBanner || !hideLogo ? '130px' : '0' }}>
              {!hideBanner ? (
                <div className="card-img-block">
                  <img
                    className="img-bg-banner"
                    src={banner.url || "/assets/template_600x130.png"}
                    alt="banner"
                    width="100%"
                  />
                </div>
              ) : null}
              {!hideLogo ? (
                <div className="image-cropper d-flex align-items-center">
                  <img
                    src={logo.url || "/assets/template_200x130.png"}
                    alt="logo"
                    className="profile-pic"
                  />
                </div>
              ) : null}
            </div>
            <CardBody className="mt-3 px-2 px-md-4">
              <Container className="">
                <h5 className="text-left font-weight-bold mt-2" style={{ color: primaryColor }}>
                  Estimado(a)
                </h5>
                <p className="mt-3">
                  Esta previsualización corresponde al formato de los correos que saldrán desde la
                  plataforma <span style={{ color: primaryColor }}>SomosEmpleos.</span> Puedes
                  cambiar Banner, Logo y colores de textos principales.
                </p>
                <p>
                  Puede agregar un Banner o un Logo a los emails que serán de contacto directo con
                  el candidato. En caso de no agregar nada, solo me mostrará el texto.
                </p>
                <p className="font-weight-bold">
                  En caso de que tu proceso sea confidencial, l@s candidat@s verán un correo
                  genérico sin las configuraciones de tu marca empleadora (logo, banner y color).
                </p>
                <p>Recuerda que al finalizar los cambios debes presionar el botón "Guardar".</p>
                <p className="text-center mt-5 mb-3">
                  <strong
                    style={{
                      fontFamily: "'Open Sans', sans-serif",
                      fontSsize: "14px",
                      lineHeight: "20px",
                      color: primaryColor,
                      marginTop: 0,
                      fontWeight: "bold"
                    }}
                  >
                    Recibe un cordial saludo,
                    <br />
                    Equipo SomosEmpleos
                  </strong>
                </p>
              </Container>
            </CardBody>
            <CardFooter
              className="text-center"
              style={{ backgroundColor: primaryColor || "#5a3ee0" }}
            >
              <span
                style={{
                  fontFamily: "'Open Sans', sans-serif",
                  color: "#fff",
                  fontSize: "11px",
                  fontWeight: 300
                }}
              >
                <a
                  style={{
                    fontFamily: "'Open Sans', sans-serif",
                    color: "#ffffff",
                    fontWeight: "bold",
                    textDecoration: "none"
                  }}
                >
                  SomosEmpleos
                </a>{" "}
                El Portal Laboral Inclusivo de Chile.
              </span>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default CompanyBrandsForm;
