import React, { Fragment, useState, useEffect } from "react";
import {
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label
} from "reactstrap";
import Select from "react-select";
import _ from "lodash";
import {
  getAllApplicants,
  getAllCourses,
  sendCourseInviteToApplicants
} from "./Axios";
import FieldWrapper from "../commons/FieldWrapper";
import { serialize } from "uri-js";

const CourseInviteModal = ({
  buttonSize,
  showNotification,
  applicant,
  courseId,
  _applicantsSelected
}) => {
  const [modal, setModal] = useState(false);
  const [applicants, setApplicants] = useState([]);
  const [courses, setCourses] = useState([]);
  const [applicantsSelected, setApplicantsSelected] = useState(
    _applicantsSelected
  );
  const [courseSelected, setCourseSelected] = useState(null);
  const [applicantsError, setApplicantsError] = useState(false);
  const [courseError, setCourseError] = useState(false);

  const toggle = () => setModal(!modal);

  useEffect(() => {
    const getApplicants = () => {
      const newApplicants = applicants;
      getAllApplicants(courseId, response => {
        _.map(response.data, applicant => {
          newApplicants.push({
            value: applicant.id,
            label: applicant.full_name
          });
        });
      });
      setApplicants(newApplicants);
    };

    const getCourses = () => {
      const newCourses = courses;
      getAllCourses(response => {
        _.map(response.data, course => {
          newCourses.push({ value: course.id, label: course.title });
        });
      });
      setCourses(newCourses);
    };

    if (!applicant) getApplicants();
    getCourses();
  }, []);

  useEffect(() => {
    if (modal) {
      setApplicantsSelected(_applicantsSelected);
    } else {
      setApplicantsSelected([]);
      setCourseSelected(null);
      setApplicantsError(false);
      setCourseError(false);
    }
  }, [modal]);

  const handleChangeApplicantsSelected = newValue => {
    if (newValue) {
      setApplicantsSelected(newValue);
      setApplicantsError(false);
    } else {
      setApplicantsSelected([]);
    }
  };

  const handleChangeCourseSelected = newValue => {
    if (newValue) {
      setCourseSelected(newValue);
      setCourseError(false);
    } else {
      setCourseSelected(null);
    }
  };

  const serializeData = () => {
    let formData = new FormData();
    if (applicant) {
      formData.append("applicant_ids[]", applicant.value);
    } else {
      _.map(applicantsSelected, applicant => {
        formData.append("applicant_ids[]", applicant.value);
      });
    }

    formData.append("course_id", courseSelected.value);

    return formData;
  };

  const handleSubmit = () => {
    if (!applicant && applicantsSelected.length === 0) {
      setApplicantsError(true);
      return;
    }

    if (!applicant && !courseSelected) {
      setCourseError(true);
      return;
    }

    setApplicantsError(false);
    setCourseError(false);
    const data = serializeData();
    sendCourseInviteToApplicants(data, response => {
      if (response.status === 200) {
        showNotification("Genial", "Invitación enviada con éxito", "success");
        toggle();
      }
    });
  };

  return (
    <Fragment>
      <Button color="info" outline size={buttonSize} onClick={toggle}>
        Invitar a Proceso
      </Button>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>
          Invitación de candidatos a proceso
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md="8">
              {applicant ? (
                <p>
                  Candidato(a): <strong>{applicant.label}</strong>
                </p>
              ) : (
                <FieldWrapper
                  errors={
                    applicantsError ? ["Debe seleccionar candidatos"] : []
                  }
                >
                  <Label>Seleccione Candidatos</Label>
                  <Select
                    isMulti
                    placeholder="Seleccione Candidatos"
                    options={applicants}
                    onChange={handleChangeApplicantsSelected}
                    value={applicantsSelected}
                  />
                </FieldWrapper>
              )}
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="8">
              <FieldWrapper
                errors={courseError ? ["Debe seleccionar proceso"] : []}
              >
                <Label>Seleccione un Proceso</Label>
                <Select
                  placeholder="Seleccione Proceso"
                  options={courses}
                  onChange={handleChangeCourseSelected}
                  value={courseSelected}
                />
              </FieldWrapper>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleSubmit}>
            Enviar
          </Button>{" "}
          <Button color="danger" onClick={toggle}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default CourseInviteModal;
