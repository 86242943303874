import React, { Component } from "react";
import { Row, Col, Button, Card, CardBody, Badge } from "reactstrap";

class MinisitePublicationsList extends Component {
  render() {
    return (
      <Row>
        <Col md="6">
          {/* Should render PublicationItem component but without the specific HIDDEN content -> */}
          <Card className="mb-2 publication-list-item job-card material-shadow">
            <CardBody>
              <div className="d-flex justify-content-between flex-wrap">
                <div className="flex-grow-1 pr-md-3">
                  <h2 className="h5 mb-3">
                    Titulo de Oferta Laboral&nbsp;
                    <Badge color="primary" className="badge-indicator">
                      Nueva Oferta
                    </Badge>
                    <small className="d-inline-block text-muted d-block">
                      <strong>Área:&nbsp;</strong>
                      Área laboral
                    </small>
                  </h2>
                  <div className="media flex-wrap">
                    <img
                      className="mr-2 mb-2 area-icon"
                      alt="Cargo dinámico"
                      src="/assets/cargos/guardia-ebf370455154c76482d7753dba73b790bf00b83c7d6bd29e14e22956c536ecf0.png"
                    />
                    <div className="media-body">
                      <ul className="fa-ul list-unstyled mb-0 ml-4 publication-detail-list">
                        <li>
                          <span className="fa-li">
                            <i
                              aria-hidden="true"
                              className="far fa-calendar-alt"
                              title="Fecha de Publicación"
                            ></i>
                          </span>
                          Fecha de Publicación 23-08-2019
                        </li>
                        <li>
                          <span className="fa-li">
                            <i
                              aria-hidden="true"
                              className="fas fa-business-time"
                              title="Jornada, Horarios y/o Turnos"
                            ></i>
                          </span>
                          Jornada Completa
                        </li>
                        <li>
                          <span className="fa-li">
                            <i
                              aria-hidden="true"
                              className="fas fa-map-marker-alt"
                              title="Ubicación"
                            ></i>
                          </span>
                          Las Condes, Región Metropolitana.
                        </li>
                        <li>
                          <span className="fa-li">
                            <i
                              className="fas fa-universal-access"
                              aria-hidden="true"
                              title="Infraestructura"
                            ></i>
                          </span>
                          Infraestructura Accesible
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="flex-shrink text-center d-flex flex-column flex-sm-row flex-md-row flex-lg-column align-items-center justify-content-around">
                  <div className="company_info text-center align-self-start">
                    <Button
                      color="link"
                      size="sm"
                      aria-label="Acerca de la Empresa"
                      title="Acerca de la Empresa"
                      className="p-0 ml-2 text-muted float-right"
                    >
                      <i className="fas fa-external-link-alt"></i>
                    </Button>
                    <img
                      src="//via.placeholder.com/120x60"
                      className="company_logo img-fluid d-inline-block mb-2"
                      alt="..."
                    />
                  </div>
                  <Button href="/publication/show" block size="sm" color="primary" className="my-3">
                    Ver Oferta
                  </Button>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md="6">
          <Card className="mb-2 publication-list-item job-card material-shadow">
            <CardBody>
              <div className="d-flex justify-content-between flex-wrap">
                <div className="flex-grow-1 pr-md-3">
                  <h2 className="h5 mb-3">
                    Titulo de Oferta Laboral&nbsp;
                    <Badge color="primary" className="badge-indicator">
                      Nueva Oferta
                    </Badge>
                    <small className="d-inline-block text-muted d-block">
                      <strong>Área:&nbsp;</strong>
                      Área laboral
                    </small>
                  </h2>
                  <div className="media flex-wrap">
                    <img
                      className="mr-2 mb-2 area-icon"
                      alt="Cargo dinámico"
                      src="/assets/cargos/guardia-ebf370455154c76482d7753dba73b790bf00b83c7d6bd29e14e22956c536ecf0.png"
                    />
                    <div className="media-body">
                      <ul className="fa-ul list-unstyled mb-0 ml-4 publication-detail-list">
                        <li>
                          <span className="fa-li">
                            <i
                              aria-hidden="true"
                              className="far fa-calendar-alt"
                              title="Fecha de Publicación"
                            ></i>
                          </span>
                          Fecha de Publicación 23-08-2019
                        </li>
                        <li>
                          <span className="fa-li">
                            <i
                              aria-hidden="true"
                              className="fas fa-business-time"
                              title="Jornada, Horarios y/o Turnos"
                            ></i>
                          </span>
                          Jornada Completa
                        </li>
                        <li>
                          <span className="fa-li">
                            <i
                              aria-hidden="true"
                              className="fas fa-map-marker-alt"
                              title="Ubicación"
                            ></i>
                          </span>
                          Las Condes, Región Metropolitana.
                        </li>
                        <li>
                          <span className="fa-li">
                            <i
                              className="fas fa-universal-access"
                              aria-hidden="true"
                              title="Infraestructura"
                            ></i>
                          </span>
                          Infraestructura Accesible
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="flex-shrink text-center d-flex flex-column flex-sm-row flex-md-row flex-lg-column align-items-center justify-content-around">
                  <div className="company_info text-center align-self-start">
                    <Button
                      color="link"
                      size="sm"
                      aria-label="Acerca de la Empresa"
                      title="Acerca de la Empresa"
                      className="p-0 ml-2 text-muted float-right"
                    >
                      <i className="fas fa-external-link-alt"></i>
                    </Button>
                    <img
                      src="//via.placeholder.com/120x60"
                      className="company_logo img-fluid d-inline-block mb-2"
                      alt="..."
                    />
                  </div>
                  <Button href="/publication/show" block size="sm" color="primary" className="my-3">
                    Ver Oferta
                  </Button>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default MinisitePublicationsList;
