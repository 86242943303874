import React, { Fragment, useState } from "react";
import { Row, Col, Button } from "reactstrap";
import { createPayment } from "./Axios";

const Flow = ({ companyPurchase }) => {
  const [loading, setLoading] = useState(false);

  const handleSubmitPayment = () => {
    setLoading(true);
    createPayment(companyPurchase.id, response => {
      if (response.status === 200) {
        const { token, url } = response.data.flow_payment;
        const paymentUrl = `${url}?token=${token}`;
        window.location.href = paymentUrl;
      }
    });
  };

  return (
    <Fragment>
      <Row className="text-center mt-5">
        <Col md="12">
          <Button color="primary" onClick={handleSubmitPayment}>
            {loading ? "Procesando Pago" : "Paga Con FLOW"}
            {loading ? (
              <i className="fas fa-spinner fa-pulse ml-2"></i>
            ) : (
              <i className="fas fa-shopping-bag ml-2"></i>
            )}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <div className="d-flex justify-content-center align-items-center">
            <img
              src="/assets/flow/webpayplus-3172dbad8bd3a18eaafc309732fbfef72866c6784f8570c6e1a5637418ff943c.svg"
              className="img-fluid p-2"
              width="150"
            />
            <img
              src="/assets/flow/onepay-5a8f53a881507c988347548ac6b192e0bcef53e8d3828a6e73b248e00c9673e9.svg"
              className="img-fluid p-2"
              width="150"
            />
            <img
              src="/assets/flow/mach-836edea58aa29075036854458f48357edd578fbe5d2c98231fbcf568cf01eff4.svg"
              className="img-fluid p-2"
              width="150"
            />
            <img
              src="/assets/flow/servipag-2f7855f0a514fd9b224390671010b87a99e1e8d6f38d92e06584b012d02a8cc1.svg"
              className="img-fluid p-2"
              width="150"
            />
            <img
              src="/assets/flow/cryptocompra-2dbf7f422cdd4ad03d350028af20470178f1cd5cf56a2de777178609621ba738.svg"
              className="img-fluid p-2"
              width="150"
            />
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Flow;
