import React, { Fragment, useState } from "react";
import { Row, Card, CardBody, Button, Col } from "reactstrap";
import Switch from "react-switch";
import NumberFormat from "react-number-format";
import EditProduct from "./Edit";
import { switchState } from "./Axios";

const UnlimitedProductHit = ({ product, canEdit }) => {
  const [productHit, setProductHit] = useState(product);

  const updateProduct = data => {
    setProductHit(data);
  };

  const handleSwithAasmState = () => {
    let switch_state;
    if (productHit.aasm_state === "active") {
      switch_state = "desactivate";
    } else {
      switch_state = "activate";
    }
    var formData = new FormData();
    formData.append("switch_state", switch_state);
    switchState(product.id, formData, response => {
      if (response.status == 200) {
        updateProduct(response.data);
      }
    });
  };

  return (
    <Col md="12" lg="12" className="p-lg-0">
      <Card className="mb-4 price-card with-ribbon promo border-0 py-2">
        <div className="ribbon ribbon-top-left ribbon-warning">
          <span>ilimitado!</span>
        </div>
        <CardBody className="text-center">
          <Row className="d-flex justify-content-center align-items-center ">
            <Col sm="12" lg="3" className="text-center">
              <h4 className="card-title clearfix">
                <span className="font-weight-bold product-name h4">
                  {productHit.name}
                </span>
                <br />
                <span className="h2 price font-weight-bold">
                  <span className="h6 font-weight-bold mr-1">$</span>
                  <NumberFormat
                    value={productHit.price}
                    displayType={"text"}
                    thousandSeparator="."
                    decimalSeparator=","
                  />
                  <span className="h6 font-weight-bold d-block">CLP + IVA</span>
                </span>
              </h4>
            </Col>
            <Col sm="12" lg="3">
              <ul className="list-group list-group-flush text-left">
                <li> Publicaciones ilimitadas con vigencia de hasta 30 días</li>
                <li> Plan vigencia de 1 año</li>
              </ul>
            </Col>
            <Col sm="12" lg="3" size="12" className="text-center">
              <a
                className="btn btn-primary btn-sm px-4 font-weight-bold text-wrap"
                href={`/purchases/new?product_id=${productHit.id}`}
              >
                Comprar
              </a>
            </Col>
          </Row>
          <div className="absolute-img d-none d-md-block">
            <img
              className="img-fluid"
              src="/assets/ilimitado-2d8f33f954bf97eec1590d45410904235b58677e583580d43f22d99cfbe20e50.png"
              alt=""
            />
          </div>
        </CardBody>
        {canEdit && (
          <div className="products-actions-container text-left d-block bg-light rounded-pill border">
            <Fragment>
              <EditProduct
                product={productHit}
                updateProduct={updateProduct}
              />{" "}
              <Switch
                checked={productHit.aasm_state === "active"}
                onChange={handleSwithAasmState}
                offColor="#ff3547"
                onColor="#00c851"
                onHandleColor="#00c851"
                offHandleColor="#ff3547"
                handleDiameter={20}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={10}
                width={30}
                className="react-switch align-middle"
                id="material-switch"
              />
            </Fragment>
          </div>
        )}
      </Card>
    </Col>
  );
};

export default UnlimitedProductHit;
