import React, { Fragment, useState } from "react";
import { SearchkitManager, SearchkitProvider, Hits, NoHits, Pagination } from "searchkit";
import { Card, CardBody } from "reactstrap";
import MyPostulationsHits from "./Hits";

const MyPostulationsIndex = ({ route }) => {
  const [searchkit, setSearchkit] = useState(
    new SearchkitManager(route, {
      httpHeaders: { "X-CSRF-TOKEN": window.SomosEmpleos.token }
    })
  );

  return (
    <Card className="material-shadow">
      <CardBody>
        <SearchkitProvider searchkit={searchkit}>
          <Fragment>
            <Hits hitsPerPage={10} listComponent={<MyPostulationsHits />} />
            <NoHits
              translations={{
                "NoHits.NoResultsFound": "No se encontraron postulaciones",
                "NoHits.DidYouMean": "Busqueda para {suggestion}",
                "NoHits.SearchWithoutFilters": "Busqueda para {query} sin filtros",
                "NoHits.NoResultsFoundDidYouMean":
                  "No se encontraron resultados para {query}. Quisiste decir {suggestion} ?"
              }}
            />
            <div className="mt-3">
              <Pagination
                showNumbers={true}
                translations={{
                  "pagination.previous": "Anterior",
                  "pagination.next": "Siguiente"
                }}
              />
            </div>
          </Fragment>
        </SearchkitProvider>
      </CardBody>
    </Card>
  );
};

export default MyPostulationsIndex;
