import React from "react";
import Moment from "react-moment";

const MyPostulationHit = ({ postulation }) => {
  return (
    <tr>
      <td>
        <img
          className="mr-2 mb-2 area-icon"
          style={{ width: "50px" }}
          alt={postulation.course.laboralArea.name}
          src={postulation.course.laboralArea.attachment}
        />{" "}
        {postulation.course.title}
        <span className="small text-muted"> {postulation.course.laboralArea.name}</span>
      </td>
      <td className="align-middle">
        {!postulation.course.minisitePublication.isConfidential
          ? postulation.course.companyCentral.name
          : null}
      </td>
      <td className="align-middle">
        <Moment format="DD/MM/YYYY HH:mm">{postulation.created_at}</Moment>
      </td>
    </tr>
  );
};

export default MyPostulationHit;
