import React, { Component } from "react";
import ReactNotification, { store } from "react-notifications-component";

class Notifications extends Component {
  addNotifications = (title, message, type) => {
    store.addNotification({
      title: title,
      message: message,
      type: type,
      insert: "top",
      container: "top-center",
      animationIn: ["animated", "bounceIn"],
      animationOut: ["animated", "fadeOut"],
      width: 330,
      dismiss: {
        duration: 7000,
        showIcon: true
      }
    });
  };
  render() {
    return <ReactNotification />;
  }
}

export default Notifications;
