import axios from "axios";

export const saveCompanyCredits = (companyCentralId, data, callback) => {
  var promise = axios({
    method: "post",
    url: `/api/company_centrals/${companyCentralId}/company_credits`,
    data: data,
    headers: {
      "X-CSRF-TOKEN": window.SomosEmpleos.token
    }
  });
  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};

export const deleteCompanyCredit = (companyCentralId, companyCreditId, callback) => {
  var promise = axios({
    method: "delete",
    url: `/api/company_centrals/${companyCentralId}/company_credits/${companyCreditId}`,
    headers: {
      "X-CSRF-TOKEN": window.SomosEmpleos.token
    }
  });
  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};
