import React, { Fragment, useState, useEffect, useRef } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import _ from "lodash";
import NewProduct from "./New";
import ProductsList from "./ProductsList";

const ProductsIndex = ({ products }) => {
  const [productsList, setProductsList] = useState([]);
  const notifications = useRef(null);

  useEffect(() => {
    setProductsList(products);
  }, []);

  const addProduct = product => {
    const newProducts = [...productsList, product];
    setProductsList(newProducts);
  };

  return (
    <Fragment>
      <Card className="mb-4 material-shadow">
        <CardBody className="bg-light">
          <Row className="d-flex align-items-center">
            <Col md="8">
              <div className="d-block mb-1 d-flex align-items-center">
                <i className="fas fa-award fa-pull-left h4 mb-0" />
                <h3 className="h4 mb-0 d-inline-block">Planes y Productos</h3>
              </div>
              <p className="small text-muted mb-sm-0">
                Listado de Planes y Productos ofrecidos en el Portal
              </p>
            </Col>
            <Col md="4" className="text-right">
              <NewProduct addProduct={addProduct} />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card className="mb-4 bg-light">
        <CardBody className="p-4">
          <ProductsList products={productsList} canEdit />
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default ProductsIndex;
