import React, { Component, Fragment } from "react";
import { Row, Col, Form, FormGroup, Label, Input, Button } from "reactstrap";
import EducationInstitutionsSelect from "../../education/institutions/Select";
import EducationDegreesSelect from "../../education/degrees/Select";
import EducationCareersSelect from "../../education/careers/Select";
import { savePersonalEducation } from "./Axios";
import FieldWrapper from "../../commons/FieldWrapper";

class EducationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      personalEducation: {
        id: { value: "" },
        educationDegree: { value: "", label: "", errors: [] },
        educationInstitution: { value: "", label: "", errors: [] },
        educationCareer: { value: "", label: "", errors: [] },
        graduatedAt: { value: "", errors: [] },
        careerName: { value: "", errors: [] },
        institutionName: { value: "", errors: [] }
      }
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { personalEducation } = this.props;

    if (personalEducation && this.state.personalEducation.id.value !== personalEducation.id) {
      this.setState({
        ...this.state,
        personalEducation: {
          id: { value: personalEducation.id },
          educationDegree: {
            value: personalEducation.education_degree.value,
            label: personalEducation.education_degree.label,
            errors: []
          },
          educationInstitution: {
            value: personalEducation.education_institution.value,
            label: personalEducation.education_institution.label,
            errors: []
          },
          educationCareer: {
            value: personalEducation.education_career.value,
            label: personalEducation.education_career.label,
            errors: []
          },
          graduatedAt: { value: personalEducation.graduated_at, errors: [] },
          careerName: { value: personalEducation.career_name, errors: [] },
          institutionName: { value: personalEducation.institution_name, errors: [] }
        }
      });
    }
  }

  handleChangePersonalEducation = (e, fieldName = null) => {
    const { personalEducation } = this.state;
    if (fieldName) {
      personalEducation[fieldName]["value"] = e.value;
      personalEducation[fieldName]["label"] = e.label;
      personalEducation[fieldName]["errors"] = [];
    } else {
      personalEducation[e.target.id]["value"] = e.target.value;
      personalEducation[e.target.id]["errors"] = [];
    }
    this.setState({ personalEducation });
  };

  handleCleanPersonalEducation = () => {
    const { personalEducation } = this.state;
    personalEducation.educationInstitution = { value: "", label: "", errors: [] };
    personalEducation.educationCareer = { value: "", label: "", errors: [] };
    personalEducation.careerName = { value: "", errors: [] };
    personalEducation.institutionName = { value: "", errors: [] };
    this.setState({ personalEducation });
  };

  serializeData = () => {
    const { personalEducation } = this.state;
    var formData = new FormData();

    if (personalEducation.id.value) {
      formData.append("personal_education[id]", personalEducation.id.value);
    }

    formData.append(
      "personal_education[education_career_id]",
      personalEducation.educationCareer.value
    );
    formData.append(
      "personal_education[education_institution_id]",
      personalEducation.educationInstitution.value
    );
    formData.append(
      "personal_education[education_degree_id]",
      personalEducation.educationDegree.value
    );
    formData.append(
      "personal_education[institution_name]",
      personalEducation.institutionName.value
    );
    formData.append("personal_education[career_name]", personalEducation.careerName.value);
    formData.append("personal_education[graduated_at]", personalEducation.graduatedAt.value);

    return formData;
  };

  setErrors = errors => {
    const { personalEducation } = this.state;
    personalEducation.educationInstitution.errors = errors.education_institution || [];
    personalEducation.educationCareer.errors = errors.education_career || [];
    personalEducation.educationDegree.errors = errors.education_degree || [];
    personalEducation.institutionName.errors = errors.institution_name || [];
    personalEducation.careerName.errors = errors.career_name || [];
    personalEducation.graduatedAt.errors = errors.graduated_at || [];
    this.setState({ personalEducation });
  };

  handleCleanState = () => {
    this.setState({
      personalEducation: {
        id: { value: "" },
        educationDegree: { value: "", label: "", errors: [] },
        educationInstitution: { value: "", label: "", errors: [] },
        educationCareer: { value: "", label: "", errors: [] },
        graduatedAt: { value: "", errors: [] },
        careerName: { value: "", errors: [] },
        institutionName: { value: "", errors: [] }
      }
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const data = this.serializeData();

    savePersonalEducation(data, response => {
      if (response.status === 201) {
        this.props.addPersonalEducation(response.data);
        this.props.toggle();
        this.handleCleanState();
        this.props.show_notification("Guardado", "Guardado exitoso", "success");
      } else if (response.status === 200) {
        this.props.handleUpdateHit(response.data);
        this.props.toggle();
        this.props.show_notification("Guardado", "Guardado exitoso", "success");
      } else {
        this.setErrors(response.data);
        this.props.show_notification(
          "Guardado",
          "Ocurrio un error, revisa el formulario",
          "danger"
        );
      }
    });
  };

  drawInstitutionCareerForm = () => {
    const { personalEducation } = this.state;
    return (
      <Fragment>
        {personalEducation.educationDegree.label === "Técnico Superior" ||
        personalEducation.educationDegree.label === "Profesional" ? (
          <Fragment>
            <Col md="4">
              <FormGroup>
                <Label htmlFor="educationInstitution" className="input_required">
                  Nombre Institución
                </Label>
                <FieldWrapper errors={personalEducation.educationInstitution.errors}>
                  <EducationInstitutionsSelect
                    handleChangePersonalEducation={this.handleChangePersonalEducation}
                    value={personalEducation.educationInstitution}
                  />
                </FieldWrapper>
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label htmlFor="educationCareer" className="input_required">
                  Titulo Cursado u Obtenido
                </Label>
                <FieldWrapper errors={personalEducation.educationCareer.errors}>
                  <EducationCareersSelect
                    handleChangePersonalEducation={this.handleChangePersonalEducation}
                    value={personalEducation.educationCareer}
                  />
                </FieldWrapper>
              </FormGroup>
            </Col>
          </Fragment>
        ) : (
          <Fragment>
            <Col md="4">
              <FormGroup>
                <Label htmlFor="institutionName" className="input_required">
                  Nombre Institución
                </Label>
                <FieldWrapper errors={personalEducation.institutionName.errors}>
                  <Input
                    className="form-control"
                    id="institutionName"
                    type="text"
                    onChange={this.handleChangePersonalEducation}
                    value={personalEducation.institutionName.value}
                  />
                </FieldWrapper>
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label htmlFor="careerName" className="input_required">
                  Titulo Cursado u Obtenido
                </Label>
                <FieldWrapper errors={personalEducation.careerName.errors}>
                  <Input
                    className="form-control"
                    id="careerName"
                    type="text"
                    onChange={this.handleChangePersonalEducation}
                    value={personalEducation.careerName.value}
                  />
                </FieldWrapper>
              </FormGroup>
            </Col>
          </Fragment>
        )}
      </Fragment>
    );
  };

  render() {
    const { personalEducation } = this.state;

    return (
      <Form>
        <Row>
          <Col md="4">
            <FormGroup>
              <Label htmlFor="educationDegree" className="input_required">
                Tipo
              </Label>
              <FieldWrapper errors={personalEducation.educationDegree.errors}>
                <EducationDegreesSelect
                  handleChangePersonalEducation={(e, fieldName = null) => {
                    this.handleChangePersonalEducation(e, fieldName);
                    this.handleCleanPersonalEducation();
                  }}
                  value={personalEducation.educationDegree}
                />
              </FieldWrapper>
            </FormGroup>
          </Col>
          {this.drawInstitutionCareerForm()}
          <Col md="4">
            <FormGroup>
              <Label htmlFor="graduatedAt" className="input_required">
                Fecha de Egreso
              </Label>
              <FieldWrapper errors={personalEducation.graduatedAt.errors}>
                <Input
                  invalid={personalEducation.graduatedAt.errors.length > 0 ? true : false}
                  className="form-control"
                  id="graduatedAt"
                  type="date"
                  onChange={this.handleChangePersonalEducation}
                  value={personalEducation.graduatedAt.value}
                />
              </FieldWrapper>
            </FormGroup>
          </Col>
          <Col md="12" className="text-right">
            <Button outline color="dark" className="my-4 mr-2" onClick={this.props.toggle}>
              Descartar
            </Button>
            <Button color="primary" className="my-4" onClick={this.handleSubmit}>
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default EducationForm;
