import React, { Component } from "react";
import AnswerSelect from "./AnswerSelect";
import { saveFunctionalRequirementAnswers } from "./Axios";
import { Row, Col, Form, FormGroup, Label, Button } from "reactstrap";

class FunctionalRequirements extends Component {
  constructor(props) {
    super(props);

    const { functional_answers } = this.props;

    this.state = {
      personalRequirementAnswers: { functional_answers }
    };
  }

  handleChangePersonalRequirementAnswers = (e, fieldName = null, question_id = null) => {
    const { personalRequirementAnswers } = this.state;

    let index = personalRequirementAnswers.functional_answers.findIndex(
      o => o.functional_question_id === question_id
    );

    personalRequirementAnswers.functional_answers[index]["functional_available_answer_id"] =
      e.value;
    personalRequirementAnswers.functional_answers[index]["functional_available_answer_label"] =
      e.label;
    personalRequirementAnswers.functional_answers[index][
      "functional_available_answer_company_label"
    ] = e.label;

    this.setState({ personalRequirementAnswers });
  };

  handleSubmit = e => {
    const { personalRequirementAnswers } = this.state;
    e.preventDefault();
    saveFunctionalRequirementAnswers(personalRequirementAnswers, response => {
      if (response.status === 200) {
        this.props.showNotification("Genial", "Información guardada con éxito", "success");
        this.props.toggleTab("accessibilityRequirementsForm");
      } else if (response.status === 422) {
        this.props.showNotification("Ops", "Ocurrio un error, revisa el formulario", "danger");
      }
    });
  };

  render() {
    const { personalRequirementAnswers } = this.state;
    let _this = this;
    return (
      <Form>
        <Row>
          <Col md="12">
            {personalRequirementAnswers.functional_answers.map(function(answer, index) {
              return (
                <FormGroup key={"functional_question" + index} className="py-4 border-bottom mb-0">
                  <Label htmlFor={"functionalQuestions_" + index}>
                    {answer.functional_question_label}
                  </Label>
                  <AnswerSelect
                    is_course={_this.props.is_course}
                    question_id={answer.functional_question_id}
                    value={{
                      value: answer.functional_available_answer_id,
                      label: _this.props.is_course
                        ? answer.functional_available_answer_company_label
                        : answer.functional_available_answer_label
                    }}
                    handleChange={_this.handleChangePersonalRequirementAnswers}
                    index={index}
                  />
                </FormGroup>
              );
            })}
          </Col>

          <Col md="12" className="text-right">
            <Button color="primary" className="my-4" onClick={this.handleSubmit}>
              Guardar
              <i className="fa fa-arrow-right ml-2"></i>
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default FunctionalRequirements;
