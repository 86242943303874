import axios from "axios";

export const saveDocumentDescriptors = (descriptor, data, callback) => {
  let method, url;
  if (descriptor) {
    (method = "patch"), (url = `/api/document_descriptors/${descriptor.id}`);
  } else {
    method = "post";
    url = "/api/document_descriptors";
  }
  var promise = axios({
    method: method,
    data: data,
    url: url,
    headers: {
      "X-CSRF-TOKEN": window.SomosEmpleos.token
    }
  });
  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};

export function switchState(descriptor_id, data, callback) {
  var promise = axios({
    method: "patch",
    url: `/api/document_descriptors/${descriptor_id}/switch_state`,
    data: data,
    headers: {
      "X-CSRF-TOKEN": window.SomosEmpleos.token
    }
  });
  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
}
