import React from "react";
import { Badge } from "reactstrap";

export const drawAasmState = aasmState => {
  let color, text;
  switch (aasmState) {
    case "inactive":
      color = "danger";
      text = "Inactivo(a)";
      break;
    case "active":
      color = "success";
      text = "Activo(a)";
      break;
    case "opened":
      color = "success";
      text = "Abierto";
      break;
    case "closed":
      color = "danger";
      text = "Cerrado";
      break;
    case "pending":
      color = "warning";
      text = "Pendiente";
      break;
    case "approved":
      color = "success";
      text = "Aprobado";
      break;
    case "rejected":
      color = "danger";
      text = "Rechazado";
      break;
    case "in_draft":
      color = "dark";
      text = "En Borrador";
      break;
    case "expired":
      color = "danger";
      text = "Expirado";
      break;
    case "pending_payment":
      color = "warning";
      text = "Pago Pendiente";
      break;
    case "canceled":
      color = "danger";
      text = "Pago Cancelado";
      break;
    case "paid_out":
      color = "success";
      text = "Pagado";
      break;
    case "available":
      color = "success";
      text = "Disponible";
      break;
    case "used":
      color = "primary";
      text = "Usado";
      break;
    default:
      break;
  }
  return (
    <Badge className="text-white" color={color}>
      {text}
    </Badge>
  );
};
