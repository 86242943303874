import React from "react";
import { Row, Col, Card, CardBody, ButtonGroup } from "reactstrap";
import NextStageModal from "./NextStageModal";
import UpdateInfoModal from "./UpdateInfoModal";
import CourseInviteModal from "./CourseInviteModal";

const ApplicantsTools = props => {
  const {
    isPostulation,
    courseId,
    workFlow,
    reloadQuery,
    showNotification,
    applicantsSelected,
    checkAllApplicant,
    handleCheckAllApplicant
  } = props;

  const drawCheckAll = () => {
    return (
      <input
        type="checkbox"
        onClick={handleCheckAllApplicant}
        value={checkAllApplicant}
        className="float-left"
      />
    );
  };

  return (
    <Row className="mb-3">
      <Col>
        <Card className="material-shadow">
          <CardBody>
            {drawCheckAll()}

            <ButtonGroup className="responsive-btn-group-vertical ml-3">
              {isPostulation && (
                <NextStageModal
                  courseId={courseId}
                  workFlow={workFlow}
                  reloadQuery={reloadQuery}
                  showNotification={showNotification}
                />
              )}
              <UpdateInfoModal
                courseId={courseId}
                showNotification={showNotification}
                _applicantsSelected={applicantsSelected}
              />
              {!isPostulation && (
                <CourseInviteModal
                  courseId={courseId}
                  showNotification={showNotification}
                  _applicantsSelected={applicantsSelected}
                />
              )}
            </ButtonGroup>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default ApplicantsTools;
