import React, { Fragment, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  FormGroup
} from "reactstrap";
import Select from "react-select";
import _ from "lodash";
import { saveProduct } from "./Axios";
import FieldWrapper from "../commons/FieldWrapper";

const NewProduct = ({ addProduct }) => {
  const [modal, setModal] = useState(false);
  const [type, setType] = useState({});
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [discount, setDiscount] = useState("");
  const [quantity, setQuantity] = useState("");
  const [nameErrors, setNameErrors] = useState([]);
  const [priceErrors, setPriceErrors] = useState([]);
  const [quantityErrors, setQuantityErrors] = useState([]);

  const availableTypes = [
    { value: "Product::Trial", label: "Prueba Gratis" },
    { value: "Product::Limited", label: "Plan Limitado" },
    { value: "Product::Unlimited", label: "Plan ilimitado" }
  ];

  const toggle = () => {
    setModal(!modal);
    setType({});
    setName("");
    setPrice("");
    setDiscount("");
    setQuantity("");
  };

  const handleChangeType = newValue => {
    setType(newValue);
    setName("");
    setPrice("");
    setDiscount("");
    setQuantity("");
    setNameErrors([]);
    setPriceErrors([]);
    setQuantityErrors([]);
  };

  const setErrors = errors => {
    const newNameErrors = [...nameErrors, errors.name];
    const newPriceErrors = [...priceErrors, errors.price];
    const newQuantityErrors = [...quantityErrors, errors.quantity];

    setNameErrors(newNameErrors);
    setPriceErrors(newPriceErrors);
    setQuantityErrors(newQuantityErrors);
  };

  const handleSubmitProduct = () => {
    if (!type.value) {
      const errors = ["Debe seleccionar un Tipo"];
      setType({ errors });
      return;
    }

    let data = new FormData();
    data.append("product[type]", type.value);
    data.append("product[name]", name);
    data.append("product[price]", price);
    data.append("product[discount]", discount);
    data.append("product[quantity]", quantity);

    saveProduct(data, response => {
      if (response.status === 201) {
        addProduct(response.data);
        toggle();
      } else if (response.status === 422) {
        setErrors(response.data);
      }
    });
  };

  return (
    <Fragment>
      <Button
        color="success"
        onClick={toggle}
        size="sm"
        style={{ color: "white" }}
      >
        Nuevo Producto
      </Button>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle} className="bg-primary text-white">
          Nuevo Producto
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>Tipo de Producto</Label>
            <FieldWrapper errors={type.errors}>
              <Select
                options={availableTypes}
                clearable={true}
                placeholder="Seleccione Opción"
                noResultsText="No se encuentra la opción"
                onChange={handleChangeType}
              />
            </FieldWrapper>
          </FormGroup>
          {type.value && (
            <FormGroup>
              <Label>Nombre</Label>
              <FieldWrapper errors={nameErrors}>
                <Input
                  type="text"
                  onChange={e => setName(e.target.value)}
                  value={name}
                />
              </FieldWrapper>
            </FormGroup>
          )}
          {type.value && type.value !== "Product::Trial" && (
            <FormGroup>
              <Label>Precio</Label>
              <FieldWrapper errors={priceErrors}>
                <Input
                  type="number"
                  onChange={e => setPrice(e.target.value)}
                  value={price}
                />
              </FieldWrapper>
            </FormGroup>
          )}
          {type.value && type.value !== "Product::Trial" && (
            <FormGroup>
              <Label>Descuento</Label>
              <Input
                type="number"
                onChange={e => setDiscount(e.target.value)}
                value={discount}
              />
            </FormGroup>
          )}
          {type.value && type.value !== "Product::Unlimited" && (
            <FormGroup>
              <Label>Cantidad</Label>
              <FieldWrapper errors={quantityErrors}>
                <Input
                  type="number"
                  onChange={e => setQuantity(e.target.value)}
                  value={quantity}
                />
              </FieldWrapper>
            </FormGroup>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleSubmitProduct}>
            Guardar
          </Button>{" "}
          <Button color="danger" onClick={toggle}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default NewProduct;
