import React, { useState, useEffect } from "react";
import _ from "lodash";
import Select from "react-select";
import { getTerritoryRegions } from "./Axios";

const RegionsSelect = ({ uniqueId, value, countryId, handleChange }) => {
  const [regions, setRegions] = useState([]);

  useEffect(() => {
    if (countryId) {
      getRegions(countryId);
    }
  }, [countryId]);

  const getRegions = countryId => {
    getTerritoryRegions(countryId, response => {
      const newRegions = [];
      _.map(response.data, region => {
        newRegions.push({ value: region.id, label: region.name });
      });
      setRegions(newRegions);
    });
  };

  return (
    <Select
      inputId={`${uniqueId}`}
      onChange={e => handleChange(e, "region")}
      options={regions}
      placeholder="Seleccione una opción"
      value={value.value ? value : null}
    />
  );
};

export default RegionsSelect;
