import React, { Fragment, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import HubspotForm from "react-hubspot-form";
import { BeatLoader } from "react-spinners";

const CustomProductForm = () => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  return (
    <Fragment>
      <Button
        color="primary"
        onClick={toggle}
        size="sm"
        style={{ color: "white" }}
      >
        Solicitar
      </Button>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle} className="bg-primary text-white">
          Solicitar Plan Personalizado
        </ModalHeader>
        <ModalBody>
          <HubspotForm
            portalId="5212387"
            formId="81858bc1-1cf7-4dec-a74e-312e1c0cb6f9"
            onSubmit={() =>
              setTimeout(() => {
                toggle();
              }, 5000)
            }
            loading={
              <div className="sweet-loading text-center">
                <BeatLoader color={"#123abc"} loading />
              </div>
            }
          />
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default CustomProductForm;
