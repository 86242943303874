import React, { Fragment } from "react";
import { Row, Col, ListGroup, ListGroupItem, FormGroup, Label, Input } from "reactstrap";

const PublicationQuestions = ({ publicationQuestions }) => {
  return (
    <Fragment>
      {publicationQuestions.length > 0 && (
        <Row>
          <Col md="12">
            <h3 className="h6 font-weight-bold">
              <i className="fas fa-question mr-2"></i>
              Preguntas de la Publicación
            </h3>
            <ListGroup flush>
              {_.map(publicationQuestions, (question, key) => (
                <ListGroupItem key={key}>
                  <FormGroup>
                    <Label for={`question${key + 1}`} className="input_required">
                      Pregunta {key + 1} {question.value}
                    </Label>

                    <Input
                      type="textarea"
                      placeholder="Escriba su Respuesta"
                      name="text"
                      id={`question${key + 1}`}
                      disabled
                    />
                  </FormGroup>
                </ListGroupItem>
              ))}
            </ListGroup>
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

export default PublicationQuestions;
