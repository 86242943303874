import React from "react";
import { Card, CardBody, Alert } from "reactstrap";
import FunctionalRequirements from "./functional_requirements/Form";

const FunctionalPublicationProfile = ({ course, toggleTab, showNotification }) => {
  return (
    <div>
      <Card className="mb-5">
        <CardBody className="pt-3">
          <Alert color="info">
            El <strong>perfil funcional</strong> es parte del Análisis de Puesto de trabajo en línea que
            facilita SomosEmpleos. <strong>Completarlo ayuda a compatibilizar tus requerimientos
            con los usuarios</strong>.
          </Alert>
          <FunctionalRequirements
            is_course
            functional_answers={course.functionalAnswers}
            toggleTab={toggleTab}
            showNotification={showNotification}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default FunctionalPublicationProfile;
