import React, { Fragment } from "react";
import { Row, Col, Card, CardBody, Table, Button } from "reactstrap";
import _ from "lodash";
import Moment from "react-moment";
import { drawAasmState } from "../../commons/Functions";

const MyPurchases = ({ companyPurchases }) => {
  return (
    <Fragment>
      <h4 className="mt-2 mb-4 text-primary">Planes Adquiridos</h4>
      <hr />
      <Row>
        <Col md="4">
          <h6 className="mt-2 mb-4 text-primary">Proceso de Compra</h6>
          <hr />
          <Card className="material-shadow">
            <CardBody>
              <ol>
                <li>
                  Ve a nuestro <a href="/planes">Planes</a> y elige el que mejor te acomode.
                </li>
                <li>Confirma que el producto elegido sea el que deseas.</li>
                <li>
                  Realiza y Confirma el pago a través de{" "}
                  <img
                    src="/assets/flow/logo-flow-45a90f0aac7e4fb5465ed53e8efbe88db0e3ac875d50cb072d724cb1565c6470.svg"
                    alt="FLOW"
                    className="img-fluid"
                    width="100"
                  />
                </li>
              </ol>
            </CardBody>
          </Card>
        </Col>
        <Col md="8">
          <h6 className="mt-2 mb-4 text-primary">Listado de Planes adquiridos</h6>
          <hr />
          <Card className="material-shadow">
            <CardBody>
              <Table>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Producto</th>
                    <th>Fecha de Compra</th>
                    <th>Fecha de Expiración</th>
                    <th>Estado</th>
                  </tr>
                </thead>
                <tbody>
                  {_.map(companyPurchases, (purchase, index) => (
                    <tr key={purchase.id}>
                      <td>{index + 1}</td>
                      <td>
                        {purchase.product.name}{" "}
                        <span className="text-muted" style={{ fontSize: "10px" }}>
                          ({purchase.id})
                        </span>
                      </td>
                      <td>
                        <Moment format="DD/MM/YYYY HH:mm">{purchase.createdAt}</Moment>
                      </td>
                      <td className="text-center">
                        {purchase.expiredAt ? (
                          <Moment format="DD/MM/YYYY">{purchase.expiredAt}</Moment>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>{drawAasmState(purchase.aasmState)}</td>
                      <td>
                        {(purchase.aasmState === "pending_payment" ||
                          purchase.aasmState === "canceled") && (
                          <Button
                            color="primary"
                            size="sm"
                            outline
                            href={`/purchases/${purchase.id}`}
                          >
                            Pagar
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default MyPurchases;
