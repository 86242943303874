import React, { useState } from "react";
import { Row, Col, Card, CardBody, CardHeader, ListGroup, ListGroupItem, Button, Badge } from "reactstrap";
import _ from "lodash";
import MatchIndicator from "../commons/MatchIndicator";

const MatchDetail = ({ applicant, course}) => {

  const divClassPreferredSchedule = () => {
    var divClass = 'badge badge-soft-danger badge-pill';
    if(applicant.user.personal_laboral_requirement.preferred_schedule==course.minisitePublication.preferredSchedule) {
      divClass = 'badge badge-soft-success badge-pill';
    }
    return divClass;
  };
  const divClassPreferredLaborDay = () => {
    var divClass = "badge badge-soft-danger badge-pill";
    if(applicant.user.personal_laboral_requirement.preferred_labor_day==course.minisitePublication.preferredLaborDay) {
      divClass = "badge badge-soft-success badge-pill";
    }
    return divClass
  };
  const divClassPreferredTurn = () => {
    var divClass = "badge badge-soft-danger badge-pill";
    if(applicant.user.personal_laboral_requirement.preferred_turn==course.minisitePublication.preferredTurn) {
      divClass = "badge badge-soft-success badge-pill";
    }
    return divClass
  };
  const divClassLaboralAreas = (laboral_area) => {
    var divClass = "badge badge-soft-danger badge-pill text-wrap";
    if(laboral_area == course.laboralArea.name) {
      divClass = "badge badge-soft-success badge-pill text-wrap";
    }
    return divClass
  };
  const divClassCommunes = (commune) => {
    var divClass = "badge badge-soft-danger badge-pill";
    if(commune == course.minisitePublication.commune.name) {
      divClass = "badge badge-soft-success badge-pill";
    }
    return divClass
  };

  const getCorrectAnswer = (answer) => {
    let _answer = _.find(applicant.user.functional_answers, fa => fa.functional_question_id == answer.functional_question_id)
    return _answer
  };

  const drawFunctionalProfile = (answer) => {
    let correctAnswer = getCorrectAnswer(answer);
    return(
      <li className="list-group-item" key={answer.id}>
        <Row>
          <Col md="3">
            <span className="d-block font-weight-bold small">{answer.functional_question_label}</span>
          </Col>
          <Col md="4" className="text-md-right">
            <span className="badge badge-soft-success badge-pill">
              {correctAnswer.functional_available_answer_label}
            </span>
          </Col>
          <Col md="5" className="text-md-right">
            <div className="d-inline-block small font-weight-bold text-primary">
              <i className="fas fa-exclamation-triangle mr-2"></i>
              Respuesta esperada:
              {answer.functional_available_answer_label}
            </div>
          </Col>
        </Row>
      </li>
    )

  };
  return (

    <Row>
      <Col md="12">
        <Card className="mb-3">
          <CardHeader>
            <h5 className="card-title mb-0 h6 font-weight-bold">
              <i className="fas mr-2 fa-info-circle" aria-hidden="true"></i>
              Expectativas Laborales
            </h5>
          </CardHeader>
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              <Row>
                <Col md="3">
                  <span className="d-block font-weight-bold small">Jornada Laboral</span>
                </Col>
                <Col md="4">
                  {applicant.user.personal_laboral_requirement.preferred_schedule && (
                    <span className={divClassPreferredSchedule()}>
                      {I18n.t(
                      `activerecord.enums.personal/laboral_requirement.preferred_schedules.${applicant.user.personal_laboral_requirement.preferred_schedule}`
                    )}
                    </span>
                  )}
                </Col>
                <Col md="5" className="text-md-right">
                  <div className="d-inline-block small font-weight-bold text-primary">
                    <i className="fas fa-exclamation-triangle mr-2"></i>
                    Respuesta esperada:
                    {I18n.t(
                      `activerecord.enums.personal/laboral_requirement.preferred_schedules.${course.minisitePublication.preferredSchedule}`
                    )}
                  </div>
                </Col>
              </Row>
            </li>
            <li className="list-group-item">
              <Row>
                <Col md="3">
                  <span className="d-block font-weight-bold small">Extensión de la jornada</span>
                </Col>
                <Col md="4">
                  {applicant.user.personal_laboral_requirement.preferred_labor_day && (
                    <span className={divClassPreferredLaborDay()}>
                      {I18n.t(
                        `activerecord.enums.personal/laboral_requirement.preferred_labor_days.${applicant.user.personal_laboral_requirement.preferred_labor_day}`
                      )}
                    </span>
                  )}
                </Col>
                <Col md="5" className="text-md-right">
                  <div className="d-inline-block small font-weight-bold text-primary">
                    <i className="fas fa-exclamation-triangle mr-2"></i>
                    Respuesta esperada:
                    {I18n.t(
                      `activerecord.enums.personal/laboral_requirement.preferred_labor_days.${course.minisitePublication.preferredLaborDay}`
                    )}
                  </div>
                </Col>
              </Row>
            </li>
            <li className="list-group-item">
              <Row>
                <Col md="3">
                  <span className="d-block font-weight-bold small">Horarios y/o Turnos</span>
                </Col>
                <Col md="4">
                  {applicant.user.personal_laboral_requirement.preferred_turn && (
                    <span className={divClassPreferredTurn()}>
                      {I18n.t(
                        `activerecord.enums.personal/laboral_requirement.preferred_turns.${applicant.user.personal_laboral_requirement.preferred_turn}`
                      )}
                    </span>
                  )}
                </Col>
                <Col md="5" className="text-md-right">
                  <div className="d-inline-block small font-weight-bold text-primary">
                    <i className="fas fa-exclamation-triangle mr-2"></i>
                    Respuesta esperada:
                    {I18n.t(
                      `activerecord.enums.personal/laboral_requirement.preferred_turns.${course.minisitePublication.preferredTurn}`
                    )}
                  </div>
                </Col>
              </Row>
            </li>
            <li className="list-group-item">
              <Row>
                <Col md="3">
                  <span className="d-block font-weight-bold small">Área Laboral de Interés</span>
                </Col>
                <Col md="4">
                  <div className="">
                    {_.map(applicant.user.personal_laboral_requirement.laboral_areas, area => (
                      <span key={area.value} className={divClassLaboralAreas(area.label)}>
                        {area.label}
                      </span>
                    ))}
                  </div>
                </Col>
                <Col md="5" className="text-md-right">
                  <div className="d-inline-block small font-weight-bold text-primary">
                    <i className="fas fa-exclamation-triangle mr-2"></i>
                    Respuesta esperada:
                    {course.laboralArea.name}
                  </div>
                </Col>
              </Row>
            </li>
            <li className="list-group-item">
              <Row>
                <Col md="3">
                  <span className="d-block font-weight-bold small">Comunas de Interés</span>
                </Col>
                <Col md="4">
                  <div className="">
                    {_.map(applicant.user.personal_laboral_requirement.desired_communes, commune => (
                      <span key={commune.value} className={divClassCommunes(commune.label)}>
                        {commune.label}
                      </span>
                    ))}
                  </div>
                </Col>
                <Col md="5" className="text-md-right">
                  <div className="d-inline-block small font-weight-bold text-primary">
                    <i className="fas fa-exclamation-triangle mr-2"></i>
                    Respuesta esperada:
                    {course.minisitePublication.commune.name}
                  </div>
                </Col>
              </Row>
            </li>

          </ul>
        </Card>
      </Col>
      {/*<Col md="12">
        <Card className="mb-3">
          <CardHeader>
            <h5 className="card-title mb-0 h6 font-weight-bold">
              <i className="fas mr-2 fa-info-circle" aria-hidden="true"></i>
              Perfil Funcional
            </h5>
          </CardHeader>
          <ul className="list-group list-group-flush">
          {_.map(applicant.user.functional_answers, answer => (
              drawFunctionalProfile(answer)
          ))}
          </ul>
        </Card>
      </Col>*/}
    </Row>
  );
};

export default MatchDetail;
