import React, { Fragment, useState, useEffect } from "react";
import {
  ReactiveBase,
  ReactiveList,
  DataSearch,
  ReactiveComponent,
  MultiDropdownList,
  SelectedFilters
} from "@appbaseio/reactivesearch";
import { Container, Card, CardBody, Row, Col } from "reactstrap";
import _ from "lodash";
import CompanyPurchasesHits from "../Hits";
import PaginationComponent from "../../../commons/Pagination";
import PurchasesCountCard from "./PurchasesCountCard";
import PurchasesPaidCard from "./PurchasesPaidCard";
import PurchasesPendingCard from "./PurchasesPendingCard";

const size = 15;

const theme = {
  typography: {
    fontFamily: '"Nunito", "Open Sans", sans-serif',
    fontSize: "16px"
  },

  colors: {
    textColor: "#424242",
    primaryTextColor: "#fff",
    primaryColor: "#5a3ee0",
    titleColor: "#5a3ee0",
    alertColor: "#28a745"
  }
};

const defaultQuery = {
  query: {
    bool: {
      filter: {
        terms: { "product.type": ["Product::Limited", "Product::Unlimited"] }
      }
    }
  }
};

const CompanyPurchasesDashboard = ({ url }) => {
  const [countHits, setCountHits] = useState(0);

  const componentsIds = (except = null) => {
    var allIds = ["SearchSensor", "ProductNameSensor", "AasmStateSensor"];
    return _.without(allIds, except);
  };

  return (
    <Container fluid>
      <ReactiveBase
        app="/"
        url={url}
        headers={{
          "X-CSRF-TOKEN": window.SomosEmpleos.token
        }}
        theme={theme}
      >
        <Row>
          <Col md="12">
            <Card className="material-shadow">
              <CardBody>
                <Row>
                  <Col md="6">
                    <DataSearch
                      componentId="SearchSensor"
                      dataField={[
                        "flowPayment.flowOrder",
                        "companyCentral.name",
                        "boughtBy.email"
                      ]}
                      placeholder="Buscar compras por N° Orden, Empresa o Comprador"
                      autosuggest={false}
                      queryFormat="and"
                      strictSelection
                      innerClass={{
                        input: "form-control"
                      }}
                      react={{
                        and: componentsIds()
                      }}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md="2">
            <Card className="mt-1 material-shadow">
              <CardBody>
                <div className="my-3">
                  <MultiDropdownList
                    defaultQuery={() => defaultQuery}
                    componentId="ProductNameSensor"
                    dataField="product.name"
                    showSearch={true}
                    title="Tipo de Producto"
                    sortBy="count"
                    showCount={true}
                    placeholder="Buscar Tipo de Producto"
                    searchPlaceholder="Buscar"
                    showFilter={true}
                    filterLabel="Tipo de Producto"
                    loader="Cargando ..."
                    react={{
                      and: componentsIds("ProductNameSensor")
                    }}
                  />
                </div>
                <div className="my-3">
                  <MultiDropdownList
                    defaultQuery={() => defaultQuery}
                    componentId="AasmStateSensor"
                    dataField="aasmStateText"
                    showSearch={true}
                    title="Estado"
                    sortBy="count"
                    showCount={true}
                    placeholder="Buscar Estado"
                    searchPlaceholder="Buscar"
                    showFilter={true}
                    filterLabel="Estado"
                    loader="Cargando ..."
                    react={{
                      and: componentsIds("AasmStateSensor")
                    }}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="10">
            <Row className="mb-1">
              <Col md="12">
                <div className="d-flex justify-content-between">
                  <SelectedFilters
                    showClearAll={true}
                    clearAllLabel="Limpiar Filtros"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md="4">
                <ReactiveComponent
                  componentId="purchasesCount"
                  defaultQuery={() => defaultQuery}
                  customQuery={() => ({
                    aggs: {
                      purchases_count: {
                        value_count: {
                          field: "_id"
                        }
                      }
                    },
                    size: 0
                  })}
                  react={{
                    and: componentsIds()
                  }}
                  render={data => <PurchasesCountCard {...data} />}
                />
              </Col>
              <Col md="4">
                <ReactiveComponent
                  componentId="purchasesPaid"
                  defaultQuery={() => ({
                    query: {
                      bool: {
                        filter: {
                          term: { aasmState: "paid_out" }
                        }
                      }
                    }
                  })}
                  customQuery={() => ({
                    aggs: {
                      sum_purchases_paid: {
                        sum: {
                          field: "flowStatus.amount"
                        }
                      }
                    },
                    size: 0
                  })}
                  react={{
                    and: componentsIds()
                  }}
                  render={data => <PurchasesPaidCard {...data} />}
                />
              </Col>
              <Col md="4">
                <ReactiveComponent
                  componentId="purchasesPending"
                  defaultQuery={() => ({
                    query: {
                      bool: {
                        filter: {
                          term: { aasmState: "pending_payment" }
                        }
                      }
                    }
                  })}
                  customQuery={() => ({
                    aggs: {
                      sum_purchases_pending: {
                        sum: {
                          field: "totalPrice"
                        }
                      }
                    },
                    size: 0
                  })}
                  react={{
                    and: componentsIds()
                  }}
                  render={data => <PurchasesPendingCard {...data} />}
                />
              </Col>
            </Row>
            <ReactiveList
              defaultQuery={() => defaultQuery}
              showResultStats={false}
              dataField="createdAt"
              sortBy="desc"
              componentId="SearchResult"
              pagination
              paginationAt="bottom"
              size={size}
              renderPagination={props => (
                <PaginationComponent {...props} size={size} total={countHits} />
              )}
              render={({ resultStats, data }) => {
                setCountHits(resultStats.numberOfResults);
                return <CompanyPurchasesHits hits={data} />;
              }}
              react={{
                and: componentsIds()
              }}
            />
          </Col>
        </Row>
      </ReactiveBase>
    </Container>
  );
};

export default CompanyPurchasesDashboard;
