import React, { Fragment, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const TermsConditionsModal = () => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  return (
    <Fragment>
      Acepto{" "}
      <a href="" data-toggle="modal" onClick={toggle}>
        Términos y Condiciones
      </a>
      <Modal
        isOpen={modal}
        toggle={toggle}
        size="lg"
        className="modal-dialog-scrollable"
      >
        <ModalHeader toggle={toggle}>
          <h4 className="h5 modal-title font-weight-bold">
            Términos y Condiciones
          </h4>
        </ModalHeader>
        <ModalBody style={{ overflowY: "auto" }}>
          <div className="mb-4">
            <p className="h6 font-weight-bold">1. INTRODUCCIÓN.</p>
            <p className="text-justify">
              Las presentes condiciones generales de uso del Portal Laboral
              Inclusivo <strong>Somos Empleos</strong> regulan los términos y
              condiciones de acceso y uso de www.somosempleos.cl, propiedad de
              la empresa 4Talent SpA, con domicilio en Santiago de Chile, en
              adelante, «la Empresa», que el usuario del Portal{" "}
              <strong>Somos Empleos</strong> deberá de leer y aceptar para usar
              todos los servicios e información que se facilitan desde el
              portal. El mero acceso y/o utilización del portal, de todos o
              parte de sus contenidos y/o servicios significa la plena
              aceptación de las presentes condiciones generales de uso.
            </p>
          </div>
          <div className="mb-4">
            <p className="h6 font-weight-bold">2. CONDICIONES DE USO.</p>
            <p className="text-justify">
              Las presentes condiciones generales de uso del portal regulan el
              acceso y la utilización del portal <strong>Somos Empleos</strong>{" "}
              incluyendo los contenidos y los servicios puestos a disposición de
              los usuarios en y/o a través del portal, bien por el portal, bien
              por sus usuarios, bien por terceros. No obstante, el acceso y la
              utilización de ciertos contenidos y/o servicios puede encontrarse
              sometido a determinadas condiciones específicas.
            </p>
          </div>
          <div className="mb-4">
            <p className="h6 font-weight-bold">3. MODIFICACIONES.</p>
            <p className="text-justify">
              La empresa se reserva la facultad de modificar en cualquier
              momento las condiciones generales de uso del portal. En todo caso,
              se recomienda que consulte periódicamente los presentes términos
              de uso del portal, ya que pueden ser modificados.
            </p>
          </div>
          <div className="mb-4">
            <p className="h6 font-weight-bold">
              4. OBLIGACIONES DEL USUARIO.
            </p>
            <p className="text-justify">
              El usuario deberá respetar en todo momento los términos y
              condiciones establecidos en las presentes condiciones generales de
              uso del portal. De forma expresa el usuario manifiesta que
              utilizará el portal de forma diligente y asumiendo cualquier
              responsabilidad que pudiera derivarse del incumplimiento de las
              normas.
            </p>
            <p className="text-justify">
              Asimismo, el usuario no podrá utilizar el portal para transmitir,
              almacenar, divulgar promover o distribuir datos o contenidos que
              sean portadores de virus o cualquier otro código informático,
              archivos o programas diseñados para interrumpir, destruir o
              perjudicar el funcionamiento de cualquier programa o equipo
              informático o de telecomunicaciones.
            </p>
          </div>
          <div className="mb-4">
            <p className="h6 font-weight-bold">
              5. RESPONSABILIDAD DEL PORTAL.
            </p>
            <p className="text-justify">
              El usuario conoce y acepta que el Portal{" "}
              <strong>Somos Empleos</strong>no otorga ninguna garantía de
              cualquier naturaleza, ya sea expresa o implícita, sobre los datos,
              contenidos, información y servicios que se incorporan y ofrecen
              desde el Portal.
            </p>
            <p className="text-justify">
              Exceptuando los casos que la Ley imponga expresamente lo
              contrario, y exclusivamente con la medida y extensión en que lo
              imponga, el Portal no garantiza ni asume responsabilidad alguna
              respecto a los posibles daños y perjuicios causados por el uso y
              utilización de la información, datos y servicios del Portal.
            </p>
            <p className="text-justify">
              Asimismo, el Portal excluye cualquier responsabilidad por los
              daños y perjuicios que puedan deberse a la información y/o
              servicios prestados o suministrados por terceros diferentes de la
              Empresa. Toda responsabilidad será del tercero ya sea proveedor o
              colaborador.
            </p>
            <p className="text-justify">
              Sin perjuicio de lo anterior, el Portal{" "}
              <strong>Somos Empleos</strong> no recopilará a través del Sitio
              Web ninguna información sobre el usuario que pueda identificarlo
              personalmente, como por ejemplo; Nombre, dirección, número de
              teléfono o dirección de correo electrónico (en adelante,
              Información Personal), a menos que usted brinde la información de
              forma voluntaria. Si usted, no desea que recopilemos su
              Información Personal, por favor abténgase de proporcionarla. No
              obstante lo anterior, el usuario debe tener en cuenta que en caso
              de no proporcionarnos su Información Personal, no podrá acceder a
              los servicios e información disponibles a través de{" "}
              <strong>Somos Empleos</strong>.
            </p>
            <p className="text-justify">
              En caso de que usted nos brinde su Información Personal, le
              comunicamos que esa Información Personal será objeto de
              tratamiento automatizado e incorporada a la base de datos de la
              empresa.
            </p>
            <p className="text-justify">
              La empresa, en ningún momento recopilará, venderá o difundirá
              información sensible sobre usted.
            </p>
          </div>
          <div className="mb-4">
            <p className="h6 font-weight-bold">6. PROPIEDAD INTELECTUAL.</p>
            <p className="text-justify">
              Todos los contenidos, marcas, logos, dibujos, íconos,
              documentación, programas informáticos o cualquier otro elemento
              susceptible de protección por la legislación de propiedad
              intelectual, que sean accesibles en el portal corresponden
              exclusivamente a la empresa o a sus legítimos titulares y quedan
              expresamente reservados todos los derechos sobre los mismos. Queda
              expresamente prohibida la creación de enlaces de hipertexto
              (links) a cualquier elemento integrante de las páginas web del
              Portal <strong>Somos Empleos</strong> sin la autorización de la
              empresa, siempre que no sean a una página web del Portal que no
              requiera identificación o autenticación para su acceso, o el mismo
              esté restringido.
            </p>
            <p className="text-justify">
              Queda también, expresamente prohibida la distribución del
              contenido, uso o plagio del Perfil Funcional, diseñado y creado
              por Red Incluye, donde la empresa ha implementado el Perfil
              Funcional haciendo uso de la información y datos otorgados por los
              usuarios.
            </p>
            <p className="text-justify">
              En cualquier caso, el portal <strong>Somos Empleos</strong> se
              reserva todos los derechos sobre los contenidos, información de
              datos y servicios que ostente sobre los mismos. El portal{" "}
              <strong>Somos Empleos</strong> no concede ninguna licencia o
              autorización de uso al usuario sobre sus contenidos, datos o
              servicios, distinta de la que expresamente se detalle en las
              presentes condiciones generales de uso del portal.
            </p>
          </div>
          <div className="mb-4">
            <p className="h6 font-weight-bold">
              7. LEGISLACIÓN APLICABLE, JURISDICCIÓN COMPETENTE Y
              NOTIFICACIONES.
            </p>
            <p className="text-justify">
              Las presentes condiciones se rigen y se interpretan de acuerdo con
              las Leyes de Chile. Todas las notificaciones, requerimientos,
              peticiones y otras comunicaciones que el Usuario desee efectuar a
              la Empresa 4Talent del Portal <strong>Somos Empleos</strong>{" "}
              deberán realizarse por escrito y se entenderá que han sido
              correctamente realizadas cuando hayan sido recibidas en la
              siguiente dirección <strong>contact@somosempleos.cl</strong>
            </p>
          </div>
          <div className="mb-4">
            <p className="h6 font-weight-bold">
              8. MODIFICACIÓN DE LA INFORMACIÓN.
            </p>
            <p className="text-justify">
              SomosEmpleos  se reserva la facultad y el derecho de actualizar, 
              modificar o eliminar, en parte o en todo, y sin expresión de causa, 
              cualquier contenido de información pública en este sitio según lo estime 
              conveniente, pudiendo hacer uso de tal facultad en cualquier momento y sin previo aviso.
            </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default TermsConditionsModal;
