import React, { Component } from "react";
import { Row, Col, Form, FormGroup, Label, Input, Button, Card, CardBody, Collapse } from "reactstrap";
import { saveLaboralExperience } from "./Axios";
import LaboralAreasSelect from "../../laboral/areas/Select";
import FieldWrapper from "../../commons/FieldWrapper";

class LaboralExperienceForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      laboralExperience: {
        id: { value: "" },
        companyPosition: { value: "", errors: [] },
        companyName: { value: "", errors: [] },
        startAt: { value: "", errors: [] },
        endAt: { value: "", errors: [] },
        laboralArea: { value: "", label: "", errors: [] },
        hasJobRecomendation: { value: "", errors: [] },
        recomendationName: { value: "", errors: [] },
        recomendationPhoneNumber: { value: "", errors: [] },
        recomendationEmail: { value: "", errors: [] },
        experienceDetails: { value: "", errors: [] },
        achievements: { value: "", errors: [] }
      }
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { laboralExperience } = this.props;

    if (laboralExperience && this.state.laboralExperience.id.value !== laboralExperience.id) {
      this.setState({
        ...this.state,
        laboralExperience: {
          id: { value: laboralExperience.id },
          companyPosition: { value: laboralExperience.company_position, errors: [] },
          companyName: { value: laboralExperience.company_name, errors: [] },
          startAt: { value: laboralExperience.start_at, errors: [] },
          endAt: { value: laboralExperience.end_at, errors: [] },
          laboralArea: {
            value: laboralExperience.laboral_area.value,
            label: laboralExperience.laboral_area.label,
            errors: []
          },
          hasJobRecomendation: { value: laboralExperience.has_job_recomendation, errors: [] },
          recomendationName: { value: laboralExperience.recomendation_name, errors: [] },
          recomendationPhoneNumber: {
            value: laboralExperience.recomendation_phone_number,
            errors: []
          },
          recomendationEmail: { value: laboralExperience.recomendation_email, errors: [] },
          experienceDetails: { value: laboralExperience.experience_details, errors: [] },
          achievements: { value: laboralExperience.achievements, errors: [] }
        }
      });
    }
  }

  handleChangeLaboralExperience = (e, fieldName = null) => {
    const { laboralExperience } = this.state;
    if (fieldName) {
      laboralExperience[fieldName]["value"] = e.value;
      laboralExperience[fieldName]["label"] = e.label;
      laboralExperience[fieldName]["errors"] = [];
    } else if (e.target.id === "hasJobRecomendation") {
      laboralExperience[e.target.id]["value"] = e.target.checked;
      laboralExperience[e.target.id]["errors"] = [];
    } else {
      laboralExperience[e.target.id]["value"] = e.target.value;
      laboralExperience[e.target.id]["errors"] = [];
    }
    this.setState({ laboralExperience });
  };

  handleCleanRecomendation = e => {
    const { laboralExperience } = this.state;
    if (
      laboralExperience.hasJobRecomendation.value === "true" ||
      laboralExperience.hasJobRecomendation.value === false
    ) {
      const { laboralExperience } = this.state;
      laboralExperience.recomendationName = { value: "", errors: [] };
      laboralExperience.recomendationEmail = { value: "", errors: [] };
      laboralExperience.recomendationPhoneNumber = { value: "", errors: [] };
      this.setState({ laboralExperience });
    }
  };

  serializeData = () => {
    const { laboralExperience } = this.state;
    var formData = new FormData();

    if (laboralExperience.id.value) {
      formData.append("laboral_experience[id]", laboralExperience.id.value);
    }

    formData.append("laboral_experience[company_position]", laboralExperience.companyPosition.value);
    formData.append("laboral_experience[company_name]", laboralExperience.companyName.value);
    formData.append("laboral_experience[start_at]", laboralExperience.startAt.value);
    formData.append("laboral_experience[end_at]", laboralExperience.endAt.value);
    formData.append("laboral_experience[has_job_recomendation]", laboralExperience.hasJobRecomendation.value);
    formData.append("laboral_experience[recomendation_name]", laboralExperience.recomendationName.value);
    formData.append("laboral_experience[recomendation_phone_number]", laboralExperience.recomendationPhoneNumber.value);
    formData.append("laboral_experience[recomendation_email]", laboralExperience.recomendationEmail.value);
    formData.append("laboral_experience[laboral_area_id]", laboralExperience.laboralArea.value);
    formData.append("laboral_experience[experience_details]", laboralExperience.experienceDetails.value);
    formData.append("laboral_experience[achievements]", laboralExperience.achievements.value);

    return formData;
  };

  setErrors = errors => {
    const { laboralExperience } = this.state;
    laboralExperience.companyName.errors = errors.company_name || [];
    laboralExperience.companyPosition.errors = errors.company_position || [];
    laboralExperience.startAt.errors = errors.start_at || [];
    laboralExperience.endAt.errors = errors.end_at || [];
    laboralExperience.laboralArea.errors = errors.laboral_area || [];
    laboralExperience.recomendationName.errors = errors.recomendation_name || [];
    laboralExperience.recomendationPhoneNumber.errors = errors.recomendation_phone_number || [];
    laboralExperience.recomendationEmail.errors = errors.recomendation_email || [];
    laboralExperience.experienceDetails.errors = errors.experience_details || [];
    laboralExperience.achievements.errors = errors.achievements || [];
    this.setState({ laboralExperience });
  };

  handleCleanState = () => {
    this.setState({
      laboralExperience: {
        id: { value: "" },
        companyPosition: { value: "", errors: [] },
        companyName: { value: "", errors: [] },
        startAt: { value: "", errors: [] },
        endAt: { value: "", errors: [] },
        laboralArea: { value: "", label: "", errors: [] },
        hasJobRecomendation: { value: "", errors: [] },
        recomendationName: { value: "", errors: [] },
        recomendationPhoneNumber: { value: "", errors: [] },
        recomendationEmail: { value: "", errors: [] },
        experienceDetails: { value: "", errors: [] },
        achievements: { value: "", errors: [] }
      }
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const data = this.serializeData();

    saveLaboralExperience(data, response => {
      if (response.status === 201) {
        this.props.addLaboralExperience(response.data);
        this.props.toggle();
        this.handleCleanState();
        this.props.show_notification("Guardado", "Guardado exitoso", "success");
      } else if (response.status === 200) {
        this.props.handleUpdateHit(response.data);
        this.props.toggle();
        this.props.show_notification("Guardado", "Guardado exitoso", "success");
      } else {
        this.setErrors(response.data);
        this.props.show_notification("Guardado", "Ocurrio un error, revisa el formulario", "danger");
      }
    });
  };

  render() {
    const { laboralExperience } = this.state;

    return (
      <Form>
        <Row>
          <Col md="4">
            <FormGroup>
              <Label htmlFor="companyPosition" className="input_required">
                Cargo
              </Label>
              <FieldWrapper errors={laboralExperience.companyPosition.errors}>
                <Input
                  className="form-control"
                  id="companyPosition"
                  placeholder="Cargo"
                  type="text"
                  onChange={this.handleChangeLaboralExperience}
                  value={laboralExperience.companyPosition.value}
                />
              </FieldWrapper>
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <Label htmlFor="companyName" className="input_required">
                Empresa
              </Label>
              <FieldWrapper errors={laboralExperience.companyName.errors}>
                <Input
                  className="form-control"
                  id="companyName"
                  placeholder="Empresa"
                  type="text"
                  onChange={this.handleChangeLaboralExperience}
                  value={laboralExperience.companyName.value}
                />
              </FieldWrapper>
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <Label htmlFor="laboralArea" className="input_required">
                Área Laboral
              </Label>
              <FieldWrapper errors={laboralExperience.laboralArea.errors}>
                <LaboralAreasSelect
                  value={laboralExperience.laboralArea}
                  handleChange={this.handleChangeLaboralExperience}
                />
              </FieldWrapper>
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <Label htmlFor="startAt" className="input_required">
                Fecha de Inicio
              </Label>
              <FieldWrapper errors={laboralExperience.startAt.errors}>
                <Input
                  className="form-control"
                  id="startAt"
                  type="date"
                  onChange={this.handleChangeLaboralExperience}
                  value={laboralExperience.startAt.value}
                />
              </FieldWrapper>
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <Label htmlFor="endAt">Fecha de Término</Label>
              <Input
                className="form-control"
                id="endAt"
                type="date"
                onChange={this.handleChangeLaboralExperience}
                value={laboralExperience.endAt.value}
              />
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup>
              <Label htmlFor="experienceDetails">
                En este espacio escribe todas las tareas que has realizado en este trabajo.
              </Label>
              <textarea
                className="form-control"
                id="experienceDetails"
                rows="3"
                onChange={this.handleChangeLaboralExperience}
                value={laboralExperience.experienceDetails.value}
              ></textarea>
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup>
              <Label htmlFor="achievements">
                En este espacio coloca los logros que te gustarían destacar de tu trabajo.
              </Label>
              <textarea
                className="form-control"
                id="achievements"
                rows="3"
                onChange={this.handleChangeLaboralExperience}
                value={laboralExperience.achievements.value}
              ></textarea>
            </FormGroup>
          </Col>
          <Col md="12">
            <div className="form-check">
              <Label
                className="form-check-Label"
                htmlFor="hasJobRecomendation"
                data-toggle="collapse"
                data-target="#recommendationCollapse"
                aria-expanded="false"
                aria-controls="recommendationCollapse"
              >
                <Input
                  className="form-check-Input mr-3"
                  id="hasJobRecomendation"
                  type="checkbox"
                  onChange={e => {
                    this.handleChangeLaboralExperience(e);
                    this.handleCleanRecomendation();
                  }}
                  checked={
                    laboralExperience.hasJobRecomendation.value === "true" ||
                    laboralExperience.hasJobRecomendation.value === true
                  }
                />
                Indica a una persona de este empleo que pueda contarnos más acerca de ti
              </Label>
            </div>
          </Col>
        </Row>
        <Collapse
          isOpen={
            laboralExperience.hasJobRecomendation.value === "true" ||
            laboralExperience.hasJobRecomendation.value === true
          }
        >
          <Row>
            <Col md="12">
              <Card className="bg-light my-2">
                <CardBody>
                  <h3 className="font-weight-bold h6">
                    <i className="far fa-address-book mr-2"></i>
                    Recomendación de este empleo
                  </h3>
                  <Row>
                    <Col lg="4" sm="6">
                      <FormGroup>
                        <Label htmlFor="recomendationName" className="input_required">
                          Nombre
                        </Label>
                        <FieldWrapper errors={laboralExperience.recomendationName.errors}>
                          <Input
                            className="form-control"
                            id="recomendationName"
                            placeholder="Nombre"
                            type="text"
                            onChange={this.handleChangeLaboralExperience}
                            value={laboralExperience.recomendationName.value}
                          />
                        </FieldWrapper>
                      </FormGroup>
                    </Col>
                    <Col lg="4" sm="6">
                      <FormGroup>
                        <Label htmlFor="recomendationPhoneNumber" className="input_required">
                          Teléfono
                        </Label>
                        <FieldWrapper errors={laboralExperience.recomendationPhoneNumber.errors}>
                          <Input
                            className="form-control"
                            id="recomendationPhoneNumber"
                            placeholder="Teléfono"
                            type="text"
                            onChange={this.handleChangeLaboralExperience}
                            value={laboralExperience.recomendationPhoneNumber.value}
                          />
                        </FieldWrapper>
                      </FormGroup>
                    </Col>
                    <Col lg="4" sm="6">
                      <FormGroup>
                        <Label htmlFor="recomendationEmail" className="input_required">
                          Email
                        </Label>
                        <FieldWrapper errors={laboralExperience.recomendationEmail.errors}>
                          <Input
                            className="form-control"
                            id="recomendationEmail"
                            placeholder="Email"
                            type="text"
                            onChange={this.handleChangeLaboralExperience}
                            value={laboralExperience.recomendationEmail.value}
                          />
                        </FieldWrapper>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Collapse>
        <Row>
          <Col md="12" className="text-right">
            <Button outline color="dark" className="my-4 mr-2" onClick={this.props.toggle}>
              Descartar
            </Button>
            <Button color="primary" className="my-4" onClick={this.handleSubmit}>
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default LaboralExperienceForm;
