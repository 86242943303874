import React from "react";
import { Row, Col, Card, CardBody, Table } from "reactstrap";
import _ from "lodash";
import CompanyPurchasesHit from "./Hit";

const CompanyPurchasesHits = ({ hits }) => {
  return (
    <Row>
      <Col>
        <Card>
          <CardBody className="material-shadow">
            <Table responsive hover size="sm">
              <thead>
                <tr>
                  <th>N° Órden</th>
                  <th>Empresa</th>
                  <th>Comprado Por</th>
                  <th>Producto</th>
                  <th>Monto</th>
                  <th>Método</th>
                  <th>Fecha de Pago</th>
                  <th>Estado</th>
                </tr>
              </thead>
              <tbody>
                {_.map(hits, hit => (
                  <CompanyPurchasesHit key={hit.id} hit={hit} />
                ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default CompanyPurchasesHits;
