import React, { Fragment, useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Select from "react-select";
import _ from "lodash";
import { getAllApplicants, sendUpdateRequestToApplicant } from "./Axios";
import FieldWrapper from "../commons/FieldWrapper";

const UpdateInfoModal = ({
  buttonSize,
  applicant,
  showNotification,
  courseId,
  _applicantsSelected
}) => {
  const [modal, setModal] = useState(false);
  const [applicants, setApplicants] = useState([]);
  const [applicantsSelected, setApplicantsSelected] = useState(
    _applicantsSelected
  );
  const [error, setError] = useState(false);

  const toggle = () => setModal(!modal);

  useEffect(() => {
    const getApplicants = () => {
      const newApplicants = applicants;
      getAllApplicants(courseId, response => {
        _.map(response.data, applicant => {
          newApplicants.push({
            value: applicant.id,
            label: applicant.full_name
          });
        });
      });
      setApplicants(newApplicants);
    };

    if (!applicant) getApplicants();
  }, []);

  useEffect(() => {
    if (modal) {
      setApplicantsSelected(_applicantsSelected);
    } else {
      setApplicantsSelected([]);
      setError(false);
    }
  }, [modal]);

  const handleChangeApplicantsSelected = newValue => {
    if (newValue) {
      setApplicantsSelected(newValue);
      setError(false);
    } else {
      setApplicantsSelected([]);
    }
  };

  const serializeData = () => {
    let formData = new FormData();
    if (applicant) {
      formData.append("applicant_ids[]", applicant.value);
    } else {
      _.map(applicantsSelected, applicant => {
        formData.append("applicant_ids[]", applicant.value);
      });
    }

    return formData;
  };

  const handleSubmit = () => {
    if (!applicant && applicantsSelected.length === 0) {
      setError(true);
      return;
    }

    setError(false);
    const data = serializeData();
    sendUpdateRequestToApplicant(data, response => {
      if (response.status === 200) {
        showNotification("Genial", "Solicitud enviada con éxito", "success");
        toggle();
      }
    });
  };
  return (
    <Fragment>
      <Button color="primary" size={buttonSize} outline onClick={toggle}>
        Actualización de Datos
      </Button>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>
          Solicitud de actualización de datos
        </ModalHeader>
        <ModalBody>
          {applicant ? (
            <p>
              Candidato(a): <strong>{applicant.label}</strong>
            </p>
          ) : (
            <FieldWrapper errors={error ? ["Debe seleccionar candidatos"] : []}>
              <Select
                isMulti
                placeholder="Seleccione Candidatos"
                options={applicants}
                onChange={handleChangeApplicantsSelected}
                value={applicantsSelected}
              />
            </FieldWrapper>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleSubmit}>
            Enviar
          </Button>{" "}
          <Button color="danger" onClick={toggle}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default UpdateInfoModal;
