import React, { Fragment, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody, Row, Col } from "reactstrap";
import { BeatLoader } from "react-spinners";

const ModalAccount = ({product}) => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  return (
    <Fragment>
      <Button
        color="primary"
        onClick={toggle}
        size="sm"
        style={{ color: "white" }}
        className="font-weight-bold"
      >
        Comprar
      </Button>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle} className="bg-primary text-white">
          Comprar Plan
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <h3 className="font-weight-bold fs-5">¿Ya tienes cuenta empresa?</h3>
                  <a
                    className="btn btn-primary btn-sm px-4 font-weight-bold text-wrap d-inline"
                    href={`/purchases/new?product_id=${product.id}`}
                  >
                    Comprar
                  </a>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card>
                <CardBody>
                  <h3 className="font-weight-bold fs-5">¿No tienes cuenta empresa?</h3>
                  <a
                    className="btn btn-outline-primary btn-sm px-4 font-weight-bold text-wrap"
                    href="/empresas#registro"
                  >
                    Crear Cuenta
                  </a>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default ModalAccount;
