import React, { Fragment, useEffect, useRef } from "react";
import { Card, CardBody, Table, Row, Col, Button } from "reactstrap";
import NumberFormat from "react-number-format";
import Flow from "./Flow";
import Notifications from "../../commons/Notifications";

const CompanyPurchasesShow = ({ companyPurchase }) => {
  const notificationsRef = useRef();

  useEffect(() => {
    if (companyPurchase.aasmState === "paid_out") {
      notificationsRef.current.addNotifications(
        "Genial",
        "Tu pago se ha realizado exitosamente!",
        "success"
      );
    } else if (companyPurchase.aasmState === "canceled") {
      notificationsRef.current.addNotifications(
        "Ops",
        "Tu pago ha sido cancelado o no se pudo efectuar!",
        "danger"
      );
    }
  }, []);

  return (
    <Fragment>
      <Notifications ref={notificationsRef} />
      <h4 className="mt-2 mb-4 text-primary">Tu Compra</h4>
      <hr />
      <Row>
        <Col md="12">
          <Card className="material-shadow">
            <CardBody>
              <Table>
                <thead>
                  <tr>
                    <th>Producto</th>
                    <th>Cantidad</th>
                    <th className="text-center">Precio</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{companyPurchase.product.name}</td>
                    <td>{companyPurchase.product.quantity || "ilimitado"}</td>
                    <td className="text-right">
                      <NumberFormat
                        value={companyPurchase.netPrice}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$ "}
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale="0"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3"></td>
                  </tr>
                </tbody>
              </Table>
              <Row>
                <Col md={{ size: 4, offset: 8 }}>
                  <Table>
                    <tbody>
                      <tr>
                        <th>NETO</th>
                        <td className="text-right">
                          <NumberFormat
                            value={companyPurchase.netPrice}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale="0"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>I.V.A</th>
                        <td className="text-right">
                          <NumberFormat
                            value={companyPurchase.tax}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale="0"
                          />
                        </td>
                      </tr>
                      <tr className="text-primary">
                        <th>TOTAL</th>
                        <td className="text-right">
                          <NumberFormat
                            value={companyPurchase.totalPrice}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale="0"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </CardBody>
          </Card>
          {(companyPurchase.aasmState === "pending_payment" ||
            companyPurchase.aasmState === "canceled") && <Flow companyPurchase={companyPurchase} />}
          {companyPurchase.aasmState === "paid_out" && (
            <Row className="text-center mt-5">
              <Col md="12">
                <Button color="primary" href="/company/purchases/my_purchases">
                  Ir a mis compras
                </Button>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Fragment>
  );
};

export default CompanyPurchasesShow;
