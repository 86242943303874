import React, { Fragment, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import { updatePassword } from "./Axios";

const UpdatePasswordModal = ({ buttonSize, applicant, showNotification }) => {
  const [modal, setModal] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setModal(!modal)
    setPassword("")
    setPasswordConfirmation("")
  }

  const handleSubmit = () => {
    if (password.trim() === "" || passwordConfirmation.trim() === "") {
      setError("Todos los campos son obligatorios");
      return;
    } else if (password.length < 6) {
      setError("La contraseña debe tener un mínimo de 6 caracteres");
      return;
    } else if (password !== passwordConfirmation) {
      setError("Las contraseñas no coinciden");
      return;
    }

    setError(null);
    setLoading(true);

    let formData = new FormData();
    formData.append("user[password]", password);

    updatePassword(applicant.id, formData, response => {
      if (response.status === 200) {
        showNotification(
          "Genial",
          "Contraseña actualizada exitosamente",
          "success"
        );
        toggle();
      } else {
        showNotification("Ops", "Hubo un error", "danger");
      }
      setLoading(false);
    });
  };

  return (
    <Fragment>
      <Button color="info" size={buttonSize} outline onClick={toggle}>
        Actualizar Contraseña
      </Button>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle} className="bg-info text-white">
          Actualizar Contraseña
        </ModalHeader>
        <ModalBody>
          {error ? (
            <p className="alert alert-danger text-center font-weight-bold">
              {error}
            </p>
          ) : null}
          <FormGroup>
            <Label for="password">Nueva Contraseña</Label>
            <Input
              type="password"
              name="password"
              id="password"
              placeholder="Nueva Contraseña"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="passwordConfirmation">Confirmar Contraseña</Label>
            <Input
              type="password"
              name="passwordConfirmation"
              id="passwordConfirmation"
              placeholder="Confirmar Contraseña"
              value={passwordConfirmation}
              onChange={e => setPasswordConfirmation(e.target.value)}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleSubmit}>
            {loading ? "Actualizando" : "Actualizar"}
            {loading ? <i className="fas fa-spinner fa-pulse ml-2"></i> : null}
          </Button>{" "}
          <Button color="danger" onClick={toggle}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default UpdatePasswordModal;
