import React, { useState, useEffect } from "react";
import Select from "react-select";
import { getEducationCareers } from "./Axios";

const EducationCareersSelect = ({ handleChangePersonalEducation, value }) => {
  const [educationCareers, setEducationCareers] = useState([]);

  const handleChange = inputValue => {
    handleChangePersonalEducation(inputValue, "educationCareer");
  };

  useEffect(() => {
    const newEducationCareers = educationCareers;
    getEducationCareers(response => {
      response.data.map(career => {
        newEducationCareers.push({ value: career.id, label: career.name });
      });
    });
    setEducationCareers(newEducationCareers);
  }, []);

  return (
    <Select
      inputId="educationCareer"
      onChange={handleChange}
      options={educationCareers}
      placeholder="Seleccione una opción"
      value={value.value ? value : null}
    />
  );
};

export default EducationCareersSelect;
