import React, { Component } from "react";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import { BeatLoader } from "react-spinners";
import ReCAPTCHA from "react-google-recaptcha";
import FieldWrapper from "../../commons/FieldWrapper";
import { saveCompanyRegisterRequest } from "./Axios";
import Notifications from "../../commons/Notifications";
import TermsConditionsModal from "./TermsConditionsModal";

const initialState = {
  error: false,
  loading: false,
  recaptcha: {
    isChecked: false,
    value: "",
    errors: [],
  },
  contact: {
    firstName: "",
    lastName: "",
    email: "",
    emailErrors: [],
    phoneNumber: "",
    companyPosition: "",
    companyName: "",
    companyNameErrors: [],
    message: "",
    termsConditions: false,
    termsConditionsErrors: [],
  },
};

class CompanyContactForm extends Component {
  state = { ...initialState };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({
      ...this.state,
      loading: true,
    });
    const { recaptcha, contact } = this.state;
    const { firstName, lastName, email, phoneNumber, companyPosition, companyName, message, termsConditions } = contact;

    if (
      firstName.trim() === "" ||
      lastName.trim() === "" ||
      email.trim() === "" ||
      phoneNumber.trim() === "" ||
      companyPosition.trim() === "" ||
      companyName.trim() === "" ||
      message.trim() === ""
    ) {
      this.setState({ error: true, loading: false });
      return;
    }

    if (!termsConditions) {
      contact.termsConditionsErrors.push("Debe aceptar los Términos y Condiciones.");
      this.setState({ ...this.state, contact, error: true, loading: false });
      return;
    }

    if (recaptcha.isChecked === false) {
      recaptcha.errors.push("Debe marcar esta opción");
      this.setState({ recaptcha, loading: false });
      return;
    }

    var formData = new FormData();
    formData.append("company_request[first_name]", firstName);
    formData.append("company_request[last_name]", lastName);
    formData.append("company_request[email]", email);
    formData.append("company_request[phone_number]", phoneNumber);
    formData.append("company_request[company_position]", companyPosition);
    formData.append("company_request[company_name]", companyName);
    formData.append("company_request[message]", message);
    formData.append("company_request[terms_and_conditions]", true);

    saveCompanyRegisterRequest(formData, response => {
      if (response.status === 200) {
        this.setState({ ...initialState });
        this.refs.recaptchaRef.reset();
        this.refs.notifications.addNotifications("Genial", response.data.message, "success");
        window.location.replace("/planes");
      } else if (response.status === 422 && response.data.company_employee) {
        this.refs.notifications.addNotifications("Ops", response.data.company_employee[0], "danger");
      } else if (response.status === 422) {
        const errors = response.data;
        this.setErrors(response.data);
      }
      this.setState({
        ...this.state,
        loading: false,
      });
    });
  };

  setErrors = errors => {
    const { contact } = this.state;
    contact.emailErrors = errors.email;
    this.setState({ contact });
  };

  handleChange = e => {
    this.setState({
      contact: {
        ...this.state.contact,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleChangeTerms = e => {
    this.setState({
      contact: {
        ...this.state.contact,
        termsConditions: e.target.checked,
      },
    });
  };

  handleChangeRecaptcha = value => {
    this.setState({
      ...this.state.recaptcha,
      recaptcha: {
        isChecked: true,
        value: value,
        errors: [],
      },
    });
  };

  render() {
    const { recaptcha, contact } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <Notifications ref="notifications" />
        {this.state.error && (
          <Row>
            <Col>
              <div className="alert alert-danger">
                <p>Todos los campos son obligatorios!</p>
              </div>
            </Col>
          </Row>
        )}
        <Row>
          <Col md="6">
            <FormGroup>
              <Label
                htmlFor="firstName"
                className="input_required"
              >
                Nombres
              </Label>
              <FieldWrapper>
                <Input
                  id="firstName"
                  name="firstName"
                  type="text"
                  onChange={this.handleChange}
                  value={contact.firstName}
                />
              </FieldWrapper>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label
                htmlFor="lastName"
                className="input_required"
              >
                Apellidos
              </Label>
              <FieldWrapper>
                <Input
                  id="lastName"
                  name="lastName"
                  type="text"
                  onChange={this.handleChange}
                  value={contact.lastName}
                />
              </FieldWrapper>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label
                htmlFor="email"
                className="input_required"
              >
                Email Personal
              </Label>
              <FieldWrapper errors={contact.emailErrors}>
                <Input
                  id="email"
                  name="email"
                  type="text"
                  onChange={this.handleChange}
                  value={contact.email}
                />
              </FieldWrapper>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label
                htmlFor="phoneNumber"
                className="input_required"
              >
                Teléfono
              </Label>
              <FieldWrapper>
                <Input
                  id="phoneNumber"
                  name="phoneNumber"
                  type="text"
                  onChange={this.handleChange}
                  value={contact.phoneNumber}
                />
              </FieldWrapper>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label
                htmlFor="companyPosition"
                className="input_required"
              >
                Cargo
              </Label>
              <FieldWrapper>
                <Input
                  id="companyPosition"
                  name="companyPosition"
                  type="text"
                  onChange={this.handleChange}
                  value={contact.companyPosition}
                />
              </FieldWrapper>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label
                htmlFor="companyName"
                className="input_required"
              >
                Empresa
              </Label>
              <FieldWrapper>
                <Input
                  id="companyName"
                  name="companyName"
                  type="text"
                  onChange={this.handleChange}
                  value={contact.companyName}
                />
              </FieldWrapper>
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup>
              <Label
                htmlFor="message"
                className="input_required"
              >
                Mensaje
              </Label>
              <FieldWrapper>
                <Input
                  id="message"
                  name="message"
                  type="textarea"
                  onChange={this.handleChange}
                  value={contact.message}
                />
              </FieldWrapper>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <FormGroup className="ml-4">
              <FieldWrapper errors={contact.termsConditionsErrors}>
                <Input
                  type="checkbox"
                  checked={contact.termsConditions}
                  onChange={this.handleChangeTerms}
                />
                <TermsConditionsModal />
              </FieldWrapper>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <FieldWrapper errors={recaptcha.errors}>
              <ReCAPTCHA
                ref="recaptchaRef"
                sitekey="6LeqZboUAAAAAEu2ttbKlzgDQVDdNXLXU3OLBXZo"
                onChange={this.handleChangeRecaptcha}
              />
            </FieldWrapper>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={{ size: 4, offset: 4 }}>
            {this.state.loading ? (
              <div className="sweet-loading text-center">
                <BeatLoader
                  color={"#123abc"}
                  loading={this.state.loading}
                />
              </div>
            ) : (
              <Input
                type="submit"
                value="Enviar"
                className="btn btn-primary"
                id="companyFormButton"
                disabled={!this.state.recaptcha.isChecked}
              />
            )}
          </Col>
        </Row>
      </form>
    );
  }
}

export default CompanyContactForm;
