import React, { Fragment } from "react";
import _ from "lodash";
import PersonalDocumentForm from "./Form";

const PersonalDocumentsIndex = ({ documentDescriptors, personalDocuments }) => {
  return (
    <Fragment>
      {_.map(documentDescriptors, descriptor => (
        <PersonalDocumentForm
          key={descriptor.id}
          descriptor={descriptor}
          personalDocuments={personalDocuments}
          canDelete
        />
      ))}
    </Fragment>
  );
};

export default PersonalDocumentsIndex;
