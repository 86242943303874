import React, { Fragment, useState, useEffect } from "react";
import { Table } from "reactstrap";
import _ from "lodash";
import MyPostulationHit from "./Hit";

const MyPostulationsHits = ({ hits }) => {
  const [myPostulations, setMyPostulations] = useState(_.map(hits, hit => hit._source));

  useEffect(() => {
    const newPostulations = _.map(hits, hit => hit._source);
    setMyPostulations(newPostulations);
  }, [hits]);

  return (
    <Fragment>
      <Table hover>
        <thead>
          <tr>
            <th style={{ borderTop: "inherit" }}>Título</th>
            <th style={{ borderTop: "inherit" }}>Empresa</th>
            <th style={{ borderTop: "inherit" }}>Fecha de Postulación</th>
          </tr>
        </thead>
        <tbody>
          {_.map(myPostulations, postulation => (
            <MyPostulationHit key={postulation.id} postulation={postulation} />
          ))}
        </tbody>
      </Table>
    </Fragment>
  );
};

export default MyPostulationsHits;
