import React, { Component } from "react";
import { Row, Col, Nav, NavItem, NavLink, Card, CardBody, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import PersonalInformationForm from "./personal_information/Form";
import EducationsIndex from "./education/Index";
import LaboralExperience from "./laboral_experience/Index";
import PersonalLaboralRequirementForm from "./personal_laboral_requirement/Form";
import Notifications from "../commons/Notifications";
import Navigation from "../commons/Navigation";
import PersonalDocumentsIndex from "./document/Index";

const availableTabs = [
  "personalinfo",
  "academicinfo",
  "laboralinfo",
  "requirementsinfo",
  "documentsinfo"
];

class UserProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "personalinfo"
    };

    this.showNotification = this.showNotification.bind(this);
  }

  toggleTab = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  showNotification = (title, message, type) => {
    this.refs.notifications.addNotifications(title, message, type);
  };

  render() {
    const { documentDescriptors } = this.props;

    return (
      <div>
        <Notifications ref="notifications" />
        <Row id="profile-panel-forms">
          <Col md="12">
            <Nav pills className="perfil-tabs-navigation text-center steps mb-3" role="tablist">
              <NavItem role="presentation">
                <NavLink
                  className={
                    "d-flex align-items-center " +
                    classnames({
                      active: this.state.activeTab === "personalinfo"
                    })
                  }
                  onClick={() => {
                    this.toggleTab("personalinfo");
                  }}
                  href="#personalinfo"
                  aria-controls="personalinfo"
                  role="tab"
                  data-toggle="tab"
                  id="personalinfo-tab"
                  aria-label="Información Personal"
                >
                  <span className="step-content-link">
                    <i className="fa fa-user" aria-hidden="true"></i>
                    <span className="d-none d-sm-inline-block ml-2">Información Personal</span>
                    <div className="input_required"></div>
                  </span>
                </NavLink>
              </NavItem>
              <NavItem role="presentation">
                <NavLink
                  className={
                    "d-flex align-items-center " +
                    classnames({
                      active: this.state.activeTab === "academicinfo"
                    })
                  }
                  onClick={() => {
                    this.toggleTab("academicinfo");
                  }}
                  href="#academicinfo"
                  aria-controls="academicinfo"
                  role="tab"
                  data-toggle="tab"
                  id="academicinfo-tab"
                  aria-label="Estudios"
                >
                  <span className="step-content-link">
                    <i className="fa fa-graduation-cap" aria-hidden="true"></i>
                    <span className="d-none d-sm-inline-block ml-2">Estudios</span>
                    <div className="input_required"></div>
                  </span>
                </NavLink>
              </NavItem>
              <NavItem role="presentation">
                <NavLink
                  className={
                    "d-flex align-items-center " +
                    classnames({
                      active: this.state.activeTab === "laboralinfo"
                    })
                  }
                  onClick={() => {
                    this.toggleTab("laboralinfo");
                  }}
                  href="#laboralinfo"
                  aria-controls="laboralinfo"
                  role="tab"
                  data-toggle="tab"
                  id="laboralinfo-tab"
                  aria-label="Experiencia Laboral"
                >
                  <span className="step-content-link">
                    <i className="fa fa-briefcase" aria-hidden="true"></i>
                    <span className="d-none d-sm-inline-block ml-2">Experiencia</span>
                    <div className="input_required"></div>
                  </span>
                </NavLink>
              </NavItem>
              <NavItem role="presentation">
                <NavLink
                  className={
                    "d-flex align-items-center " +
                    classnames({
                      active: this.state.activeTab === "requirementsinfo"
                    })
                  }
                  onClick={() => {
                    this.toggleTab("requirementsinfo");
                  }}
                  href="#requirementsinfo"
                  aria-controls="requirementsinfo"
                  role="tab"
                  data-toggle="tab"
                  id="requirementsinfo-tab"
                  aria-label="Expectativas Laborales"
                >
                  <span className="step-content-link">
                    <i className="fas fa-info-circle" aria-hidden="true"></i>
                    <span className="d-none d-sm-inline-block ml-2">Expectativas Laborales</span>
                    <div className="input_match"></div>
                  </span>
                </NavLink>
              </NavItem>
              <NavItem role="presentation">
                <NavLink
                  className={
                    "d-flex align-items-center " +
                    classnames({
                      active: this.state.activeTab === "documentsinfo"
                    })
                  }
                  onClick={() => {
                    this.toggleTab("documentsinfo");
                  }}
                  href="#documentsinfo"
                  aria-controls="documentsinfo"
                  role="tab"
                  data-toggle="tab"
                  id="documentsinfo-tab"
                  aria-label="Documentos Personales"
                >
                  <span className="step-content-link">
                    <i className="fas mr-2 fa-folder-open" aria-hidden="true"></i>
                    <span className="d-none d-sm-inline-block">Documentos Personales</span>
                  </span>
                </NavLink>
              </NavItem>
              {/*
                <NavItem role="presentation">
                  <NavLink className={"d-flex align-items-center " + classnames({ active: this.state.activeTab === 'skillsinfo' })}
                           onClick={() => { this.toggle('skillsinfo'); }}
                           href="#skillsinfo"
                           aria-controls="skillsinfo"
                           role="tab" data-toggle="tab"
                           id="skillsinfo-tab"
                  >
                    <span className="step-content-link"><i className="far mr-2 fa-lightbulb" aria-hidden="true"></i>
                      <span className="d-none d-sm-inline-block">Habilidades</span>
                    </span>
                  </NavLink>
                </NavItem>
              */}
            </Nav>
          </Col>
        </Row>

        <Card className="mb-5">
          <Navigation
            currentTab={this.state.activeTab}
            availableTabs={availableTabs}
            toggleTab={this.toggleTab}
          />
          <CardBody className="pt-3">
            <TabContent activeTab={this.state.activeTab} id="pills-tabContent">
              <TabPane id="personalinfo" tabId="personalinfo" aria-labelledby="personalinfo-tab">
                <h2 className="tab-pane-title text-muted font-weight-bold">
                  <i className="fa mr-2 fa-user" aria-hidden="true"></i>
                  <small className="">Información Personal</small>
                  <div className="input_required"></div>
                  <hr />
                </h2>
                <PersonalInformationForm
                  current_user={this.props.current_user}
                  show_notification={this.showNotification}
                  toggleTab={this.toggleTab}
                />
              </TabPane>
              <TabPane id="academicinfo" tabId="academicinfo" aria-labelledby="academicinfo-tab">
                <h2 className="tab-pane-title text-muted font-weight-bold">
                  <i className="fa mr-2 fa-graduation-cap" aria-hidden="true"></i>
                  <small className="">Estudios</small>
                  <hr />
                </h2>
                <EducationsIndex
                  show_notification={this.showNotification}
                  toggleTab={this.toggleTab}
                />
              </TabPane>
              <TabPane id="laboralinfo" tabId="laboralinfo" aria-labelledby="laboralinfo-tab">
                <h2 className="tab-pane-title text-muted font-weight-bold">
                  <i className="fa mr-2 fa-briefcase" aria-hidden="true"></i>
                  <small className="">Experiencia Laboral</small>
                  <hr />
                </h2>
                <LaboralExperience
                  show_notification={this.showNotification}
                  toggleTab={this.toggleTab}
                  isMyFirstJob={this.props.current_user.professional_information.is_my_first_job}
                />
              </TabPane>
              <TabPane
                id="requirementsinfo"
                tabId="requirementsinfo"
                aria-labelledby="requirementsinfo-tab"
              >
                <h2 className="tab-pane-title text-muted font-weight-bold">
                  <i className="fas mr-2 fa-info-circle" aria-hidden="true"></i>
                  <small className="">Expectativas Laborales</small>
                  <div className="input_match"></div>
                  <hr />
                </h2>
                <PersonalLaboralRequirementForm
                  personal_laboral_requirement={
                    this.props.current_user.personal_laboral_requirement
                  }
                  show_notification={this.showNotification}
                  toggleTab={this.toggleTab}
                />
              </TabPane>
              <TabPane id="documentsinfo" tabId="documentsinfo" aria-labelledby="documentsinfo-tab">
                <h2 className="tab-pane-title text-muted font-weight-bold">
                  <i className="fas mr-2 fa-folder-open" aria-hidden="true"></i>
                  <small className="">Documentos Personales</small>
                  <div className="input_match"></div>
                  <hr />
                </h2>
                <PersonalDocumentsIndex
                  documentDescriptors={documentDescriptors}
                  personalDocuments={this.props.current_user.personal_documents}
                  show_notification={this.showNotification}
                  toggleTab={this.toggleTab}
                />
              </TabPane>
              {/*
                <TabPane id="skillsinfo" tabId="skillsinfo" aria-labelledby="skillsinfo-tab">
                  <h2 className="tab-pane-title text-muted font-weight-bold">
                    <i className="far mr-2 fa-lightbulb" aria-hidden="true"></i>
                    <small className="">Habilidades</small>
                    <div className="input_required"></div>
                    <hr/>
                  </h2>
                  <SkillsForm />
                </TabPane>
              */}
            </TabContent>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default UserProfile;
