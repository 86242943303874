import React, { Component } from "react";
import { Card, CardHeader, CardBody } from "reactstrap";

const CourseStages = ({ postulationsCount }) => {
  return (
    <div className="flex-fill overflow-auto d-flex justify-content-between align-items-center">
      <div className="stages-progress my-3 d-flex justify-content-between align-items-center flex-nowrap">
        <Card className="stage-card d-inline-block text-center">
          <CardHeader className="py-2 px-1">
            <i className="fas fa-users fa-lg d-block mb-sm-2"></i>
            <span className="font-weight-bold small d-none d-sm-block text-truncate">
              Postulantes
            </span>
          </CardHeader>
          <CardBody className="py-1 px-1">
            <span className="h4 font-weight-bold mb-0">{postulationsCount}</span>
          </CardBody>
        </Card>

        <Card className="stage-card d-inline-block text-center inactive">
          <CardHeader className="py-2 px-1">
            <i className="far fa-comments fa-lg d-block mb-sm-2"></i>
            <span className="font-weight-bold small d-none d-sm-block text-truncate">
              Contactados
            </span>
          </CardHeader>
          <CardBody className="py-1 px-1">
            <span className="h4 font-weight-bold mb-0">0</span>
          </CardBody>
        </Card>

        <Card className="stage-card d-inline-block text-center inactive">
          <CardHeader className="py-2 px-1">
            <i className="fas fa-user-check fa-lg d-block mb-sm-2"></i>
            <span className="font-weight-bold small d-none d-sm-block text-truncate">
              Seleccionados
            </span>
          </CardHeader>
          <CardBody className="py-1 px-1">
            <span className="h4 font-weight-bold mb-0">0</span>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default CourseStages;
