import React, { Fragment, useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  FormGroup
} from "reactstrap";
import Select from "react-select";
import _ from "lodash";
import FieldWrapper from "../../commons/FieldWrapper";
import { saveDocumentDescriptors } from "./Axios";

const DocumentDescriptorForm = props => {
  const {
    bgColor,
    buttonText,
    showNotification,
    addDescriptor,
    descriptor,
    updateHit,
    size
  } = props;

  const [modal, setModal] = useState(false);
  const [name, setName] = useState("");
  const [showApplicant, setShowApplicant] = useState(false);
  const [description, setDescription] = useState("");
  const [allowedFileTypes, setAllowedFileTypes] = useState([]);
  const [errors, setErrors] = useState({
    nameErrors: [],
    allowedFileTypesErrors: []
  });

  useEffect(() => {
    if (descriptor) {
      setName(descriptor.name);
      setDescription(descriptor.description);
      setAllowedFileTypes(descriptor.allowed_file_types);
    }
  }, [descriptor]);

  const options = [
    { value: "image/png", label: "png" },
    { value: "image/jpg", label: "jpg" },
    { value: "image/jpeg", label: "jpeg" },
    { value: "application/pdf", label: "pdf" },
    { value: "application/docx", label: "docx" },
    { value: "application/xls", label: "xls" },
    { value: "application/xlsx", label: "xlsx" }
  ];

  const toggle = () => {
    setModal(!modal);
  };

  const saveErrors = errors => {
    const newErrors = errors;
    newErrors.nameErrors = errors.name;
    newErrors.allowedFileTypesErrors = errors.allowed_file_types;
    setErrors(newErrors);
  };

  const handleSubmitDescriptor = () => {
    const formData = new FormData();
    formData.append("document_descriptor[name]", name);
    formData.append("document_descriptor[description]", description);
    formData.append("document_descriptor[show_applicant]", showApplicant);
    _.map(allowedFileTypes, type => {
      formData.append("document_descriptor[allowed_file_types][]", type.value);
    });

    saveDocumentDescriptors(descriptor, formData, response => {
      if (response.status === 201) {
        showNotification(
          "Genial",
          "Información guardada con éxito!",
          "success"
        );
        toggle();
        setName("");
        setDescription("");
        setAllowedFileTypes([]);
        setErrors({
          nameErrors: [],
          allowedFileTypesErrors: []
        });
        addDescriptor(response.data);
      } else if (response.status === 200) {
        showNotification(
          "Genial",
          "Información guardada con éxito!",
          "success"
        );
        toggle();
        updateHit(response.data);
      } else if (response.status === 422) {
        saveErrors(response.data);
        showNotification(
          "Ops",
          "Hubo un errors, revisa el formulario!",
          "danger"
        );
      }
    });
  };

  return (
    <Fragment>
      <Button
        color={bgColor}
        onClick={toggle}
        size={size}
        style={{ color: "white" }}
      >
        {buttonText}
      </Button>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>
          {buttonText} tipo de Documento
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="name" className="input_required">
              Nombre
            </Label>
            <FieldWrapper errors={errors.nameErrors}>
              <Input
                id="name"
                type="text"
                placeholder="Nombre del Documento"
                onChange={e => setName(e.target.value)}
                value={name}
              />
            </FieldWrapper>
          </FormGroup>
          <FormGroup>
            <Label for="description">Descripción</Label>
            <Input
              id="description"
              type="textarea"
              placeholder="Descripción del Documento"
              onChange={e => setDescription(e.target.value)}
              value={description}
            />
          </FormGroup>
          <FormGroup>
            <Label for="allowedTypes" className="input_required">
              Extensiones Permitidas
            </Label>
            <FieldWrapper errors={errors.allowedFileTypesErrors}>
              <Select
                isMulti
                placeholder="Selecciones Opciones"
                options={options}
                onChange={newValue => setAllowedFileTypes(newValue)}
                value={allowedFileTypes}
              />
            </FieldWrapper>
          </FormGroup>
          <div className="form-check">
            <Label className="form-check-Label" htmlFor="firstJob">
              <Input
                className="form-check-Input mr-3"
                id="firstJob"
                type="checkbox"
                onChange={e => setShowApplicant(e.target.checked)}
                checked={showApplicant}
              />
              Solicitar en Perfil del Candidato
            </Label>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleSubmitDescriptor}>
            Guardar
          </Button>{" "}
          <Button color="danger" onClick={toggle}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default DocumentDescriptorForm;
