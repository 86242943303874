import React, { Component } from "react";
import { saveAccessibilityRequirementAnswers } from "./Axios";
import { Row, Col, Form, FormGroup, Label, Input, Button } from "reactstrap";

class AccessibilityRequirements extends Component {
  constructor(props) {
    super(props);

    const { accessibility_answers } = this.props;

    this.state = {
      accessibilityRequirementAnswers: accessibility_answers
    };
  }

  handleCheckChieldElement = event => {
    const { accessibilityRequirementAnswers } = this.state;
    let accessibility_requirements = accessibilityRequirementAnswers;
    accessibility_requirements.forEach(requirement => {
      if (requirement.value.toString() === event.target.value)
        requirement.isChecked = event.target.checked;
    });
    this.setState({ accessibilityRequirementAnswers: accessibility_requirements });
  };

  handleObservationElement = event => {
    const { accessibilityRequirementAnswers } = this.state;
    let accessibility_requirements = accessibilityRequirementAnswers;
    accessibility_requirements.forEach(requirement => {
      if (requirement.id.toString() === event.target.id)
        requirement.observations = event.target.value;
    });
    this.setState({ accessibilityRequirementAnswers: accessibility_requirements });
  };

  handleSubmit = e => {
    const { accessibilityRequirementAnswers } = this.state;
    e.preventDefault();
    saveAccessibilityRequirementAnswers(accessibilityRequirementAnswers, response => {
      if (response.status === 422) {
        this.props.showNotification("Ops", "Ocurrio un error, revisa el formulario", "danger");
      } else {
        this.props.showNotification("Genial", "Información guardada con éxito", "success");
        if (this.props.isCourse) {
          this.props.toggleTab("publicationPreview");
        }
      }
    });
  };

  render() {
    const { accessibilityRequirementAnswers } = this.state;
    return (
      <div>
        <Form>
          <Row>
            {accessibilityRequirementAnswers.map((requirement, index) => {
              return (
                <Col
                  key={"requirement_answer_" + index}
                  lg="3"
                  md="4"
                  sm="6"
                  className="text-center"
                >
                  <FormGroup
                    check
                    className={
                      "accessibility-requirement-item border mb-5 d-flex flex-column justify-content-center align-items-center " +
                      (requirement.isChecked ? "active bg-light" : "")
                    }
                  >
                    <Label
                      check
                      id={requirement.value}
                      className="w-100 flex-fill text-center p-3 d-flex flex-column justify-content-center align-items-center"
                    >
                      <img
                        className="rounded-lg mb-2 d-block p-2 infraestructure-img-form mx-auto"
                        alt=""
                        src={requirement.iconSrc}
                      />
                      <Input
                        type="checkbox"
                        key={requirement.id}
                        onChange={this.handleCheckChieldElement}
                        checked={requirement.isChecked}
                        value={requirement.value}
                      />
                      {requirement.label}
                    </Label>
                    <div
                      className="w-100 px-3 pb-3"
                      style={{ display: requirement.isChecked ? "block" : "none" }}
                    >
                      <Input
                        className=""
                        type="textarea"
                        id={requirement.id}
                        key={requirement.id}
                        onChange={this.handleObservationElement}
                        value={requirement.observations}
                        name="text"
                        aria-labelledby={requirement.id}
                        placeholder="Agregue observaciones"
                      />
                    </div>
                  </FormGroup>
                </Col>
              );
            })}
            {/*<Col lg="12" sm="12" className="text-left">
              <FormGroup className="mb-5">
                <Label for="other_requirements">Otras Consideraciones importantes</Label>
                <Input className=""
                       type="textarea"
                       name="text"
                       id="other_requirements"
                       placeholder="Agregue observaciones" />
              </FormGroup>
            </Col>*/}
          </Row>
          <Row>
            <Col md="12" className="text-right d-flex justify-content-between">
              <Button
                outline
                color="primary"
                className="my-4 mr-auto"
                onClick={() => this.props.toggleTab("functionalrequirements")}
              >
                <i className="fa fa-arrow-left mr-2"></i>
                Anterior
              </Button>
              <Button color="primary" className="my-4" onClick={this.handleSubmit}>
                Guardar
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default AccessibilityRequirements;
