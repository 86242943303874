import axios from "axios";

export const saveAccessibilityRequirementAnswers = (data, callback) => {
  var promise = axios({
    method: "put",
    url: "/api/accessibility_answers/massive_update",
    data: data,
    headers: {
      "X-CSRF-TOKEN": window.SomosEmpleos.token
    }
  });
  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};

