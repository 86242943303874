import WebpackerReact from "webpacker-react";
import "./token";
import UserProfile from "../components/profile/UserProfile";
import FunctionalProfile from "../components/functional_requirements/FunctionalProfile";
import PublicationStepperForm from "../components/publication/StepperForm";
import PublicationShow from "../components/publication/Show";
import MinisitePublicationsList from "../components/publication/MinisitePublicationsList";
import CompanyCentralsIndex from "../components/company/centrals";
import PublicationsIndex from "../components/publication/Index";
import CompanyCentralsProfile from "../components/company/centrals/Profile";
import CompanyCentralsDashboard from "../components/company/centrals/Dashboard";
import CompanyContactForm from "../components/company/centrals/CompanyContactForm";
import CourseShow from "../components/course/Show";
import CoursesIndex from "../components/course/Index";
import ApplicantsIndex from "../components/applicant/Index";
import ApplicantShow from "../components/applicant/Show";
import DocumentDescriptorsIndex from "../components/document/descriptors/Index";
import MyPostulationsIndex from "../components/my_postulations/Index";
import CompanyBrandsForm from "../components/company/brands/Form";
import ProductsIndex from "../components/products/Index";
import ProductsList from "../components/products/ProductsList";
import UploadDocumentsForm from "../components/documentation/requests/UploadDocumentsForm";
import MatchDetail from "../components/applicant/MatchDetail";
import CompanyPurchasesNew from "../components/company/purchases/New";
import CompanyPurchasesShow from "../components/company/purchases/Show";
import MyPurchases from "../components/company/purchases/MyPurchases";
import CompanyPurchasesDashboard from "../components/company/purchases/dashboard/Dashboard";

WebpackerReact.setup({
  UserProfile,
  FunctionalProfile,
  PublicationStepperForm,
  PublicationShow,
  MinisitePublicationsList,
  CompanyCentralsIndex,
  PublicationsIndex,
  CompanyCentralsProfile,
  CompanyCentralsDashboard,
  CompanyContactForm,
  CourseShow,
  CoursesIndex,
  ApplicantsIndex,
  ApplicantShow,
  DocumentDescriptorsIndex,
  MyPostulationsIndex,
  CompanyBrandsForm,
  ProductsIndex,
  ProductsList,
  UploadDocumentsForm,
  MatchDetail,
  CompanyPurchasesNew,
  CompanyPurchasesShow,
  MyPurchases,
  CompanyPurchasesDashboard
});
