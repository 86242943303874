import React from "react";
import { Button } from "reactstrap";

const Prev = ({ handlePrev }) => {
  return (
    <Button aria-label="Anterior" color="primary" id="prev" title="Anterior" onClick={handlePrev}>
      <i className="fa fa-arrow-left"></i>{" "}
      <small className="inline-item d-none d-sm-inline-block">Volver</small>
    </Button>
  );
};

const Next = ({ handleNext }) => {
  return (
    <Button aria-label="Siguiente" color="primary" id="next" title="Siguiente" onClick={handleNext}>
      <small className="inline-item d-none d-sm-inline-block">Continuar</small>{" "}
      <i className="fa fa-arrow-right"></i>
    </Button>
  );
};

const Navigation = ({ currentTab, availableTabs, toggleTab }) => {
  const firstTab = availableTabs[0];
  const lastTab = availableTabs[availableTabs.length - 1];

  const handlePrev = () => {
    const currentIndex = availableTabs.indexOf(currentTab);
    const prevTab = availableTabs[currentIndex - 1];
    toggleTab(prevTab);
  };

  const handleNext = () => {
    const currentIndex = availableTabs.indexOf(currentTab);
    const nextTab = availableTabs[currentIndex + 1];
    toggleTab(nextTab);
  };

  return (
    <div className="required-navigation">
      {currentTab !== firstTab && <Prev handlePrev={handlePrev} />}
      {currentTab !== lastTab && <Next handleNext={handleNext} />}
    </div>
  );
};

export default Navigation;
