import React, { Component } from "react";
import { Row, Col, Form, FormGroup, Label, Input, Button, Collapse } from "reactstrap";
import { format } from "rut.js";
import TerritoryNestedSelects from "../../territory/nested/Selects";
import { saveCompanyEmployee } from "./Axios";
import FieldWrapper from "../../commons/FieldWrapper";

class CompanyEmployeesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      companyEmployee: {
        id: { value: "" },
        userId: { value: "" },
        personalInformationId: { value: "" },
        firstName: { value: "", errors: [] },
        lastName: { value: "", errors: [] },
        identificationNumber: { value: "", errors: [] },
        email: { value: "", errors: [] },
        lastEmail: { value: "", errors: [] },
        country: { value: "", label: "", errors: [] },
        region: { value: "", label: "", errors: [] },
        commune: { value: "", label: "", errors: [] },
        phoneNumber: { value: "", errors: [] },
        currentPosition: { value: "", errors: [] },
        password: { value: "", errors: [] }
      }
    };
  }

  toggle = () => {
    this.setState(state => ({ collapse: !state.collapse }));
  };

  componentDidUpdate(prevProps, prevState) {
    const { companyEmployee } = this.props;

    if (companyEmployee && this.state.companyEmployee.id.value !== companyEmployee.id) {
      this.setState({
        ...this.state,
        companyEmployee: {
          id: { value: companyEmployee.id },
          userId: { value: companyEmployee.user.id },
          personalInformationId: { value: companyEmployee.user.personalInformation.id },
          firstName: { value: companyEmployee.user.personalInformation.firstName, errors: [] },
          lastName: { value: companyEmployee.user.personalInformation.lastName, errors: [] },
          identificationNumber: {
            value: companyEmployee.user.identificationDocumentNumber,
            errors: []
          },
          email: { value: companyEmployee.user.email, errors: [] },
          lastEmail: { value: companyEmployee.user.personalInformation.lastEmail, errors: [] },
          country: {
            value: companyEmployee.user.personalInformation.country.id,
            label: companyEmployee.user.personalInformation.country.name,
            errors: []
          },
          region: {
            value: companyEmployee.user.personalInformation.region.id,
            label: companyEmployee.user.personalInformation.region.name,
            errors: []
          },
          commune: {
            value: companyEmployee.user.personalInformation.commune.id,
            label: companyEmployee.user.personalInformation.commune.name,
            errors: []
          },
          phoneNumber: { value: companyEmployee.user.personalInformation.phoneNumber, errors: [] },
          currentPosition: { value: companyEmployee.currentPosition, errors: [] },
          password: { value: "", errors: [] }
        }
      });
    }
  }

  handleChangeCompanyEmployee = (e, fieldName = null) => {
    const { companyEmployee } = this.state;
    if (fieldName) {
      companyEmployee[fieldName]["value"] = e.value;
      companyEmployee[fieldName]["label"] = e.label;
      companyEmployee[fieldName]["errors"] = [];
    } else if (e.target.name === "identificationNumber") {
      companyEmployee[e.target.name]["value"] = format(e.target.value);
      companyEmployee[e.target.name]["errors"] = [];
    } else {
      companyEmployee[e.target.name]["value"] = e.target.value;
      companyEmployee[e.target.name]["errors"] = [];
    }
    this.setState({ companyEmployee });
  };

  serializeData = () => {
    const { companyEmployee } = this.state;
    var formData = new FormData();

    if (companyEmployee.id.value) {
      formData.append("company_employee[id]", companyEmployee.id.value);
    }
    if (companyEmployee.userId.value) {
      formData.append("company_employee[user_attributes][id]", companyEmployee.userId.value);
    }
    if (companyEmployee.personalInformationId.value) {
      formData.append(
        "company_employee[user_attributes][personal_information_attributes][id]",
        companyEmployee.personalInformationId.value
      );
    }

    formData.append("company_employee[current_position]", companyEmployee.currentPosition.value);
    formData.append("company_employee[user_attributes][email]", companyEmployee.email.value);
    if (companyEmployee.password.value !== "") {
      formData.append(
        "company_employee[user_attributes][password]",
        companyEmployee.password.value
      );
    }
    formData.append(
      "company_employee[user_attributes][identification_document_number]",
      companyEmployee.identificationNumber.value
    );
    formData.append("company_employee[user_attributes][terms_and_conditions]", true);
    formData.append(
      "company_employee[user_attributes][personal_information_attributes][who_completed_form]",
      "me"
    );
    formData.append(
      "company_employee[user_attributes][personal_information_attributes][first_name]",
      companyEmployee.firstName.value
    );
    formData.append(
      "company_employee[user_attributes][personal_information_attributes][last_name]",
      companyEmployee.lastName.value
    );
    formData.append(
      "company_employee[user_attributes][personal_information_attributes][last_email]",
      companyEmployee.lastEmail.value
    );
    formData.append(
      "company_employee[user_attributes][personal_information_attributes][cellphone]",
      companyEmployee.phoneNumber.value
    );
    formData.append(
      "company_employee[user_attributes][personal_information_attributes][territory_commune_id]",
      companyEmployee.commune.value
    );

    return formData;
  };

  setErrors = errors => {
    const { companyEmployee } = this.state;
    companyEmployee.firstName.errors = errors["user.personal_information.first_name"] || [];
    companyEmployee.lastName.errors = errors["user.personal_information.last_name"] || [];
    companyEmployee.identificationNumber.errors =
      errors["user.identification_document_number"] || [];
    companyEmployee.email.errors = errors["user.email"] || [];
    companyEmployee.lastEmail.errors = errors["user.personal_information.last_email"] || [];
    companyEmployee.phoneNumber.errors = errors["user.personal_information.cellphone"] || [];
    companyEmployee.country.errors = errors["user.personal_information.territory_commune"] || [];
    companyEmployee.region.errors = errors["user.personal_information.territory_commune"] || [];
    companyEmployee.commune.errors = errors["user.personal_information.territory_commune"] || [];
    companyEmployee.currentPosition.errors = errors.current_position || [];
    companyEmployee.password.errors = errors["user.password"] || [];
    this.setState({
      ...this.state,
      companyEmployee
    });
  };

  handleCleanState = () => {
    this.setState({
      companyEmployee: {
        id: { value: "" },
        userId: { value: "" },
        personalInformationId: { value: "" },
        firstName: { value: "", errors: [] },
        lastName: { value: "", errors: [] },
        identificationNumber: { value: "", errors: [] },
        email: { value: "", errors: [] },
        lastEmail: { value: "", errors: [] },
        country: { value: "", label: "", errors: [] },
        region: { value: "", label: "", errors: [] },
        commune: { value: "", label: "", errors: [] },
        phoneNumber: { value: "", errors: [] },
        currentPosition: { value: "", errors: [] },
        password: { value: "", errors: [] }
      }
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const data = this.serializeData();

    saveCompanyEmployee(data, this.props.companyCentralId, response => {
      if (response.status === 201) {
        this.props.handleAddEmployee(response.data);
        this.props.toggle();
        this.handleCleanState();
        this.props.showNotification("Guardado", "Guardado exitoso", "success");
      } else if (response.status === 200) {
        this.props.handleUpdateEmployee(response.data);
        this.props.toggle();
        this.props.showNotification("Guardado", "Guardado exitoso", "success");
      } else {
        this.setErrors(response.data);
        this.props.showNotification("Guardado", "Ocurrio un error, revisa el formulario", "danger");
      }
    });
  };

  render() {
    const { companyEmployee } = this.state;

    return (
      <Form>
        <Row>
          <Col md="4">
            <FormGroup>
              <Label htmlFor="employeeFirstName" className="input_required">
                Nombres
              </Label>
              <FieldWrapper errors={companyEmployee.firstName.errors}>
                <Input
                  id="employeeFirstName"
                  name="firstName"
                  placeholder="Nombres"
                  type="text"
                  onChange={this.handleChangeCompanyEmployee}
                  value={companyEmployee.firstName.value}
                />
              </FieldWrapper>
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <Label htmlFor="employeeLastName" className="input_required">
                Apellidos
              </Label>
              <FieldWrapper errors={companyEmployee.lastName.errors}>
                <Input
                  id="employeeLastName"
                  name="lastName"
                  placeholder="Apellidos"
                  type="text"
                  onChange={this.handleChangeCompanyEmployee}
                  value={companyEmployee.lastName.value}
                />
              </FieldWrapper>
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <Label htmlFor="employeeIdentificationNumber">RUT</Label>
              <FieldWrapper errors={companyEmployee.identificationNumber.errors}>
                <Input
                  id="employeeIdentificationNumber"
                  name="identificationNumber"
                  placeholder="RUT"
                  type="text"
                  onChange={this.handleChangeCompanyEmployee}
                  maxLength={12}
                  value={format(companyEmployee.identificationNumber.value)}
                />
              </FieldWrapper>
            </FormGroup>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md="4">
            <FormGroup>
              <Label htmlFor="employeeEmail" className="input_required">
                Email
              </Label>
              <FieldWrapper errors={companyEmployee.email.errors}>
                <Input
                  id="employeeEmail"
                  name="email"
                  placeholder="Email"
                  type="text"
                  onChange={this.handleChangeCompanyEmployee}
                  value={companyEmployee.email.value}
                />
              </FieldWrapper>
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <Label htmlFor="employeeLastEmail">Email adicional</Label>
              <FieldWrapper errors={companyEmployee.lastEmail.errors}>
                <Input
                  id="employeeLastEmail"
                  name="lastEmail"
                  placeholder="Email adicional"
                  type="text"
                  onChange={this.handleChangeCompanyEmployee}
                  value={companyEmployee.lastEmail.value}
                />
              </FieldWrapper>
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <Label htmlFor="employeePhoneNumber" className="input_required">
                Teléfono
              </Label>
              <FieldWrapper errors={companyEmployee.phoneNumber.errors}>
                <Input
                  id="employeePhoneNumber"
                  name="phoneNumber"
                  placeholder="Teléfono"
                  type="text"
                  onChange={this.handleChangeCompanyEmployee}
                  value={companyEmployee.phoneNumber.value}
                />
              </FieldWrapper>
            </FormGroup>
          </Col>
        </Row>
        <hr />
        <Row>
          <TerritoryNestedSelects
            col="4"
            uniqueId="employee"
            handleChange={this.handleChangeCompanyEmployee}
            countryValue={companyEmployee.country}
            regionValue={companyEmployee.region}
            communeValue={companyEmployee.commune}
          />
        </Row>
        <hr />
        <Row>
          <Col md="4">
            <FormGroup>
              <Label htmlFor="currentPosition" className="input_required">
                Cargo
              </Label>
              <FieldWrapper errors={companyEmployee.currentPosition.errors}>
                <Input
                  id="currentPosition"
                  name="currentPosition"
                  placeholder="Cargo"
                  type="text"
                  onChange={this.handleChangeCompanyEmployee}
                  value={companyEmployee.currentPosition.value}
                />
              </FieldWrapper>
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <Label htmlFor="password" className="input_required">
                Contraseña
              </Label>
              <FieldWrapper errors={companyEmployee.password.errors}>
                <Input
                  id="password"
                  name="password"
                  type="password"
                  onChange={this.handleChangeCompanyEmployee}
                />
              </FieldWrapper>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md="12" className="text-right">
            <Button
              type="button"
              outline
              color="dark"
              className="my-4 mr-2"
              onClick={this.props.toggle}
            >
              Descartar
            </Button>
            <Button type="button" color="primary" className="my-4" onClick={this.handleSubmit}>
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default CompanyEmployeesForm;
