import axios from "axios";

export const saveLaboralExperience = (data, callback) => {
  let method,
    url = "";
  if (data.get("laboral_experience[id]")) {
    method = "patch";
    url = `/api/personal_laboral_experiences/${data.get("laboral_experience[id]")}`;
  } else {
    method = "post";
    url = "/api/personal_laboral_experiences";
  }

  axios({
    method: method,
    url: url,
    data: data,
    headers: {
      "X-CSRF-TOKEN": window.SomosEmpleos.token
    }
  })
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};

export const getAllLaboralExperiences = callback => {
  var promise = axios({
    method: "get",
    url: "/api/personal_laboral_experiences"
  });
  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};

export const deleteLaboralExperience = (id, callback) => {
  var promise = axios({
    method: "delete",
    url: `/api/personal_laboral_experiences/${id}`,
    headers: {
      "X-CSRF-TOKEN": window.SomosEmpleos.token
    }
  });
  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};

export const updateProfessionalInformation = (data, callback) => {
  var promise = axios({
    method: "put",
    url: "/api/profiles/update_professional_information",
    data: data,
    headers: {
      "X-CSRF-TOKEN": window.SomosEmpleos.token
    }
  });
  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};
