import React, { Component } from "react";

const MatchIndicator = ({ matchpercentage }) => {
  return (
    <div className="match-indicator">
      <div className="single-chart">
        <svg viewBox="0 0 36 36" className="circular-chart">
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" stopColor="#06dcf9" />
              <stop offset="100%" stopColor="#7455ff" />
            </linearGradient>
          </defs>
          <path
            className="circle-bg"
            d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <path
            className="circle"
            fill="none"
            strokeDasharray={`${matchpercentage}, 100`}
            stroke="url(#gradient)"
            d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <text x="18" y="23" className="small-info sr-only">
            Compatibilidad
          </text>
          <text x="18" y="19" className="percentage text-dark font-weight-bold">
            {matchpercentage}%
          </text>
          <text x="18" y="23" className="small-info" aria-hidden="true">
            Compatibilidad
          </text>
        </svg>
      </div>
    </div>
  );
};

export default MatchIndicator;
