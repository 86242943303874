import React, { useState } from "react";
import _ from "lodash";
import Switch from "react-switch";
import { drawAasmState } from "../../commons/Functions";
import DocumentDescriptorForm from "./Form";
import { switchState } from "./Axios";

const DocumentDescriptorHit = ({ index, descriptor, showNotification }) => {
  const [documentDescriptor, setDocumentDescriptor] = useState(descriptor);
  const [checked, setChecked] = useState(descriptor.aasm_state === "active" ? true : false);

  const updateHit = data => {
    setDocumentDescriptor(data);
  };

  const handleSwithAasmState = () => {
    let switch_state;
    if (checked) {
      switch_state = "desactivate";
    } else {
      switch_state = "activate";
    }
    var formData = new FormData();
    formData.append("switch_state", switch_state);
    switchState(descriptor.id, formData, response => {
      if (response.status == 200) {
        setChecked(!checked);
        updateHit(response.data);
        showNotification("Genial", "Estado cambiado con éxtio!", "success");
      } else if (response.status == 422) {
        showNotification("Ops", "Hubo un error al cambiar de estado!", "danger");
      }
    });
  };

  return (
    <tr>
      <td>{index}</td>
      <td>
        {documentDescriptor.name} {drawAasmState(documentDescriptor.aasm_state)}
      </td>
      <td>{documentDescriptor.description}</td>
      <td>{documentDescriptor.show_applicant ? "Si" : "No"}</td>
      <td>
        <ul>
          {_.map(documentDescriptor.allowed_file_types, type => (
            <li key={type.value}>{type.label}</li>
          ))}
        </ul>
      </td>
      <td>
        <Switch
          checked={checked}
          onChange={handleSwithAasmState}
          offColor="#ff3547"
          onColor="#00c851"
          onHandleColor="#00c851"
          offHandleColor="#ff3547"
          handleDiameter={30}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          height={15}
          width={43}
          className="react-switch align-middle"
          id="material-switch"
        />
      </td>
      <td>
        <DocumentDescriptorForm
          bgColor="warning"
          buttonText="Editar"
          descriptor={documentDescriptor}
          updateHit={updateHit}
          showNotification={showNotification}
          size="sm"
        />
      </td>
    </tr>
  );
};

export default DocumentDescriptorHit;
