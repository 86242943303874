import React, { Fragment, useState, useContext } from "react";
import { Row, Col, Label, Alert, Button } from "reactstrap";
import Select from "react-select";
import _ from "lodash";
import Moment from "react-moment";
import { CourseContext } from "../../course/CourseContext";
import { saveDocumentationRequest, rememberRequest } from "./Axios";

const DocumentationRequestForm = props => {
  const { stage, setApplicanttHit, showNotification, toggle, applicant } = props;
  const documentationRequest = stage ? stage.documentation_request : null;

  // Contexts
  const { descriptors } = useContext(CourseContext);

  // States
  const [documentsSelected, setDocumentsSelected] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChangeDocuments = newValue => {
    newValue ? setDocumentsSelected(newValue) : setDocumentsSelected([]);
  };

  const handleSubmit = () => {
    if (documentsSelected.length === 0) {
      setError(true);
      return;
    }

    setError(false);
    setLoading(true);

    let data = new FormData();
    data.append("documentation_request[documentation_stage_id]", stage.id);
    _.map(documentsSelected, document => {
      data.append("document_descriptors[]", document.value);
    });
    data.append("applicant_id", applicant.id);

    saveDocumentationRequest(data, response => {
      if (response.status === 201) {
        setApplicanttHit(response.data);
        showNotification("Genial", "Solicitud enviada exitosamente", "success");
        toggle();
      }
    });
  };

  const handleRememberRequest = () => {
    setLoading(true);
    rememberRequest(stage.documentation_request.id, response => {
      showNotification("Genial", "Recordatorio enviado exitosamente!", "success");
      setLoading(false);
    });
  };

  const drawRequestedDocuments = () => {
    const { requestedDocuments, requestedBy } = documentationRequest;
    return (
      <Fragment>
        <Row>
          <Col md="12">
            <p>
              <strong>Solitado por: </strong>
              <span>{requestedBy.email}</span>
            </p>
          </Col>
        </Row>
        {_.map(requestedDocuments, document => (
          <Row className="mb-3 align-middle" key={document.id}>
            <Col xs="10" lg="8" md="8">
              <div className="media">
                <i
                  className={`far fa-file fa-2x mr-3 mt-1 ${
                    document.document ? "text-success" : null
                  }`}
                />
                <div className="media-body">
                  <h4 className={`mt-1 mb-0 h5 ${document.document ? "text-success" : null}`}>
                    {document.document_descriptor.name}
                  </h4>
                  {document.document ? (
                    <p className="small text-success mb-1">
                      Fecha de Actualización{" "}
                      <Moment format="DD/MM/YYYY HH:mm">{document.updated_at}</Moment>
                    </p>
                  ) : null}
                </div>
              </div>
            </Col>
            <Col xs="12" sm="12" md="4" lg="4" className="mt-3 text-lg-right">
              {document.document && (
                <Button
                  color="info"
                  outline
                  size="sm"
                  href={document.document}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Descargar
                </Button>
              )}
            </Col>
          </Row>
        ))}
        <Row className="mt-3 text-right">
          <Col md="12">
            <Button size="sm" onClick={handleRememberRequest}>
              <i className="fas fa-file-alt" /> {loading ? "Solicitando" : "Reenviar Solicitud"}
              {loading ? <i className="fas fa-spinner fa-pulse ml-2"></i> : null}
            </Button>
          </Col>
        </Row>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <h5 className="font-weight-bold text-muted">Documentos a Solicitar</h5>
      {documentationRequest ? (
        drawRequestedDocuments()
      ) : (
        <Fragment>
          {error && <Alert color="danger">Debe seleccionar al menos un documento!</Alert>}
          <Row>
            <Col md="12">
              <Label>Seleccion los Documento a solicitar</Label>
              <Select
                isMulti
                placeholder="Seleccione Documentos"
                options={_.map(descriptors, descriptor => ({
                  value: descriptor.id,
                  label: descriptor.name
                }))}
                onChange={handleChangeDocuments}
              />
            </Col>
          </Row>
          <Row className="mt-3 text-right">
            <Col md="12">
              <Button size="sm" onClick={handleSubmit}>
                <i className="fas fa-file-alt" /> {loading ? "Solicitando" : "Enviar Solicitud"}
                {loading ? <i className="fas fa-spinner fa-pulse ml-2"></i> : null}
              </Button>
            </Col>
          </Row>
        </Fragment>
      )}
    </Fragment>
  );
};

export default DocumentationRequestForm;
