import React, { Fragment, useState, useRef } from "react";
import {
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Input,
  Label,
  FormGroup,
  Button
} from "reactstrap";
import _ from "lodash";
import Notifications from "../commons/Notifications";
import FieldWrapper from "../commons/FieldWrapper";
import { savePostulation } from "./Axios";

const PostulationForm = props => {
  const notificationsRef = useRef();
  const { publicationQuestions, publicationId } = props;
  const [isAlreadyPostulated, setIsAlreadyPostulated] = useState(
    props.isAlreadyPostulated
  );
  const [postulationAnswers, setPostulationAnswers] = useState(
    !isAlreadyPostulated
      ? _.map(publicationQuestions, question => ({
          questionId: question.id,
          value: "",
          errors: []
        }))
      : props.postulationAnswers
  );

  const handleChangeAnswer = (e, id) => {
    const newPostulationAnswers = [...postulationAnswers];
    newPostulationAnswers[id]["value"] = e.target.value;
    newPostulationAnswers[id]["errors"] = [];
    setPostulationAnswers(newPostulationAnswers);
  };

  const serializeData = () => {
    var formData = new FormData();
    formData.append(`minisite_postulation[id]`, "");
    if (publicationQuestions.length > 0) {
      _.map(publicationQuestions, (question, key) => {
        formData.append(
          `minisite_postulation[postulation_answers_attributes][${key}][answer]`,
          postulationAnswers[key].value
        );
        formData.append(
          `minisite_postulation[postulation_answers_attributes][${key}][publication_question_id]`,
          postulationAnswers[key].questionId
        );
      });
    }

    return formData;
  };

  const handleSubmit = e => {
    e.preventDefault();
    const data = serializeData();
    savePostulation(data, publicationId, response => {
      if (response.status == 201) {
        notificationsRef.current.addNotifications(
          "Excelente!",
          "Tu postulación has sido guardada con éxito!",
          "success"
        );
        setIsAlreadyPostulated(true);
      } else if (response.status === 422 && response.data.applicant) {
        notificationsRef.current.addNotifications(
          "Ops",
          response.data.applicant[0],
          "danger"
        );
      } else if (response.status === 422 && response.data.enrollment) {
        notificationsRef.current.addNotifications(
          "Ops",
          response.data.enrollment[0],
          "danger"
        );
      } else if (response.status === 422) {
        notificationsRef.current.addNotifications(
          "Ops",
          "Debe responder a todas las preguntas!",
          "danger"
        );
        const newPostulationAnswers = [...postulationAnswers];
        _.map(newPostulationAnswers, answer => {
          if (answer.value === "")
            answer["errors"].push("Debe ingresar una respuesta");
        });
        setPostulationAnswers(newPostulationAnswers);
      }
    });
  };

  return (
    <Fragment>
      <Notifications ref={notificationsRef} />
      {publicationQuestions.length > 0 && (
        <Row>
          <Col md="12">
            <h3 className="h6 font-weight-bold">
              <i className="fas fa-question mr-2"></i>
              Preguntas de la Publicación
            </h3>
            <ListGroup flush>
              {_.map(publicationQuestions, (question, key) => (
                <ListGroupItem key={key}>
                  <FormGroup>
                    <Label
                      for={`question${key + 1}`}
                      className="input_required"
                    >
                      {key + 1}.- {question.value}
                    </Label>
                    <FieldWrapper errors={postulationAnswers[key].errors}>
                      <Input
                        type="textarea"
                        placeholder="Escriba su Respuesta"
                        id={`question${key + 1}`}
                        onChange={e => handleChangeAnswer(e, key)}
                        value={postulationAnswers[key].value}
                        disabled={isAlreadyPostulated}
                      />
                    </FieldWrapper>
                  </FormGroup>
                </ListGroupItem>
              ))}
            </ListGroup>
          </Col>
        </Row>
      )}
      <Row>
        <Col md="12" className="text-center">
          {isAlreadyPostulated ? (
            <p className="alert alert-primary p-2">Gracias por postular!</p>
          ) : (
            <Button
              type="button"
              color="primary"
              className="my-3"
              onClick={handleSubmit}
            >
              Postular
            </Button>
          )}
        </Col>
      </Row>
    </Fragment>
  );
};

export default PostulationForm;
