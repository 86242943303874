import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { FadeLoader } from "react-spinners";
import NumberFormat from "react-number-format";

const PurchasesPendingCard = ({ aggregations }) => {
  if (aggregations && aggregations.sum_purchases_pending) {
    const sumPurchasesPending = aggregations.sum_purchases_pending.value;
    return (
      <Card className="material-shadow">
        <CardBody>
          <Row>
            <Col>
              <h5 className="card-title text-uppercase text-warning mb-0">Total Pendiente</h5>
              <span className="h3 text-muted mb-0">
                <NumberFormat
                  value={sumPurchasesPending}
                  displayType={"text"}
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix={"$ "}
                />
              </span>
            </Col>
            <div className="col-auto d-flex justify-content-center align-items-center">
              <i className="fa fa-3x fa-money-bill-wave text-warning" />
            </div>
          </Row>
        </CardBody>
      </Card>
    );
  } else {
    return (
      <Row>
        <Col md="12">
          <FadeLoader />
        </Col>
      </Row>
    );
  }
};

export default PurchasesPendingCard;
