import React, { Fragment } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import LimitedProductHit from "./LimitedHit";
import UnlimitedProductHit from "./UnlimitedHit";
import TrialProductHit from "./TrialHit";
import CustomProductForm from "./CustomForm";

const ProductsList = ({ products, currentUser, hasProductTrial, canEdit }) => {
  return (
    <Fragment>
      <Row className="text-center">
        {_.map(products, product => {
          if (product.type === "Product::Trial") {
            return (
              <TrialProductHit
                key={product.id}
                product={product}
                currentUser={currentUser}
                hasProductTrial={hasProductTrial}
                canEdit={canEdit}
              />
            );
          }
        })}
      </Row>
      <Row className="d-flex justify-contecnt-center align-items-stretch">
        {_.map(products, product => {
          if (product.type === "Product::Limited") {
            return (
              <LimitedProductHit
                key={product.id}
                product={product}
                currentUser={currentUser}
                canEdit={canEdit}
              />
            );
          }
        })}
      </Row>
      <Row className="text-center">
        {_.map(products, product => {
          if (product.type === "Product::Unlimited") {
            return (
              <UnlimitedProductHit
                key={product.id}
                product={product}
                canEdit={canEdit}
              />
            );
          }
        })}
      </Row>
      {!canEdit && (
        <Row>
          <Col md="12">
            <hr />
            <div className="d-flex justify-content-center align-items-center flex-wrap mb-4">
              <h2 className="text-center font-weight-bold h4 m-3">
                Formas de Pago
                <small className="d-block">Compra y paga en línea</small>
              </h2>
              <img
                src="/assets/flow/webpayplus-3172dbad8bd3a18eaafc309732fbfef72866c6784f8570c6e1a5637418ff943c.svg"
                className="img-fluid p-2"
                width="150"
              />
              <img
                src="/assets/flow/onepay-5a8f53a881507c988347548ac6b192e0bcef53e8d3828a6e73b248e00c9673e9.svg"
                className="img-fluid p-2"
                width="150"
              />
              <img
                src="/assets/flow/mach-836edea58aa29075036854458f48357edd578fbe5d2c98231fbcf568cf01eff4.svg"
                className="img-fluid p-2"
                width="150"
              />
              <img
                src="/assets/flow/servipag-2f7855f0a514fd9b224390671010b87a99e1e8d6f38d92e06584b012d02a8cc1.svg"
                className="img-fluid p-2"
                width="150"
              />
              <img
                src="/assets/flow/cryptocompra-2dbf7f422cdd4ad03d350028af20470178f1cd5cf56a2de777178609621ba738.svg"
                className="img-fluid p-2"
                width="150"
              />
            </div>
          </Col>
          <Col md={{ size: 10, offset: 1 }} className="p-lg-0">
            <Card className="mb-4 price-card with-ribbon promo border-0 py-2">
              <div className="ribbon ribbon-top-left ribbon-primary">
                <span>Custom!</span>
              </div>
              <CardBody className="text-center">
                <Row className="d-flex justify-content-center align-items-center ">
                  <Col sm="12" lg="4" className="text-center">
                    <h4 className="card-title clearfix">
                      <span className="font-weight-bold product-name h4">
                        Plan Personalizado
                      </span>
                      <br />
                      <span className="h2 price font-weight-bold">
                        Personalizado
                      </span>
                    </h4>
                  </Col>
                  <Col sm="12" lg="5">
                    <ul className="list-group list-group-flush text-left">
                      <li> Buscas otra modalidad de Pago?</li>
                      <li> Requieres otro tipo de Plan para tu Empresa?</li>
                      <li>Escribenos y con gusto te ayudaremos</li>
                    </ul>
                  </Col>
                  <Col sm="12" lg="3" size="12" className="text-center">
                    <CustomProductForm />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

export default ProductsList;
