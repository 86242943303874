import axios from "axios";

export const saveCourse = (data, callback) => {
  let method, url;

  if (data.get("course[id]")) {
    method = "patch";
    url = `/api/courses/${data.get("course[id]")}`;
  } else {
    method = "post";
    url = "/api/courses";
  }

  var promise = axios({
    method: method,
    url: url,
    data: data,
    headers: {
      "X-CSRF-TOKEN": window.SomosEmpleos.token
    }
  });
  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};

export const switchState = (data, callback) => {
  let method, url;
  method = "put";
  url = `/api/publication_bases/${data.publication_id}/switch_state`;
  var promise = axios({
    method: "put",
    url: url,
    data: data,
    headers: {
      "X-CSRF-TOKEN": window.SomosEmpleos.token
    }
  });
  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};
