import axios from "axios";

export const savePersonalEducation = (data, callback) => {
  let method,
    url = "";
  if (data.get("personal_education[id]")) {
    method = "patch";
    url = `/api/personal_educations/${data.get("personal_education[id]")}`;
  } else {
    method = "post";
    url = "/api/personal_educations";
  }

  axios({
    method: method,
    url: url,
    data: data,
    headers: {
      "X-CSRF-TOKEN": window.SomosEmpleos.token
    }
  })
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};

export const getAllPersonalEducations = callback => {
  var promise = axios({
    method: "get",
    url: "/api/personal_educations"
  });
  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};

export const deletePersonalEducation = (id, callback) => {
  var promise = axios({
    method: "delete",
    url: `/api/personal_educations/${id}`,
    headers: {
      "X-CSRF-TOKEN": window.SomosEmpleos.token
    }
  });
  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};
