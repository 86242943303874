import React, { Fragment, useState } from "react";
import { SearchkitManager, SearchkitProvider, Hits, NoHits, Pagination } from "searchkit";
import _ from "lodash";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Row, Col, Button } from "reactstrap";
import CompanyCreditsHits from "./Hits";
import { saveCompanyCredits } from "./Axios";

const CompanyCreditsIndex = ({ companyCentralId, routeCredits }) => {
  const [searchkit, setSearchkit] = useState(
    new SearchkitManager(routeCredits, {
      httpHeaders: { "X-CSRF-TOKEN": window.SomosEmpleos.token }
    })
  );

  const addCredits = () => {
    Swal.fire({
      title: "Cuanto créditos desea agregar?",
      input: "number",
      inputAttributes: {
        autocapitalize: "off",
        min: 1,
        required: true
      },
      showCancelButton: true,
      confirmButtonText: "Agregar",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#d33",
      showLoaderOnConfirm: true,
      allowOutsideClick: false,
      preConfirm: value => {
        let formData = new FormData();
        formData.append("credits", value);
        saveCompanyCredits(companyCentralId, formData, () => {
          reloadQuery();
        });
        return new Promise(resolve => {
          setTimeout(() => {
            resolve();
          }, 4000);
        });
      }
    }).then(result => {
      if (result.value) {
        Swal.fire({
          type: "success",
          title: "Genial!",
          text: "Créditos creados con éxito!"
        });
      }
    });
  };

  const reloadQuery = () => {
    const newSearchkit = searchkit;
    setTimeout(function() {
      searchkit.reloadSearch();
    }, 1000);
    setSearchkit(newSearchkit);
  };

  return (
    <SearchkitProvider searchkit={searchkit}>
      <Fragment>
        <Row className="text-right mb-3">
          <Col md="12">
            <Button color="success" size="sm" onClick={addCredits}>
              Agregar Créditos
            </Button>
          </Col>
        </Row>
        <Hits
          hitsPerPage={5}
          listComponent={<CompanyCreditsHits companyCentralId={companyCentralId} />}
        />
        <NoHits
          translations={{
            "NoHits.NoResultsFound": "No se encontraron resultados para {query}",
            "NoHits.DidYouMean": "Busqueda para {suggestion}",
            "NoHits.SearchWithoutFilters": "Busqueda para {query} sin filtros",
            "NoHits.NoResultsFoundDidYouMean":
              "No se encontraron resultados para {query}. Quisiste decir {suggestion} ?"
          }}
        />
        <div className="mt-3">
          <Pagination
            showNumbers={true}
            translations={{
              "pagination.previous": "Anterior",
              "pagination.next": "Siguiente"
            }}
          />
        </div>
      </Fragment>
    </SearchkitProvider>
  );
};

export default CompanyCreditsIndex;
