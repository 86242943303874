import React, { Fragment, useState, useEffect, useRef } from "react";
import { Card, CardBody, Table, Row, Col } from "reactstrap";
import _ from "lodash";
import DocumentDescriptorHit from "./Hit";
import DocumentDescriptorForm from "./Form";
import Notifications from "../../commons/Notifications";

const DocumentDescriptorsIndex = ({ documentDescriptors }) => {
  const [descriptors, setDescriptors] = useState([]);
  const notifications = useRef(null);

  useEffect(() => {
    setDescriptors(documentDescriptors);
  }, []);

  const showNotification = (title, message, type) => {
    notifications.current.addNotifications(title, message, type);
  };

  const addDescriptor = descriptor => {
    const newDescriptors = [...descriptors, descriptor];
    setDescriptors(newDescriptors);
  };

  return (
    <Fragment>
      <Notifications ref={notifications} />
      <Card className="mb-4 material-shadow">
        <CardBody className="bg-light">
          <Row className="d-flex align-items-center">
            <Col md="8">
              <div className="d-block mb-1 d-flex align-items-center">
                <i className="fas fa-folder-open fa-pull-left h4 mb-0" />
                <h3 className="h4 mb-0 d-inline-block">Documentos Solicitados</h3>
              </div>
              <p className="small text-muted mb-sm-0">
                Listado de Documentos solicitados a los Candidatos
              </p>
            </Col>
            <Col md="4" className="text-right">
              <DocumentDescriptorForm
                bgColor="success"
                buttonText="Nuevo"
                showNotification={showNotification}
                addDescriptor={addDescriptor}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card className="material-shadow">
        <CardBody>
          <Table>
            <thead>
              <tr>
                <th>#</th>
                <th>Nombre</th>
                <th>Descripción</th>
                <th>Pefil Candidato</th>
                <th>Estensiones Permitidas</th>
                <th>Estado</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {_.map(descriptors, (descriptor, index) => (
                <DocumentDescriptorHit
                  key={descriptor.id}
                  index={index + 1}
                  descriptor={descriptor}
                  showNotification={showNotification}
                />
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default DocumentDescriptorsIndex;
