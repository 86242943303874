import React, { Component } from "react";
import { Row, Col, Button, Card, CardBody, Badge } from "reactstrap";
import Switch from "react-switch";
import { switchState, switchAccessApi } from "./Axios";
import { drawAasmState } from "../../commons/Functions";

class CompanyEmployeesHit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      companyEmployee: this.props.companyEmployee
    };
  }

  updateHit = data => {
    this.setState({ companyEmployee: data });
  };

  handleSwithAasmState = () => {
    const { companyCentralId } = this.props;
    const { companyEmployee } = this.state;
    let switch_state;
    if (companyEmployee.aasmState === "active") {
      switch_state = "desactivate";
    } else {
      switch_state = "activate";
    }
    var formData = new FormData();
    formData.append("switch_state", switch_state);
    switchState(companyCentralId, companyEmployee.id, formData, response => {
      if (response.status == 200) {
        this.updateHit(response.data);
        this.props.showNotification(
          "Genial",
          "Estado cambiado con éxtio!",
          "success"
        );
      } else if (response.status == 422) {
        this.props.showNotification(
          "Ops",
          "Debe completar la información del Empleado!",
          "danger"
        );
      }
    });
  };

  handleSwitchAccessApi = value => {
    const { companyCentralId } = this.props;
    const { companyEmployee } = this.state;

    let formData = new FormData();
    formData.append("access_api", value);
    switchAccessApi(
      companyCentralId,
      companyEmployee.id,
      formData,
      response => {
        if (response.status == 200) {
          this.updateHit(response.data);
          this.props.showNotification(
            "Genial",
            "Acceso API cambiado con éxtio!",
            "success"
          );
        } else if (response.status == 422) {
          this.props.showNotification("Ops", "Hubo un error!", "danger");
        }
      }
    );
  };

  render() {
    const { companyEmployee } = this.state;

    return (
      <Card className="bg-light">
        <CardBody>
          <div className="hit-actions float-right text-right">
            <Button
              outline
              color="info"
              aria-label="Editar"
              title="Editar"
              className="btn-circle d-block mb-2"
              onClick={() => this.props.handleEditEmployee(companyEmployee)}
            >
              <i className="fas fa-edit"></i>
            </Button>
          </div>
          <Row>
            <Col lg="6">
              <h3 className="font-weight-bold h6 mb-3 mb-lg-0">
                <span className="d-block">
                  {companyEmployee.user.personalInformation.firstName}{" "}
                  {companyEmployee.user.personalInformation.lastName}
                </span>
                <small className="text-muted d-block mb-2">
                  <i className="fas fa-user-tie mr-2" />
                  {companyEmployee.currentPosition}
                </small>

                {drawAasmState(companyEmployee.aasmState)}
              </h3>
              <Switch
                checked={companyEmployee.aasmState === "active"}
                onChange={this.handleSwithAasmState}
                offColor="#ff3547"
                onColor="#00c851"
                onHandleColor="#00c851"
                offHandleColor="#ff3547"
                handleDiameter={30}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={15}
                width={43}
                className="react-switch align-middle mt-1"
                id="material-switch"
              />
            </Col>
            <Col lg="4">
              <ul className="list-unstyled small mb-lg-0">
                <li>
                  Rut: {companyEmployee.user.identificationDocumentNumber}
                </li>
                <li>Email: {companyEmployee.user.email}</li>
                <li>
                  Email Adicional:{" "}
                  {companyEmployee.user.personalInformation.lastEmail}
                </li>
                <li>
                  Teléfono:{" "}
                  {companyEmployee.user.personalInformation.phoneNumber}
                </li>
              </ul>
              <h3 className="font-weight-bold h6 mb-3 mb-lg-0 mt-3">
                <span className="d-block ">API</span>
                <small className="text-muted d-block mb-2">
                  <i className="fas fa-key mr-2" />
                  {companyEmployee.user.token}
                </small>
                <small className="text-muted d-block mb-2 align-middle">
                  <i className="fas fa-laptop-code mr-2" />
                  Acceso
                  <Switch
                    checked={companyEmployee.accessApi}
                    onChange={this.handleSwitchAccessApi}
                    height={10}
                    width={30}
                    className="react-switch align-middle ml-1"
                    id="material-switch"
                    offColor="#ff3547"
                    onColor="#00c851"
                    onHandleColor="#00c851"
                    offHandleColor="#ff3547"
                    handleDiameter={20}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  />
                </small>
              </h3>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default CompanyEmployeesHit;
