import React, { Component } from "react";
import { Row, Col, Button, Collapse } from "reactstrap";
import EducationForm from "./Form";
import EducationHit from "./Hit";
import { getAllPersonalEducations } from "./Axios";

class EducationsIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      personalEducations: [],
      collapse: false,
      personalEducation: null
    };

    this.handleUpdateHit = this.handleUpdateHit.bind(this);
  }

  componentDidMount() {
    this.getPersonalEducations();
  }

  getPersonalEducations = () => {
    getAllPersonalEducations(response => {
      this.setState({
        ...this.state,
        personalEducations: response.data
      });
    });
  };

  toggle = () => {
    const { collapse } = this.state;

    this.setState({
      ...this.state,
      collapse: !collapse
    });
    if (collapse) {
      this.setState({
        personalEducation: null
      });
      this.refs.educationForm.handleCleanState();
    }
  };

  addPersonalEducation = data => {
    this.setState({
      ...this.state,
      personalEducations: [data, ...this.state.personalEducations]
    });
  };

  removePersonalEducation = id => {
    const currentPersonalEducations = [...this.state.personalEducations];
    const personalEducations = currentPersonalEducations.filter(
      personalEducation => personalEducation.id !== id
    );
    this.setState({ personalEducations });
  };

  handleEditPersonalEducation = personalEducation => {
    this.setState({ personalEducation, collapse: true });
  };

  handleUpdateHit = data => {
    this.refs[`educationHit-${data.id}`].updateHit(data);
  };

  render() {
    const { personalEducations, collapse, personalEducation } = this.state;

    return (
      <Row>
        <Col md="12">
          <Collapse isOpen={collapse} className="collapse-border">
            <EducationForm
              handleUpdateHit={this.handleUpdateHit}
              ref="educationForm"
              toggle={this.toggle}
              addPersonalEducation={this.addPersonalEducation}
              personalEducation={personalEducation}
              show_notification={this.props.show_notification}
            />
          </Collapse>
          <Col md="12" className="text-right">
            {!this.state.collapse && (
              <Button outline color="primary" className="my-2" onClick={this.toggle}>
                <i className="fas fa-plus mr-2"></i>
                Agregar Estudios
              </Button>
            )}
          </Col>
          {personalEducations.map(education => (
            <EducationHit
              ref={`educationHit-${education.id}`}
              key={education.id}
              personalEducation={education}
              handleEditPersonalEducation={this.handleEditPersonalEducation}
              removePersonalEducation={this.removePersonalEducation}
            />
          ))}
          <Row>
            <Col md="12 text-right">
              <Button
                color="primary"
                className="my-4"
                onClick={() => this.props.toggleTab("laboralinfo")}
              >
                Continuar
                <i className="fas fa-arrow-right ml-2"></i>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default EducationsIndex;
