import React, { Fragment, useState, useRef } from "react";
import { Row, Col, Nav, NavItem, NavLink, Card, CardBody, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import CompanyCentralsForm from "./Form";
import Notifications from "../../commons/Notifications";
import Navigation from "../../commons/Navigation";
import CompanyEmployeesIndex from "../employees/Index";
import CompanyCreditsIndex from "../credits/Index";

const availableTabs = ["companyInfo"];

const CompanyCentralsDashboard = ({ companyCentral }) => {
  const notifications = useRef(null);

  const [activeTab, setActiveTab] = useState("companyInfo");

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const showNotification = (title, message, type) => {
    notifications.current.addNotifications(title, message, type);
  };

  return (
    <div>
      <Notifications ref={notifications} />
      <Row id="profile-panel-forms">
        <Col md="12">
          <Nav pills className="perfil-tabs-navigation text-center steps mb-3" role="navigation">
            <NavItem role="presentation">
              <NavLink
                className={
                  "d-flex align-items-center " +
                  classnames({
                    active: activeTab === "companyInfo"
                  })
                }
                onClick={() => {
                  toggleTab("companyInfo");
                }}
                href="#companyInfo"
                aria-controls="companyInfo"
                role="tab"
                data-toggle="tab"
                id="companyInfo-tab"
                aria-label="Datos Empresa y/o Fundación"
              >
                <span className="step-content-link">
                  <i className="fas mr-2 fa-city" aria-hidden="true"></i>
                  <span className="d-none d-sm-inline-block">Datos Empresa y/o Fundación</span>
                  <div className="input_required"></div>
                </span>
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>

      <Card className="mb-5">
        <Navigation currentTab={activeTab} availableTabs={availableTabs} toggleTab={toggleTab} />
        <CardBody className="pt-3">
          <TabContent activeTab={activeTab} id="pills-tabContent">
            <TabPane id="companyInfo" tabId="companyInfo" aria-labelledby="companyInfo-tab">
              <h2 className="tab-pane-title text-muted font-weight-bold">
                <i className="fas mr-2 fa-city" aria-hidden="true"></i>
                <small className="">Datos Empresa y/o Fundación</small>
                <hr />
              </h2>
              <CompanyCentralsForm
                showNotification={showNotification}
                toggleTab={toggleTab}
                companyCentral={companyCentral}
              />
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </div>
  );
};

export default CompanyCentralsDashboard;
