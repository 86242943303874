import React, { useState } from "react";
import { Row, Col, Button, Card, CardBody } from "reactstrap";
import Moment from "react-moment";
import Swal from "sweetalert2";
import ApplicantsIndex from "../applicant/Index";
import { drawAasmState } from "../commons/Functions";
import { switchCourseState } from "./Axios";
import CourseButtonTools from "./ButtonTools";
import CourseProvider from "./CourseContext";

const CourseShow = ({ course, route, workFlow, documentDescriptors }) => {
  const [aasmState, setAasmState] = useState(course.aasmState);

  const handleCloseCourse = () => {
    Swal.fire({
      title: "Estás seguro?",
      text: `El proceso se va a cerrar, esto desactivará la oferta laboral del portal.`,
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Si, cerrar`,
      cancelButtonText: "Cancelar"
    }).then(result => {
      if (result.value) {
        var formData = new FormData();
        formData.append("switch_state", "close");
        try {
          switchCourseState(course.id, formData, response => {
            if (response.status === 200) {
              Swal.fire(`Cerrado!`, `El proceso ha sido cerrado exitosamente!.`, "success");
              setAasmState(response.data.aasm_state);
            } else if (response.status === 403) {
              Swal.fire({
                type: "error",
                title: "Oops...",
                text: "No tienes los permisos suficientes para realizar esta acción!"
              });
            }
          });
        } catch (error) {
          Swal.fire({
            type: "error",
            title: "Oops...",
            text: "Hubo un error, vuelve a intentarlo!"
          });
        }
      }
    });
  };

  return (
    <div>
      <CourseProvider documentDescriptors={documentDescriptors}>
        <Card className="mb-4 publication-full-card bg-light">
          <CardBody>
            <Row>
              <Col lg="10" md="8">
                <h2 className="h4 mb-3">
                  {course.title}&nbsp;
                  <div className="d-inline-block badge-indicator">{drawAasmState(aasmState)}</div>
                  <small className="d-inline-block text-muted d-block">
                    <strong>Área:&nbsp;</strong>
                    {course.laboralArea.name}
                  </small>
                </h2>
                <div className="media mb-4 mb-md-0 d-flex align-items-start flex-wrap">
                  <img
                    src={course.laboralArea.attachment}
                    className="img-fluid mb-2 mb-lg-0 mr-3 area-icon"
                    alt={course.laboralArea.name}
                  />
                  <div className="media-body">
                    <ul className="fa-ul list-unstyled mb-0 ml-4 publication-detail-list">
                      <li>
                        <span className="fa-li">
                          <i
                            aria-hidden="true"
                            className="far fa-calendar-alt"
                            title="Fecha de Publicación"
                          ></i>
                        </span>
                        <Moment format="DD/MM/YYYY">{course.createdAt}</Moment>
                      </li>
                      <li>
                        <span className="fa-li">
                          <i aria-hidden="true" className="fas fa-briefcase" title="Contrato"></i>
                        </span>
                        Contrato{" "}
                        {I18n.t(
                          `activerecord.enums.course.contract_types.${course.contractType.value}`
                        )}
                      </li>
                      <li>
                        <span className="fa-li">
                          <i
                            aria-hidden="true"
                            className="fas fa-business-time"
                            title="Jornada, Horarios y/o Turnos"
                          ></i>
                        </span>
                        {I18n.t(
                          `activerecord.enums.publication/base.preferred_schedules.${course.minisitePublication.preferredSchedule}`
                        )}
                        {". "}
                        {I18n.t(
                          `activerecord.enums.publication/base.preferred_labor_days.${course.minisitePublication.preferredLaborDay}`
                        )}
                        {". "}
                        {I18n.t(
                          `activerecord.enums.publication/base.preferred_turns.${course.minisitePublication.preferredTurn}`
                        )}
                        {". "}
                        {I18n.t(
                          `activerecord.enums.publication/base.dress_code.${course.minisitePublication.dressCode}`
                        )}
                      </li>
                      <li>
                        <span className="fa-li">
                          <i aria-hidden="true" className="fas fa-users" title="Vacantes"></i>
                        </span>
                        {course.vacancies} Vacantes
                      </li>
                      <li>
                        <span className="fa-li">
                          <i
                            aria-hidden="true"
                            className="fas fa-map-marker-alt"
                            title="Ubicación"
                          ></i>
                        </span>
                        {course.minisitePublication.address}.{" "}
                        {course.minisitePublication.commune.name},{" "}
                        {course.minisitePublication.region.name}.{" "}
                        {course.minisitePublication.country.name}.
                      </li>
                      <li>
                        <span className="fa-li">
                          <i
                            className="fas fa-universal-access"
                            aria-hidden="true"
                            title="Infraestructura"
                          ></i>
                        </span>
                        Infraestructura Accesible
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col lg="2" md="4" className="text-right">
                <div className="flex-fill text-right">
                  <CourseButtonTools course={course} handleCloseCourse={handleCloseCourse} />
                  <Button
                    size="sm"
                    color="primary"
                    className="mr-1 mb-2 mb-sm-0 mt-2"
                    aria-label="Ver Publicación"
                    title="Ver Publicación"
                    href={`/publications/${course.minisitePublication.slug}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ver Publicación
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <ApplicantsIndex route={route} isPostulation courseId={course.id} workFlow={workFlow} />
      </CourseProvider>
    </div>
  );
};

export default CourseShow;
