import React, { useState, useEffect } from "react";
import _ from "lodash";
import Select from "react-select";
import { getTerritoryCountries } from "./Axios";

const CountriesSelect = ({ uniqueId, value, handleChange }) => {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    getTerritoryCountries(response => {
      const newCountries = [];
      _.map(response.data, country => {
        newCountries.push({ value: country.id, label: country.name });
      });
      setCountries(newCountries);
    });
  }, []);

  return (
    <Select
      inputId={`${uniqueId}`}
      onChange={e => handleChange(e, "country")}
      options={countries}
      placeholder="Seleccione una opción"
      value={value.value ? value : null}
    />
  );
};

export default CountriesSelect;
