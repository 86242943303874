import React from "react";
import { Row } from "reactstrap";
import _ from "lodash";
import CompanyCentralsHit from "./Hit";

const CompanyCentralsHits = ({ hits, showNotification }) => {
  return (
    <Row>
      {_.map(hits, hit => (
        <CompanyCentralsHit key={hit._id} hit={hit} showNotification={showNotification} />
      ))}
    </Row>
  );
};

export default CompanyCentralsHits;
