import React, { useState } from "react";
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

const CourseButtonTools = ({ course, handleCloseCourse }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  return (
    <ButtonDropdown
      isOpen={dropdownOpen}
      toggle={toggleDropdown}
      className="mr-sm-1 mb-2 mb-sm-0"
      aria-label="Administrar Publicación"
      title="Administrar Publicación"
    >
      <DropdownToggle caret color="secondary" size="sm">
        <i className="fas fa-cogs mr-2" aria-hidden="true"></i>
        Administrar
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem>
          <a href={`/courses/${course.id}/edit`}>Editar Publicación</a>
        </DropdownItem>
        <DropdownItem>
          <a href={`/courses/${course.id}/download_postulations_report.xlsx`}>Descargar Postulantes</a>
        </DropdownItem>
        {(course.aasm_state === "opened" || course.aasmState === "opened") && (
          <DropdownItem onClick={handleCloseCourse}>Cerrar Proceso</DropdownItem>
        )}
      </DropdownMenu>
    </ButtonDropdown>
  );
};

export default CourseButtonTools;
