import React from "react";
import { Card, CardBody } from "reactstrap";
import AccessibilityRequirements from "./accessibility_requirements/Form";

const AccessibilityPublicationForm = ({ course, toggleTab, showNotification }) => {
  return (
    <div>
      <Card className="mb-5">
        <CardBody className="pt-3">
          <AccessibilityRequirements
            accessibility_answers={course.accessibilityAnswers}
            toggleTab={toggleTab}
            isCourse
            showNotification={showNotification}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default AccessibilityPublicationForm;
