import React, { Fragment, useRef } from "react";
import { Table, Row, Col, Card, CardBody, Button } from "reactstrap";
import NumberFormat from "react-number-format";
import { confirmPurchase } from "./Axios";
import Notifications from "../../commons/Notifications";

const CompanyPurchasesNew = ({ product }) => {
  const notification = useRef(null);
  const drawNewPrice = () => {
    const { price, discount } = product;
    const dsc = discount / 100;
    const factor = 1 - dsc;
    return (
      <NumberFormat
        value={price * factor}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$ "}
        thousandSeparator="."
        decimalSeparator=","
        decimalScale="0"
      />
    );
  };

  const handleSubmitPurchase = () => {
    let formData = new FormData();
    formData.append("company_purchase[product_base_id]", product.id);

    confirmPurchase(formData, response => {
      if (response.status === 201) {
        window.location.href = `/purchases/${response.data.id}`;
      } else if (response.status === 422) {
        notification.current.addNotifications("Ops", response.data.product[0], "danger");
      }
    });
  };

  return (
    <Fragment>
      <Notifications ref={notification} />
      <h4 className="mt-2 mb-4 text-primary">Confirma tu Compra</h4>
      <hr />
      <Row>
        <Col md={{ size: "8", offset: 2 }} className="p-lg-0">
          <Card className="mb-4 price-card with-ribbon promo border-0 py-2">
            <CardBody className="text-center">
              <Row className="">
                <Col sm="5" lg="6">
                  <h4 className="card-title clearfix">
                    <span className="font-weight-bold product-name h4">{product.name}</span>
                    <br />
                    <span className="h2 price font-weight-bold">
                      {drawNewPrice()}
                      <span className="h6 font-weight-bold d-block">CLP + IVA</span>
                    </span>
                  </h4>
                  {product.discount && (
                    <h6 className="font-weight-light text-muted">
                      <del>
                        <NumberFormat
                          value={product.price}
                          displayType={"text"}
                          thousandSeparator="."
                          decimalSeparator=","
                          prefix={"$ "}
                        />{" "}
                        CLP + IVA
                      </del>
                    </h6>
                  )}
                </Col>
                <Col sm="7" lg="6">
                  <ul className="list-group list-group-flush text-left">
                    {product.discount && <li>{product.discount}% dcto.</li>}
                    <li> {product.name} con vigencia de hasta 30 días</li>
                    <li> Pack vigencia de 1 año</li>
                  </ul>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <hr />
      <Row className="text-center">
        <Col md="12">
          <Button color="success" onClick={handleSubmitPurchase}>
            Confirmar Compra
          </Button>{" "}
          <Button href="/planes" color="primary">
            Volver a Planes
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
};

export default CompanyPurchasesNew;
