import React, { Fragment, useState } from "react";
import { Card, CardBody, CardTitle, Col } from "reactstrap";
import Switch from "react-switch";
import NumberFormat from "react-number-format";
import EditProduct from "./Edit";
import { switchState } from "./Axios";
import ModalAccount from "./ModalAccount";

const LimitedProductHit = ({ product, canEdit, currentUser }) => {
  const [productHit, setProductHit] = useState(product);

  const handleSwithAasmState = () => {
    let switch_state;
    if (productHit.aasm_state === "active") {
      switch_state = "desactivate";
    } else {
      switch_state = "activate";
    }
    var formData = new FormData();
    formData.append("switch_state", switch_state);
    switchState(product.id, formData, response => {
      if (response.status == 200) {
        updateProduct(response.data);
      }
    });
  };

  const updateProduct = data => {
    setProductHit(data);
  };

  const drawNewPrice = () => {
    const { price, discount } = productHit;
    const dsc = discount / 100;
    const factor = 1 - dsc;
    return (
      <NumberFormat
        value={price * factor}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$ "}
        thousandSeparator="."
        decimalSeparator=","
        decimalScale="0"
      />
    );
  };

  return (
    <Col
      size="12"
      md="6"
      lg="3"
      className="p-lg-0 d-flex align-items-stretch justify-content-center"
    >
      <Card
        className="mb-4 price-card border-0 align-items-center"
        style={{ borderRadius: "10px" }}
      >
        {productHit.discount && (
          <div className="promo-badge text-warning">
            <i className="fas fa-certificate" aria-hidden="true" />
            <span className="discount rs_skip">{productHit.discount}% dcto</span>
            <span className="discount sr-only">{productHit.discount}% de descuento</span>
          </div>
        )}
        <CardTitle className="text-center mt-3">
          <h4 className="card-title clearfix" />
          <span className="font-weight-bold product-name">
            {productHit.name}
          </span>
          <br />
          <span className="h2 price font-weight-bold">
            {drawNewPrice()}
            <span className="h6 font-weight-bold d-block">CLP + IVA</span>
          </span>
          {productHit.discount && (
            <h6 className="font-weight-light small text-muted">
              <span className="sr-only">Antes</span>
              <del>
                <NumberFormat
                  value={productHit.price}
                  displayType={"text"}
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix={"$ "}
                />{" "}
                CLP + IVA
              </del>
            </h6>
          )}
        </CardTitle>
        <CardBody className="text-center pt-0">
          <div className="text-center">
            <ul className="list-group list-group-flush text-left">
              <li>
                {" "}
                {productHit.quantity}{" "}
                {productHit.quantity > 1 ? "Publicaciones" : "Publicación"} con
                vigencia de hasta 30 días
              </li>
              <li> Plan vigencia de 1 año</li>
            </ul>
          </div>
        </CardBody>
        {currentUser ? (
          <a
            className="btn btn-primary btn-sm px-4 font-weight-bold text-wrap d-inline"
            href={`/purchases/new?product_id=${productHit.id}`}
          >
            Comprar
          </a>
        ) : currentUser ? null : (
          <ModalAccount product={productHit} />
        )}
        {/*
          <a
            className="btn btn-primary btn-sm px-4 font-weight-bold text-wrap d-inline"
            href={`/purchases/new?product_id=${productHit.id}`}
          >
            Comprar
          </a>
        */}
        {canEdit && (
          <div className="products-actions-container text-left d-block bg-light rounded-pill border">
            <Fragment>
              <EditProduct
                product={productHit}
                updateProduct={updateProduct}
              />{" "}
              <Switch
                checked={productHit.aasm_state === "active"}
                onChange={handleSwithAasmState}
                offColor="#ff3547"
                onColor="#00c851"
                onHandleColor="#00c851"
                offHandleColor="#ff3547"
                handleDiameter={20}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={10}
                width={30}
                className="react-switch align-middle"
                id="material-switch"
              />
            </Fragment>
          </div>
        )}
      </Card>
    </Col>
  );
};

export default LimitedProductHit;
